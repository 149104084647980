import React from 'react';
import { Flex, Button, Text, Icon } from "@aws-amplify/ui-react";
import { Link, Navigate } from 'react-router-dom';
import { MdClose } from 'react-icons/md';

const PopUpTemplate = function(props) {
	try {
		const popUpText = props.text;
		const yesButtonFunction = props.yesButtonFunction;
		const yesButtonProps = props.yesButtonProps;
		const noButtonFunction = props.noButtonFunction;
		const okButtonFunction = props.okButtonFunction;
		let reloadOnDismiss = props.reloadOnDismiss;
		if (reloadOnDismiss == null) {
			reloadOnDismiss = false;
		}
		const showXButton = props.showXButton;

		const form = props.form;

		const text = <Text color="#08007d" fontWeight={"medium"} fontSize={"20px"}>
			{popUpText}
		</Text>

		const yesButton = <Button
			key={"yesButton"}
			children="Yes"
			type="button"
			variation="secondary"
			size="large"
			onClick={() => {
			yesButtonFunction(yesButtonProps)
			props.setPopUpVisibility(false);
			}}
		></Button>

		const noButton = <Button
			key={"noButton"}
			children="No"
			type="button"
			variation="secondary"
			size="large"
			onClick={() => {
			noButtonFunction();
			props.setPopUpVisibility(false);
			}}
		></Button>
		
		const okButton = <Button
			key={"okButton"}
			children="Ok"
			type="button"
			variation="secondary"
			size="large"
			onClick={() => {
			okButtonFunction();
			props.setPopUpVisibility(false);
			}}
		/>


		const mainButtons = [];
		if (yesButtonFunction != null) {
			mainButtons.push(yesButton);
		}
		if (noButtonFunction != null) {
			mainButtons.push(noButton);
		}
		if (okButtonFunction != null) {
			mainButtons.push(okButton);
		}

		const customButtons = props.customButtons || [];
		for (const button of customButtons) {
			const buttonObject = <Button
			key={button.text}
			children={button.text}
			type="button"
			variation="secondary"
			size="large"
			onClick={() => {
				button.onClickFunction();
			}}
			isDisabled={button.isDisabled}
			></Button>
			mainButtons.push(buttonObject);
		}

		const xButton = <Icon
			key={"closeButton"}
			width={"30px"}
			height={"30px"}
			as={MdClose}
			color={"#000000"}
			position={"absolute"}
			right={"0px"}
			top={"0px"}
			//backgroundColor={"#ff0202"}
			borderRadius={"50%"}
			border={"2px solid #ff0000"}
			onClick={() => {
				props.setPopUpVisibility(false)
			}}
			className={"HoverBackground"}
			style={{
				"--backgroundColour": "#ff0202",
				"--hoverBackgroundColour": "#eb876c"
			}}
		/>
		
		const optionalButtons = [];
		if (showXButton === true) {
			optionalButtons.push(xButton);
		}
		
		let positionType = "fixed";
		if (props.positionType != null) {
			positionType = props.positionType;
		}

		return (
			<Flex 
				direction={"column"}
				position={positionType}
				width="fit-content"
				maxWidth={"80vw"}
				background="#00000050"
				style={{
					borderRadius: "1vh",
					background: "#cbefff",
					border: "0.3vh solid #999",
					zIndex: 3,
				}}
				top={"45%"}
				transform={"translateY(-50%)"}
			>
				<Flex
					position={"absolute"}
					justifyContent={"flex-end"}
					right="-1vh"
					top="-1vh"
				>
					{optionalButtons}
				</Flex>
				<Flex 
					direction={"column"} 
					gap="3vh" 
					alignItems={"center"} 
					textAlign="center" 
					paddingLeft="3vh" 
					paddingRight="3vh"
					maxHeight={"70vh"}
					className="CustomScrollbar"
				>
					{text}
					{form}
					<Flex justifyContent={"center"} gap={"4vw"} marginBottom="1vh">
						{mainButtons}
					</Flex>
				</Flex>
			</Flex>
		);
	}
	catch (error) {
		throw "PopUpTemplate Error: " + error;
	}
}

export default PopUpTemplate;