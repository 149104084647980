import React, { useEffect, useState } from "react";
import {NavigationBarTemplate, TextFieldTemplate} from '../../custom-ui-components';
import { Button, CheckboxField, Divider, Flex, Text } from "@aws-amplify/ui-react";
import PopUpTemplate from "../../custom-ui-components/form-components/PopUpTemplate";
import { LoadingSpinnerPage } from "../LoadingPage";
import { ErrorLoadingPage } from "../ErrorLoadingPage";
import { priceValuetoString } from "../../App";

const ViewInAppCredit = function(props) {
	const standardTextProps = props.standardTextProps;
	const extraDetailsTextProps = {...standardTextProps, fontStyle: "italic"};
	extraDetailsTextProps.fontSize = (extraDetailsTextProps.fontSize || "25px").split("px")[0] - 7 + "px";
	const titleTextProps = props.titleTextProps;
	const width = props.width;
	const title = <Text {...titleTextProps}>View App Credit</Text>;

	const parentModels = props.parents;
	const studentModels = props.students;
	const tutorModels = props.tutors;

	const [totalParentCredit, setTotalParentCredit] = useState(null);
	const [totalStudentCredit, setTotalStudentCredit] = useState(null);
	const [totalTutorCredit, setTotalTutorCredit] = useState(null);
	const [negativeCredit, setNegativeCredit] = useState(null);
	const [negativeTutorCredit, setNegativeTutorCredit] = useState(null);
	const [creditUI, setCreditUI] = useState(null);

	//Set credit values
	useEffect(() => {
		if (parentModels == null || parentModels == "loading" || parentModels == "error") {
			return;
		}
		if (studentModels == null || studentModels == "loading" || studentModels == "error") {
			return;
		}
		if (tutorModels == null || tutorModels == "loading" || tutorModels == "error") {
			return;
		}

		const testUserIDs = [
			"0c7fe414-4c80-41f4-a26c-46e983ef715b",
			"d15f03c9-3bea-4ddf-880f-8aafbcb8a65f",
			"f32200a5-a521-4946-a58a-8b32105f1f14",
			"9e428431-8f9e-4642-9f56-db1d0923fb92",
			"301cf986-36c8-4966-b25c-8fc789150dc2",
			"23594d2c-6268-47ad-96da-793fcee531f4"
		];

		let totalNegativeCredit = 0;
		let totalNegativeTutorCredit = 0;
		let newTotalParentCredit = 0;
		for (const parent of parentModels) {
			let isTestUser = false;
			for (const testUserID of testUserIDs) {
				if (parent.id == testUserID) {
					isTestUser = true;
				}
			}
			const parentCredit = parent.lessonCredits || 0;
			if (parentCredit > 0 && !isTestUser) {
				newTotalParentCredit = newTotalParentCredit + parentCredit;
			}
			else if (parentCredit < 0 && !isTestUser) {
				totalNegativeCredit = totalNegativeCredit + parentCredit;
			}
		}
		let newTotalStudentCredit = 0;
		for (const student of studentModels) {
			if (student.parentID != null) {
				let isTestUser = false;
				for (const testUserID of testUserIDs) {
					if (student.id == testUserID) {
						isTestUser = true;
					}
				}
				const studentCredit = student.lessonCredits || 0;
				if (studentCredit > 0 && !isTestUser) {
					newTotalStudentCredit = newTotalStudentCredit + studentCredit;
				}
				else if (studentCredit < 0 && !isTestUser) {
					totalNegativeCredit = totalNegativeCredit + studentCredit;
				}
			}
		}
		let newTotalTutorCredit = 0;
		for (const tutor of tutorModels) {
			let isTestUser = false;
			for (const testUserID of testUserIDs) {
				if (tutor.id == testUserID) {
					isTestUser = true;
				}
			}
			const tutorCredit = tutor.credit || 0;
			if (tutorCredit > 0 && !isTestUser) {
				newTotalTutorCredit = newTotalTutorCredit + tutorCredit;
			}
			else if (tutorCredit < 0 && !isTestUser) {
				totalNegativeTutorCredit = totalNegativeTutorCredit + tutorCredit;
			}
		}
		setTotalParentCredit(newTotalParentCredit);
		setTotalStudentCredit(newTotalStudentCredit);
		setTotalTutorCredit(newTotalTutorCredit);
		setNegativeCredit(totalNegativeCredit);
		setNegativeTutorCredit(totalNegativeTutorCredit);
	}, [parentModels, studentModels, tutorModels]);

	useEffect(() => {
		if (totalParentCredit == null || totalStudentCredit == null) {
			return;
		}
		const worstCaseLessonCost = 25;
		const worstCaseLessonProfit = 5;

		const totalCredit = totalParentCredit + totalStudentCredit;
		const totalCreditWithOwing = totalCredit - negativeCredit;
		const worstCaseTotalLessons = Math.floor(totalCredit / worstCaseLessonCost);
		const worstCaseCreditProfit = worstCaseTotalLessons * worstCaseLessonProfit;
		const worstCaseTutorPayments = (totalCredit - worstCaseCreditProfit) + totalTutorCredit;

		const worstCaseTotalLessonsWithOwing = Math.floor(totalCreditWithOwing / worstCaseLessonCost);
		const worstCaseCreditProfitWithOwing = worstCaseTotalLessonsWithOwing * worstCaseLessonProfit;
		const worstCaseTutorPaymentsWithOwing = (totalCreditWithOwing - worstCaseCreditProfitWithOwing) + totalTutorCredit;

		setCreditUI(<Flex direction={"column"}>
			<Text {...titleTextProps} fontSize={"23px"}>Worse case assumption: all credit spent on £25 lessons with us profiting £5 on each</Text>

			<Text {...titleTextProps} fontSize={"23px"}>Excluding any negative credit:</Text>
			<Flex direction={"column"} gap={"0px"}>
				<Text {...standardTextProps}>Total positive credit in app: {priceValuetoString(totalCredit)}</Text>
				<Text {...extraDetailsTextProps}>Positive credit across all parent and student accounts</Text>
			</Flex>
			<Flex direction={"column"} gap={"0px"}>
				<Text {...standardTextProps}>Worst case profit: {priceValuetoString(worstCaseCreditProfit)}</Text>
				<Text {...extraDetailsTextProps}>Positive parent credit divided into £{worstCaseLessonCost} lessons with £{worstCaseLessonProfit} profit on each</Text>
			</Flex>
			<Flex direction={"column"} gap={"0px"}>
				<Text {...standardTextProps}>Total positive tutor credit in app: {priceValuetoString(totalTutorCredit)}</Text>
				<Text {...extraDetailsTextProps}>Total negative tutor credit in app, ignored in all calculations: {priceValuetoString(negativeTutorCredit)}</Text>
			</Flex>
			<Flex direction={"column"} gap={"0px"}>
				<Text {...standardTextProps}>Worst case credit needed to payout tutors: {priceValuetoString(worstCaseTutorPayments)}</Text>
				<Text {...extraDetailsTextProps}>(Total positive parent/student credit - worst case profit) + total positive tutor credit</Text>
			</Flex>

			<Text {...titleTextProps} fontSize={"23px"}>Including negative credit:</Text>
			<Text {...standardTextProps}>Negative parent/student credit (owed to us): {priceValuetoString(negativeCredit)}</Text>
			<Text {...standardTextProps}>Total Credit in App including owing: {priceValuetoString(totalCreditWithOwing)}</Text>
			<Text {...standardTextProps}>Worst case profit: {priceValuetoString(worstCaseCreditProfitWithOwing)}</Text>
			<Text {...standardTextProps}>Worst case credit needed to payout tutors: {priceValuetoString(worstCaseTutorPaymentsWithOwing)}</Text>
		</Flex>);
	}, [totalParentCredit, totalStudentCredit, totalTutorCredit, negativeCredit, negativeTutorCredit]);

	if (parentModels == null || parentModels == "loading" || studentModels == null || studentModels == "loading" || tutorModels == null || tutorModels == "loading") {
		return <LoadingSpinnerPage {...props}/>
	}
	else if (parentModels == "error" || studentModels == "error") {
		return <ErrorLoadingPage {...props}/>
	}

  	return (
		<Flex direction={"column"} gap={"2vh"}>
			{title}
			{creditUI}
		</Flex>
  	);
}

export default ViewInAppCredit;