import {useEffect, useState, React} from 'react';
import { LoadingSpinnerPage } from '../LoadingPage';
import { Button, Flex, Text } from '@aws-amplify/ui-react';
import {Link, Navigate, useLocation} from "react-router-dom";
import {MdCancel} from 'react-icons/md';
import LessonDateTemplate from '../../custom-ui-components/LessonDateTemplate';
import PopUpTemplate from '../../custom-ui-components/form-components/PopUpTemplate';
import { ErrorLoadingPage } from '../ErrorLoadingPage';
import LessonTemplate from '../../custom-ui-components/LessonTemplate';
import LessonRequestTemplate from '../../custom-ui-components/LessonRequestTemplate';
import BackButton from '../../custom-ui-components/BackButton';

const MyStudent = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    //Get the current user to pass to subsequent functions
    const user = props.user;
    const width = props.width;
    const height = props.height;
    const APIFunctions = props.APIFunctions;
    const studentDictionary = props.studentDictionary;

    const [deleteStudentPopUp, setDeleteStudentPopUp] = useState(false);
    const [deletingStudent, setDeletingStudent] = useState(false);
    const [deletedStudent, setDeletedStudent] = useState(false);

    const [deleteRequestPopUp, setDeleteRequestPopUp] = useState(false);
    const [deletingLessonRequest, setDeletingLessonRequest] = useState(false);
    const [deletedLessonRequest, setDeletedLessonRequest] = useState(false);
    const [currentLessonRequest, setCurrentLessonRequest] = useState(false);

    const [errorPopUp, setErrorPopUp] = useState(false);

    const [studentProps, setStudentProps] = useState(null);
    const [noStudentModel, setNoStudentModel] = useState(false);

    const askDeleteLessonRequest = function (currentLessonRequestProps, clickEvent) {
        if (deleteRequestPopUp == true) {
            setDeleteRequestPopUp(false);
        }
        else {
            setCurrentLessonRequest(currentLessonRequestProps);
            const controlClick = clickEvent.ctrlKey;
            if (controlClick != true) {
                setDeleteRequestPopUp(true);
            }
            else {
                deleteLessonRequest(currentLessonRequestProps);
            }
        }
    }

    useEffect(() => {
        if (studentDictionary == null) {
            return;
        }

        const queryParams = new URLSearchParams(window.location.search);
        const studentID = queryParams.get("studentID");
        if (studentID == null) {
            setNoStudentModel(true);
            return;
        }
        const studentModel = studentDictionary[studentID];
        if (studentModel == null) {
            setNoStudentModel(true);
            return;
        }
        setStudentProps(studentModel);
    }, [studentDictionary, window.location.search]);
    
    if (noStudentModel) {
        console.log("Going to parent");
        return <Navigate to={"/Parent"} />
    }

    const studentLessonDictionary = props.studentLessonDictionary;
    const parentLessonRequests = props.parentLessonRequests;

    if (studentProps == null || studentLessonDictionary == null || parentLessonRequests == null) {
        return <LoadingSpinnerPage {...props} />
    }
    if (studentProps == "loading" || studentLessonDictionary == "loading" || parentLessonRequests == "loading") {
        return <LoadingSpinnerPage {...props} />
    }
    else if (studentProps == "error" || studentLessonDictionary == "error" || parentLessonRequests == "error") {
        return <ErrorLoadingPage {...props}/>
    }

    const studentLessons = studentLessonDictionary[studentProps.id] ||[];
    const studentLessonRequests = [];
    for (const parentLessonRequest of parentLessonRequests) {
        const requestStudentIDs = parentLessonRequest.studentIDs;
        for (const requestStudentID of requestStudentIDs) {
            if (requestStudentID == studentProps.id) {
                studentLessonRequests.push(parentLessonRequest);
            }
        }
    }
    let numStudentLessonRequests = 0;
    for (const request of studentLessonRequests) {
        if (request.status != 3) {
            numStudentLessonRequests = numStudentLessonRequests + 1;
        }
    }
    let numRequestsSuffex = "s";
    if (numStudentLessonRequests == 1) {
        numRequestsSuffex = "";
    }

    const UIs = [];
    UIs.push(
        <Text {...standardTextProps} key={"studentName"}>
            Student name: {studentProps.firstNames + " " + studentProps.lastName}
        </Text>,
        <Text {...standardTextProps} key={"yearGroup"}>
            Student year group: {studentProps.yearGroup}
        </Text>,
        <Text {...standardTextProps} key={"numLessons"}>
            {studentLessons.length} assigned lessons
        </Text>,
        <Text {...standardTextProps} key={"numLessonRequests"}>
            {numStudentLessonRequests} requested lesson{numRequestsSuffex}
        </Text>
    );


    const studentFlex = <Flex direction={"column"} alignItems={"flex-start"}>
        {UIs}
    </Flex>

    const lessonUIs = [];
    let lessonTitleText = null;
    if (studentLessons.length > 0) {
        lessonTitleText = <Text {...standardTextProps} key={"lessonsTitleText"}>
            Student's Lessons:
        </Text>
    }
    lessonUIs.push(lessonTitleText);
    for (const lesson of studentLessons) {
        const lessonUI = <LessonTemplate {...props} lessonID={lesson.id} key={lesson.id + "lesson"}  width={width} height={height}/>
        lessonUIs.push(lessonUI);
    }
    let lessonFlex = <Flex direction={"column"} alignItems={"center"}>
        {lessonUIs}
    </Flex>

    if (lessonUIs.length == 0) {
        lessonFlex = null;
    }

    const lessonRequestsUIs = [];
    let lessonRequestsTitleText = null;
    if (studentLessonRequests.length > 0) {
        lessonRequestsTitleText = <Text {...standardTextProps} key={"lessonRequestTitleText"}>
            Student's Lesson Requests:
        </Text>
    }

    lessonRequestsUIs.push(lessonRequestsTitleText);
    
    for (const lessonRequest of studentLessonRequests) {
        lessonRequest.parentsStudent = studentProps;
        if (lessonRequest.status != 3) {
            const lessonRequestUI = <LessonRequestTemplate 
                {...props}
                userType={"parentStudent"} 
                requestID={lessonRequest.id}
                key={lessonRequest.id + "lessonRequest"} 
                width={width} 
                height={height}
                deleteLessonRequest={askDeleteLessonRequest}
            />
            lessonRequestsUIs.push(lessonRequestUI);
        }
    }
    let lessonRequestFlex = <Flex direction={"column"}>
        {lessonRequestsUIs}
    </Flex>

    if (lessonRequestsUIs.length == 0) {
        lessonRequestFlex = null;
    }

    
    
    const titleText = <Text {...titleTextProps}>My Student</Text>

    const backButton = <BackButton {...props} defaultBack={"/Parent"} />

    const deleteLessonRequest = async function (currentLessonRequestProps) {
        try {
            if (currentLessonRequestProps == null) {
                currentLessonRequestProps = currentLessonRequest;
            }
            setDeletingLessonRequest(true);
            const newLessonRequest = {};
            newLessonRequest.id = currentLessonRequestProps.id;
            newLessonRequest.deleted = true;
            const newLessonRequestModel = await APIFunctions.updateLessonRequest(newLessonRequest, "parent");
            console.log(newLessonRequestModel);
            setDeletingLessonRequest(false);
            setDeletedLessonRequest(true);
        }
        catch (error) {
            setDeleteRequestPopUp(false);
            setDeletingLessonRequest(false);
            setDeletedLessonRequest(false);
            try {
                if (error.response.data == "Error updating lesson request data model, given error: User does not have permission to edit this lesson request") {
                    setErrorPopUp("You don't have permission to edit this lesson request");
                }
                else {
                    setErrorPopUp(error.response.data);
                }
            }
            catch {
                if (typeof(error) == "string") {
                    setErrorPopUp(error);
                }
                else {
                    setErrorPopUp("Error deleting lesson request");
                }
            }
        }
    }

    const deleteRequestText = "Are you sure you want to delete this lesson request?";
    const deleteRequestPopUpComponent = <PopUpTemplate text={deleteRequestText} setPopUpVisibility={setDeleteRequestPopUp} yesButtonFunction={deleteLessonRequest} noButtonFunction={function(){}} showXButton={true} />
    const deletingRequestText = "Deleting lesson request...";
    const deletingLessonRequestPopUp = <PopUpTemplate text={deletingRequestText} setPopUpVisibility={setDeletingLessonRequest} showXButton={false} />
    const deletedLessonRequestText = "Lesson request deleted";
    const deletedLessonRequestPopUp = <PopUpTemplate text={deletedLessonRequestText} okButtonFunction={function(){}} setPopUpVisibility={setDeletedLessonRequest} showXButton={false} />


    // const deleteStudent = async function () {
    //     try {
    //         console.log("Deleting student");
            
    //         setErrorPopUp(false);
    //         setDeletingStudent(true);
    //         const newStudentAttributes = {};
    //         newStudentAttributes.id = studentProps.id;
    //         newStudentAttributes._deleted = true;
            
    //         const newStudentModel = await APIFunctions.updateStudent(newStudentAttributes);
    //         console.log(newStudentModel);
    //         setLessonProps(newLessonModel);
    //         setDeletingStudent(false);
    //         setDeletedStudent(true);
    //     }
    //     catch (error) {
    //         console.log(error);
    //         try {
    //             console.log(error.response.data);
    //         } catch {}
    //         setDeletingStudent(false);
    //         setDeletedStudent(false);
    //         if (typeof(error) == "string") {
    //             setErrorPopUp(error);
    //         }
    //         else {
    //             setErrorPopUp("Error deleting student");
    //         }
    //     }
    // }

    // const deleteText = "Are you sure you want to delete this student?";
    // const deletePopUpComponent = <PopUpTemplate text={deleteText} setPopUpVisibility={setDeletePopUp} yesButtonFunction={deleteLessonDate} noButtonFunction={function(){}} showXButton={true} />
    // const deletingText = "Deleting student";
    // const deletingPopUp = <PopUpTemplate text={deletingText} setPopUpVisibility={setDeletingDate} showXButton={false} />
    // const deletedText = "Student deleted";
    // const deletedPopUp = <PopUpTemplate text={deletedText} okButtonFunction={function(){}} setPopUpVisibility={setDeletedDate} showXButton={false} />


    const errorPopUpComponent = <PopUpTemplate text={errorPopUp} okButtonFunction={function(){}} setPopUpVisibility={setErrorPopUp} showXButton={true} />

    const lessonRequestButton = <Link to={"/Parent/NewLessonRequest"} style={{textDecoration: 'none'}} state={{student: studentProps.id}}><Button>Make a new lesson request</Button></Link>

    return (
        <Flex direction={"column"} gap={"4vh"} > 
            <Flex direction={"column"} gap={"4vh"} alignItems={"center"}>
                {titleText}
                <Flex gap={"4vw"}>
                    {backButton}
                </Flex>
            </Flex>
            <Flex direction={"column"} gap={"3vh"} alignItems={"center"}>                
                {studentFlex}
                {lessonFlex}
                {lessonRequestFlex}
                {lessonRequestButton}
            </Flex>
            <Flex direction={"column"} gap={"4vh"} alignItems={"center"}> 
                {/* {deletePopUp && deletePopUpComponent}
                {deletingStudent && deletingPopUp}
                {deletedStudent && deletedPopUp} */}
             
                {deleteRequestPopUp && deleteRequestPopUpComponent}
                {deletingLessonRequest && deletingLessonRequestPopUp}
                {deletedLessonRequest && deletedLessonRequestPopUp}
                {(errorPopUp != false) && errorPopUpComponent}
            </Flex>
        </Flex>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default MyStudent;