import React, { useEffect, useState } from "react";
import { Button, Flex, Grid, Icon, Text, Image, TextField, Link } from "@aws-amplify/ui-react";
import { MdDone, MdClose } from 'react-icons/md';
import {GrSend} from 'react-icons/gr';
import {ReactComponent as SendIcon} from "../../Images/sendIcon.svg"

import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
	MainContainer,
	Sidebar,
	Search,
	ConversationList,
	Conversation,
	ConversationHeader,
	ChatContainer,
	MessageList,
	Message,
	MessageGroup,
	MessageInput,
	MessageSeparator,
	Avatar,
	TypingIndicator,
} from "@chatscope/chat-ui-kit-react";

//import {ReactComponent as LogoSVG} from "../../Images/NextDoorTutorLogoExport.svg";
import { Navigate, useLocation } from "react-router-dom";
import TextFieldTemplate from "../../custom-ui-components/form-components/TextFieldTemplate";
import { shortDateString } from "../../App";

const AdminChats = function(props) {
	let defaultSideBarChat = "sideBar";
	let defaultSelectedChat = null;
	const location = useLocation();
	const extraProps = location.state || {};
	if (extraProps.recipientID != null && extraProps.senderID != null) {
		defaultSideBarChat = "chat";
		defaultSelectedChat = extraProps.senderID + "," + extraProps.recipientID;
	}

	const [userNames, setUserNames] = useState(null);
	const [adminChats, setAdminChats] = useState(null);
	const [selectedChat, setSelectedChat] = useState(defaultSelectedChat);
	const [conversationListUI, setConversationListUI] = useState(null);
	const [conversationHeaderUI, setConversationHeaderUI] = useState(null);
	const [messageListUI, setMessageListUI] = useState(null);
	const [messageInputUI, setMessageInputUI] = useState(null);
	const [sideBar, setSideBar] = useState(null);
	const [sideBarOrChat, setSideBarOrChat] = useState(defaultSideBarChat);
	const [loading, setLoading] = useState(true);
	const [redirect, setRedirect] = useState(null);
	const [showReadTime, setShowReadTime] = useState(null);
	const [showAdminMessageOptions, setShowAdminMessageOptions] = useState(null);

	const width = props.width;
	const titleTextProps = props.titleTextProps;
	const standardTextProps = props.standardTextProps;
	const chats = props.adminAllChats;
	const usersLastOnline = props.usersLastOnline;
	const usersTyping = props.usersTyping;
	const userID = props.userID;
	const APIFunctions = props.APIFunctions;
	const webSocket = props.webSocket;
	const pageVisibility = props.pageVisibility;
	const tutors = props.tutors;
	const students = props.students;
	const parents = props.parents;


	//Get all messageable recipient names and IDs from the lessons that the user is part of
	useEffect(() => {
		const allNames = {};
		let usersLoading = false;
		if (parents == null || tutors == null || students == null) {
			usersLoading = true;
		}
		else if (parents == "loading" || tutors == "loading" || students == "loading") {
			usersLoading = true;
		}
		else if (parents == "error" || tutors == "error" || students == "error" || chats == "error") {
			setUserNames("error");
		}

		if (!usersLoading && chats == null) {
			APIFunctions.adminGetChats();
		}
		else if (!usersLoading) {
			for (const chat of chats) {
				const recipientID = chat.recipientID;
				const senderID = chat.senderID;
				if (allNames[recipientID] == null || allNames[senderID] == null) {
					for (const parent of parents) {
						if (allNames[recipientID] == null && parent.id == recipientID) {
							allNames[recipientID] = {
								firstNames: parent.firstNames,
								lastName: parent.lastName,
								userType: "Parent"
							};
						}
						if (allNames[senderID] == null && parent.id == senderID) {
							allNames[senderID] = {
								firstNames: parent.firstNames,
								lastName: parent.lastName,
								userType: "Parent"
							};
						}
						if (allNames[recipientID] != null && allNames[senderID] != null) {
							break;
						}
					}
				}
				if (allNames[recipientID] == null || allNames[senderID] == null) {
					for (const tutor of tutors) {
						if (allNames[recipientID] == null && tutor.id == recipientID) {
							allNames[recipientID] = {
								firstNames: tutor.firstNames,
								lastName: tutor.lastName,
								userType: "Tutor"
							};
						}
						if (allNames[senderID] == null && tutor.id == senderID) {
							allNames[senderID] = {
								firstNames: tutor.firstNames,
								lastName: tutor.lastName,
								userType: "Tutor"
							};
						}
						if (allNames[recipientID] != null && allNames[senderID] != null) {
							break;
						}
					}
				}
				if (allNames[recipientID] == null || allNames[senderID] == null) {
					for (const student of students) {
						if (allNames[recipientID] == null && student.id == recipientID) {
							allNames[recipientID] = {
								firstNames: student.firstNames,
								lastName: student.lastName,
								userType: "Student"
							};
						}
						if (allNames[senderID] == null && student.id == senderID) {
							allNames[senderID] = {
								firstNames: student.firstNames,
								lastName: student.lastName,
								userType: "Student"
							};
						}
						if (allNames[recipientID] != null && allNames[senderID] != null) {
							break;
						}
					}
				}
				if (allNames[recipientID] == null) {
					allNames[recipientID] = {
						firstNames: "Unknown",
						lastName: "Unknown",
						userType: "Unknown"
					}
				}
				if (allNames[senderID] == null) {
					allNames[senderID] = {
						firstNames: "Unknown",
						lastName: "Unknown",
						userType: "Unknown"
					}
				}
			}
			
			setUserNames(allNames);
		}
	}, [tutors, students, parents, chats, APIFunctions]);
	
	//Set the users chat messages to either a blank array or the existing messages. Also sort existing messages by date/time
	useEffect(() => {
		if (userNames != null && chats != null) {
			const newAdminChats = {};
			for (const chat of chats) {
				const senderID = chat.senderID;
				const recipientID = chat.recipientID;
				newAdminChats[senderID + "," + recipientID] = chat.messages.sort((a, b) => {
					return new Date(a.time).valueOf() - new Date(b.time).valueOf();
				});
			}

			setAdminChats(newAdminChats);
		}
	}, [userNames, chats]);

	//Generate the conversation list side panel from the available users to message and the existing messages
	useEffect(() => {
		if (userNames != null && adminChats != null) {
			const conversationUIs = [];
			const chatArray = Object.entries(adminChats);
		
			const sortedChatArray = chatArray.sort((a, b) => {
				const aID = a[0];
				const aMessages = a[1];
				const bID = b[0];
				const bMessages = b[1];
				let aLastMessage = null;
				if (aMessages.length > 0) {
					aLastMessage = aMessages[aMessages.length - 1];
				}
				let bLastMessage = null;
				if (bMessages.length > 0) {
					bLastMessage = bMessages[bMessages.length - 1];
				}

				if (aLastMessage == null && bLastMessage == null) {
					const aFirstNames = userNames[aID].firstNames;
					const bFirstNames = userNames[bID].firstNames;
					if (aFirstNames < bFirstNames) {
						return -1;
					}
					else {
						return 1
					}
				}
				else if (aLastMessage == null) {
					return 1;
				}
				else if (bLastMessage == null) {
					return -1;
				}
				else {
					const aLastMessageTime = new Date(aLastMessage.time).valueOf();
					const bLastMessageTime = new Date(bLastMessage.time).valueOf();
					return bLastMessageTime - aLastMessageTime;
				}
			});
			
			for (const chatObject of sortedChatArray) {
				const IDs = chatObject[0];
				const senderID = IDs.split(",")[0];
				const senderNameObject = userNames[senderID];
				const senderName = senderNameObject.firstNames + " " + senderNameObject.lastName;
				const recipientID = IDs.split(",")[1];
				const recipientNameObject = userNames[recipientID];
				const recipientName = recipientNameObject.firstNames + " " + recipientNameObject.lastName;

				let lastMessage = {};
				let lastSenderName = null;
				if (adminChats[IDs].length > 0) {
					lastMessage = adminChats[IDs][adminChats[IDs].length - 1];
					
					if (lastMessage.sender == recipientID) {
						lastSenderName = recipientName;
					}
					else {
						lastSenderName = senderName;
					}
				}
				
				let activeConversation = false;
				if (IDs == selectedChat) {
					activeConversation = true;
				}
				const conversationUI = <Conversation
					key={"conversation:" + recipientID + "," + senderID}
					name={senderName + ", " + recipientName}
					lastSenderName={lastSenderName} 
					info={lastMessage.message}
					//unreadCnt={unreadMessages[recipientID]}
					active={activeConversation}
					onClick={() => {
						if (selectedChat != IDs) {
							setSelectedChat(IDs);
							setSideBarOrChat("chat");
						}
						else {
							setSelectedChat(null);
							setConversationHeaderUI(null);
							setMessageInputUI(null);
							setSideBarOrChat("sideBar");
						}
						setShowAdminMessageOptions(null);
					}}
					style={{
						padding: "10px",
						width: "100%"
					}}
				/>

				conversationUIs.push(conversationUI);
			}
			
			const newConversationList = <ConversationList>
				{conversationUIs}
			</ConversationList>
			setConversationListUI(newConversationList)
		}
	}, [userNames, adminChats, selectedChat]);

	//Generate the conversation header and message input based on the current conversation
	useEffect(() => {
		if (selectedChat != null && adminChats != null && userNames != null) {
			const senderID = selectedChat.split(",")[0];
			const senderName = userNames[senderID].firstNames + " " + userNames[senderID].lastName;
			const senderUserType = userNames[senderID].userType;
			const recipientID = selectedChat.split(",")[1];
			const recipientName = userNames[recipientID].firstNames + " " + userNames[recipientID].lastName;
			const recipientUserType = userNames[recipientID].userType;

			let backButton = <ConversationHeader.Back onClick={() => {
				setSelectedChat(null);
				setConversationHeaderUI(null);
				setMessageInputUI(null);
				setSideBarOrChat("sideBar")
			}}/>;
			if (width < 500 && sideBarOrChat == "chat") { 
				backButton = <ConversationHeader.Back onClick={() => {
					setSelectedChat(null);
					setConversationHeaderUI(null);
					setMessageInputUI(null);
					setSideBarOrChat("sideBar")
				}}/>
			}
			
			const senderText = <Text 
				onClick={() => {
					setRedirect(<Navigate to={"/Admin/ViewUser?id=" + senderID}/>);
				}}
				color={"#2176ff"}
				style={{cursor: "pointer"}}
			>
				{senderName} ({senderUserType}),
			</Text>

			const recipientText = <Text 
				onClick={() => {
					setRedirect(<Navigate to={"/Admin/ViewUser?id=" + recipientID}/>);
				}}
				color={"#2176ff"}
				style={{cursor: "pointer"}}
			>
				{recipientName} ({recipientUserType})
			</Text>
			const newConversationHeaderUI = <ConversationHeader >
				{backButton}
				<ConversationHeader.Content 
					children={<Flex gap={"5px"}>{senderText} {recipientText}</Flex>}
				/>
			</ConversationHeader>
			setConversationHeaderUI(newConversationHeaderUI);
		}
	}, [adminChats, selectedChat, userNames, usersLastOnline, width]);

	//Generate the messages and typing indicator
	useEffect(() => {
		if (adminChats != null && selectedChat != null) {
			const userMessages = [...adminChats[selectedChat]];
			const senderID = selectedChat.split(",")[0];
			const messageUIs = [];

			for (let messageIndex = 0; messageIndex < userMessages.length; messageIndex = messageIndex + 1) {
				const message = userMessages[messageIndex];
				const messageTime = message.time;
				const sender = message.sender;
				const messageHidden = message.hidden || {};

				let direction = "incoming";
				if (message.sender == senderID) {
					direction = "outgoing";
				}

				const messageTimeObject = new Date(messageTime)
				let hours = messageTimeObject.getHours();
				if (hours < 10) {
					hours = "0" + hours;
				}
				let minutes = messageTimeObject.getMinutes();
				if (minutes < 10) {
					minutes = "0" + minutes;
				}
				const timeString = hours + ":" +  minutes;
				let messageSeparator = null;
				
				let showMessageSeparator = false;
				if (messageIndex > 0) {
					const previousMessage = userMessages[messageIndex - 1];
					const previousMessageTimeObject = new Date(previousMessage.time);
					if (messageTimeObject.toDateString() != new Date(previousMessageTimeObject).toDateString()) {
						showMessageSeparator = true;
					}
				}
				else if (messageIndex == 0 && messageTimeObject.toDateString() != new Date().toDateString()) {
					showMessageSeparator = true;
				}

				if (showMessageSeparator) {
					messageSeparator = <MessageSeparator
						key={"separator:" + messageTime + message.message}
						style={{marginBottom: "10px"}}
					>
						{messageTimeObject.toDateString()}
					</MessageSeparator>
				}
				
				let hiddenText = null;
				let hiddenString = "";
				for (const [hiddenUserID, hidden] of Object.entries(messageHidden)) {
					if (hidden) {
						const hiddenUsername = userNames[hiddenUserID].firstNames + " " + userNames[hiddenUserID].lastName;
						if (hiddenString != "") {
							hiddenString = hiddenString + ", ";
						}
						hiddenString = hiddenString + hiddenUsername;
					}
				}
				if (hiddenString != "") {
					hiddenText = <Text 
						fontSize={"10px"} 
						color={"#ff0000"}
					>
						Hidden
					</Text>
				}



				let readReceipt = <Flex 
					gap={"0px"}
				>
					<Icon
						key={"greyTick1"}
						width={"10px"}
						height={"10px"}
						as={MdDone}
						color={"#c9c9c9"}
					/>
					<Icon
						key={"greyTick2"}
						width={"10px"}
						height={"10px"}
						as={MdDone}
						color={"#c9c9c9"}
						marginLeft={"-5px"}
					/>
				</Flex>

				if (message.read != null) {
					readReceipt = <Flex 
						gap={"0px"}
					>
						<Icon
							key={"blueTick1"}
							width={"10px"}
							height={"10px"}
							as={MdDone}
							color={"#2176ff"}
						/>
						<Icon
							key={"blueTick2"}
							width={"10px"}
							height={"10px"}
							as={MdDone}
							color={"#2176ff"}
							marginLeft={"-5px"}
						/>
					</Flex>
				}
				
				if (message.delivered == false) {
					readReceipt = null;
				}

				let readTime = null;
				if (showReadTime != null && showReadTime.id == message.id) {
					const readTimeObject = new Date(showReadTime.read);
					let hours = readTimeObject.getHours();
					if (hours < 10) {
						hours = "0" + hours;
					}
					let minutes = readTimeObject.getMinutes();
					if (minutes < 10) {
						minutes = "0" + minutes;
					}
					let string = hours + ":" + minutes;
					if (messageTimeObject.toDateString() != new Date().toDateString()) {
						let dateString = "Today";
						if (readTimeObject.toDateString() != new Date().toDateString()) {
							dateString = shortDateString(readTimeObject);
							const currentDate = new Date().getDate();
							const yesterday = new Date().setDate(currentDate - 1);
							if (new Date(yesterday).toDateString() == readTimeObject.toDateString()) {
								dateString = "Yesterday";
							}
						}
						string = string + " - " + dateString;
					}
					string = "Read: " + string;
					readTime = <Text
						position={"absolute"}
						alignSelf={"center"}
						right={"0px"}
						fontSize={"10px"} 
						style={{pointerEvents: "none", textWrap:"nowrap"}}
					>
						{string}
					</Text>

					if (message.message != null && message.message.length < 30) {
						let messageLength = message.message.length;
						if (messageLength < 11) {
							messageLength = 11;
						}
						let right = null;
						let left = null;
						if (direction == "incoming") {
							left = "70px";
						}
						else {
							right = (((messageLength) * 7) - 20) + "px";
						}
						readTime = <Flex
							backgroundColor={"#c6e3fa"}
							paddingTop={"2px"}
							paddingBottom={"2px"}
							paddingLeft={"5px"}
							paddingRight={"5px"}
							borderRadius={"5px"}
							position={"absolute"}
							alignSelf={"center"}
							left={left}
							right={right}
						>
							<Text
								fontSize={"10px"} 
								style={{pointerEvents: "none", textWrap:"nowrap"}}
							>
								{string}
							</Text>
						</Flex>
					}
				}
				
				let marginBottom = "5px";
				if (messageIndex == userMessages.length - 1) {
					marginBottom = "30px";
				}
				let messageUI = <Message
					key={messageTime + message.message}
					model={{
						sender: sender,
						direction: direction
					}}
					style={{marginBottom: marginBottom}}
				>
					<Message.CustomContent className="Message">
						<Flex 
							alignItems={"flex-start"} 
							position={"relative"} 
							minWidth={"80px"} 
							direction={"column"} 
							gap={"0px"}
							style={{cursor: "pointer"}}
							onClick={() => {
								if (showReadTime != null && showReadTime.id == message.id) {
									return;
								}
								setShowAdminMessageOptions({message: message});
							}}
						>
							<Flex alignSelf={"center"} position={"absolute"} left={"-13px"} top={"-8px"} width={"110%"} height={"150%"} style={{opacity: 0, zIndex: 0}}/>
							<Text 
								fontSize={"10px"} 
								marginTop={"-5px"}
							>
								{userNames[sender].firstNames + " " + userNames[sender].lastName}
							</Text>
							<Text 
								fontSize={"15px"} 
								marginBottom={"7px"}
							>
								{message.message}
							</Text>
							<Flex 
								alignItems={"center"} 
								position={"absolute"} 
								bottom={"-5px"} 
								right={"-5px"} 
								gap={"7px"}
								onMouseEnter={() => {
									if (message.read == null) {
										return;
									}
									setShowReadTime({
										id: message.id,
										read: message.read
									});
								}}
								onMouseLeave={() => {
									if (message.read == null) {
										return;
									}
									setShowReadTime(null);
								}}
							>
								<Flex 
									backgroundColor={"#cccccc00"}
									width={"70px"}
									position={"absolute"}
									right={"-10px"}
									bottom={"-4px"}
									overflow={"visible"}
									height={"20px"}
								/>
								{hiddenText}
								<Flex position={"relative"} alignSelf={"center"}>
									{readTime}
								</Flex>
								<Text fontSize={"10px"}>{timeString}</Text>
								{readReceipt}
							</Flex>
						</Flex>
					</Message.CustomContent>
				</Message>

				messageUIs.push(messageSeparator, messageUI);
			}

			const userTyping = usersTyping[selectedChat];
			let typingIndicator = null;
			if (userTyping) {
				typingIndicator = <TypingIndicator content={userNames[selectedChat].firstNames + " is typing"} style={{backgroundColor: "#00000000"}}/>
			}
			const newMessageListUI = <MessageList
				key={messageUIs.length}
				typingIndicator={typingIndicator} 
				className={"Message"}
			>
				{messageUIs}
			</MessageList>
			setMessageListUI(newMessageListUI);

			// let foundUnread = false
			// for (const message of userMessages) {
			//   if (message.read == null && message.sender != userID) {
			//     foundUnread = true;
			//     break;
			//   }
			// }
			// if (foundUnread && pageVisibility == "visible") {
			//   APIFunctions.readMessage(selectedChat);
			// }
		}
		else if (adminChats != null && selectedChat == null) {
			setMessageListUI(null);
		}
	}, [selectedChat, adminChats, pageVisibility, userNames, showReadTime]);

	//Generate the side bar
	useEffect(() => {
		const sideBarTitle = <Text 
			textAlign={"center"}
			fontWeight={"600"}
			paddingTop={"20px"}
			paddingBottom={"21px"}
			width={"100%"}
			backgroundColor={"#f6fbff"}
			style={{
				borderBottomStyle: "solid",
				borderWidth: "1px",
				borderColor: "#c1ddf3"
			}}
		>
			Chats
		</Text>
		let newSideBar = <Sidebar 
			position="left" 
			loading={loading}
			style={{
				width: "100%",
			}}
		>
			{sideBarTitle}
			{/* <Search placeholder="Search..." /> */}
			{conversationListUI}
		</Sidebar>

		if (width < 500 && sideBarOrChat == "chat" && selectedChat != null) {
			newSideBar = null;
		}
		else if (width < 500) {
			newSideBar = <Sidebar 
				position="left"
				loading={loading}
				style= {{
					display: "flex",
					flexBasis: "auto",
					width: "100%",
					maxWidth: "100%",
				}}
			>
				{sideBarTitle}  
				{/* <Search placeholder="Search..." /> */}
				{conversationListUI}
			</Sidebar>
		}
		setSideBar(newSideBar);
	}, [width, selectedChat, sideBarOrChat, conversationListUI]);

	useEffect(() => {
		if (showAdminMessageOptions == null || showAdminMessageOptions.message == null) {
			return;
		}
		if (showAdminMessageOptions.flex != null) {
			return;
		}
		const message = showAdminMessageOptions.message;
		const senderID = message.sender;
		if (senderID == null || userNames == null || userNames[senderID] == null) {
			return;
		}
		const senderName = userNames[senderID].firstNames + " " + userNames[senderID].lastName;
		let messageText = message.message;
		//Only show message preview ending in '...' if the message is longer than a certain length
		if (messageText.length > 65) {
			messageText = messageText.substring(0, 65);
			//If the truncated message ends in a space, remove it
			if (messageText[messageText.length - 1] == " ") {
				messageText = messageText.substring(0, messageText.length - 1);
			}
			messageText = messageText + "...";
		}

		const adminOptionsText = {...standardTextProps, fontSize: "15px"};

		const deleteMessageButton = <Button
			key={"deleteMessageButton"}
			onClick={() => {
				console.log("Delete message");
			}}
		>
			Delete message
		</Button>

		const hideFromSenderButton = <Button
			key={"hideFromSenderButton"}
			onClick={() => {
				console.log("Hide from sender");
			}}
			>
			Hide from sender
		</Button>

		const hideFromRecipientButton = <Button
			key={"hideFromRecipientButton"}
			onClick={() => {
				console.log("Hide from recipient");
			}}
		>
			Hide from recipient
		</Button>

		const adminOptions = <Flex
			position={"absolute"}
			backgroundColor={"#6edb8f"}
			alignSelf={"center"}
			top={"20vh"}
			width={"50vw"}
			maxWidth={"300px"}
			direction={"column"}
			alignItems={"center"}
			borderRadius={"15px"}
			gap={"6px"}
			paddingLeft={"10px"}
			paddingRight={"10px"}
			paddingTop={"5px"}
			paddingBottom={"10px"}
			border={"2px solid #000000"}
		>
			<Icon
				key={"closeButton"}
				width={"30px"}
				height={"30px"}
				as={MdClose}
				color={"ffffff"}
				position={"absolute"}
				right={"-10px"}
				top={"-10px"}
				backgroundColor={"#ff0000"}
				border={"1px solid #000000"}
				borderRadius={"50%"}
				onClick={() => {
					setShowAdminMessageOptions(null);
				}}
				style={{cursor: "pointer"}}
			/>
			<Text {...standardTextProps}>Message Options</Text>
			<Text {...adminOptionsText}>{senderName}: {messageText}</Text>
			{deleteMessageButton}
			{hideFromSenderButton}
			{hideFromRecipientButton}
			(These don't currently work)
		</Flex>
		const newShowAdminMessageOptions = {...showAdminMessageOptions};
		newShowAdminMessageOptions.flex = adminOptions;
		setShowAdminMessageOptions(newShowAdminMessageOptions);
	}, [standardTextProps, showAdminMessageOptions, userNames]);

	//Check that everything has loaded
	const checkLoaded = function () {
		let newLoading = false;
		if (students == null || students == "loading") {
			newLoading = true;
		}
		else if (parents == null || parents == "loading") {
			newLoading = true;
		}
		else if (tutors == null || tutors == "loading") {
			newLoading = true;
		}
		else if (userNames == null) {
			newLoading = true;
		}
		else if (chats == null) {
			newLoading = true;
		}
		else if (webSocket == null || webSocket.readyState == 3) {
			newLoading = true;
		}
		
		if (newLoading != loading) {
			setLoading(newLoading);
		}
	}

	checkLoaded();

	let chatHeight = "80vh";
	if (width < 500) {
		chatHeight = "60vh";
	} 

	let chatContainer = <Flex 
		direction={"column"} 
		maxHeight={chatHeight} 
		gap={"0px"}
		style={{
			borderStyle: "solid",
			borderWidth: "1px",
			borderColor: "#c1ddf3"
		}}
		width={"100%"}
	>
		{conversationHeaderUI}
		{messageListUI}
		{messageInputUI}
		{(showAdminMessageOptions != null && showAdminMessageOptions.flex != null) && showAdminMessageOptions.flex}
	</Flex>

	let sideBarWidth = "30vw";
	let sideBarMaxWidth = "300px"
	if ((width < 500 && sideBarOrChat == "sideBar") || selectedChat == null) {
		chatContainer = null;
		sideBarWidth = "95vw";
	}
	if (width < 500 && sideBarOrChat == "sideBar") {
		sideBarMaxWidth = "500px";
	}

	let sideBarUI = <Flex 
		width={sideBarWidth} 
		maxWidth={sideBarMaxWidth} 
		style={{
			borderStyle: "solid",
			borderWidth: "1px",
			borderColor: "#c1ddf3"
		}} 
	>
		{sideBar}
	</Flex>

	if (sideBar == null) {
		sideBarUI = null;
	}

	return (
		<Flex 
			style={{
				width:"95vw", 
				height: chatHeight, 
				borderStyle: "solid",
				borderWidth: "1px",
				borderColor: "#c1ddf3"
			}} 
			gap={"0px"}
			backgroundColor={"#ffffff"}
		>
			{sideBarUI}
			{chatContainer}
			{redirect}
		</Flex>
	);
}

export default AdminChats;