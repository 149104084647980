import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, RadioGroupField, Text } from "@aws-amplify/ui-react";
import { useState, useEffect } from 'react';
import { validateField } from "./FormValidation";
import OptionsTemplate from "./OptionsTemplate";

const RadioButtonsTemplate = function(props) {
  try {
    const labelTextProps = props.questionLabelProps || {};
    const errorTextProps = props.questionErrorProps || {};

    //Set question variables from passed properties
    const questionID = props.id;
    const questionLabel = props.label;
    const questionDescription = props.description;
    const options = props.options
    const defaultValue = props.defaultValue;
    const required = props.required;
    const requiredAsterisk = props.requiredAsterisk;
    const questionValidations = props.validations || [];
    const answersSuccessfullySubmitted = props.answersSuccessfullySubmitted;

    if (required == true) {
      let requiredValidationFound = false;
      for (const validation of questionValidations) {
        if (validation.type == "Required" && validation.questionType == "radio") {
          requiredValidationFound = true
        }
      }
      if (requiredValidationFound == false) {
        questionValidations.push({type: "Required", questionType: "radio"});
      }
    }

    //Set state variables used to store the current state and any errors
    const [selectedAnswer, setSelectedAnswer] = useState(defaultValue);
    const [errors, setErrors] = useState({questionError:""});

    useEffect(() => {
      const validationResult = validateField(selectedAnswer, questionValidations);
      const questionHasError = validationResult.hasError;
      props.handleChange(questionID, selectedAnswer, questionHasError);
    }, []);

    let labelColour = labelTextProps.color || "#000000";
    if (props.validationError != null && props.validationError != null) {
      labelColour = props.validationErrorColour || "#ff0000";
    }
    //Get the text for the question label
    const labelText = <Text {...labelTextProps} color={labelColour}>
      {questionLabel}
    </Text>

    const descriptionText = <Text {...labelTextProps} fontSize={"14px"} fontStyle={"italic"}>
      {questionDescription}
    </Text>
    
    const errorText = <Text {...errorTextProps} top={"0px"}>
      {errors.questionError}
    </Text>

    let renderedRequiredAsterisk = null;
    if (required == true) {
      renderedRequiredAsterisk = requiredAsterisk;
    }

    //Ensure that selectedAnswer has an ASCII value to display
    if (selectedAnswer == null) {
      setSelectedAnswer("");
    }
    
    if (errors.errorMessage === null) {
      setErrors({
        questionError:""
      });
    }

    const submitChange = function (option) {
      if (selectedAnswer === option) {
        setSelectedAnswer(null);

        const validationResult = validateField(null, questionValidations);
        const questionHasError = validationResult.hasError;
        const questionErrorMessage = validationResult.errorMessage;
        props.handleChange(questionID, null, questionHasError);
        setErrors({
          questionHasError:questionHasError, 
          questionError:questionErrorMessage
        });
      }
    }

    const optionComponents = OptionsTemplate(options, "radio", submitChange);

    //Generate the radio button field component
    const radioGroupField = <RadioGroupField
      placeholder={"-Select an option-"}
      value={selectedAnswer}
      size={"small"}
      isDisabled={answersSuccessfullySubmitted}

      //Runs when it detects a change in the input field
      onChange={(e) => {
        const currentValue = e.target.value;
        setSelectedAnswer(currentValue);

        const validationResult = validateField(currentValue, questionValidations);
        const questionHasError = validationResult.hasError;
        const questionErrorMessage = validationResult.errorMessage;
        props.handleChange(questionID, currentValue, questionHasError);
        setErrors({
          questionHasError:questionHasError, 
          questionError:questionErrorMessage
        });
      }} 
    >
      {optionComponents}
    </RadioGroupField>

    if (errors.questionHasError == false && errors.questionError != props.warning) {
      const newErrors = {...errors}
      newErrors.questionError = props.warning
      setErrors(newErrors);
    }

    //Return text field component to calling function (Most likely a Form Template)
    return (
      <Flex direction={"column"} gap={"0.5vh"} alignItems={"center"}>
        <Flex alignItems={"center"} >
          {renderedRequiredAsterisk}
          {labelText}
        </Flex>
        {descriptionText}
        <Flex direction={"column"} alignItems={"center"}>
          {radioGroupField}
        </Flex>
        <Flex direction={"column"} alignItems={"center"} justifyContent={"center"} position={"relative"}>
          {errorText}
        </Flex>
      </Flex>
    );
  }
  catch (error) {
    throw "RadioButtonsTemplate Error: " + error;
  }
}

export default RadioButtonsTemplate;