import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { TextField, Text, Flex } from "@aws-amplify/ui-react";
import { useState, useEffect } from 'react';
import { validateField } from "./FormValidation";

const TimeRangeTemplate = function(props) {
  try {
    const labelTextProps = {...props.questionLabelProps};
    const errorTextProps = {...props.questionErrorProps};

    //Set question variables from passed properties
    const questionID = props.id;
    const questionLabel = props.label;
    const questionDescription = props.description;
    const required = props.required;
    const requiredAsterisk = props.requiredAsterisk;
    const placeHolder = props.placeHolder;
    let defaultValue = props.defaultValue;
    let questionValidations = props.validations;
    if (questionValidations == null) {
      questionValidations = [];
    }
    let flexDirection = props.direction;
    if (flexDirection == null) {
      flexDirection = "column";
    }
    let label1 = props.label1;
    if (label1 == null) {
      label1 = "Start Time";
    }
    let label2 = props.label2;
    if (label2 == null) {
      label2 = "End Time";
    }
    const answersSuccessfullySubmitted = props.answersSuccessfullySubmitted;

    let foundDefaultValidaton = false;
    for (const existingValidation of questionValidations) {
      if (existingValidation.type == "ValidTimeRange") {
        foundDefaultValidaton = true;
      }
    }
    if (foundDefaultValidaton == false) {
      questionValidations.push({
        "type": "ValidTimeRange"
      });
    }

    if (defaultValue == null) {
      defaultValue = {
        "startTime": "",
        "endTime": ""
      };
    }

    //Set state variables used to store the current state and any errors
    const [timeAnswer, setTimeAnswer] = useState(defaultValue);
    const [errors, setErrors] = useState({questionError:"", questionHasError: false});

    const [forceUpdate, setForceUpdate] = useState(0);

    const [startTimeObject, setStartTimeObject] = useState({"target": {"value":""}});
    const [endTimeObject, setEndTimeObject] = useState({"target": {"value":""}});


    useEffect(() => {
      const validationResult = validateField(timeAnswer, questionValidations);
      const questionHasError = validationResult.hasError;
      props.handleChange(questionID, timeAnswer, questionHasError);
    }, []);

    let labelColour = labelTextProps.color || "#000000";
    if (props.validationError != null && props.validationError != null) {
      labelColour = props.validationErrorColour || "#ff0000";
    }
    //Get the text for the question label
    let labelText = <Text {...labelTextProps} color={labelColour}>
      {questionLabel}
    </Text>

    if (questionLabel == null) {
      labelText = null;
    }

    let descriptionText = <Text {...labelTextProps} fontSize={"14px"} fontStyle={"italic"}>
      {questionDescription}
    </Text>
    if (questionDescription == null) {
      descriptionText = null;
    }
    
    
    const errorText = <Text {...errorTextProps} top={"0px"}>
      {errors.questionError}
    </Text>

    let renderedRequiredAsterisk = null;
    if (required == true) {
      renderedRequiredAsterisk = requiredAsterisk;
    }

    //Ensure that timeAnswer has an ASCII value to display
    if (timeAnswer == null) {
      setTimeAnswer("");
    }
    
    if (errors.errorMessage === null) {
      setErrors({
        questionError:""
      });
    }

    //Generate the text field component
    const startTimeField = <TextField
          //color={myTheme.tokens.colors.font.primary.value}
          size={"medium"}
          label={label1}
          errorMessage={errors.questionError}
          value={timeAnswer["startTime"]}
          type="time"
          disabled={answersSuccessfullySubmitted}
          style={{minHeight:"42px", minWidth:"100px"}}

          //Runs when it detects a change in the input field
          onChange={(e) => {
            const currentValue = e.target.value;
            let newAnswer = timeAnswer;
            newAnswer["startTime"] = currentValue

            setTimeAnswer(newAnswer);
            setForceUpdate(forceUpdate + 1);

            const validationResult = validateField(newAnswer, questionValidations);
            const questionHasError = validationResult.hasError;
            const questionErrorMessage = validationResult.errorMessage;
            props.handleChange(questionID, timeAnswer, questionHasError);
            setErrors({
              questionHasError:questionHasError, 
              questionError:questionErrorMessage
            });
          }}
        ></TextField>
    
    const endTimeField = <TextField
        //color={myTheme.tokens.colors.font.primary.value}
        size={"medium"}
        label={label2}
        errorMessage={errors.questionError}
        value={timeAnswer["endTime"]}
        type="time"
        disabled={answersSuccessfullySubmitted}
        style={{minHeight:"42px", minWidth:"100px"}}

        //Runs when it detects a change in the input field
        onChange={(e) => {
          const currentValue = e.target.value;
          let newAnswer = timeAnswer;
          newAnswer["endTime"] = currentValue

          setTimeAnswer(newAnswer);
          setForceUpdate(forceUpdate + 1);

          const validationResult = validateField(newAnswer, questionValidations);
          const questionHasError = validationResult.hasError;
          const questionErrorMessage = validationResult.errorMessage;
          props.handleChange(questionID, timeAnswer, questionHasError);
          setErrors({
            questionHasError:questionHasError, 
            questionError:questionErrorMessage
          });
        }}
      ></TextField>


    if (errors.questionHasError == false && errors.questionError != props.warning) {
      const newErrors = {...errors}
      newErrors.questionError = props.warning
      setErrors(newErrors);
    }

    //Return text field component to calling function (Most likely a Form Template)
    return (
      <Flex direction={flexDirection}>
        <Flex alignItems={"center"} alignSelf={"center"}>
          {renderedRequiredAsterisk}
          {labelText}
        </Flex>
        {descriptionText}
        <Flex direction={flexDirection} alignItems={"center"}>
          <Flex alignItems={"center"}> 
            {startTimeField}
            {endTimeField}
          </Flex>
        </Flex>
        <Flex direction="column" alignItems={"center"} justifyContent={"center"} position={"relative"} backgroundColor={"#cccccc"}>
          {errorText}
        </Flex>
      </Flex>
    );
  }
  catch (error) {
    throw "TimeRangeTemplate Error: " + error;
  }
}

export default TimeRangeTemplate;