import { Button, Flex, Image, Text } from "@aws-amplify/ui-react";
import React, { useState } from "react";
import techSupportEmail from "../Images/Emails/techsupport@nextdoortutor.co.uk.png"
import { Link, Navigate, useNavigate } from "react-router-dom";

export const ErrorLoadingPage = function(props) {
	const height = props.height;
	const width = props.width;

	const [redirect, setRedirect] = useState(null);

	const contactPerson = "contact";
	const domain = "nextdoortutor.co.uk";

	const errorText = <Text fontSize={"25px"} color={"#08007d"} textAlign={"center"}>Error loading page... Try refreshing browser</Text>
	const helpText = <Text fontSize={"25px"} color={"#08007d"} textAlign={"center"}>If this issue persists, please email {contactPerson}{String.fromCharCode(64)}{domain} or report a bug</Text>
	// let techSupportEmailImage = <Image src={techSupportEmail} width={"23vw"}/>
	// if (width < 900) {
	// 	techSupportEmailImage = <Image src={techSupportEmail} width={"80vw"}/>
	// }

	const helpTextEmail = <Flex direction={"column"} gap={"10px"} alignItems={"center"}>
		{helpText}
		{/* {techSupportEmailImage} */}
	</Flex>

	const bugReportButton = <Button
		onClick={() => {
			if (props.resetErrorBoundary != null) {
				props.resetErrorBoundary();
			}
			else {
				setRedirect(<Navigate 
					to={"/ReportIssue"}
					state={{defaultFormType: "bug"}} 
				/>);
			}
		}}
		maxWidth={"300px"}
	>
		Report a bug
	</Button>


	return (
		<Flex direction={"column"} style={{minHeight:height}} gap={"10vh"} alignItems={"center"}>
			{errorText}
			{bugReportButton}
			{helpTextEmail}
			{redirect}
		</Flex>
	);
}

