import React from 'react';
import { LoadingSpinnerPage } from '../LoadingPage';
import { Button, Flex, Text } from '@aws-amplify/ui-react';
import {Link, Navigate, useLocation} from "react-router-dom";
import { ErrorLoadingPage } from '../ErrorLoadingPage';
import BackButton from '../../custom-ui-components/BackButton';

const ViewLessonRequest = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    const requestTextProps = {...standardTextProps}
    requestTextProps.textAlign = "left";
    delete requestTextProps.maxWidth;
    //Get the current user to pass to subsequent functions
    const user = props.user;
    
    const location = useLocation();
    const extraProps = location.state;
    if (extraProps == null) {
        return <Navigate to={"/"} />
    }

    const UIs = [];

    let userType = "student";
    if (extraProps.userType == "parent") {
        userType = "parent";
    }
    else if (extraProps.userType == "parentStudent") {
        userType = "parentStudent";
    }

    let studentNames = null;
    const studentModels = extraProps.studentModels;

    if (userType == "parent" || userType == "parentStudent") {
        if (studentModels == null) {
            return <LoadingSpinnerPage />
        }
        else if (studentModels == "loading") {
            return <LoadingSpinnerPage />
        }
        else if (studentModels == "error") {
            return <ErrorLoadingPage />
        }

        let studentIDs = null;
        if (extraProps.studentID != null) {
            studentIDs = [extraProps.studentID]
        }
        else {
            studentIDs = extraProps.studentIDs
        }
        
        studentNames = "";
        for (let studentIndex = 0; studentIndex < studentModels.length; studentIndex = studentIndex + 1) {
            const studentModel = studentModels[studentIndex];
            studentNames = studentNames + studentModel.firstNames + " " + studentModel.lastName;
            if (studentIndex < studentModels.length - 2) {
                studentNames = studentNames + ", ";
            }
            else if (studentIndex < studentModels.length - 1) {
                studentNames = studentNames + " and ";
            }
        }
    }

    let studentTitle = "Student:";
    if (studentModels.length > 1) {
        studentTitle = "Students:"
    }
    let studentNameText = <Text {...standardTextProps} key={"studentNameText"}>
        {studentTitle} {studentNames}
    </Text>

    if (studentNames == null) {
        studentNameText = null;
    }
    UIs.push(studentNameText);

    const availabilityUI = [];
    const availabilityDays = Object.entries(extraProps.availability);
    const availabilityTitleText = <Text {...standardTextProps} key={"availabilityTitleText"} >
        Given availability:
    </Text>
    availabilityUI.push(availabilityTitleText);
    const dayOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const dayFlexes = [];
    for (const day of availabilityDays) {
        const dayName = day[0];
        let dayIndex = null;
        for (let validDayIndex = 0; validDayIndex < dayOrder.length; validDayIndex = validDayIndex + 1) {
          const validDay = dayOrder[validDayIndex];
          if (validDay == dayName) {
            dayIndex = validDayIndex;
          }
        }
        const dayAvailability = day[1];

        let dayAvailabilityText = "";
        const dayAvailabilityTextArray = [];
        for (let availabilityIndex = 0; availabilityIndex < dayAvailability.length; availabilityIndex = availabilityIndex + 1) {
            const availability = dayAvailability[availabilityIndex];
            const startTime = availability.startTime;
            const endTime = availability.endTime;
            
            let inserted = false;
            const startTimeHour = parseInt(startTime.split(":")[0]);
            const startTimeMinute = parseInt(startTime.split(":")[1]);
            const startTimeInt = (startTimeHour * 60) + startTimeMinute;
            const windowObject = {
                startTime: startTime,
                endTime: endTime,
                startTimeInt: startTimeInt
            };
            for (let windowIndex = 0; windowIndex < dayAvailabilityTextArray.length; windowIndex = windowIndex + 1) {
                const window = dayAvailabilityTextArray[windowIndex];
                if (startTimeInt < window.startTimeInt && inserted == false) {
                    dayAvailabilityTextArray.splice(windowIndex, 0, windowObject)
                    inserted = true;
                }
            }
            if (inserted == false) {
                dayAvailabilityTextArray.push(windowObject);
            }
        }
        const timesFlex = [];
        timesFlex.push(<Text {...standardTextProps} key={"DayName:" + dayName}>{dayName}:</Text>);
        for (const window of dayAvailabilityTextArray) {
            dayAvailabilityText = dayAvailabilityText + (window.startTime + " - " + window.endTime) + ",\n";
            const timeText = <Text {...standardTextProps} fontSize={"18px"} key={dayName + window.startTime}>{window.startTime} - {window.endTime}</Text>
            timesFlex.push(timeText);
        }
        let dayAvailabilityFlex = null;
        if (dayAvailabilityText != "") {
            dayAvailabilityFlex = <Flex 
                {...requestTextProps} 
                key={dayName + "Flex"} 
                direction={"column"} 
                textAlign={"start"} 
                alignItems={"flex-start"}
                gap={"0vh"}
            >
                {timesFlex}
            </Flex>
        }
        dayFlexes[dayIndex] = dayAvailabilityFlex;
    }
    for (const dayFlex of dayFlexes) {
        if (dayFlex != null) {
            availabilityUI.push(dayFlex);
        }
    }

    UIs.push(
        <Text {...requestTextProps} key={"subject"}>
            Subject: {extraProps.subject}
        </Text>,
        <Text {...requestTextProps} key={"lessonType"}>
            Lesson type: {extraProps.lessonType}
        </Text>,
        <Text {...requestTextProps} key={"numSessions"}>
            Lessons per week: {extraProps.numSessions}
        </Text>,
        <Text {...requestTextProps} key={"sessionLength"}>
            Session length: {extraProps.sessionLength}
        </Text>
    );
    let requestedStartDate = extraProps.requestedStartDate;
    if (extraProps.requestedStartDate == new Date().toDateString()) {
        requestedStartDate = "As soon as possible";
    }
    UIs.push(
        <Text {...requestTextProps} key={"requestedStartDate"}>
            Requested start date: {requestedStartDate}
        </Text>,
    );
    UIs.push(
        <Flex direction={"column"} alignItems={"flex-start"} key={"availability"}>
            {availabilityUI}
        </Flex>
    );
    if (extraProps.lessonType != "Online") {
        UIs.push(
            <Text {...requestTextProps} key={"address1stLine"}>
                Requested address: {extraProps.address.address1stLine}, {extraProps.address.postcode}
            </Text>
        );
    }
    if (extraProps.notes != null) {
        UIs.push(
            <Text {...requestTextProps} key={"notes"}>
                Notes: {extraProps.notes}
            </Text>
        );
    }

    const requestFlex = <Flex direction={"column"} alignItems={"start"}>
        {UIs}
    </Flex>
    
    const titleText = <Text {...titleTextProps}>View lesson request</Text>
    
    let backLink = "/Student/LessonRequests";
    let state = null;
    if (userType == "parent") {
        backLink = "/Parent/LessonRequests";
    }
    else if (userType == "parentStudent") {
        backLink = "/Parent/MyStudent";
        state = {student: extraProps.parentsStudent};
    }

    const backButton = <BackButton {...props} defaultBack={backLink} />

    return (
        <Flex direction={"column"} gap={"4vh"} alignItems={"center"}> 
            <Flex direction={"column"} gap={"4vh"} alignItems={"center"}>
                {titleText}
                <Flex gap={"4vw"}>
                    {backButton}
                </Flex>
            </Flex>
            <Flex direction={"column"} gap={"4vh"} alignItems={"flex-start"} width={"fit-content"}>                
                {requestFlex}
            </Flex>
        </Flex>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default ViewLessonRequest;