import { Flex, Text } from '@aws-amplify/ui-react';
import React from 'react';
import {Link, Navigate, useLocation} from "react-router-dom";
import {AppNavButton, FormTemplate} from '../../custom-ui-components'
import { useState } from 'react';
import * as Auth from 'aws-amplify/auth';

const AgreeTerms = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    const APIFunctions = props.APIFunctions;
    const [questionAnswers, setQuestionAnswers] = useState({});

    //Get the current user to pass to subsequent functions
    const user = props.user;
    const userAttributes = user.attributes

    const location = useLocation();
    const extraProps = location.state;
    if (extraProps == null || extraProps.newUpdates == null) {
        return <Navigate to={"/"} />
    }
    const newUpdates = extraProps.newUpdates;
    const neededAttributes = [];


    //Create an object for each needed question that is passed to the form template
    let questions = [];

    questions.push({
        id: "name", 
        label: "Full Name:", 
        type: "text", 
        required: true, 
        validations: [{type: "Name"}], 
        defaultValue: null, 
        placeholder: "Enter name"
    });
    
    const submitAnswers = async function(returnedAnswers) {
        setQuestionAnswers(returnedAnswers);
        console.log("Returned Answers");
        const answers = Object.entries(returnedAnswers);

        const newAttributes = [];
        for (const answer of answers) {
            const questionID = answer[0];
            const answerValue = answer[1];

            if (questionID === "name") {
                const newAttribute = {Name:"name", Value:answerValue};
                newAttributes.push(newAttribute)
            }
            else if (questionID === "phoneNumber") {
                const newAttribute = {Name:"custom:phoneNumber", Value:answerValue};
                newAttributes.push(newAttribute)
            }
        }

        try {
            const updateAttributesSuccess = await APIFunctions.updateUserAttributes(newAttributes);
            console.log(updateAttributesSuccess);
            console.log(Auth.getCurrentUser().attributes);

        }
        catch (error) {
            console.log("Error updating attributes. Given error:\n" + error);
        }
    }

    const signOutButton = <Link to={"/SignOut"} style={{textDecoration: 'none'}}> 
    <AppNavButton text={"Sign Out"} width={props.width} />
    </Link>

    return (  
        <Flex direction={"column"} justifyContent="center" alignItems="center" gap="1vh" wrap={"wrap"}>
            <Flex marginBottom={"2vh"}>
                {signOutButton}
            </Flex>
            <FormTemplate {...props} submitAnswers={submitAnswers} existingAnswers={questionAnswers} questions={questions} okButtonFunction={()=>{}} />
        </Flex>
    );
}


//Return the correct page back up to the app and index files (with authenticator to force login)
export default AgreeTerms;