import { React, useEffect, useState } from "react";
import { Button, CheckboxField, Flex, Grid, Icon, Text } from "@aws-amplify/ui-react";
import { calculateLessonCost, calculateLessonWage, compareObjects, priceValuetoString } from "../../App";
import { LoadingSpinner, LoadingSpinnerPage } from "../LoadingPage";
import {MdCached} from 'react-icons/md';
import CheckBoxesTemplate from "../../custom-ui-components/form-components/CheckBoxesTemplate";
import { Link } from "react-router-dom";
import BackButton from "../../custom-ui-components/BackButton";


const PayTutors = function(props) {
  const titleTextProps = props.titleTextProps;
  const standardTextProps = props.standardTextProps;
  const APIFunctions = props.APIFunctions;
  const tutors = props.tutors;

  const titleText = <Text {...titleTextProps}>Pay Tutors</Text>
  const airwallexBalance = props.airwallexBalance; 

  const [airwallexBalanceText, setAirwallexBalanceText] = useState(null);
  const [tutorUIs, setTutorUIs] = useState(null);
  const [sortedTutors, setSortedTutors] = useState(null);
  const [selectedTutors, setSelectedTutors] = useState({});
  const [totalCost, setTotalCost] = useState(0);
  const [totalCostUI, setTotalCostUI] = useState(null);
  const [hideIncompletePaymentDetails, setHideIncompletePaymentDetails] = useState(false);
  const [airwallexBalanceEnough, setAirwallexBalanceEnough] = useState(true);
  const [payTutorsButton, setPayTutorsButton] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  //Get Airwallex balance
  useEffect(() => {
    if (airwallexBalance == null) {
      APIFunctions.getAirwallexBalance();
    }

    if (airwallexBalance != null) {
      setAirwallexBalanceText(
        <Text {...standardTextProps}>Airwallex Balance: {priceValuetoString(airwallexBalance)}</Text>
      );
    }
    else {
      setAirwallexBalanceText(
        <Flex>
          <Text {...standardTextProps}>Airwallex Balance:</Text>
          <LoadingSpinner />
        </Flex>
      );
    }
  }, [airwallexBalance, APIFunctions]);

  //Sort tutors
  useEffect(() => {
    if (tutors != null) {
      const newSortedTutors = tutors.sort((tutor1, tutor2) => {
        if (tutor1.bankDetails == null && tutor2.bankDetails != null) {
          return 1;
        }
        else if (tutor1.bankDetails != null && tutor2.bankDetails == null) {
          return -1;
        }
        else if (tutor1.firstNames < tutor2.firstNames) {
          return -1;
        }
        else {
          return 1
        }
      });
      setSortedTutors(newSortedTutors);
    }
  }, [tutors]);

  //Create tutor UIs
  useEffect(() => {
    if (sortedTutors == null) {
      setTutorUIs(<LoadingSpinner {...props} height={"50px"}/>);
    }
    else {
      //Get tutors with balance over 0
      const newTutorUIs = [];
      const newSelectedTutors = {...selectedTutors};
      for (const tutor of sortedTutors) {
        if (tutor.credit != null && tutor.credit > 0) {
          console.log(tutor.id, tutor.firstNames, tutor.lastName, tutor.credit);
          let tutorCanBePaid = true;
          let hasBankDetails = true;
          if (tutor.bankDetails == null) {
            tutorCanBePaid = false;
            hasBankDetails = false;
          }
          if (tutor.credit < 9.37) {
            tutorCanBePaid = false;
          }

          if (newSelectedTutors[tutor.id] == null) {
            newSelectedTutors[tutor.id] = {
              credit: tutor.credit,
              selected: tutorCanBePaid,
              airwallexID: tutor.bankDetails
            };
            setSelectedTutors(newSelectedTutors);
          }

          if (tutorCanBePaid || !hideIncompletePaymentDetails) {
            let unableToPayText = null;
            let tutorCheckbox = <Flex width={"20px"}/>;
            if (!hasBankDetails) {
              unableToPayText = <Text {...standardTextProps}>(No Airwallex ID)</Text>
            }
            else if (!tutorCanBePaid) {
              unableToPayText = <Text {...standardTextProps}>(Balance under £9.37)</Text>
            }
            else {
              tutorCheckbox = <CheckboxField 
                size={"large"}
                checked={newSelectedTutors[tutor.id].selected}
                onChange={(event) => {
                  const updateSelectedTutors = {...selectedTutors};
                  const newSelectedTutor = {...updateSelectedTutors[tutor.id]};
                  newSelectedTutor.selected = event.target.checked;
                  updateSelectedTutors[tutor.id] = newSelectedTutor;
                  setSelectedTutors(updateSelectedTutors);
                }}
                disabled={!tutorCanBePaid}
              />
            }

            const tutorUI = <Flex key={tutor.id}>
              {tutorCheckbox}
              <Text {...standardTextProps}>{tutor.firstNames + " " + tutor.lastName}</Text>
              {unableToPayText}
              <Text {...standardTextProps}>{priceValuetoString(tutor.credit)}</Text>
            </Flex>
            
            newTutorUIs.push(tutorUI);
          }
        }
      }
      setTutorUIs(
        <Flex 
          direction={"column"} 
          alignItems={"flex-start"}
          style={{
            borderStyle: "solid",
            borderColor: "#0000ff",
            borderWidth: "3px"
          }}
          borderRadius={"20px"}
          padding={"10px"}
        >
          {newTutorUIs}
        </Flex>
      );
      if (!compareObjects(selectedTutors, newSelectedTutors)) {
        setSelectedTutors(newSelectedTutors);
      }
    }

  }, [sortedTutors, selectedTutors, hideIncompletePaymentDetails]);

  //Create total cost UI
  useEffect(() => {
    const selectedTutorsArray = Object.values(selectedTutors);
    let newTotalCost = 0;
    for (const selectedTutor of selectedTutorsArray) {
      if (selectedTutor.selected) {
        newTotalCost = newTotalCost + selectedTutor.credit;
      }
    }
    let textColour = standardTextProps.color;
    console.log(airwallexBalance);
    console.log(newTotalCost);
    if (airwallexBalance != null && airwallexBalance < newTotalCost) {
      textColour = "#ff0000"
      if (airwallexBalanceEnough) { 
        setAirwallexBalanceEnough(false);
      }
    }
    else {
      if (!airwallexBalanceEnough) { 
        setAirwallexBalanceEnough(true);
      }
    }
    setTotalCost(newTotalCost);
    setTotalCostUI(
      <Text {...standardTextProps} color={textColour}>Total cost: {priceValuetoString(newTotalCost)}</Text>
    );
  }, [selectedTutors, airwallexBalance, airwallexBalanceEnough]);

  //Create submission button
  useEffect(() => {
    let textColour = standardTextProps.color;
    if (!airwallexBalanceEnough) {
      textColour = "#ff0000"
    }

    setPayTutorsButton(
      <Button
      disabled={(!airwallexBalanceEnough) || totalCost == 0 || submitting}
        onClick={async () => {
          if (airwallexBalanceEnough) {
            setSubmitting(true);
            const selectedTutorsArray = Object.entries(selectedTutors);
            const tutorPayouts = {};
            for (const selectedTutor of selectedTutorsArray) {
              const tutorID = selectedTutor[0];
              const tutorDetails = selectedTutor[1] || {};
              if (tutorDetails.selected == true) {
                tutorPayouts[tutorID] = tutorDetails.credit;
              }
            }
            console.log(tutorPayouts);
            const payoutsReply = await APIFunctions.payTutors(tutorPayouts);
            console.log(payoutsReply);
            setSubmitting(false);
          }
        }}
      >
        <Text {...standardTextProps} color={textColour}>Pay Tutors</Text>
      </Button>
    );
  }, [airwallexBalanceEnough, selectedTutors, totalCost, APIFunctions, submitting]);

  const reloadBalanceButton = <Button
    key={"reloadBalanceButton"}
    onClick={() => {
      props.setAirwallexBalance(null); 
      APIFunctions.getAirwallexBalance();
    }}
    disabled={airwallexBalance == null}
  >
    <Icon
      key={"refreshIcon"}
      width={"25px"}
      height={"25px"}
      as={MdCached}
    />
  </Button>

  const selectAllButton = <Button
    onClick={() => {
      const newSelectedTutors = {...selectedTutors}
      const selectedTutorsArray = Object.entries(selectedTutors);
      for (const selectedTutor of selectedTutorsArray) {
        const tutorID = selectedTutor[0];
        const newSelectedTutor = {...selectedTutor[1]};
        if (newSelectedTutor.airwallexID != null) {
          newSelectedTutor.selected = true;
          newSelectedTutors[tutorID] = newSelectedTutor;
        }
      }
      setSelectedTutors(newSelectedTutors);
    }}
  >
    <Text {...standardTextProps} margin={"-5px"} fontSize={"20px"}>Select All</Text>
  </Button>

  const deselectAllButton = <Button
    onClick={() => {
      const newSelectedTutors = {...selectedTutors}
      const selectedTutorsArray = Object.entries(selectedTutors);
      for (const selectedTutor of selectedTutorsArray) {
        const tutorID = selectedTutor[0];
        const newSelectedTutor = {...selectedTutor[1]};
        if (newSelectedTutor.airwallexID != null) {
          newSelectedTutor.selected = false;
          newSelectedTutors[tutorID] = newSelectedTutor;
        }
      }
      setSelectedTutors(newSelectedTutors);
    }}
    >
    <Text {...standardTextProps} margin={"-5px"} fontSize={"20px"}>Deselect All</Text>
  </Button>

  let hideIncompletePaymentDetailsText = "Hide tutors with incomplete payment details";
  if (hideIncompletePaymentDetails) {
    hideIncompletePaymentDetailsText = "Show tutors with incomplete payment details"
  }

  const showHideIncompleteTutors = <Button
    onClick={() => {
      setHideIncompletePaymentDetails(!hideIncompletePaymentDetails);
    }}
    >
    <Text {...standardTextProps} margin={"-5px"} fontSize={"20px"}>{hideIncompletePaymentDetailsText}</Text>
  </Button>

  const backButton = <BackButton {...props} defaultBack={"/Admin"} />

  return (
      <Flex direction={"column"} gap={"3vh"} alignItems={"center"} >
        {titleText}
        {backButton}
        <Flex>
          {airwallexBalanceText}
          {reloadBalanceButton}
        </Flex>
        {showHideIncompleteTutors}
        <Flex>
          {selectAllButton}
          {deselectAllButton}
        </Flex>
        {tutorUIs}
        {totalCostUI}
        {payTutorsButton}
      </Flex>
  );
}

export default PayTutors;