import { Button, Flex, Icon, Text } from '@aws-amplify/ui-react';
import {React, useEffect, useState } from 'react';
import { DropDownQuestionTemplate } from '../../custom-ui-components';
import AutoCompleteTemplate from '../../custom-ui-components/form-components/AutoCompleteTemplate';
import CheckBoxesTemplate from '../../custom-ui-components/form-components/CheckBoxesTemplate';
import TimeRangeTemplate from '../../custom-ui-components/form-components/TimeRangeTemplate';
import { LoadingSpinner, LoadingSpinnerPage } from '../LoadingPage';
import {MdCached, MdSouth, MdNorth} from 'react-icons/md';
import { ErrorLoadingPage } from '../ErrorLoadingPage';
import TransactionTemplate from '../../custom-ui-components/TransactionsTemplate';
import { Link } from 'react-router-dom';
import BackButton from '../../custom-ui-components/BackButton';

const ViewTransactions = function(props) {
    const width = props.width;
    const userType = props.userType;
    let transactions = props.userTransactions;
    if (userType == "admin") {
        transactions = props.adminTransactions;
    }
    
    const titleTextProps = {...props.titleTextProps};
    const standardTextProps = {...props.standardTextProps};
    //Get the current user to pass to subsequent functions
    const user = props.user;
    
    let defaultSortBy = "Sort by date";
    if (userType == "admin") {
        defaultSortBy = "Sort by status";
    }

    const noFilters = {Day:false, Time:false, Subject:false, Tutor:false, Student:false}
    const [transactionFilters, setTransactionFilters] = useState(noFilters);
    const [showFilters, setShowFilters] = useState(false);
    const showNoDaysValue = {Monday: false, Tuesday: false, Wednesday: false, Thursday: false, Friday: false, Saturday: false, Sunday: false};
    const [shownDays, setShownDays] = useState(showNoDaysValue);
    const [shownSubject, setShownSubject] = useState("");
    const [shownTutor, setShownTutor] = useState(null);
    const [sortBy, setSortBy] = useState(defaultSortBy);
    const [sortOrder, setSortOrder] = useState("down");
    //const showNoTimesValue = {Monday: false, Tuesday: false, Wednesday: false, Thursday: false, Friday: false, Saturday: false, Sunday: false};
    const [shownTimeRange, setShownTimeRange] = useState({startTime:"00:00", endTime: "23:59"});
    const [numRefreshes, setNumRefreshes] = useState(0);
    const [transactionUIs, setTransactionUIs] = useState(null);
    const [shownTransactionUIs, setShownTransactionUIs] = useState(null);

    useEffect(() => {
        if (transactions != null && transactions != "loading" && transactions != "error") {
            getTransactionUIs();
        }
    }, []);
    
    if (transactions == null) {
        return <LoadingSpinnerPage {...props} />
    }
    else if (transactions == "loading") {
        return <LoadingSpinnerPage {...props} />
    }
    else if (transactions == "error") {
        return <ErrorLoadingPage {...props}/>
    }

    const updateShownTransactionUIs = function(givenTransactionUIs) {
        if (givenTransactionUIs == null) {
            givenTransactionUIs = transactionUIs
        }
        setShownTransactionUIs("loading");
        const newShownUIs = [];
        for (const transactionUI of givenTransactionUIs) {
            newShownUIs.push(transactionUI);
        }
        const sortedUIs = updateSortOrder(newShownUIs, true);
        setShownTransactionUIs(sortedUIs);
    }
    const updateSortOrder = function(givenShownUIs, returnInstead = false, newSortBy, newSortOrder = null, recursiveCallNum = 0) {
        setShownTransactionUIs("loading");
        recursiveCallNum = recursiveCallNum + 1;
        let newShownUIs = [];
        if (transactionUIs != null && givenShownUIs == null) {
            newShownUIs = [...transactionUIs];
        }
        else if (givenShownUIs != null) {
            newShownUIs = [...givenShownUIs];
        }
        if (newSortBy == null) {
            newSortBy = sortBy;
        }
        if (newSortOrder == null) {
            newSortOrder = sortOrder;
        }
        
        if ((newSortBy == "Sort by value" || newSortBy == "Sort by status") && recursiveCallNum == 1) {
            newShownUIs = [...updateSortOrder(newShownUIs, true, "Sort by date", "down", recursiveCallNum)];
        }

        newShownUIs.sort((a, b) => {
            const transactionA = a.props.transaction;
            const transactionB = b.props.transaction;

            if (newSortBy == "Sort by date") {
                const transactionACreationDate = new Date(transactionA.createdAt);
                const transactionBCreationDate = new Date(transactionB.createdAt);
                if (newSortOrder == "down") {
                    return transactionBCreationDate.valueOf() - transactionACreationDate.valueOf();
                }
                else {
                    return transactionACreationDate.valueOf() - transactionBCreationDate.valueOf();
                }
            }
            else if (newSortBy == "Sort by value") {
                const purchasedItemsA = transactionA.purchasedItems;
                const purchasedItemsB = transactionB.purchasedItems;
                let totalCostA = 0;
                for (const item of purchasedItemsA) {
                    const itemCost = item.totalItemCost/100;
                    totalCostA = totalCostA + itemCost;
                }
                let totalCostB = 0;
                for (const item of purchasedItemsB) {
                    const itemCost = item.totalItemCost/100;
                    totalCostB = totalCostB + itemCost;
                }
                if (newSortOrder == "down") {
                    return totalCostB - totalCostA;
                }
                else {
                    return totalCostA - totalCostB;
                }
            }
            else if (newSortBy == "Sort by status") {
                const completedA = transactionA.completed;
                const refundedA = transactionA.refunded;
                const refundRequestedA = transactionA.refundRequested;
                let statusStringA = "Invalid Status";
                if (refundedA == false) {
                    if (refundRequestedA == true) {
                        statusStringA = "Refund Requested";
                    }
                    else if (completedA != true) {
                        statusStringA = "Pending";
                    }
                    else if (completedA == true) {
                        statusStringA = "Completed";
                    }
                }
                else if (refundedA != false) {
                    statusStringA = "Refunded";
                }

                const completedB = transactionB.completed;
                const refundedB = transactionB.refunded;
                const refundRequestedB = transactionB.refundRequested;
                let statusStringB = "Invalid Status";
                if (refundedB == false) {
                    if (refundRequestedB == true) {
                        statusStringB = "Refund Requested";
                    }
                    else if (completedB != true) {
                        statusStringB = "Pending";
                    }
                    else if (completedB == true) {
                        statusStringB = "Completed";
                    }
                }
                else if (refundedB != false) {
                    statusStringB = "Refunded";
                }
                if (statusStringA == statusStringB) {
                    return 0;
                }

                let returnValue = 1;
                if (newSortOrder == "up") {
                    returnValue = -1
                }

                if (statusStringA == "Pending" && statusStringB == "Completed") {
                    return -returnValue;
                }
                else if (statusStringA == "Completed" && statusStringB == "Pending") {
                    return returnValue;
                }
                
                else if (statusStringA == "Refund Requested" && statusStringB == "Completed") {
                    return -returnValue;
                }
                else if (statusStringA == "Completed" && statusStringB == "Refund Requested") {
                    return returnValue;
                }
                
                else if (statusStringA == "Completed" && statusStringB == "Refunded") {
                    return -returnValue;
                }
                else if (statusStringA == "Refunded" && statusStringB == "Completed") {
                    return returnValue;
                }

                else if (statusStringA == "Refund Requested" && statusStringB == "Refunded") {
                    return -returnValue;
                }
                else if (statusStringA == "Refunded" && statusStringB == "Refund Requested") {
                    return returnValue;
                }

                else if (statusStringA == "Pending" && statusStringB == "Refunded") {
                    return -returnValue;
                }
                else if (statusStringA == "Refunded" && statusStringB == "Pending") {
                    return returnValue;
                }

                else if (statusStringA == "Pending" && statusStringB == "Refund Requested") {
                    return -returnValue;
                }
                else if (statusStringA == "Refund Requested" && statusStringB == "Pending") {
                    return returnValue;
                }
                else {
                    console.log("error checking status order");
                }
                
            }
        });
        if (returnInstead) {
            return newShownUIs;
        }
        else {
            setShownTransactionUIs(newShownUIs);
        }
    }
    const getTransactionUIs = function() {
        setTransactionUIs("loading");
        const newTransactionUIs = [];
        for (const transaction of transactions) {
            transaction.type = "Credit Purchase";
            const transactionUI = <TransactionTemplate transaction={transaction} key={transaction.checkOutSessionID} userType={userType} />
            newTransactionUIs.push(transactionUI);
        }
        setTransactionUIs(newTransactionUIs);
        updateShownTransactionUIs(newTransactionUIs);
    }

    if (transactionUIs == null) {
        getTransactionUIs();
    }

    if (transactionUIs == null || transactionUIs == "loading") {
        return <LoadingSpinnerPage {...props} />
    }


    const titleText = <Text {...titleTextProps} >
        Your transactions
    </Text>

    const lessonsFoundText = <Text {...standardTextProps} fontSize={"18px"}>
        Transactions found: {shownTransactionUIs.length}
    </Text>

    const backButton = <BackButton {...props} defaultBack={"/LessonCredits"} />
    

    let showFiltersText = "Show Filters";
        if (showFilters == true) {
            showFiltersText = "Hide Filters"
        }
    const showFiltersButton = <Button
        key={"ShowFiltersButton"}
        onClick={() => {
            const previousLessonFilters = {...transactionFilters};
            if (showFilters == true) {
                setShowFilters(false);
            }
            else {
                setShowFilters(true);
            }
        }}
    >
        {showFiltersText}
    </Button>

    let refreshButtonDisabled = false;
    if (numRefreshes >= 10) {
        refreshButtonDisabled = true
    }

    let filterDirection = "row";
    if (width < 900) {
        filterDirection = "column"
    }
    const getFilterFlex = function () {
        const mainFilterTextProps = {...standardTextProps}
        const filterTextProps = {...mainFilterTextProps}
        mainFilterTextProps.fontSize = "20px"
        filterTextProps.fontSize = "18px";
        
        const checkboxTextProps = {...standardTextProps}
        checkboxTextProps.fontSize = "16px"

        const filterMenu = <CheckBoxesTemplate
            questionLabelProps = {mainFilterTextProps}
            checkboxTextProps = {checkboxTextProps}
            id = "FilterMenu"
            label = "Filter by:"
            options = {["Day", "Time", "Subject", "Tutor"]}
            defaultValue = {transactionFilters}
            handleChange = {(questionID, selectedAnswers, errors) => {
                const newFilters = {...selectedAnswers}
                setTransactionFilters(newFilters);
            }}
            direction = {filterDirection}
        />

        const dayFilters = <CheckBoxesTemplate
            questionLabelProps = {filterTextProps}
            checkboxTextProps = {checkboxTextProps}
            id = "DayFilter"
            label = "Show days:"
            options = {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]}
            defaultValue = {shownDays}
            handleChange = {(questionID, selectedAnswers, errors) => {
                const newDayFilters = {...selectedAnswers}
                setShownDays(newDayFilters);
            }}
            direction = {filterDirection}
        />
        

        const timeFilter = <TimeRangeTemplate
            questionLabelProps = {filterTextProps}
            id = "TimeFilter"
            label = "Start time range:"
            label1={""}
            label2={""}
            defaultValue = {{startTime:shownTimeRange.startTime, endTime:shownTimeRange.endTime}}
            questionErrorProps = {{position:"absolute", style:{display:"none"}}}
            handleChange = {(questionID, selectedAnswer, errors) => {
                const startTime = selectedAnswer.startTime;
                const startHour = parseInt(startTime.split(":")[0]);
                const startMinute = parseInt(startTime.split(":")[1]);
                let startInt = (startHour * 60) + startMinute;
                const endTime = selectedAnswer.endTime;
                const endHour = parseInt(endTime.split(":")[0]);
                const endMinute = parseInt(endTime.split(":")[1]);
                let endInt = (endHour * 60) + endMinute;
                
                if (!(startInt >= 0)) {
                    startInt = null;
                }
                if (!(endInt >= 0)) {
                    endInt = null;
                }
                setShownTimeRange({startTime:startTime, endTime:endTime, startInt:startInt, endInt:endInt});
            }}
            direction = {filterDirection}
        />

        const filterFlex = <Flex alignItems={"center"} gap={"1vh"} direction={"column"}>
            {filterMenu}
            {transactionFilters.Day && dayFilters}
            {transactionFilters.Time && timeFilter}
        </Flex>

        return filterFlex;
    }

    const filterFlex = getFilterFlex();

    const sortByDropDown = <DropDownQuestionTemplate
        id = {"sortBy"}
        key={"sortBy" + sortBy}
        options = {["Sort by date", "Sort by value", "Sort by status"]}
        defaultValue = {sortBy}
        placeHolder = {null}
        handleChange = {(questionID, selectedAnswer, errors) => {
            if (selectedAnswer != null) {
                setSortBy(selectedAnswer);
                //updateSortOrder(null, false, null, selectedAnswer, null);
                updateSortOrder();
            }
        }}
        direction = {filterDirection}
    />

    const toggleSortDirection = function () {
        if (sortOrder === "down") {
            setSortOrder("up");
            updateSortOrder(null, false, null, "up");
        }
        else {
            setSortOrder("down");
            updateSortOrder(null, false, null, "down");
        }
    }
    const iconSize = "40";
    let iconMD = MdNorth;
    if (sortOrder === "down") {
        iconMD = MdSouth;
    }
    const orderIcon = <Button 
        style={{cursor:"pointer"}} 
        onClick={toggleSortDirection}
        width={iconSize + "px"}
        height={iconSize + "px"}
        justifyContent={"center"}
        alignItems={"center"}
        
    >
        <Icon
            key={"orderChangeButton"}
            width={"30px"}
            height={"30px"}
            as={iconMD}
        />
    </Button>
    
    return (
        <div className='StudentLessonsPage'>
            <Flex alignItems={"center"} gap={"3vh"} direction={"column"} marginBottom={"4vh"}>
                    {backButton}
                    {titleText}
                <Flex gap={"2vh"} alignItems={"center"} direction={"column"}>
                    <Flex alignItems={"center"}>
                        {/* {showFiltersButton} */}
                        {sortByDropDown}
                        {orderIcon}
                    </Flex>
                    {/* {showFilters && filterFlex} */}
                    <Flex direction={"column"} alignItems={"center"}>
                        <Flex alignItems={"center"}>
                            {lessonsFoundText}
                        </Flex>
                        <Flex direction={"column"} alignItems={"center"} gap={"5vh"}>
                            {shownTransactionUIs}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </div>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default ViewTransactions;