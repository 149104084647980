import { Flex, Text } from "@aws-amplify/ui-react";
import {React, useEffect, useState} from "react";
import "./Spinner.css";

export const LoadingSpinnerPage = function(props) {
  const [tipText, setTip] = useState(null);
  useEffect(() => {
    setTip(getRandomTip());
  }, []);

  const userIsParent = props.userIsParent;
  const userIsStudent = props.userIsStudent;
  const userIsTutor = props.userIsTutor;
  const userIsAdmin = props.userIsAdmin;

  const getRandomTip = function() {
    const tips = [
      "Holding the control/command key when pressing a button will skip any confirmation pop-up",
      "General tip 2",
      "General tip 3",
      "General tip 4",
      "General tip 5"
    ];
  
    const adminTips = [
      "You can view pending and complete lesson requests by checking the filters when viewing lesson requests as an admin",
      "Admin tip 2",
      "Admin tip 3",
      "Admin tip 4",
      "Admin tip 5"
    ];
  
    const tutorTips = [
      "Tutor tip 1",
      "Tutor tip 2",
      "Tutor tip 3",
      "Tutor tip 4",
      "Tutor tip 5"
    ];
  
    const studentTips = [
      "Student tip 1",
      "Student tip 2",
      "Student tip 3",
      "Student tip 4",
      "Student tip 5"
    ];
  
    const parentTips = [
      "Parent tip 1",
      "Parent tip 2",
      "Parent tip 3",
      "Parent tip 4",
      "Parent tip 5"
    ];

    if (userIsAdmin) {
      tips.push(...adminTips);
    }
    if (userIsTutor) {
      tips.push(...tutorTips);
    }
    if (userIsStudent) {
      tips.push(...studentTips);
    }
    if (userIsParent) {
      tips.push(...parentTips);
    }

    let tipNumber = Math.floor(Math.random() * (tips.length));
    if (tipNumber == tips.length) {
      tipNumber = tipNumber - 1;
    }
    const tip = "Tip: " + tips[tipNumber];
    return tip;
  }

  let tip = <Text fontSize={"18px"}>{tipText}</Text>;

  if (props.settings != null) {
    if (props.settings.showHints == false) {
      tip = null;
    }
  }

  //const titleTextProps = props.titleTextProps;
  //const standardTextProps = props.standardTextProps;
  const height = props.height;
  return (
    <div >
      <div className="spinner-container" style={{minHeight:height}}>
        <Flex direction={"column"} marginTop={"10vh"} >
          <h1>Loading</h1>
          <div className="loading-spinner"></div>
          <h1>Please Wait</h1>
          {/* {tip} */}
        </Flex>
      </div>
    </div>
  );
}

export const LoadingSpinner = function(props) {
  //const titleTextProps = props.titleTextProps;
  //const standardTextProps = props.standardTextProps;
  const height = props.height;
  return (
    <div >
      <div className="spinner-container" style={{minHeight:height}}>
        <Flex direction={"column"} >
          <div className="loading-spinner"></div>
        </Flex>
      </div>
    </div>
  );
}

