import { Badge, Flex, Grid, Icon, Text } from "@aws-amplify/ui-react";
import { Link, Navigate } from "react-router-dom";
import {React, useEffect, useState} from 'react';
import {MdModeEdit, MdDelete, MdSettingsBackupRestore} from 'react-icons/md';
import { calculateLessonCost, calculateLessonWage, getDatesForLesson, getStudentNames, getTutorName, modelsStringBuilder } from "../App";
import { LoadingSpinner } from "../pages/LoadingPage";

const LessonTemplate = function(props) {
    const [redirect, setRedirect] = useState(null);
    const [nextLessonString, setNextLessonString] = useState(null);
    const [lessonAttributesUI, setLessonAttributesUI] = useState(null);
    const standardTextProps = {...props.standardTextProps};
    const standardTitleProps = {...props.titleTextProps};
    standardTextProps.fontSize = "1.2vw";
    standardTitleProps.fontSize = "1.2vw";

    standardTextProps.fontSize = "17px";
    standardTitleProps.fontSize = "17px";

    const lessonTemplateType = props.lessonTemplateType || "normal";

    let showAddress = props.showAddress;
    if (showAddress == null){
        showAddress = true;
    }

    const width = props.width;
    const height = props.height;
    const amendLessons = props.amendLessons || false;
    const lessonDictionary = props.userType == "admin" && props.adminLessonDictionary || props.lessonDictionary;
    const studentDictionary = props.studentDictionary;
    const parentDictionary = props.parentDictionary;
    const tutorDictionary = props.tutorDictionary;
    const userGroups = props.userGroups;
    const pageType = props.pageType;

    const [studentDetails, setStudentDetails] = useState(null);
    const [tutorDetails, setTutorDetails] = useState(null);
    const [lessonProps, setLessonProps] = useState(null);
    const [noLessonModel, setNoLessonModel] = useState(false);
    const [userType, setUserType] = useState(null);


    //Set request props
    useEffect(() => {
        if (lessonDictionary == null) {
            return;
        }

        const lessonID = props.lessonID;
        if (lessonID == null) {
            console.log("No lessonID ID");
            setNoLessonModel(true);
            return;
        }
        const lessonModel = lessonDictionary[lessonID];
        if (lessonModel == null) {
            console.log("Lesson not found");
            setNoLessonModel(true);
            return;
        }

        setLessonProps(lessonModel);
    }, [lessonDictionary, props.lessonID, window.location.search]);

    //Set user types
    useEffect(() => {
        if (props.userID == null || userGroups == null || lessonProps == null || pageType == null) {
            return;
        }

        const studentIDs = lessonProps.studentIDs || [];
        const tutorID = lessonProps.tutorID;
        const parentID = lessonProps.parentID;
        let isLessonStudent = false;
        let isLessonParent = false;
        let isLessonTutor = false;
        if (studentIDs.includes(props.userID)) {
            isLessonStudent = true;
        }
        if (parentID == props.userID) {
            isLessonParent = true;
        }
        if (tutorID == props.userID) {
            isLessonTutor = true;
        }

        setUserType({
            isLessonStudent: isLessonStudent,
            isLessonParent: isLessonParent,
            isLessonTutor: isLessonTutor,
            userIsAdmin: userGroups.userIsAdmin,
            userType: pageType
        });
    }, [props.userID, pageType, userGroups, lessonProps]);

    //Get student details
    useEffect(() => {
        if (userType == null || lessonProps == null) {
            return;
        }
        if (userType.isLessonParent || userType.userIsAdmin) {
            if (studentDictionary == null) {
                return;
            }
        }
        if (userType.userIsAdmin && parentDictionary == null) {
            return;
        }
        let lessonStudentDetails = {};
        if (userType.isLessonParent || userType.userIsAdmin) {
            const studentIDs = lessonProps.studentIDs;
            if (studentIDs == null) {
                console.log("No student IDs");
                setNoLessonModel(true);
                return;
            }
            //Get student models
            const studentModels = [];
            for (const studentID of studentIDs) {
                const studentModel = studentDictionary[studentID];
                const parentID = lessonProps.parentID;
                if (userType.userIsAdmin && parentID != null) {
                    const lessonParentModel = parentDictionary[parentID];
                    if (lessonParentModel == null) {
                        console.log("Parent model not found");
                        setNoLessonModel(true);
                        return;
                    }
                    studentModel.email = lessonParentModel.email;
                    studentModel.phoneNumber = lessonParentModel.phoneNumber;
                }
                if (studentModel == null) {
                    console.log("Student model not found");
                    setNoLessonModel(true);
                    return;
                }
                studentModels.push(studentModel);
            }
            
            if (userType.userIsAdmin) {
                lessonStudentDetails.email = studentModels[0].email;
                lessonStudentDetails.phoneNumber = studentModels[0].phoneNumber;
            }

            //Get student details
            lessonStudentDetails.numStudents = studentIDs.length;
            const studentDetailsTemplate = [
                {type: "key", key: "firstNames"},
                {type: "string", string: " "},
                {type: "key", key: "lastName"},
                {type: "string", string: " (", }, 
                {type: "key", key: "yearGroup"}, 
                {type: "string", string: " - ", conditions: [{parameter: "key", type: "exists", key: "schoolSet"}]}, 
                {type: "key", key: "schoolSet", conditions: [{parameter: "key", type: "exists", key: "schoolSet"}]},
                {type: "string", string: ")"}
            ];
            lessonStudentDetails.studentDetailsString = modelsStringBuilder(studentModels, studentDetailsTemplate, ", ", ", ");

            const namesTemplate = [
                {type: "key", key: "firstNames"},
                {type: "string", string: " "},
                {type: "key", key: "lastName"},
            ];
            lessonStudentDetails.studentNamesString = modelsStringBuilder(studentModels, namesTemplate, ", ", ", ");
        }  
        else if (userType.isLessonTutor) {
            lessonStudentDetails = {
                studentNamesString: lessonProps.studentNames,
                yearGroups: lessonProps.yearGroups,
                studentNamesObject: lessonProps.studentNamesObject
            }
            if (lessonProps.studentNames == null) {
                console.log("No student details in lesson");
                setNoLessonModel(true);
                return;
            }
        }

        setStudentDetails(lessonStudentDetails);
    }, [lessonProps, studentDictionary, parentDictionary, userType]);

    //Get tutor details
    useEffect(() => {
        if (userType == null || lessonProps == null) {
            return;
        }
        if (userType.userIsAdmin) {
            if (tutorDictionary == null) {
                return;
            }
        }
        let lessonTutorDetails = {}
        if (userType.userIsAdmin) {
            const tutorID = lessonProps.tutorID;
            if (tutorID == null) {
                console.log("No tutor ID");
                setNoLessonModel(true);
                return;
            }
            //Get tutor model
            const tutorModel = tutorDictionary[tutorID];
            if (tutorModel == null) {
                console.log("Tutor model not found");
                setNoLessonModel(true);
                return;
            }
            tutorModel.name = tutorModel.firstNames + " " + tutorModel.lastName;
            lessonTutorDetails = tutorModel;
        }  
        else if (userType.isLessonParent || userType.isLessonStudent) {
            lessonTutorDetails = lessonProps.tutorDetails;
            if (lessonTutorDetails == null) {
                console.log("No tutor details in lesson");
                setNoLessonModel(true);
                return;
            }
        }

        setTutorDetails(lessonTutorDetails);
    }, [lessonProps, tutorDictionary, userType]);

    const widthThreshold = 830;

    let attributesGap = "2vw";
    let attributeGap = "1vh";
    if (width < widthThreshold) {
        attributesGap = "1vh";
        attributeGap = "2vw"
        standardTitleProps.textAlign = "left";
        standardTextProps.textAlign = "left";
    }

    const getColumn = function(type, attributeNum) {
        if (width < widthThreshold) {
            if (type == "label") {
                return 1;
            }
            else {
                return 3;
            }
        }
        else {
            return attributeNum;
        }
    }

    const getRow = function(type, attributeNum) {
        if (width < widthThreshold) {
            return attributeNum;
        }
        else {
            if (type == "label") {
                return 1;
            }
            else {
                return 3;
            }
            
        }
    }

    const lessonClick = function () {
        if (userType.userIsAdmin) {
            setRedirect(<Navigate to={"/Admin/EditLesson"} state={{"lessonProps":lessonProps}}/>);
        }
    }
    
    useEffect(() => {
        if (lessonProps == null || studentDetails == null || tutorDetails == null || userType == null) {
            return;
        }

        let subject = lessonProps.subject;
        if (subject == null) {
            subject = "No lesson subject given"
        }
        let lessonDay = lessonProps.lessonDay;
        if (lessonDay == null) {
            lessonDay = "No lesson day given"
        }
        let lessonType = lessonProps.lessonType;
        if (lessonType == null) {
            lessonType = "No lesson type given"
        }
        let startTime = lessonProps.startTime;
        if (startTime == null) {
            startTime = "No start time given"
        }
        let endTime = lessonProps.endTime;
        if (endTime == null) {
            endTime = "No end time given"
        }
        const tutorName = tutorDetails.name
        const studentNames = studentDetails.studentNamesString
        ;
        let startDate = "N/A";
        if (lessonProps.startDate != null) {
            const startDateObject = new Date(lessonProps.startDate);
            startDate = startDateObject.getDate() + "/" + (startDateObject.getMonth() + 1) + "/" + startDateObject.getFullYear();
        }
        let address = lessonProps.address;
        if (address == null) {
            address = "No address given"
        }  
        let postcode = lessonProps.postcode;
        if (postcode == null) {
            postcode = "No postcode given"
        }
        
        const tutorLevel = lessonProps.tutorLevel;
        const cost = calculateLessonCost(tutorLevel, startTime, endTime, lessonType, true);
        
        let wage = calculateLessonWage(tutorLevel, startTime, endTime, lessonType, true);

        let lessonDates = getDatesForLesson(lessonProps, null, 1);
        let localNextLessonString = null;
        if (Array.isArray(lessonDates) && lessonDates.length != 0) {
            const nextLesson = lessonDates[0];
            setNextLessonString(nextLesson.date + " (" + nextLesson.startTime + "-" + nextLesson.endTime + ")");
            localNextLessonString = nextLesson.date + " (" + nextLesson.startTime + "-" + nextLesson.endTime + ")";
        }
        else {
            setNextLessonString(null);
            standardTitleProps.color = "#a8a8a8";
            standardTextProps.color = "#a8a8a8";
        }
        const lessonAttributesText = [];
        
        let studentTitle = "Student:";
        if (lessonProps.studentIDs != null && lessonProps.studentIDs.length > 1) {
            studentTitle = "Students:";
        }
        let attributeNum = 1;
        const studentNameLabel = 
            <Text {...standardTitleProps} key={"studentNameLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                {studentTitle}
            </Text>
        const studentNameText = 
            <Text {...standardTextProps} key={"studentName"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                {studentNames}
            </Text>
        if (pageType != "student") {
            lessonAttributesText.push(studentNameLabel, studentNameText);
            attributeNum = attributeNum + 1;
        }

        const tutorNameLabel = 
            <Text {...standardTitleProps} key={"tutorNameLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                Tutor:
            </Text>
        const tutorNameText = 
            <Text {...standardTextProps} key={"tutorName"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                {tutorName}
            </Text>
        if (pageType != "tutor") {
            lessonAttributesText.push(tutorNameLabel, tutorNameText);
            attributeNum = attributeNum + 1;
        }

        const subjectLabel = 
            <Text {...standardTitleProps} key={"subjectLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                Subject:
            </Text>
        const subjectText =
            <Text {...standardTextProps} key={"subject"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                {subject}
            </Text>
        lessonAttributesText.push(subjectLabel, subjectText);
        attributeNum = attributeNum + 1;

        const lessonDayTimeLabel = 
            <Text {...standardTitleProps} key={"dayTimeLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                Day/Time:
            </Text>
        const lessonDayTimeText =
            <Text {...standardTextProps} key={"dayTime"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                {lessonDay}s: {startTime} - {endTime}
            </Text>
        lessonAttributesText.push(lessonDayTimeLabel, lessonDayTimeText);
        attributeNum = attributeNum + 1;

        const startDateLabel = 
            <Text {...standardTitleProps} key={"startDateLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                Start Date:
            </Text>
        const startDateText =
            <Text {...standardTextProps} key={"startDate"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                {startDate}
            </Text>
        lessonAttributesText.push(startDateLabel, startDateText);
        attributeNum = attributeNum + 1;

        const lessonTypeLabel = 
            <Text {...standardTitleProps} key={"lessonTypeLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                Lesson Type:
            </Text>
        const lessonTypeText = 
            <Text {...standardTextProps} key={"lessonType"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                {lessonType}
            </Text>
        lessonAttributesText.push(lessonTypeLabel, lessonTypeText);
        attributeNum = attributeNum + 1;

        if (lessonType != "Online") {
            const addressLabel = 
                <Text {...standardTitleProps} key={"addressLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                    Address:
                </Text>
            const addressText = 
                <Text {...standardTextProps} key={"address"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                    {address.address1stLine}, {address.postcode}
                </Text>
            if (showAddress) {
                lessonAttributesText.push(addressLabel, addressText);
                attributeNum = attributeNum + 1;
            }

            if (showAddress = false) {
                const postcodeLabel = 
                    <Text {...standardTitleProps} key={"postcodeLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                        Location:
                    </Text>
                const postcodeText = 
                    <Text {...standardTextProps} key={"postcode"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                        {address.postcode}
                    </Text>
                lessonAttributesText.push(postcodeLabel, postcodeText);
                attributeNum = attributeNum + 1;
            }

            // const geocodeLabel = 
            //     <Text {...standardTitleProps} key={"geocodeLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
            //         {postcodeLabelText}
            //     </Text>
            // const latitudeText = 
            //     <Text {...standardTextProps} key={"latitudeText"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
            //         {address.geocode.latitude}
            //     </Text>
            // const longitudeText = 
            // <Text {...standardTextProps} key={"longitudeText"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
            //     {address.geocode.longitude}
            // </Text>
            // lessonAttributesText.push(geocodeLabel, latitudeText, longitudeText);
            // attributeNum = attributeNum + 1;
        }
        if (userType.isLessonStudent || userType.isLessonParent || userType.userIsAdmin) {
            const lessonCostLabel = 
                <Text {...standardTitleProps} key={"lessonCostLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                    Weekly Cost:
                </Text>
            const lessonCostText = 
                <Text {...standardTextProps} key={"lessonCost"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                    {cost}
                </Text>
            lessonAttributesText.push(lessonCostLabel, lessonCostText);
            attributeNum = attributeNum + 1;
        }
        else if (userType.isLessonTutor || userType.userIsAdmin) {
            const lessonWageLabel = 
                <Text {...standardTitleProps} key={"lessonWageLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                    Weekly Wage:
                </Text>
            const lessonWageText = 
                <Text {...standardTextProps} key={"lessonWage"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                    {wage}
                </Text>
            lessonAttributesText.push(lessonWageLabel, lessonWageText);
            attributeNum = attributeNum + 1;
        }

        const nextLessonLabel = 
            <Text {...standardTitleProps} key={"nextLessonLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                Next Lesson:
            </Text>
        const nextLessonText = 
            <Text {...standardTextProps} key={"nextLesson"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                {localNextLessonString || "No more lessons"}
            </Text>
        lessonAttributesText.push(nextLessonLabel, nextLessonText);
        attributeNum = attributeNum + 1;
        
        setLessonAttributesUI(lessonAttributesText);
    }, [userType, studentDetails, tutorDetails, lessonProps, showAddress]);

    if (noLessonModel) {
        return "Error loading lesson";
    }

    if (lessonAttributesUI == null) {
        return <LoadingSpinner {...props} />
    }

    const iconSize = "40";
    // let editIcon = <Flex 
    //     style={{cursor:"pointer"}} 
    //     onClick={lessonClick}
    //     backgroundColor={"#3085c7"} 
    //     borderRadius={iconSize + "px"}
    //     width={iconSize + "px"}
    //     height={iconSize + "px"}
    //     justifyContent={"center"}
    //     alignItems={"center"}
        
    // >
    //     <Icon
    //         key={"editButton"}
    //         width={"30px"}
    //         height={"30px"}
    //         as={MdModeEdit}
    //     />
    // </Flex>

    let deleteButton = <Flex 
        style={{cursor:"pointer"}} 
        onClick={async (event) => {
            props.deleteLesson(lessonProps, event);
        }}
        backgroundColor={"#3085c7"} 
        borderRadius={iconSize + "px"}
        width={iconSize + "px"}
        height={iconSize + "px"}
        justifyContent={"center"}
        alignItems={"center"}
    >
        <Icon
            key={"deleteButton"}
            width={"30px"}
            height={"30px"}
            as={MdDelete}
        />
    </Flex>

    let recoverButton = <Flex 
        style={{cursor:"pointer"}} 
        onClick={async (event) => {
            props.askRecover(lessonProps, event);
    }}
        backgroundColor={"#3085c7"} 
        borderRadius={iconSize + "px"}
        width={iconSize + "px"}
        height={iconSize + "px"}
        justifyContent={"center"}
        alignItems={"center"}
    >
        <Icon
            key={"recoverIcon"}
            width={"30px"}
            height={"30px"}
            as={MdSettingsBackupRestore}
        />
    </Flex>

    // if (userType != "admin" || lessonTemplateType == "deleted") {
    //     editIcon = null;
    // }
    if (!userType.userIsAdmin || lessonTemplateType != "deleted") {
        recoverButton = null;
    }
    if (!userType.userIsAdmin) {
        deleteButton = null;
    }

    let backgroundColour = "#40e66c"
    if (lessonTemplateType == "deleted") {
        backgroundColour = "#fc5a03"
    }

    if (nextLessonString == null) {
        backgroundColour = "#e8e8e8";
    }

    let linkProps = {style:{textDecoration: 'none'}}
    const lessonID = lessonProps.id;
    if (amendLessons == false) {
        if (pageType == "admin") {
            linkProps.to= "/Admin/ViewLesson?lessonID=" + lessonID;
        }
        else if (pageType == "tutor") {
            linkProps.to= "/Tutor/ViewLesson?lessonID=" + lessonID;
        }
        else if (pageType == "student") {
            linkProps.to= "/Student/ViewLesson?lessonID=" + lessonID;
        }
        else if (pageType == "parent") {
            linkProps.to= "/Parent/ViewLesson?lessonID=" + lessonID;
        }
    }
    else {
        if (pageType == "admin") {
            linkProps.to= "/Admin/AmendLesson?lessonID=" + lessonID;
        }
        else if (pageType == "tutor") {
            linkProps.to= "/Tutor/AmendLesson?lessonID=" + lessonID;
        }
        else if (pageType == "student") {
            linkProps.to= "/Student/AmendLesson?lessonID=" + lessonID;
        }
        else if (pageType == "parent") {
            linkProps.to= "/Parent/AmendLesson?lessonID=" + lessonID;
        }
    }

    const amendLessonProps = {...linkProps}
    if (pageType == "admin") {
        amendLessonProps.to= "/Admin/AmendLesson?lessonID=" + lessonID;
    }
    else if (pageType == "tutor") {
        amendLessonProps.to= "/Tutor/AmendLesson?lessonID=" + lessonID;
    }
    else if (pageType == "student") {
        amendLessonProps.to= "/Student/AmendLesson?lessonID=" + lessonID;
    }
    else if (pageType == "parent") {
        amendLessonProps.to= "/Parent/AmendLesson?lessonID=" + lessonID;
    }
    const amendLessonButton = <Text {...standardTextProps} color={"#ff0000"}>Amend or Cancel Lesson</Text>


    if (!(width < widthThreshold)) {
        return (
            <Flex
                direction={"column"}
                gap={"0px"}
            >
                <Flex 
                    position={"relative"}
                    width={"fit-content"}
                >
                    {redirect}
                    <Link {...linkProps}>
                        <Flex 
                            backgroundColor={backgroundColour} 
                            //borderRadius={"15px"}
                            position={"relative"}
                            width={"fit-content"}
                            style={{
                                borderTopLeftRadius: "15px",
                                borderTopRightRadius: "15px",
                                borderBottomLeftRadius: "15px",
                                borderBottomRightRadius: "15px"
                            }}
                        >
                            <Grid
                                columnGap={attributesGap}
                                rowGap={attributeGap}
                                marginLeft={"3vw"} 
                                marginRight={"3vw"} 
                                marginTop={"2vh"} 
                                marginBottom={"2vh"}
                            >
                                {lessonAttributesUI}
                            </Grid>
                        </Flex>
                    </Link>
                    {/* <Flex position={"absolute"} right="-20px" top="-20px">
                        {editIcon}
                    </Flex> */}
                    <Flex position={"absolute"} right="-20px" top="-20px">
                        {recoverButton}
                    </Flex>
                    <Flex position={"absolute"} right="-20px" top="-20px">
                        {deleteButton}
                    </Flex>
                </Flex>
                {/* <Link {...amendLessonProps}>
                    <Flex 
                        backgroundColor={"#ffffff"} 
                        style={{
                            borderLeftStyle: "solid",
                            borderRightStyle: "solid",
                            borderBottomStyle: "solid",
                            borderColor: "#ff0000",
                            borderWidth: "2px",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px"
                        }}
                        justifyContent={"center"}
                    >
                        {amendLessonButton}
                    </Flex>
                </Link> */}
            </Flex>
        );
    }
    else {
        return (
            <Flex
                direction={"column"}
                gap={"0px"}
            >
                <Flex 
                    position={"relative"}
                    width={"fit-content"}
                >
                    {redirect}
                    <Link {...linkProps}>
                        <Flex 
                            backgroundColor={backgroundColour} 
                            style={{
                                borderTopLeftRadius: "15px",
                                borderTopRightRadius: "15px",
                                borderBottomLeftRadius: "15px",
                                borderBottomRightRadius: "15px"
                            }}
                            position={"relative"}
                            width={"fit-content"}
                        >
                            <Grid
                                columnGap={attributeGap}
                                rowGap={attributesGap}
                                marginLeft={"3vw"} 
                                marginRight={"3vw"} 
                                marginTop={"2vh"} 
                                marginBottom={"2vh"}
                            >
                                {lessonAttributesUI}
                            </Grid>
                        </Flex>
                    </Link>
                    {/* <Flex position={"absolute"} top={"-20px"} right={"-20px"}>
                        {editIcon}
                    </Flex> */}
                    <Flex position={"absolute"} right="-20px" top="-20px">
                        {recoverButton}
                    </Flex>
                    <Flex position={"absolute"} right="-20px" top="-20px">
                        {deleteButton}
                    </Flex>
                </Flex>
                {/* <Link {...amendLessonProps}>
                    <Flex 
                        backgroundColor={"#ffffff"} 
                        style={{
                            borderLeftStyle: "solid",
                            borderRightStyle: "solid",
                            borderBottomStyle: "solid",
                            borderColor: "#ff0000",
                            borderWidth: "2px",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px"
                        }}
                        justifyContent={"center"}
                    >
                        {amendLessonButton}
                    </Flex>
                </Link> */}
            </Flex>
        );
    }

    
}

export default LessonTemplate;