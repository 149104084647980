import * as React from "react";
import { TextField, Text, Flex, TextAreaField } from "@aws-amplify/ui-react";
import { useState, useEffect } from 'react';
import { validateField } from "./FormValidation";

const TextFieldTemplate = function(props) {
  try {
    const labelTextProps = props.questionLabelProps || {};
    const errorTextProps = props.questionErrorProps || {};

    const overrides = props.overrides;

    //Set question variables from passed properties
    const questionID = props.id;
    const questionLabel = props.label;
    const questionDescription = props.description;
    const required = props.required;
    const requiredAsterisk = props.requiredAsterisk;
    const placeHolder = props.placeHolder;
    const defaultValue = props.defaultValue;
    const questionValidations = props.validations || [];
    const answersSuccessfullySubmitted = props.answersSuccessfullySubmitted;
    const size = props.size || "medium";

    //Set state variables used to store the current state and any errors
    const [textAnswer, setTextAnswer] = useState(defaultValue);
    const [errors, setErrors] = useState({questionError:""});

    useEffect(() => {
      const validationResult = validateField(textAnswer, questionValidations);
      const questionHasError = validationResult.hasError;
      if (validationResult.standardPhoneNumber != null) {
        props.handleChange(questionID, validationResult.standardPhoneNumber, questionHasError);
      }
      else if (validationResult.standardName != null) {
        props.handleChange(questionID, validationResult.standardName, questionHasError);
      }
      else {
        props.handleChange(questionID, textAnswer, questionHasError);
      }
    }, []);

    let labelColour = labelTextProps.color || "#000000";
    if (props.validationError != null && props.validationError != null) {
      labelColour = props.validationErrorColour || "#ff0000";
    }
    //Get the text for the question label
    let labelText = <Text {...labelTextProps} color={labelColour}>
      {questionLabel}
    </Text>

    if (questionLabel == null || questionLabel == "") {
      labelText = null;
    }

    let descriptionText = <Text {...labelTextProps} fontSize={"14px"} fontStyle={"italic"} alignSelf={"center"} textAlign={"center"}>
      {questionDescription}
    </Text>

    if (questionDescription == null || questionDescription == "") {
      descriptionText = null;
    }
    
    let errorText = <Text {...errorTextProps}>
      {errors.questionError}
    </Text>
    if (errors.questionError == null || errors.questionError == "") {
      errorText = null;
    }

    let renderedRequiredAsterisk = null;
    if (required == true) {
      renderedRequiredAsterisk = requiredAsterisk;
    }

    //Ensure that textAnswer has an ASCII value to display
    if (textAnswer == null) {
      setTextAnswer("");
    }
    
    if (errors.errorMessage === null) {
      setErrors({
        questionError:""
      });
    }

    const extraProps = props.extraProps || {};
    let textType = "textfield";
    if (extraProps.textType == "paragraph") {
      textType = "paragraph";
    }

    let noLetters = false;
    if (extraProps.noLetters != null) {
      noLetters = extraProps.noLetters;
    }

    const sortCodeErrors = [
      "Invalid character",
      "Invalid Sort Code, too long",
      "Invalid Sort Code, too many hyphens",
      "Invalid Sort Code, wrong hyphen placement",
      "Invalid Account Number, too long"
    ];

    let textField = null;
    if (textType == "textfield") {
      //Generate the text field component
      textField = <TextField
        //color={myTheme.tokens.colors.font.primary.value}
        size={size}
        placeholder={placeHolder}
        value={textAnswer || props.value || ""}
        disabled={answersSuccessfullySubmitted}
        autoComplete={"off"}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        autoFocus={props.autoFocus}
        width={"100%"}
        maxWidth={props.maxWidth}

        //Runs when it detects a change in the input field
        onChange={(e) => {
          //console.log(e);
          let currentValue = e.target.value;
          if (extraProps.returnNumber == true) {
            currentValue = parseFloat(currentValue);
          }
          const validationResult = validateField(currentValue, questionValidations);
          let returnValue = true; 
          for (const sortCodeErrorMessage of sortCodeErrors) {
            if (validationResult.errorMessage == sortCodeErrorMessage) {
              returnValue = false;
            }
          }
          if (returnValue) {
            setTextAnswer(currentValue);
            const questionHasError = validationResult.hasError;
            const questionErrorMessage = validationResult.errorMessage;
            if (validationResult.standardPhoneNumber != null) {
              //setTextAnswer(validationResult.standardPhoneNumber);
              props.handleChange(questionID, validationResult.standardPhoneNumber, questionHasError);
            }
            else if (validationResult.standardPostcode != null) {
              props.handleChange(questionID, validationResult.standardPostcode, questionHasError);
            }
            else if (validationResult.standardName != null) {
              props.handleChange(questionID, validationResult.standardName, questionHasError);
            }
            else {
              props.handleChange(questionID, currentValue, questionHasError);
            }
            setErrors({
              questionHasError:questionHasError, 
              questionError:questionErrorMessage
            });
          }
        }}  
      ></TextField>
    }
    else if (textType == "paragraph") {
      //Generate the text field component
      textField = <TextAreaField
        //color={myTheme.tokens.colors.font.primary.value}
        size={size}
        placeholder={placeHolder}
        value={textAnswer}
        disabled={answersSuccessfullySubmitted}
        autoComplete={"off"}
        width={"100%"}
        maxWidth={props.maxWidth}
        rows={extraProps.rows}
        wrap={extraProps.wrap}

        //Runs when it detects a change in the input field
        onChange={(e) => {
          let currentValue = e.target.value;
          if (extraProps.returnNumber == true) {
            currentValue = parseFloat(currentValue);
          }
          setTextAnswer(currentValue);

          const validationResult = validateField(currentValue, questionValidations);
          const questionHasError = validationResult.hasError;
          const questionErrorMessage = validationResult.errorMessage;
          if (validationResult.standardPhoneNumber != null) {
            //setTextAnswer(validationResult.standardPhoneNumber);
            props.handleChange(questionID, validationResult.standardPhoneNumber, questionHasError);
          }
          else if (validationResult.standardPostcode != null) {
            props.handleChange(questionID, validationResult.standardPostcode, questionHasError);
          }
          else if (validationResult.standardName != null) {
            props.handleChange(questionID, validationResult.standardName, questionHasError);
          }
          else {
            props.handleChange(questionID, currentValue, questionHasError);
          }
          setErrors({
            questionHasError:questionHasError, 
            questionError:questionErrorMessage
          });
        }}  
      ></TextAreaField>
    }

    let errorFlex = <Flex direction={"column"} alignItems={"center"} justifyContent={"center"} position={"relative"} marginTop={"2vh"} marginBottom={"-1vh"}>
      {errorText}
    </Flex>
    if (errorText == null || errorText == "") {
      errorFlex = null;
    }

    if (props.noErrors == true) {
      errorFlex = null;
    }

    let labelFlex = <Flex alignItems={"center"} alignSelf={"center"}>
      {renderedRequiredAsterisk}
      {labelText}
    </Flex>

    if (props.noLabel == true || (renderedRequiredAsterisk == null && labelText == null)) {
      labelFlex = null;
    }

    //Return text field component to calling function (Most likely a Form Template)
    return (
      <Flex 
        direction={"column"}
        width={"100%"} 
      >
        {labelFlex}
        {descriptionText}
        {textField}
        {errorFlex}
      </Flex>
    );
  }
  catch (error) {
    throw "TextFieldTemplate Error: " + error;
  }
}

export default TextFieldTemplate;