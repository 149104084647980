import { Button, Flex, Icon, Text } from '@aws-amplify/ui-react';
import {React, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LoadingSpinner } from '../LoadingPage';
import {MdMenuBook} from 'react-icons/md';
import { ErrorLoadingPage } from '../ErrorLoadingPage';

const StudentHome = function(props) {
    const lessons = props.studentLessons;
    const lessonRequests = props.studentLessonRequests;

    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;

    if (lessons == null || lessonRequests == null) {
        return <LoadingSpinner height={props.height} />
    }
    else if (lessons == "loading" || lessonRequests == "loading") {
        return <LoadingSpinner height={props.height} />
    }
    if (lessons == "error" || lessonRequests == "error") {
        return <ErrorLoadingPage {...props}/>
    }

    let numLessonRequests = 0;
    for (const request of lessonRequests) {
        if (request.status != 3) {
            numLessonRequests = numLessonRequests + 1;
        }
    }

    const welcomeText = <Text {...titleTextProps}>
        Student Dashboard
    </Text>

    let viewLessonsText = <Text {...standardTextProps}>
        View your weekly lesson timetable:
    </Text>

    const bookIcon = <Icon
        key={"bookIcon"}
        width={"30px"}
        height={"30px"}
        as={MdMenuBook}
    />

    const viewLessonsButtonText = <Text>View your lessons</Text>

    let viewLessonsButton = <Link 
        to={"/Student/MyLessons"} 
        style={{textDecoration: 'none'}}
    >
        <Button>
            <Flex alignItems={"center"}>
                {viewLessonsButtonText}
                {bookIcon}
            </Flex>
        </Button>
    </Link>
    let viewLessonRequestsButton = <Link to={"/Student/LessonRequests"} style={{textDecoration: 'none'}}><Button>View submitted lesson requests</Button></Link>

    let lessonsSuffix = "s";
    if (lessons.length == 1) {
        lessonsSuffix = "";
    }
    let currentLessonsIntro = <Text {...standardTextProps}>
        You are currently registered for {lessons.length} lesson{lessonsSuffix}
    </Text>;
    if (lessons.length == 0) {
        currentLessonsIntro = <Text {...standardTextProps}>
            You are not currently registered for any lessons
        </Text>
        viewLessonsButton = null;
        viewLessonsText = null;
    }

    let requestsSuffix = "s";
    if (numLessonRequests == 1) {
        requestsSuffix = "";
    }
    let currentLessonRequestsIntro = <Text {...standardTextProps}>
        You've currently made {numLessonRequests} lesson request{requestsSuffix}
    </Text>;
    if (numLessonRequests == 0) {
        currentLessonRequestsIntro = <Text {...standardTextProps}>
            You don't have any pending lesson requests
        </Text>
        viewLessonRequestsButton = null;
        viewLessonsText = null;
    }

    let numRequestedAmendments = 0;
    for (const lesson of lessons) {
        const lessonAmendments = lesson.amendments;
        for (const amendment of lessonAmendments) {
            if (amendment.confirmed != true && amendment.rejectedBy == null) {
                const requestedBy = amendment.requestedBy;
                if (requestedBy.userType != "student") {
                    numRequestedAmendments = numRequestedAmendments + 1;
                }
            }
        }
    }

    let respondToAmendmentsButton = null;
    if (numRequestedAmendments > 0) {
        let buttonNotification = <Flex 
            borderRadius={"100px"} 
            backgroundColor={"#fc3903"} 
            width={"25px"} 
            height={"25px"}
            alignItems={"center"}
            justifyContent={"center"}
            fontSize={"15px"}
            color={"#ffffff"}
            position={"absolute"}
            right="-10px" 
            top="-10px"
        >
            {numRequestedAmendments}
        </Flex>

        let pluralText = "request";
        if (numRequestedAmendments > 1) {
            pluralText = "requests";
        }
        const buttonElement = <Flex position={"relative"}>
            {buttonNotification}
            <Button>Respond to lesson amendment {pluralText}</Button>
        </Flex>
        respondToAmendmentsButton = <Link to={"/Student/RespondToAmendments"} style={{textDecoration: 'none'}}>{buttonElement}</Link>
    }

    
    const lessonRequestButton = <Link to={"/Student/NewLessonRequest"} style={{textDecoration: 'none'}}><Button>Make a new lesson request</Button></Link>

    const guideButton = <Link to={"/Student/StartGuide"} style={{textDecoration: 'none'}}><Button>Next Door Tutor Guide</Button></Link>

    let amendLessonsButton = null;
    if (lessons.length > 0) {
        amendLessonsButton = <Link 
            to={"/Student/AmendLessons"} 
            style={{textDecoration: 'none'}}
        >
            <Button>
                <Text>Cancel or make changes to a lesson</Text>
            </Button>
        </Link>
    }
    
    return (
        <Flex direction={"column"} gap={"5vh"}>
            <Flex direction={"column"} gap={"2vh"} alignItems={"center"}>
                {welcomeText}
                {guideButton}
                {currentLessonsIntro}
                {currentLessonRequestsIntro}
                {viewLessonsText}
                {viewLessonsButton}
                {amendLessonsButton}
                {respondToAmendmentsButton}
                {viewLessonRequestsButton}
                {lessonRequestButton}
            </Flex>
        </Flex>
    );
}

export default StudentHome;