import * as React from "react";
import { Flex, Grid, Icon, Image, Text } from "@aws-amplify/ui-react";
import { Link, Navigate } from "react-router-dom";
import {useEffect, useState} from 'react';
import AppNavButton from './AppNavButtons';
import HamburgerMenuTemplate from "./HamburgerMenuTemplate";
import {ReactComponent as LogoSVG} from "../Images/NextDoorTutorLogoExport.svg";
import {ReactComponent as TextSVG} from "../Images/Text.svg";
import userPicture from "../Images/TestUserImage.png";
import {MdOutlineForum} from 'react-icons/md';



const NavigationBarTemplate = function(props) {
  const buttonList = props.buttons;
  let numButtons = 0
  if (buttonList != null) {
    numButtons = buttonList.length;
  }

  let width = props.width;
  let height = props.height;
  const userIsAdmin = props.userIsAdmin;
  const currentPage = window.location.pathname.toLowerCase();
  
  let buttonSpacing = ((width * width * width) / 200000000)/numButtons;
  const maxSpacing = 30;
  if (buttonSpacing * numButtons > maxSpacing) {
    buttonSpacing = maxSpacing / numButtons;
  }
  buttonSpacing = buttonSpacing + "vw"

  //let buttonFontSize = width/100;
  let buttonFontSize = "15px";

  const imageSize = "60px"
  let onlyButtons = props.onlyButtons;
  if (onlyButtons == null) {
    onlyButtons = false;
  }
  
  
  //Generate all buttons
  //Initialise button Attributes
  let signOutButton = false;
  const buttons = [];

  //Iteratively add all buttons to array of buttons
  for (let buttonIndex = 0; buttonIndex < numButtons; buttonIndex = buttonIndex + 1) {
    signOutButton = false;
    //Get the passed properties of the button
    const buttonProps = buttonList[buttonIndex];
    //Get the button text
    const buttonText = buttonProps.text;
    //Check if the button is for the active page
    const activeButton = buttonProps.currentPage || false;
    //Get the link for the button
    const buttonLink = buttonProps.link;
    //Check if it's a sign out button
    if (buttonProps.signOutUser == true) {
      signOutButton = true;
    }
    const buttonNotifications = buttonProps.notifications;
    let notification = null;
    if (buttonNotifications != null && buttonNotifications != 0) {
    notification = <Flex 
        borderRadius={"100px"} 
        backgroundColor={"#fc3903"} 
        width={"25px"} 
        height={"25px"}
        alignItems={"center"}
        justifyContent={"center"}
        fontSize={"15px"}
        fontWeight={"hairline"}
        color={"#ffffff"}
        position={"absolute"}
        right="-10px" 
        bottom="-10px"
    >
        {buttonNotifications}
    </Flex>
    }
    //Create the button
    const button = <AppNavButton 
      text={buttonText} 
      fontSize={buttonFontSize} 
      //width={width} 
      signOutUser={signOutButton} 
      activeButton={activeButton}
      showOutline={false}
    />
    //Add the link to the button
    const linkedButton = <Link to={buttonLink} style={{textDecoration: 'none'}}>{button}</Link>;
    const buttonFlex = <Flex position={"relative"} width={"fit-content"} key={buttonText}>
      {notification}
      {linkedButton}
    </Flex>
    //Add the linked button to the array of buttons that will be returned to be rendered
    buttons.push(buttonFlex);
  }

  //<a href="https://nextdoortutor.co.uk" target="_blank">

  let appLogo = <Link to={"/"} style={{textDecoration: 'none'}}>
    <Flex backgroundColor={"#ffffff"} borderRadius={"15px"}>
      <LogoSVG 
        width={imageSize} 
        height={imageSize}
      />
    </Flex>
  </Link>

  let userImage = <Link to={"/User"} style={{textDecoration: 'none'}}>
    <Image
      width={"40px"}
      height={"40px"}
      src={userPicture}
      alt="User Profile Picture"
    ></Image>
  </Link>

  if (onlyButtons == true) {
    appLogo = null;
    userImage = null;
  }



  const lessonCreditString = props.lessonCreditString || "£-.--";
  const currentLessonCredit = props.currentLessonCredit;
  const lessonCreditTextProps = {...props.standardTextProps};
  if (currentLessonCredit < 0) {
    lessonCreditTextProps.color = "#ff0000";
  }
  let lessonCreditText = null;
  if (lessonCreditString != null) {
    lessonCreditText = <Link to={"/LessonCredits"} style={{textDecoration: 'none'}}><Text {...lessonCreditTextProps} fontSize={"17px"}>{lessonCreditString}</Text></Link>
  }

  const numUnreadMessages = props.numUnreadMessages;
  let unreadMessagesNotification = null;
  if (numUnreadMessages > 0) {
    unreadMessagesNotification = <Flex 
      borderRadius={"100px"} 
      backgroundColor={"#fc3903"} 
      width={"25px"} 
      height={"25px"}
      alignItems={"center"}
      justifyContent={"center"}
      fontSize={"15px"}
      fontWeight={"hairline"}
      color={"#ffffff"}
      position={"absolute"}
      right="-10px" 
      top="-10px"
    >
      {numUnreadMessages}
    </Flex>
  }

  let messagesButton = <Link to={"/Messages"} style={{textDecoration: 'none'}}>
    <Flex 
      width={"50px"} 
      height={"50px"} 
      borderRadius={"7px"}
      position={"absolute"}
      right={"50px"}
      top={"70px"}
      backgroundColor={"#32a852"}
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {unreadMessagesNotification}
      <Icon
        key={"messagesIcon"}
        width={"30px"}
        height={"30px"}
        as={MdOutlineForum}
        color={"#000000"}
      />
    </Flex>
  </Link>

  // if (currentPage == "/messages") {
  //   messagesButton = <Link to={"/"} style={{textDecoration: 'none'}}>
  //     <Flex 
  //       width={"50px"} 
  //       height={"50px"} 
  //       borderRadius={"7px"}
  //       position={"absolute"}
  //       right={"50px"}
  //       top={"70px"}
  //       backgroundColor={"#32a852"}
  //       direction={"column"}
  //       justifyContent={"center"}
  //       alignItems={"center"}
  //     >
  //       <Text fontSize={"40px"}>x</Text>
  //     </Flex>
  //   </Link>
  // }
  if (!userIsAdmin) {
    messagesButton = null
  }

  if (width > 1110) {
    return(
      <Flex>
        <Flex
          direction={"row"}
          alignItems={"center"}
          backgroundColor={"#b0d4f0cc"}
          width={width}
          justifyContent={"space-between"}
          gap={"0px"}
          position={"fixed"}
          left={"0px"}
          top={"0px"}
          style={{
            backdropFilter:"blur(5px)", 
            WebkitBackdropFilter:"blur(5px)",
            boxShadow:"5px 5px 30px rgba(30,30,30,0.2)"
          }}
          borderRadius={"0px 0px 10px 10px"}
        >
          <Link to={"/"} style={{textDecoration: 'none'}}>
            <Flex
              marginLeft={"2vw"}
              position={"relative"}
              alignItems={"center"}
              width={"12vw"}
            >
              <Flex
                position={"absolute"}
              >
                <TextSVG 
                  width={"15vw"} 
                  height={"35px"}
                />
              </Flex>
            </Flex>
          </Link>
        
          <Flex
            gap={buttonSpacing}
            marginLeft={"-3vw"}
          >
            {buttons}
          </Flex>

          <Flex
            alignItems={"center"}
            marginRight={"3vw"}
            justifyContent={"center"}
            //direction={"column"}
            gap={"20px"}
          >
            {userImage}
            {lessonCreditText}
          </Flex>
        </Flex>
      </Flex>
    );
  }
  else {
    let position = "fixed";
    if (height < 500) {
      position = "absolute";
    }
    return(
        <Flex
          direction={"row"}
          alignItems={"center"}
          backgroundColor={"#b0d4f0cc"}
          width={width}
          justifyContent={"space-between"}
          gap={"0px"}
          position={position}
          left={"0px"}
          top={"0px"}
          style={{
            backdropFilter:"blur(5px)", 
            WebkitBackdropFilter:"blur(5px)",
            boxShadow:"5px 5px 30px rgba(30,30,30,0.3)"
          }}
          borderRadius={"0px 0px 10px 10px"}
        >
          <Flex marginLeft={"10vw"}>
            <HamburgerMenuTemplate buttons={props.buttons}/>
          </Flex>

          <Flex
            //style={{border:"solid"}}
            marginLeft={"4vw"}
          >
            {appLogo}
          </Flex>

          <Flex
            marginTop={"3px"}
            alignItems={"center"}
            marginRight={"5vw"}
            justifyContent={"center"}
            direction={"row"}
            gap={"10px"}
          >
            {userImage}
            {lessonCreditText}
          </Flex>
        </Flex>
    );
  }
}

export default NavigationBarTemplate;