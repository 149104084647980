import {Radio, CheckboxField} from "@aws-amplify/ui-react";

const OptionsTemplate = function(options, optionType = "array", submitChange = null) {
  try {
    /**
    * @param options An array of of string options for a form question
    * @param optionType The type of question the options are for (determines what type of option component array will be returned) 
    */

    if (options == null) {
      return [];
    } 

    //Check the given option type to return the correct thing

    //Dropdown options are required to just be returned as an array of option objects
    let optionNum = 0;
    if (optionType == "array") {
      const optionArray = [];
      for (const option of options) {
        const optionComponent = <option value={option} children={option} key={JSON.stringify(option) + optionNum} />
        optionArray.push(optionComponent);
        optionNum = optionNum + 1;
      }
      return optionArray;
    }
    else if (optionType == "dropdown") {
      const optionArray = [];
      for (const option of options) {
        if (option == null || option == "") {
          continue;
        }
        if (typeof(option) == "object" && option.hasOwnProperty("props")) {
          optionArray.push(option);
        }
        else if (option.id != null && option.label != null) {
          const optionComponent = <option value={option.id} children={option.label} key={option.id + optionNum} />
          optionArray.push(optionComponent);
        }
        else {
          const optionComponent = <option value={option} children={option} key={JSON.stringify(option) + optionNum} />
          optionArray.push(optionComponent);
        }
        optionNum = optionNum + 1;
      }
      return optionArray;
    }
    //Radio options are required to be an array of specifically radio options
    else if (optionType == "radio") {
      const optionComponents = [];
      for (const option of options) {
        let optionName = option;
        let optionValue = option;
        if (typeof(option) == "object" && option.id != null && option.label != null) {
          optionName = option.label;
          optionValue = option.id;
        }

        const optionComponent = <Radio 
          value={optionValue} 
          children={optionName} 
          key={JSON.stringify(option) + optionNum} 
          onClick={() => {
            submitChange(option);
          }}
        />
        optionComponents.push(optionComponent);
        optionNum = optionNum + 1;
      }
      return optionComponents;
    }
    else if (optionType == "autocomplete") {
      const optionComponents = [];

      for (const option of options) {
        try {
          if (typeof(option) == "string") {
            throw "isArray";
          }
          try {
            const optionComponent = {"id":option.id, "label": option.label};
            optionComponents.push(optionComponent);
          }
          catch {
            const optionComponent = {"id":option.id, "label": option.id};
            optionComponents.push(optionComponent);
          }
        }
        catch {
          const optionComponent = {"id":option, "label": option};
          optionComponents.push(optionComponent);
        }
        
      }
      return optionComponents;
    }
  }
  catch (error) {
    throw "OptionsTemplate Error: " + error;
  }
}

export default OptionsTemplate;