import React from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { Button, Flex, Grid, Text } from "@aws-amplify/ui-react";
import { LoadingSpinnerPage } from "../LoadingPage";


const SignUpThankYou = function(props) {
  const height = props.height;
  const titleTextProps = props.titleTextProps;
  const standardTextProps = props.standardTextProps;

  const location = useLocation();
  const extraProps = location.state || {};

  // if (extraProps == null) {
  //   return <Navigate to={"/"} />
  // }

  let userType = extraProps.userType;
  let userModel = null;

  userType = "tutor";

  if (userType == "tutor") {
    userModel = props.tutorModel;
  }
  else if (userType == "parent") {
    userModel = props.parentModel;
  }
  else if (userType == "student") {
    userModel = props.studentModel;
  }

  if (userModel == null) {
    return <LoadingSpinnerPage {...props} />
  }


  let fromLocation = extraProps.from;
  if (fromLocation == null) {
      const queryParams = new URLSearchParams(window.location.search);
      fromLocation = queryParams.get("from");
  }

  const titleText = <Text {...titleTextProps}>Thank You for Signing Up!</Text>

  let dashboardButton = null;
  let instructions = [];
  if (userType == "tutor") {
    dashboardButton = <Link to={"/Tutor"}><Button><Text {...standardTextProps}>Tutor Dashboard</Text></Button></Link>
    instructions.push(<Text {...standardTextProps} key={"instructions1"}>Hi {userModel.firstNames},</Text>);
    instructions.push(<Text {...standardTextProps} key={"instructions2"}>Thanks for signing up to tutor with Next Door Tutor!</Text>);
    instructions.push(<Text {...standardTextProps} key={"instructions3"}>We will send you emails regarding potential lessons that match your subjects, availability, and location.</Text>);
    instructions.push(<Text {...standardTextProps} key={"instructions4"}>You can then accept and reject the sessions here on the tutor dashboard.</Text>);
    instructions.push(<Text {...standardTextProps} key={"instructions5"}>Before your first lesson, please input your bank details (so we can pay you!); and after your first session has been planned, we will ask you to complete our ID verification and self-disclosure form.</Text>);
    instructions.push(<Text {...standardTextProps} key={"instructions6"}>You can do everything you need here, on the web app. However, some features will only be available when you have lessons to teach.</Text>);
    instructions.push(<Text {...standardTextProps} key={"instructions7"}>If you're ever stuck, don't hesitate to react out to <Link to={"mailto:contact@nextdoortutor.co.uk"}>contact@nextdoortutor.co.uk</Link></Text>);
    instructions.push(<Text {...standardTextProps} key={"instructions8"}>Good luck!</Text>);
    instructions.push(<Text {...standardTextProps} key={"instructions9"}>The Next Door Tutor Team</Text>);
  }
  else if (userType == "student") {
    dashboardButton = <Link to={"/Student"}><Button><Text {...standardTextProps}>Student Dashboard</Text></Button></Link>
  }
  else if (userType == "parent") {
    dashboardButton = <Link to={"/Parent"}><Button><Text {...standardTextProps}>Parent Dashboard</Text></Button></Link>
  }

  const instructionsFlex = <Flex direction={"column"} gap={"2vh"}>
    {instructions}
  </Flex>


  return (
      <Flex direction={"column"} gap={"7vh"} alignItems={"center"}>
        {titleText}
        {instructionsFlex}
        {dashboardButton}
      </Flex>
  );
}

export default SignUpThankYou;