import { Badge, Flex, Grid, Icon, Text } from "@aws-amplify/ui-react";
import { Link, Navigate } from "react-router-dom";
import {React, useState} from 'react';
import {MdAdd, MdHorizontalRule, MdClose} from "react-icons/md";
import { getStripePriceID, priceValuetoString } from "../App";

const BuyCreditTemplate = function(props) {
    const creditValue = props.creditValue;
    const environment = props.environment;
    const stripePriceID = getStripePriceID(creditValue, environment);
    const standardTextProps = props.standardTextProps;

    const basket = props.basket;
    const setBasket = props.setBasket;
    const basketObject = basket[creditValue] || {}
    const quantity = basketObject.quantity || 0;

    if (creditValue == null) {
        return (
            <Flex position={"relative"} alignItems={"center"} gap={"0vw"}>
                <Flex alignItems={"center"}>
                    <Text {...standardTextProps}>Error loading buy credit template</Text>
                </Flex>
            </Flex>
        );
    }

    const creditValueText = <Text {...standardTextProps} >{priceValuetoString(creditValue)}</Text>

    const backgroundColour = props.backgroundColour || "#fff0a6";

    const getNewBasketTotal = function (basketObject) {
        const basketArray = Object.entries(basketObject);
        let total = 0;
        for (const item of basketArray) {
            if (item[0] != "total") {
                const value = item[0];
                const quantity = item[1].quantity;
                total = total + (quantity * value);
            }
        }
        return total;
    }

    const addButton = <Flex 
        backgroundColor={"#dddddd"} 
        width={"35px"} 
        height={"35px"} 
        alignItems={"center"} 
        justifyContent={"center"} 
        direction={"column"}
        borderRadius={"30px"}
        style={{cursor: "pointer"}}
        onClick={() => {
            const newQuantity = quantity + 1;
            const newBasket = {...basket};
            if (newBasket[creditValue] == null) {
                newBasket[creditValue] = {
                    stripePriceID: stripePriceID,
                    quantity: newQuantity
                };
            }
            else {
                newBasket[creditValue].quantity = newQuantity;
            }
            newBasket.total = getNewBasketTotal(newBasket);
            setBasket(newBasket);
        }}
    >
        <Icon
            key={"addButton"}
            width={"25px"}
            height={"25px"}
            color={"#000000"}
            as={MdAdd}
        />
    </Flex>

    let subtractStyle = {cursor: "pointer"};
    let subtractColour = "#000000";
    if (quantity == 0) {
        subtractStyle = null;
        subtractColour = "#cccccc"
    }
    const subtractButton = <Flex 
        direction={"column"}
        backgroundColor={"#dddddd"} 
        width={"35px"} 
        height={"35px"} 
        alignItems={"center"} 
        justifyContent={"center"} 
        borderRadius={"30px"}
        style={subtractStyle}
        onClick={() => {
            const newQuantity = quantity - 1;
            if (newQuantity >= 0) {
                const newBasket = {...basket};
                if (newBasket[creditValue] == null) {
                    newBasket[creditValue] = {
                        stripePriceID: stripePriceID,
                        quantity: newQuantity
                    };
                }
                else {
                    newBasket[creditValue].quantity = newQuantity;
                }
                newBasket.total = getNewBasketTotal(newBasket);
                setBasket(newBasket);
            }
        }}
    >
        <Icon
            key={"addButton"}
            width={"25px"}
            height={"25px"}
            color={subtractColour}
            as={MdHorizontalRule}
        />
    </Flex>

    let removeAll = null;
    if (quantity > 0) {
        removeAll = <Icon
            position={"absolute"}
            right={"-40px"}
            key={"closeButton"}
            width={"30px"}
            height={"30px"}
            as={MdClose}
            color={"ff0000"}
            style={{cursor: "pointer"}}
            onClick={() => {
                const newQuantity = 0;
                const newBasket = {...basket};
                if (newBasket[creditValue] == null) {
                    newBasket[creditValue] = {
                        stripePriceID: stripePriceID,
                        quantity: newQuantity
                    };
                }
                else {
                    newBasket[creditValue].quantity = newQuantity;
                }
                newBasket.total = getNewBasketTotal(newBasket);
                setBasket(newBasket);
            }}
        />
    }

    const quantityText = <Flex
        minWidth={"30px"}
        justifyContent={"center"}
    >
        <Text {...standardTextProps} >{quantity}</Text>
    </Flex>

    let flexDirection = "row";
    let gap = "20px";
    if (props.width < 300) {
        flexDirection = "column";
        gap = "0px";
    }

    return (
        <Flex justifyContent={"center"} alignItems={"center"} position={"relative"}>
            <Flex 
                alignItems={"center"} 
                position={"relative"}
                border={"solid"} 
                borderRadius={"10px"} 
                paddingLeft={"5px"} 
                paddingRight={"5px"}
                paddingTop={"2px"}
                paddingBottom={"2px"}
                backgroundColor={backgroundColour}
                direction={flexDirection}
                gap={gap}
            >
                {creditValueText}
                <Flex justifyContent={"center"} alignItems={"center"} gap={"1vw"}>
                    {subtractButton}
                    {quantityText}
                    {addButton}
                </Flex>
            </Flex>
            {removeAll}
        </Flex>
            
    );
}

export default BuyCreditTemplate;