import { Flex, Grid, Text } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";
import {React, useState} from 'react';
import { priceValuetoString } from "../App";

const TransactionTemplate = function(props) {
    const [redirect, setRedirect] = useState(null);
    const standardTextProps = {...props.standardTextProps};
    const standardTitleProps = {...props.titleTextProps};

    standardTextProps.fontSize = "1.2vw";
    standardTitleProps.fontSize = "1.2vw";

    standardTextProps.fontSize = "17px";
    standardTitleProps.fontSize = "17px";


    const width = props.width;
    const height = props.height;
    const transactionProps = props.transaction;

    const widthThreshold = 830;

    let attributesGap = "2vw";
    let attributeGap = "1vh";
    if (width < widthThreshold) {
        attributesGap = "1vh";
        attributeGap = "2vw"
        standardTitleProps.textAlign = "left";
        standardTextProps.textAlign = "left";
    }

    const getColumn = function(type, attributeNum) {
        if (width < widthThreshold) {
            if (type == "label") {
                return 1;
            }
            else {
                return 3;
            }
        }
        else {
            return attributeNum;
        }
    }

    const getRow = function(type, attributeNum) {
        if (width < widthThreshold) {
            return attributeNum;
        }
        else {
            if (type == "label") {
                return 1;
            }
            else {
                return 3;
            }
            
        }
    }

    // const lessonClick = function () {
    //     if (userType == "admin") {
    //         setRedirect(<Navigate to={"/Admin/EditLesson"} state={{"lessonProps":lessonProps}}/>);
    //     }
    // }

    let statusString = "Invalid Status";
    const getTransactionAttributesText = function () {
        let date = "No creation date";
        if (transactionProps.createdAt != null) {
            const dateObject = new Date(transactionProps.createdAt);
            const dateString = dateObject.getUTCDate() + "/" + (dateObject.getUTCMonth() + 1) + "/" + dateObject.getUTCFullYear();
            const timeString = dateObject.toTimeString().split(" ")[0];
            date = dateString + " " + timeString;
        }

        const transactionAttributesText = [];
        
        let attributeNum = 1;
        const dateLabel = 
            <Text {...standardTitleProps} key={"dateLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                Date/Time:
            </Text>
        const dateText = 
            <Text {...standardTextProps} key={"date"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                {date}
            </Text>
        transactionAttributesText.push(dateLabel, dateText);
        attributeNum = attributeNum + 1;

        const typeLabel = 
            <Text {...standardTitleProps} key={"typeLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                Type:
            </Text>
        const typeText = 
            <Text {...standardTextProps} key={"type"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                {transactionProps.type}
            </Text>
        transactionAttributesText.push(typeLabel, typeText);
        attributeNum = attributeNum + 1;

        const purchasedItems = transactionProps.purchasedItems;
        let totalCost = 0;
        for (const item of purchasedItems) {
            const itemCost = item.totalItemCost/100;
            totalCost = totalCost + itemCost;
        }
        const totalCostText = priceValuetoString(totalCost);
        const costLabel = 
            <Text {...standardTitleProps} key={"costLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                Value:
            </Text>
        const costText = 
            <Text {...standardTextProps} key={"cost"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                {totalCostText}
            </Text>
        transactionAttributesText.push(costLabel, costText);
        attributeNum = attributeNum + 1;

        const completed = transactionProps.completed;
        const refunded = transactionProps.refunded;
        const refundRequested = transactionProps.refundRequested;
        let transactionValue = transactionProps.transactionValue;
        if (transactionValue == null) {
            transactionValue = 0;
            const transactionItems = transactionProps.purchasedItems;
            for (const item of transactionItems) {
                transactionValue = transactionValue + item.totalItemCost/100;
            }
        }
        if (refunded == false) {
            if (refundRequested == true) {
                statusString = "Refund Requested";
            }
            else if (completed != true) {
                statusString = "Pending";
            }
            else if (completed == true) {
                statusString = "Completed";
            }
        }
        else if (refunded != false) {
            if (refunded != true && refunded < transactionValue) {
                statusString = "Partially Refunded (" + priceValuetoString(refunded) + ")";
                if (refundRequested == true) {
                    statusString = "Refund Requested (already refunded " + priceValuetoString(refunded) + ")";
                }
            }
            else if (refunded == transactionValue || refunded == true) {
                statusString = "Refunded";
            }
        }
        

        const statusLabel = 
            <Text {...standardTitleProps} key={"statusLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                Status:
            </Text>
        const statusText = 
            <Text {...standardTextProps} key={"status"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                {statusString}
            </Text>
        transactionAttributesText.push(statusLabel, statusText);
        attributeNum = attributeNum + 1;

        return transactionAttributesText;
    }


    const transactionAttributesText = getTransactionAttributesText();

    let state = null;
    let linkProps = {style:{textDecoration: 'none'}, state:{...transactionProps}, to:"/Transactions/ViewTransaction"}
    if (props.userType == "admin") {
        linkProps.to = "/Admin/ViewTransaction";
    }

    let backgroundColour = "#40e66c"
    if (statusString.substring(0, 16) == "Refund Requested") {
        backgroundColour = "#ffd373"
    }
    else if (statusString == "Refunded") {
        backgroundColour = "#ff621f"
    }
    else if (statusString.substring(0, 18) == "Partially Refunded") {
        backgroundColour = "#ff621f"
    }

    let notificationDot = null;
    if (props.userType == "admin" && statusString.substring(0, 16) == "Refund Requested") {
        notificationDot = <Flex 
            borderRadius={"100px"} 
            backgroundColor={"#fc3903"} 
            width={"25px"} 
            height={"25px"}
            alignItems={"center"}
            justifyContent={"center"}
            fontSize={"15px"}
            fontWeight={"hairline"}
            color={"#ffffff"}
            position={"absolute"}
            right="-10px" 
            top="-10px"
        >1</Flex>
    }

    if (!(width < widthThreshold)) {
        return (
            <Flex 
                borderRadius={"15px"}
                position={"relative"}
                width={"fit-content"}
              >
                {redirect}
                <Link {...linkProps}>
                    <Flex 
                        backgroundColor={backgroundColour} 
                        borderRadius={"15px"}
                        position={"relative"}
                        width={"fit-content"}
                    >
                        {notificationDot}
                        <Grid
                            columnGap={attributesGap}
                            rowGap={attributeGap}
                            marginLeft={"3vw"} 
                            marginRight={"3vw"} 
                            marginTop={"2vh"} 
                            marginBottom={"2vh"}
                        >
                            {transactionAttributesText}
                        </Grid>
                    </Flex>
                </Link>
            </Flex>
        );
    }
    else {
        return (
            <Flex 
                borderRadius={"15px"}
                position={"relative"}
                width={"fit-content"}
            >
                {redirect}
                <Link {...linkProps}>
                    <Flex 
                        backgroundColor={backgroundColour} 
                        borderRadius={"15px"}
                        position={"relative"}
                        width={"fit-content"}
                    >
                        <Grid
                            columnGap={attributeGap}
                            rowGap={attributesGap}
                            marginLeft={"3vw"} 
                            marginRight={"3vw"} 
                            marginTop={"2vh"} 
                            marginBottom={"2vh"}
                        >
                            {transactionAttributesText}
                        </Grid>
                    </Flex>
                </Link>
            </Flex>
        );
    }

    
}

export default TransactionTemplate;