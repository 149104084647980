import {useEffect, useState, React} from 'react';
import { LoadingSpinner, LoadingSpinnerPage } from '../LoadingPage';
import { Button, Flex, Grid, Text } from '@aws-amplify/ui-react';
import {Link, Navigate, createSearchParams, useLocation} from "react-router-dom";
import {MdCancel} from 'react-icons/md';
import LessonDateTemplate from '../../custom-ui-components/LessonDateTemplate';
import PopUpTemplate from '../../custom-ui-components/form-components/PopUpTemplate';
import { ErrorLoadingPage } from '../ErrorLoadingPage';
import { calculateLessonCost, calculateLessonWage, getStudentNames, getStudentYearGroups, getTutorName } from '../../App';
import MapTemplate from '../../custom-ui-components/EmbededMapTemplate';
import BackButton from '../../custom-ui-components/BackButton';

const AdminViewLesson = function(props) {
    //try {
        const getDatesForLesson = function (lessonDay) {
            const getDayIndex = function (day) {
                try {
                    if (day == "Monday") {
                        return 1;
                    }
                    if (day == "Tuesday") {
                        return 2;
                    }
                    if (day == "Wednesday") {
                        return 3;
                    }
                    if (day == "Thursday") {
                        return 4;
                    }
                    if (day == "Friday") {
                        return 5;
                    }
                    if (day == "Saturday") {
                        return 6;
                    }
                    if (day == "Sunday") {
                        return 0;
                    }
                    throw "Invalid day"
                }
                catch (error) {
                    throw "Error getting day index: " + error;
                }
            };

            const currentDate = new Date();
            const endDate = new Date(currentDate);
            const currentMonth = currentDate.getMonth();
            endDate.setMonth(currentMonth + 3);
            //const currentYear = currentDate.getFullYear();
            //endDate.setFullYear(currentYear + 1);

            const dayIndex = getDayIndex(lessonDay);
            

            const foundDates = [];
            let dateIteration = new Date();
            while (dateIteration <= endDate) {
                const newDateIteration = new Date(dateIteration);
                const dateIterationDay = newDateIteration.getDay();
                if (dayIndex == dateIterationDay) {
                    foundDates.push(newDateIteration);
                }
                dateIteration.setDate(dateIteration.getDate() + 1);
            }

            return foundDates;
        }

        const titleTextProps = props.titleTextProps;
        const standardTextProps = props.standardTextProps;
        const lessonTextProps = {...standardTextProps}
        lessonTextProps.textAlign = "left";
        delete lessonTextProps.maxWidth;
        //Get the current user to pass to subsequent functions
        const user = props.user;
        const APIFunctions = props.APIFunctions;
        const tutorDictionary = props.tutorDictionary;
        const studentDictionary = props.studentDictionary;
        const parentDictionary = props.parentDictionary;
        const lessonDictionary = props.adminLessonDictionary;

        const [deletePopUp, setDeletePopUp] = useState(false);
        const [deletingDate, setDeletingDate] = useState(false);
        const [deletedDate, setDeletedDate] = useState(false);
        const [mapReload, setMapReload] = useState(0);

        const [deleteAmendmentPopUp, setDeleteAmendmentPopUp] = useState(false);
        const [deletingAmendment, setDeletingAmendment] = useState(false);
        const [deletedAmendment, setDeletedAmendment] = useState(false);

        const [currentDate, setCurrentDate] = useState(false);
        const [errorPopUp, setErrorPopUp] = useState(false);

        const [noLessonModel, setNoLessonModel] = useState(false);
        const [lessonProps, setLessonProps] = useState(null);
        const [originalLessonDates, setOriginalLessonDates] = useState([]);
        const [lessonDates, setLessonDates] = useState([]);

        const location = useLocation();
        const extraProps = location.state;

        useEffect(() => {
            try {
                if (lessonProps.lessonDay != null) {
                    const calculatedLessonDates = getDatesForLesson(lessonProps.lessonDay);
                    setOriginalLessonDates(calculatedLessonDates);
                    setLessonDates(calculatedLessonDates);
                }
            } catch {}
        }, [lessonProps]);

        useEffect(() => {
            if (lessonDictionary == null || tutorDictionary == null || studentDictionary == null || parentDictionary == null) {
                return;
            }

            const queryParams = new URLSearchParams(window.location.search);
            let lessonID = queryParams.get("lessonID");
            if (lessonID == null && extraProps != null) {
                lessonID = extraProps.lessonID;
            }
            if (lessonID == null || lessonID == "") {
                setNoLessonModel(true);
                return;
            }
            const lessonModel = lessonDictionary[lessonID];
            if (lessonModel == null) {
                setNoLessonModel(true);
                return;
            }

            const tutorID = lessonModel.tutorID;
            const studentIDs = lessonModel.studentIDs;
            const tutorName = getTutorName(tutorID, tutorDictionary);
            const studentNames = getStudentNames(studentIDs, studentDictionary);
            const tutorModel = tutorDictionary[tutorID];
            const tutorAddress = tutorModel.address || {};
            const tutorPostcode = tutorAddress.postcode;
            const tutorEmail = tutorModel.email;
            const tutorPhoneNumber = tutorModel.phoneNumber;
            const studentYearGroups = getStudentYearGroups(studentIDs, studentDictionary);
            const studentModel = studentDictionary[studentIDs[0]];
            const studentEmail = studentModel.email;
            const studentPhoneNumber = studentModel.phoneNumber;
            
            const newLessonProps = {
                ...lessonModel, 
                tutorFullName: tutorName,
                tutorEmail: tutorEmail,
                tutorPhoneNumber: tutorPhoneNumber,
                tutorPostcode: tutorPostcode,
                studentNames: studentNames,
                studentYearGroups: studentYearGroups,
                studentEmail: studentEmail,
                studentPhoneNumber: studentPhoneNumber
            };

            setLessonProps(newLessonProps);
        }, [extraProps, lessonDictionary, tutorDictionary, studentDictionary, parentDictionary, window.location.search]);

        if (noLessonModel) {
            return <Navigate to={"/Admin/Lessons"} />
        }


        if (lessonProps == null || tutorDictionary == null || studentDictionary == null || parentDictionary == null) {
            return <LoadingSpinnerPage {...props} />
        }
        if (tutorDictionary == "loading" || studentDictionary == "loading" || parentDictionary == "loading" || lessonProps == "loading") {
            return <LoadingSpinnerPage {...props} />
        }
        else if (lessonProps == "error") {
            return <ErrorLoadingPage {...props}/>
        }

        let studentTitle = "Student:";
        if (lessonProps.studentIDs != null && lessonProps.studentIDs.length > 1) {
            studentTitle = "Students:";
        }
        const UIs = [];
        UIs.push(
            <Text {...lessonTextProps} key={"subject"}>
                {lessonProps.subject}
            </Text>,
            <Text {...lessonTextProps} key={"lessonDay"}>
                {lessonProps.lessonDay}s: {lessonProps.startTime} - {lessonProps.endTime}
            </Text>,
            <Text {...lessonTextProps} key={"studentName"}>
                {studentTitle} {lessonProps.studentNames}
            </Text>,
            <Text {...lessonTextProps} key={"tutorName"}>
                Tutor: {lessonProps.tutorFullName}
            </Text>
        );
        if (lessonProps.lessonType != "Online") {
            UIs.push(
                <Text {...lessonTextProps} key={"address1stLine"}>
                    Address: {lessonProps.address.address1stLine}, {lessonProps.address.postcode}
                </Text>,
                <Text {...lessonTextProps} key={"latitude"}>
                    Geocode: {lessonProps.address.geocode != null && lessonProps.address.geocode.latitude}, {lessonProps.address.geocode != null && lessonProps.address.geocode.longitude}
                </Text>
            );
        }
        else if (lessonProps.lessonType == "Online" && lessonProps.googleMeetCode != null) {
            UIs.push(
                <Text {...lessonTextProps} key={"googleMeetCode"}>
                    Google Meet Link: <Link to={"https://meet.google.com/" + lessonProps.googleMeetCode} target='_blank'>https://meet.google.com/{lessonProps.googleMeetCode}</Link>
                </Text>
            );
        }
        UIs.push(
            <Text {...lessonTextProps} key={"startDate"}>
                First lesson date: {lessonProps.startDate}
            </Text>,
            <Text {...lessonTextProps} key={"tutorEmail"}>
                Tutor email: {lessonProps.tutorEmail}
            </Text>,
            <Text {...lessonTextProps} key={"tutorPhoneNumber"}>
                Tutor phone number: {lessonProps.tutorPhoneNumber}
            </Text>,
            <Text {...lessonTextProps} key={"studentYearGroup"}>
                Student year group: {lessonProps.studentYearGroups}
            </Text>
        );
        let endDateText = "Not set";
        if (lessonProps.endDate != null) {
            endDateText = lessonProps.endDate;
        }
        UIs.push(
            <Text {...lessonTextProps} key={"finalLesson"}>
                Final lesson date: {endDateText}
            </Text>
        );
        
        if (lessonProps.parentFirstNames != null) {
            UIs.push(
                <Text {...lessonTextProps} key={"parentName"}>
                    Parent name: {lessonProps.parentFirstNames + " " + lessonProps.parentLastName}
                </Text>
            );
        };
        UIs.push(
            <Text {...lessonTextProps} key={"studentEmail"}>
                Parent email: {lessonProps.studentEmail}
            </Text>,
            <Text {...lessonTextProps} key={"studentPhoneNumber"}>
                Parent phone number: {lessonProps.studentPhoneNumber}
            </Text>
        );
        const lessonCostText = <Text {...standardTextProps} key={"lessonCostText"}>
            Weekly lesson cost: {calculateLessonCost(lessonProps.tutorLevel, lessonProps.startTime, lessonProps.endTime, lessonProps.lessonType, true)}
        </Text>
        const lessonWageText = <Text {...standardTextProps} key={"lessonWageText"}>
            Weekly lesson wage: {calculateLessonWage(lessonProps.tutorLevel, lessonProps.startTime, lessonProps.endTime, lessonProps.lessonType, true)}
        </Text>
        UIs.push(lessonCostText, lessonWageText);
        UIs.push(
            <Text {...lessonTextProps} key={"createdAt"}>
                Lesson creation date: {new Date(lessonProps.createdAt).toUTCString()}
            </Text>,
            <Text {...lessonTextProps} key={"id"}>
                {lessonProps.id}
            </Text>,
        );

        const askDeleteDate = function (currentDate, clickEvent) {
            setCurrentDate(currentDate);
            const controlClick = clickEvent.ctrlKey;
            if (controlClick != true) {
                setDeletePopUp(true);
            }
            else {
                deleteLessonDate(currentDate);
            }
        }

        const askDeleteDateAmendment = function (currentDate, clickEvent) {
            setCurrentDate(currentDate);
            const controlClick = clickEvent.ctrlKey;
            if (controlClick != true) {
                setDeleteAmendmentPopUp(true);
            }
            else {
                deleteLessonAmendment(currentDate);
            }
        }
        
        // UIs.push(
        //     <Text {...lessonTextProps} key={"DatesTitle"}>
        //         Lesson dates for the next 3 months:
        //     </Text>
        // )
        
        // const amendments = lessonProps.amendments || [];
        // const amendmentsUIs = [];
        // amendmentsUIs.push(
        //     <Text {...lessonTextProps} key={"AmendmentsText"}>
        //         Amendments for lesson:
        //     </Text>
        // )

        //Account for any lesson amendments
        // for (const amendment of amendments) {
        //     try {
        //         const amendmentType = amendment.type;
        //         const affectedLesson = new Date(amendment.affectedLesson);
        //         //If a lesson does not ocur on a particular date, remove it from the date list
        //         if (amendmentType == "noLesson") {
        //             for (let lessonDateIndex = 0; lessonDateIndex < lessonDates.length; lessonDateIndex = lessonDateIndex + 1) {
        //                 const lessonDate = lessonDates[lessonDateIndex];
        //                 const lessonDateString = lessonDate.toDateString();
        //                 if (affectedLesson.toDateString() == lessonDateString) {
        //                     const newLessonDates = [...lessonDates];
        //                     newLessonDates.splice(lessonDateIndex, 1);
        //                     setLessonDates(newLessonDates);
        //                 }
        //             }
        //         }
        //         const amendmentText = <LessonDateTemplate 
        //             lessonTextProps={lessonTextProps} 
        //             date={affectedLesson} 
        //             userType={"admin"} 
        //             key={"DateAmendment" + affectedLesson + amendmentType}
        //             delete={askDeleteDateAmendment}
        //             amendment={amendment}
        //         />
        //         amendmentsUIs.push(amendmentText);
        //     } 
        //     catch (error) {
        //         console.log(error);
        //     }
        // }

        // for (const lessonDate of lessonDates) {
        //     const dateText = <LessonDateTemplate 
        //         lessonTextProps={lessonTextProps} 
        //         date={lessonDate} 
        //         userType={"admin"} 
        //         key={"Date" + lessonDate}
        //         delete={askDeleteDate}
        //     />
        //     UIs.push(dateText);
        // }

        const lessonFlex = <Flex direction={"column"} alignItems={"flex-start"}>
            {UIs}
            {/* {amendmentsUIs} */}
        </Flex>

        
        
        const titleText = <Text {...titleTextProps}>View lesson</Text> 

        const backButton = <BackButton {...props} defaultBack={"/Admin/Lessons"} />

        const deleteLessonDate = async function (currentLessonDate) {
            try {
                console.log("Deleting date: " + currentDate.toDateString());
                if (currentLessonDate == null) {
                    currentLessonDate = currentDate;
                }
                setDeletingDate(true);
                setErrorPopUp(false);
                const newLesson = {};
                newLesson.id = lessonProps.id;

                let newAmendments = [];
                if (lessonProps.amendments != null) {
                    newAmendments = [...lessonProps.amendments];
                }
                newAmendments.push({type: "noLesson", affectedLesson: currentLessonDate});

                newLesson.amendments = [...newAmendments];
                console.log(newLesson);
                const newLessonModel = await APIFunctions.updateLesson(newLesson, "admin");
                const newLessonProps = {...lessonProps};
                newLessonProps.amendments = newLessonModel.amendments;
                console.log(newLessonProps);
                setLessonProps(newLessonProps);
                setLessonDates(originalLessonDates);
                setDeletingDate(false);
                setDeletedDate(true);
            }
            catch (error) {
                console.log(error);
                try {
                    console.log(error.response.data);
                } catch {}
                setDeletePopUp(false);
                setDeletingDate(false);
                setDeletedDate(false);
                if (typeof(error) == "string") {
                    setErrorPopUp(error);
                }
                else {
                    setErrorPopUp("Error deleting lesson date");
                }
            }
        }

        const deleteLessonAmendment = async function (amendment) {
            try {
                if (amendment == null) {
                    amendment = currentDate;
                }
                const amendmentType = amendment.type
                const affectedLesson = new Date(amendment.affectedLesson);
                console.log("Deleting amendment: " + affectedLesson+ " - " + amendmentType);
                
                setErrorPopUp(false);
                setDeletingAmendment(true);
                const newLesson = {};
                newLesson.id = lessonProps.id;

                let newAmendments = [];
                if (lessonProps.amendments != null) {
                    newAmendments = [...lessonProps.amendments];
                }
                
                for (let amendmentIndex = 0; amendmentIndex < newAmendments.length; amendmentIndex = amendmentIndex + 1) {
                    const existingAmendment = newAmendments[amendmentIndex];
                    const existingAmendmentDate = new Date(existingAmendment.affectedLesson);
                    const existingAmendmentType = existingAmendment.type;

                    if (existingAmendmentDate.toDateString() == affectedLesson.toDateString() && amendmentType == existingAmendmentType) {
                        newAmendments.splice(amendmentIndex, 1);
                    }
                }
                newLesson.amendments = [...newAmendments];
                const newLessonModel = await APIFunctions.updateLesson(newLesson, "admin");
                const newLessonProps = {...lessonProps};
                newLessonProps.amendments = newLessonModel.amendments;
                console.log(newLessonProps);
                setLessonProps(newLessonProps);
                setLessonDates(originalLessonDates);
                setDeletingAmendment(false);
                setDeletedAmendment(true);
            }
            catch (error) {
                console.log(error);
                try {
                    console.log(error.response.data);
                } catch {}
                setDeleteAmendmentPopUp(false);
                setDeletingAmendment(false);
                setDeletedAmendment(false);
                if (typeof(error) == "string") {
                    setErrorPopUp(error);
                }
                else {
                    setErrorPopUp("Error deleting lesson amendment");
                }
            }
        }

        const deleteText = "Are you sure you want to delete this lesson date?";
        const deletePopUpComponent = <PopUpTemplate text={deleteText} setPopUpVisibility={setDeletePopUp} yesButtonFunction={deleteLessonDate} noButtonFunction={function(){}} showXButton={true} />
        const deletingText = "Deleting lesson date...";
        const deletingDatePopUp = <PopUpTemplate text={deletingText} setPopUpVisibility={setDeletingDate} showXButton={false} />
        const deletedDateText = "Lesson date removed";
        const deletedDatePopUp = <PopUpTemplate text={deletedDateText} okButtonFunction={function(){}} setPopUpVisibility={setDeletedDate} showXButton={false} />

        const deleteAmendmentText = "Are you sure you want to delete this lesson amendment?";
        const deleteAmendmentPopUpComponent = <PopUpTemplate text={deleteAmendmentText} setPopUpVisibility={setDeleteAmendmentPopUp} yesButtonFunction={deleteLessonAmendment} noButtonFunction={function(){}} showXButton={true} />
        const deletingAmendmentText = "Deleting lesson amendment...";
        const deletingAmendmentPopUp = <PopUpTemplate text={deletingAmendmentText} setPopUpVisibility={setDeletingAmendment} showXButton={false} />
        const deletedAmendmentText = "Lesson amendment removed";
        const deletedAmendmentPopUp = <PopUpTemplate text={deletedAmendmentText} okButtonFunction={function(){}} setPopUpVisibility={setDeletedAmendment} showXButton={false} />

        const errorPopUpComponent = <PopUpTemplate text={errorPopUp} okButtonFunction={function(){}} setPopUpVisibility={setErrorPopUp} showXButton={true} />

        let embeddedMap = null;
        const width = props.width;
        let mapWidth = 40*width/100
        let mapColumn = 2;
        if (width < 1200) {
            mapWidth = 80*width/100
            mapColumn = 1;
        }
        if (lessonProps.lessonType != "Online") {
            embeddedMap = <MapTemplate
                {...props}
                type={"directions"} 
                origin={lessonProps.tutorPostcode} 
                destination={lessonProps.address.postcode} 
                width={mapWidth}
                height={"400px"}
                distanceUnit={"miles"}
                mode={"walking"}
                key={"Map" + mapReload}
            />
        }

        const amendLessonButton = <Link 
            to={"/Admin/AmendLesson?lessonID=" + lessonProps.id} 
            style={{textDecoration: 'none'}} 
            state={{...lessonProps}} 
        >
            <Button>Make changes to lesson schedule</Button>
        </Link>


        return (
            <Flex direction={"column"} gap={"4vh"} > 
                <Flex direction={"column"} gap={"4vh"} alignItems={"center"}>
                    {titleText}
                    <Flex gap={"4vw"}>
                        {backButton}
                    </Flex>
                    {amendLessonButton}
                </Flex>
                <Grid alignItems={"flex-start"} columnGap={"2vw"} rowGap={"2vh"}>
                    <Flex direction={"column"} gap={"4vh"} alignItems={"left"} column={1}>                
                        {lessonFlex}
                    </Flex>
                    <Flex column={mapColumn}>
                        {embeddedMap}
                    </Flex>
                </Grid>
                <Flex direction={"column"} gap={"4vh"} alignItems={"center"}> 
                    {deletePopUp && deletePopUpComponent}
                    {deletingDate && deletingDatePopUp}
                    {deletedDate && deletedDatePopUp}
                    {deleteAmendmentPopUp && deleteAmendmentPopUpComponent}
                    {deletingAmendment && deletingAmendmentPopUp}
                    {deletedAmendment && deletedAmendmentPopUp}
                    {(errorPopUp != false) && errorPopUpComponent}
                </Flex>
            </Flex>
        );
    // }
    // catch (error) {
    //   throw "AdminViewLesson Error: " + error;
    // }
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default AdminViewLesson;