import { Flex, Image, Text } from "@aws-amplify/ui-react";
import React from "react";
import techSupportEmail from "../Images/Emails/techsupport@nextdoortutor.co.uk.png"
import BackButton from "../custom-ui-components/BackButton";

export const PageNotFound = function(props) {
  const height = props.height;
  const width = props.width;

  const errorText = <Text fontSize={"25px"} color={"#08007d"} textAlign={"center"}>Page not found...</Text>

  const helpText = <Text fontSize={"25px"} color={"#08007d"} textAlign={"center"}>This page has not been implemented yet, if you think this is an error please contact:</Text>
  let techSupportEmailImage = <Image src={techSupportEmail} width={"23vw"}/>
  if (width < 900) {
      techSupportEmailImage = <Image src={techSupportEmail} width={"80vw"}/>
  }

  const helpTextEmail = <Flex direction={"column"} gap={"10px"} alignItems={"center"}>
    {helpText}
    {techSupportEmailImage}
  </Flex>

  return (
    <div style={{minHeight:height}}>
      <Flex direction={"column"} style={{minHeight:height}} gap={"10vh"} marginTop={"10vh"} alignItems={"center"}>
          {errorText}
          <BackButton />
          {helpTextEmail}
      </Flex>
    </div>
  );
}

