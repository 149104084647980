/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "adminFunctions",
            "endpoint": "https://fexrt3mxwj.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "airwallex",
            "endpoint": "https://khe3qh4nel.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "chats",
            "endpoint": "https://fdtr4le3w4.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "errorReporting",
            "endpoint": "https://qtv9hqd0hg.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "formValidation",
            "endpoint": "https://jzsid1ejtl.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "lessonDataModels",
            "endpoint": "https://1x1vld9463.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "lessonRequests",
            "endpoint": "https://titkqnm829.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "matchingAlgorithm",
            "endpoint": "https://qv8kosx8mb.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "parentDataModels",
            "endpoint": "https://5d6k7mrrxg.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "stripe",
            "endpoint": "https://yoczb7h4mg.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "studentDataModels",
            "endpoint": "https://b87zeigmmf.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "teachingResources",
            "endpoint": "https://nivbl34qmc.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "transactions",
            "endpoint": "https://ajx3q85m36.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "tutorDataModels",
            "endpoint": "https://x8wcx8tpxa.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "updateUserAttributes",
            "endpoint": "https://ljcv26omm0.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "userModels",
            "endpoint": "https://pglq5kkif3.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://pi7xuh6lrrek5kj7pl5prjzsfq.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-2:429c6c25-77f4-4265-932c-0cfb1d240309",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_iHYCD2fds",
    "aws_user_pools_web_client_id": "4a8gurcck6q1ci00mcgpg688pv",
    "oauth": {
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://app.nextdoortutor.co.uk/",
        "redirectSignOut": "https://app.nextdoortutor.co.uk/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "nextdoortutorwebapp-storage220724-dev",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
