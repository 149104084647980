import { Flex, Image, Text } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import { Link, Navigate } from "react-router-dom";
import { AppNavButton } from '../../custom-ui-components';
import { ErrorLoadingPage } from '../ErrorLoadingPage';
import { LoadingSpinnerPage } from '../LoadingPage';

const AdminHome = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    const lessonRequests = props.adminLessonRequests;
    const lastMouseClick = props.lastMouseClick;

    const [numLessonRequets, setNumLessonRequets] = useState(null);

    const getNumLessonRequests = function () {
        setNumLessonRequets("calculating");
        let numNewLessonRequests = 0 
        for (const lessonRequest of lessonRequests) {
            if (lessonRequest.status == 0 || lessonRequest.status == 2) {
                numNewLessonRequests = numNewLessonRequests + 1
            }
        }
        setNumLessonRequets(numNewLessonRequests);
    }

    useEffect(() => {
        if (lessonRequests != null) {
            getNumLessonRequests(); 
        }
    }, []);

    if (numLessonRequets == null) {
        if (lessonRequests != null) {
            getNumLessonRequests();
        }
    }

    if (lessonRequests == null || numLessonRequets == null) {
        return <LoadingSpinnerPage {...props} />
    }
    else if (lessonRequests == "loading" || numLessonRequets == "loading") {
        return <LoadingSpinnerPage {...props} />
    }
    else if (lessonRequests == "error") {
        return <ErrorLoadingPage {...props}/>
    }


    let numRefundRequests = 0;
    if (props.adminTransactions != null && props.adminTransactions != "loading" && props.adminTransactions != "error") {
        for (const transaction of props.adminTransactions) {
            if (transaction.refundRequested == true && transaction.refunded != true) {
                numRefundRequests = numRefundRequests + 1;
            }
        }
    }

    let buttonHints = props.buttonHints;
    if (buttonHints == null) {
        buttonHints = true;
    }

    const appIssuesFunction = function () {
        window.open("https://app.nextdoortutor.co.uk/Admin/AppIssues", "_blank");
    }

    const linkPrefix = "/Admin";
    let buttonList = [
        {text: "Lessons", link: linkPrefix + "/Lessons", label:"View, filter, edit and delete all lessons currently in the database:"}, 
        {text: "Lesson Requests", link: linkPrefix + "/LessonRequests", label:"View and allocate all outstanding lesson requests from students", notifications: numLessonRequets},
        {text: "Create Lesson", link: linkPrefix + "/CreateNewLesson", label:"Create a new lesson (not from a lesson request)"},
        {text: "Pay Tutors", link: linkPrefix + "/PayTutors"},
        {text: "Send Mass Emails", link: linkPrefix + "/MassEmail"},
        {text: "View Transactions", link: linkPrefix + "/Transactions", label:"View user transactions and respond to refund requests", notifications: numRefundRequests},
        // {text: "New Tutor Access", link: linkPrefix + "/GiveAccessToTutor", label:"Give a new tutor access to sign up with their email and generate a sign up code", notifications: unNotifiedTutors}, 
        {text: "View Users", link: linkPrefix + "/ViewUsers", label:"View all app users and sort by user type"},
        {text: "Teaching Resources", link: linkPrefix + "/TeachingResourcesAdmin", label:"View and manage teaching resources"},
        {text: "Admin Messages", link: linkPrefix + "/Messages", label:"View all user messages"},
        {text: "App Credit", link: linkPrefix + "/ViewCredit", label:"View total credit stored in user accounts"},
        {text: "Previous Lessons", link: linkPrefix + "/ViewPreviousLessons", label:null},
        {text: "View Lesson Pricing/Wages", link: linkPrefix + "/ViewPricing", label:null},
        {text: "View/manage active users", link: linkPrefix + "/ForceAppUpdate", label:null},
        {text: "App Issues", link: linkPrefix + "/AppIssues", label:null, function: appIssuesFunction, condition: props.environment == "dev"},
        {text: "Dev Tests", link: linkPrefix + "/Tests", label:null},
    ];

    const buttons = [];
    for (const button of buttonList) {
        const buttonText = button.text;
        const buttonFontSize = "20px";
        const width = props.width;
        const buttonLink = button.link;
        const buttonNumNotifications = button.notifications || 0;

        let buttonNotification = <Flex 
            borderRadius={"100px"} 
            backgroundColor={"#fc3903"} 
            width={"25px"} 
            height={"25px"}
            alignItems={"center"}
            justifyContent={"center"}
            fontSize={"15px"}
            color={"#ffffff"}
            position={"absolute"}
            right="-10px" 
            top="-10px"
        >
            {buttonNumNotifications}
        </Flex>

        if (buttonNumNotifications == 0) {
            buttonNotification = null;
        }

        const buttonElement = <Flex 
            position={"relative"}
            onClick={(button.condition == false) && button.function || null}
        >
            {buttonNotification}
            <AppNavButton text={buttonText} fontSize={buttonFontSize} width={width}/>
        </Flex>
        const linkedButton = <Link to={(button.condition == null || button.condition == true) && buttonLink} style={{textDecoration: 'none'}} key={buttonText}>{buttonElement}</Link>;

        let buttonLabel = <Text {...standardTextProps}>{button.label}</Text>
        if (buttonHints == false) {
            buttonLabel = null;
        }

        const buttonFlex = <Flex direction={"column"} gap={"1vh"} alignItems={"center"} key={buttonText}>
            {buttonLabel}
            {linkedButton}
        </Flex>

        buttons.push(buttonFlex);
    }

    const welcomeText = <Text {...titleTextProps}>
        Admin Dashboard
    </Text>

    let pluralText = "are";
    if (numLessonRequets == 1) {
        pluralText = "is";
    }
    let requestsText = <Text {...standardTextProps} color={"#fc7303"}>
        There {pluralText} {numLessonRequets} lesson requests to review
    </Text>
    if (numLessonRequets == 0) {
        requestsText = null;
    }


    const verifyIDButton = <Link to={"/VerifyID"} style={{textDecoration: 'none'}}>
                <Flex 
                    backgroundColor={"#fad905"} 
                    paddingTop={"4px"} 
                    paddingBottom={"4px"} 
                    paddingLeft={"10px"} 
                    paddingRight={"10px"} 
                    borderRadius={"10px"}
                    position={"relative"}
                >
                    <Text {...standardTextProps}>Verify ID!</Text>
                    <Flex 
                        borderRadius={"100px"} 
                        backgroundColor={"#fc3903"} 
                        width={"25px"} 
                        height={"25px"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        fontSize={"15px"}
                        fontWeight={"hairline"}
                        color={"#ffffff"}
                        position={"absolute"}
                        right="-10px" 
                        top="-10px"
                    >1</Flex>
                </Flex>
            </Link>

    return (
        <Flex direction={"column"} alignItems={"center"}>
            {welcomeText}
            {requestsText}
            {verifyIDButton}
            <Flex direction={"column"} gap={"5vh"} alignItems={"center"}>
                {buttons}
            </Flex>
        </Flex>
    );

}

export default AdminHome;