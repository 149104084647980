import * as React from "react";
import { Button, Flex, Grid, Icon, Image, Text } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";

const BackButton = function(props) {
    const backStack = props.backStack;

    const naviagate = useNavigate();

    const backButton = <Button onClick={() => {
        if (backStack != null && backStack.length > 1) {
			const previousPage = backStack[backStack.length - 2];
            naviagate({pathname: previousPage.pathname, search: previousPage.search});
			const newBackStack = backStack.slice(0, backStack.length - 1);
			props.setBackStack(newBackStack);
		}
        else {
            console.log("Default back");
            let defaultBack = props.defaultBack;
            if (defaultBack == null) {
                defaultBack = "/";
            }
            naviagate(defaultBack);
        }
    }}>
        Back
    </Button>

    return(
        <Flex>
            {backButton}
        </Flex>
    );
}

export default BackButton;