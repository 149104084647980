import { Button, CheckboxField, Divider, Flex, Text } from '@aws-amplify/ui-react';
import React, { useEffect } from 'react';
import {Link, Navigate, useLocation} from "react-router-dom";
import { useState } from 'react';

const GuideStepTemplate = function(props) {
    const standardTextProps = props.standardTextProps;
    // const bulletFontSize = (standardTextProps.fontSize.split("px")[0] * 0.8) + "px";
    // const bulletTextProps = {...standardTextProps, textAlign: "left", fontSize: bulletFontSize};

    const [flexDirectionProps, setFlexDirectionProps] = useState({
        direction: "row",
        gap: "3vw"
    });

    useEffect(() => {
        if (props.width > 400) {
            if (flexDirectionProps.direction != "row"){
                setFlexDirectionProps({
                    direction: "row",
                    gap: "3vw"
                });
            }
        }
        else {
            if (flexDirectionProps.direction != "column"){
                setFlexDirectionProps({
                    direction: "column",
                    gap: "1vh"
                });
            }
        }
    }, [props.width, flexDirectionProps]);

    const [showDetails, setShowDetails] = useState(false);

    const stepComplete = props.stepComplete;
    const checkBox = <CheckboxField 
        checked={stepComplete} 
        isReadOnly={true}
        disabled={true}
        size="large"
    />;

    const stepNumber = props.stepNumber;
    const link = props.link;
    const stepText = props.stepText;

    const detailsAfterComplete = props.detailsAfterComplete; 

    const backLink = props.backLink;
    let target = null;
    if (props.newTab == true) {
        target = "_blank";
    }
    let linkedText = <Link to={link} target={target} state={{back:backLink}}>{stepText}</Link>;
    if ((stepComplete && !detailsAfterComplete) || link == null) {
        linkedText = stepText;
    }
    
    const stepNumberText = <Text {...standardTextProps} paddingBottom={"2px"} width={"fit-content"} style={{textWrap: "nowrap", whiteSpace:"nowrap"}}>Step {stepNumber}:</Text>
    const mainText = <Text {...standardTextProps} paddingBottom={"2px"} textAlign={"left"}>{linkedText}</Text>

    let moreDetailsFlex = props.moreDetailsFlex;
    let detailsButtonText = "Details";
    if (showDetails) {
        detailsButtonText = "Hide details";
    }
    else {
        moreDetailsFlex = null;
    }
    
    let detailsButton = null;
    if (!stepComplete || detailsAfterComplete) {
        detailsButton = <Button 
            onClick={() => setShowDetails(!showDetails)}
            size="small"
            width={"fit-content"}
            alignSelf={"center"}
        >
            {detailsButtonText}
        </Button>;
    }

    return (  
        <Flex direction={"column"} alignItems="flex-start" gap="1vh">
            <Flex direction={flexDirectionProps.direction} gap={flexDirectionProps.gap} width={"80vw"}>
                <Flex alignItems="flex-start" gap="1vw" style={{cursor:"default"}}>
                    <Flex gap="3vw">
                        {checkBox}
                        {stepNumberText}
                    </Flex>
                    {mainText}
                </Flex>
                {detailsButton}
            </Flex>
            {moreDetailsFlex}
        </Flex>
    );
}


//Return the correct page back up to the app and index files (with authenticator to force login)
export default GuideStepTemplate;