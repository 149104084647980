import { Button, Flex, Icon, Text } from '@aws-amplify/ui-react';
import {React, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LoadingSpinner, LoadingSpinnerPage } from '../LoadingPage';
import {MdMenuBook} from 'react-icons/md';
import { ErrorLoadingPage } from '../ErrorLoadingPage';

const ParentHome = function(props) {
    const titleTextProps = {...props.titleTextProps};
    const standardTextProps = {...props.standardTextProps};

    const parentModel = props.parentModel;
    const studentDataModels = props.parentsStudents;
    const parentLessons = props.parentLessons;
    const studentLessonDictionary = props.studentLessonDictionary;
    const parentLessonRequests = props.parentLessonRequests;

    if (parentModel == null || studentDataModels == null || parentLessons == null  || parentLessonRequests == null) {
        return <LoadingSpinnerPage {...props} />
    }
    else if (parentModel == "loading" || studentDataModels == "loading" || parentLessons == "loading"  || parentLessonRequests == "loading") {
        return <LoadingSpinnerPage {...props} />
    }
    else if (parentModel == "error" || studentDataModels == "error" || parentLessons == "error" || parentLessonRequests == null) {
        return <ErrorLoadingPage {...props}/>
    }

    const welcomeText = <Text {...titleTextProps}>
        Parent Dashboard
    </Text>
    const studentFlexes = [];
    for (const student of studentDataModels) {
        const studentID = student.id;
        const studentLessons = studentLessonDictionary[studentID] || [];
        const studentLessonRequests = [];
        for (const parentLessonRequest of parentLessonRequests) {
            const requestStudentIDs = parentLessonRequest.studentIDs;
            for (const requestStudentID of requestStudentIDs) {
                if (requestStudentID == studentID) {
                    studentLessonRequests.push(parentLessonRequest);
                }
            }
        }
        const numStudentLessons = studentLessons.length;
        let numStudentLessonRequests = 0;
        for (const request of studentLessonRequests) {
            if (request.status != 3) {
                numStudentLessonRequests = numStudentLessonRequests + 1;
            }
        }
        let numLessonsSuffex = "s";
        if (numStudentLessons == 1) {
            numLessonsSuffex = "";
        }
        let numRequestsSuffex = "s";
        if (numStudentLessonRequests == 1) {
            numRequestsSuffex = "";
        }
        studentFlexes.push(
            <Link to={"/Parent/MyStudent?studentID=" + student.id } style={{textDecoration: 'none'}} key={student.id + "Link"}>
                <Flex 
                    backgroundColor={"#48e873"} 
                    borderRadius={"1vh"} 
                    paddingLeft={"1vw"}
                    paddingRight={"1vw"}
                    paddingTop={"1vh"}
                    paddingBottom={"1vh"}
                    style={{cursor: "pointer"}}
                    key={student.id + "Flex"}
                    justifyContent={"center"}
                    direction={"column"}
                    gap={"1vh"}
                >
                    <Text {...standardTextProps} fontSize={"18px"} key={student.id}>{student.firstNames + " " + student.lastName}</Text>
                    <Text {...standardTextProps} fontSize={"18px"} key={student.id + "numLessons"}>{numStudentLessons} assigned lesson{numLessonsSuffex}</Text>
                    <Text {...standardTextProps} fontSize={"18px"} key={student.id + "numLessonRequests"}>{numStudentLessonRequests} requested lesson{numRequestsSuffex}</Text>
                </Flex>
            </Link>
        );
    }

    const myStudentFlex = <Flex direction={"column"} key={"myStudentFlex"}>
        <Text {...standardTextProps} key={"MyStudentsTitle"}>My students:</Text>
        {studentFlexes}
    </Flex>

    const addStudentButton = <Link to={"/Parent/AddNewStudent"} style={{textDecoration: 'none'}}><Button>Add a new student</Button></Link>

    const viewLessonsButtonText = <Text>View your lessons</Text>

    const bookIcon = <Icon
        key={"bookIcon"}
        width={"30px"}
        height={"30px"}
        as={MdMenuBook}
    />
    let viewLessonsButton = <Link 
        to={"/Parent/MyLessons"} 
        style={{textDecoration: 'none'}}
    >
        <Button>
            <Flex alignItems={"center"}>
                {viewLessonsButtonText}
                {bookIcon}
            </Flex>
        </Button>
    </Link>

    const amendLessonsButton = <Link 
        to={"/Parent/AmendLessons"} 
        style={{textDecoration: 'none'}}
    >
        <Button>
            <Text>Cancel or make changes to a lesson</Text>
        </Button>
    </Link>

    let numRequestedAmendments = 0;
    for (const lesson of parentLessons) {
        const lessonAmendments = lesson.amendments || [];
        for (const amendment of lessonAmendments) {
            if (amendment.confirmed != true && amendment.rejectedBy == null) {
                const requestedBy = amendment.requestedBy;
                if (requestedBy.userType != "parent") {
                    numRequestedAmendments = numRequestedAmendments + 1;
                }
            }
        }
    }

    let respondToAmendmentsButton = null;
    if (numRequestedAmendments > 0) {
        let buttonNotification = <Flex 
            borderRadius={"100px"} 
            backgroundColor={"#fc3903"} 
            width={"25px"} 
            height={"25px"}
            alignItems={"center"}
            justifyContent={"center"}
            fontSize={"15px"}
            color={"#ffffff"}
            position={"absolute"}
            right="-10px" 
            top="-10px"
        >
            {numRequestedAmendments}
        </Flex>

        let pluralText = "request";
        if (numRequestedAmendments > 1) {
            pluralText = "requests";
        }
        const buttonElement = <Flex position={"relative"}>
            {buttonNotification}
            <Button>Respond to lesson amendment {pluralText}</Button>
        </Flex>
        respondToAmendmentsButton = <Link to={"/Parent/RespondToAmendments"} style={{textDecoration: 'none'}}>{buttonElement}</Link>
    }

    let viewLessonRequestsButton = <Link to={"/Parent/LessonRequests"} style={{textDecoration: 'none'}}><Button>View submitted lesson requests</Button></Link>

    const lessonRequestButton = <Link to={"/Parent/NewLessonRequest"} style={{textDecoration: 'none'}}><Button>Make a new lesson request</Button></Link>
    
    const guideButton = <Link to={"/Parent/StartGuide"} style={{textDecoration: 'none'}}><Button>Next Door Tutor Guide</Button></Link>

    return (
        <Flex direction={"column"} gap={"5vh"}>
            <Flex direction={"column"} gap={"2vh"} alignItems={"center"}>
                {welcomeText}
                {guideButton}
                {viewLessonsButton}
                {amendLessonsButton}
                {respondToAmendmentsButton}
                {viewLessonRequestsButton}
                {lessonRequestButton}
                {addStudentButton}
                {myStudentFlex}
            </Flex>
        </Flex>
    );
}

export default ParentHome;