import {useEffect, useState, React} from 'react';
import { LoadingSpinner, LoadingSpinnerPage } from '../LoadingPage';
import { Button, Flex, Grid, Icon, Text } from '@aws-amplify/ui-react';
import {Link, Navigate, useLocation} from "react-router-dom";
import {MdArrowRight, MdArrowDropDown} from 'react-icons/md';
import { DropDownQuestionTemplate, FormTemplate } from '../../custom-ui-components';
import MapTemplate from '../../custom-ui-components/EmbededMapTemplate';
import { ErrorLoadingPage } from '../ErrorLoadingPage';
import { calculateLessonWage, getSessionLengthMins, priceValuetoString } from '../../App';

const TutorLessonRequestAccept = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    const requestTextProps = {...standardTextProps}
    requestTextProps.textAlign = "left";
    delete requestTextProps.maxWidth;
    //Get the current user to pass to subsequent functions
    const userSettings = props.settings || {};
    const width = props.width;
    const APIFunctions = props.APIFunctions;
    let distanceUnit = userSettings.distanceUnit || "imperial";
    if (distanceUnit != "metric" && distanceUnit != "imperial") {
        distanceUnit = "imperial";
    }
    distanceUnit = "metric";
    const widthThreshold = 1000;
    
    const [showDetails, setShowDetails] = useState(true);
    const [redirect, setRedirect] = useState(null);
    
    const [mapReload, setMapReload] = useState(0);
    const [anyQuestionErrors, setAnyQuestionErrors] = useState(true);

    useEffect(() => {
        if (width < widthThreshold) {
            setShowDetails(false);
        }
    }, []);

    const location = useLocation();
    const extraProps = location.state;
    if (extraProps == null) {
        return <Navigate to={"/Tutor/LessonRequest"} />
    }

    const tutorModel = props.tutorModel;
    if (tutorModel == null || tutorModel == "loading") {
        return <LoadingSpinnerPage {...props} />
    }
    else if (tutorModel == "error") {
        return <ErrorLoadingPage />
    }
    const lessonRequest = {...extraProps};

    let subject = "No subject provided";
    if (lessonRequest.subject != null) {
        subject = lessonRequest.subject;
    }
    let lessonType = "No lesson type provided";
    if (lessonRequest.lessonType != null) {
        lessonType = lessonRequest.lessonType;
    }
    let postcode = null;
    if (lessonType != "Online") {
        postcode = "No postcode provided"
        if (lessonRequest.postcode != null) {
            postcode = lessonRequest.postcode;
        }
    }
    let studentAvailability = "No student availability provided";
    if (lessonRequest.studentAvailability != null) {
        studentAvailability = lessonRequest.studentAvailability;
    }



    const studentModels = lessonRequest.studentModels
    let studentNames = "No student name given";
    let studentYearGroupsText = "No year group given";
    let studentAbilityText = "No ability given";
    if (studentModels != null) {
        studentNames = "";
        studentYearGroupsText = "";
        studentAbilityText = "";
        for (let studentIndex = 0; studentIndex < studentModels.length; studentIndex = studentIndex + 1) {
            const studentModel = studentModels[studentIndex];
            if (studentModel.firstNames != null) {
                const studentName = studentModel.firstNames + " " + studentModel.lastName;
                studentNames = studentNames + studentName;
            }
            else {
                studentYearGroupsText = studentYearGroupsText + studentModel.yearGroup;
                studentAbilityText = studentAbilityText + studentModel.yearGroup;
                if (studentModel.schoolSet != null) {
                    studentAbilityText = studentAbilityText + " (" + studentModel.schoolSet + ")";
                }
            }
            if (studentIndex < studentModels.length - 2) {
                if (studentNames != "") {
                    studentNames = studentNames + ", ";
                }
                if (studentYearGroupsText != "") {
                    studentYearGroupsText = studentYearGroupsText + ", ";
                }
                if (studentAbilityText != "") {
                    studentAbilityText = studentAbilityText + ", ";
                }
            }
            else if (studentIndex < studentModels.length - 1) {
                if (studentNames != "") {
                    studentNames = studentNames + " and ";
                }
                if (studentYearGroupsText != "") {
                    studentYearGroupsText = studentYearGroupsText + " and ";
                }
                if (studentAbilityText != "") {
                    studentAbilityText = studentAbilityText + " and ";
                }
            }
        }
    }
    
    let studentAbility = "No student year group provided";
    if (studentAbilityText != "") {
        studentAbility = studentAbilityText;
    }
    let tutorWageInPerson = "Error calculating wage for lesson";
    let tutorWageOnline = "Error calculating wage for lesson";
    let tutorWagesInPerson = "Error calculating wages for lessons";
    let tutorWagesOnline = "Error calculating wages for lessons";
    if (tutorModel != null) {
        const tutorLevel = tutorModel.level;
        const lessonDuration = getSessionLengthMins(lessonRequest.sessionLength);
        const tutorWageInPersonValue = calculateLessonWage(tutorLevel, null, null, "In-Person", false, lessonDuration);
        const tutorWageOnlineValue = calculateLessonWage(tutorLevel, null, null, "Online", false, lessonDuration);
        tutorWageInPerson = priceValuetoString(tutorWageInPersonValue);
        tutorWageOnline = priceValuetoString(tutorWageOnlineValue);
        tutorWagesInPerson = priceValuetoString(tutorWageInPersonValue * lessonRequest.numSessions);
        tutorWagesOnline = priceValuetoString(tutorWageOnlineValue * lessonRequest.numSessions);
    }
    let numSessions = "No number of sessions provided";
    if (lessonRequest.numSessions != null) {
        numSessions = lessonRequest.numSessions;
    }
    let sessionLength = "No lesson length provided";
    if (lessonRequest.sessionLength != null) {
        sessionLength = lessonRequest.sessionLength;
    }
    let requestedStartString = "No requested start date provided";
    let requestedStartDate = new Date().toDateString();
    if (lessonRequest.requestedStartDate != null) {
        requestedStartDate = new Date(lessonRequest.requestedStartDate).toDateString();
        if (new Date(lessonRequest.requestedStartDate + "UTC").valueOf() < new Date().valueOf()) {
            requestedStartString = "As soon as possible";
        }
        else {
            requestedStartString = lessonRequest.requestedStartDate;
        }
    }
    let studentSet = null;
    if (lessonRequest.studentAbility != null) {
        studentSet = lessonRequest.studentAbility;
    }
    

    const availabilityUI = [];
    const availabilityDays = Object.entries(studentAvailability);
    const availabilityTitleText = <Text {...standardTextProps} key={"availabilityTitleText"}>
        Given availability:
    </Text>
    availabilityUI.push(availabilityTitleText);
    const dayOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const dayFlexes = [];
    const validatedStudentAvailability = {};
    let sessionLengthMins = null;
    if (sessionLength == "45 Minutes") {
        sessionLengthMins = 45;
    }
    else if (sessionLength == "1 Hour") {
        sessionLengthMins = 60;
    }
    else if (sessionLength == "1 Hour 30 Minutes") {
        sessionLengthMins = 90;
    }
    else if (sessionLength == "2 Hours") {
        sessionLengthMins = 120;
    }
    else {
        throw "Invalid session length";
    }
    for (const day of availabilityDays) {
        const dayName = day[0];
        validatedStudentAvailability[dayName] = [];
        let dayIndex = null;
        for (let validDayIndex = 0; validDayIndex < dayOrder.length; validDayIndex = validDayIndex + 1) {
          const validDay = dayOrder[validDayIndex];
          if (validDay == dayName) {
            dayIndex = validDayIndex;
          }
        }
        const dayAvailability = day[1];

        let dayAvailabilityText = "";
        const dayAvailabilityTextArray = [];
        for (let availabilityIndex = 0; availabilityIndex < dayAvailability.length; availabilityIndex = availabilityIndex + 1) {
            const availability = dayAvailability[availabilityIndex];
            const startTime = availability.startTime;
            const endTime = availability.endTime;
            
            let inserted = false;
            const startTimeHour = parseInt(startTime.split(":")[0]);
            const startTimeMinute = parseInt(startTime.split(":")[1]);
            const startTimeInt = (startTimeHour * 60) + startTimeMinute;
            const endTimeHour = parseInt(endTime.split(":")[0]);
            const endTimeMinute = parseInt(endTime.split(":")[1]);
            const endTimeInt = (endTimeHour * 60) + endTimeMinute;
            const durationMins = endTimeInt - startTimeInt;
            if (durationMins >= sessionLengthMins) {
                validatedStudentAvailability[dayName].push(availability);
            }

            const windowObject = {
                startTime: startTime,
                endTime: endTime,
                startTimeInt: startTimeInt
            };
            for (let windowIndex = 0; windowIndex < dayAvailabilityTextArray.length; windowIndex = windowIndex + 1) {
                const window = dayAvailabilityTextArray[windowIndex];
                if (startTimeInt < window.startTimeInt && inserted == false) {
                    dayAvailabilityTextArray.splice(windowIndex, 0, windowObject)
                    inserted = true;
                }
            }
            if (inserted == false) {
                dayAvailabilityTextArray.push(windowObject);
            }
        }
        const timesFlex = [];
        timesFlex.push(<Text {...standardTextProps} key={"DayName:" + dayName}>{dayName}:</Text>);
        for (const window of dayAvailabilityTextArray) {
            const startTime = window.startTime;
            const endTime = window.endTime;
            const startTimeHour = parseInt(startTime.split(":")[0]);
            const startTimeMinute = parseInt(startTime.split(":")[1]);
            const startTimeInt = (startTimeHour * 60) + startTimeMinute;
            const endTimeHour = parseInt(endTime.split(":")[0]);
            const endTimeMinute = parseInt(endTime.split(":")[1]);
            const endTimeInt = (endTimeHour * 60) + endTimeMinute;
            const durationMins = endTimeInt - startTimeInt;
            dayAvailabilityText = dayAvailabilityText + (window.startTime + " - " + window.endTime) + ",\n";
            let tooShort = null;
            if (durationMins < sessionLengthMins) {
                tooShort = " (Too short)";
            }
            const timeText = <Text {...standardTextProps} fontSize={"18px"} key={dayName + window.startTime}>{window.startTime} - {window.endTime} {tooShort}</Text>
            timesFlex.push(timeText);
            
        }
        let dayAvailabilityFlex = null;
        if (dayAvailabilityText != "") {
            dayAvailabilityFlex = <Flex 
                {...requestTextProps} 
                key={dayName + "Flex"} 
                direction={"column"} 
                textAlign={"start"} 
                alignItems={"flex-start"}
                gap={"0vh"}
            >
                {timesFlex}
            </Flex>
        }
        dayFlexes[dayIndex] = dayAvailabilityFlex;
    }
    for (const dayFlex of dayFlexes) {
        if (dayFlex != null) {
            availabilityUI.push(dayFlex);
        }
    }

    let UIs = [];
    if (tutorWageInPerson != null && lessonRequest.lessonType == "In-Person" || (tutorWageInPerson == tutorWageOnline)) {
        UIs.push(
            <Text {...requestTextProps} key={"tutorWageInPerson"}>
                Lesson Wage: {tutorWageInPerson}
            </Text>
        );
        if (numSessions > 1) {
            UIs.push(
                <Text {...requestTextProps} key={"tutorWagesInPerson"}>
                    Wage for all weekly lessons: {tutorWagesInPerson}
                </Text>
            );
        }
    }
    else if (tutorWageOnline != null && lessonRequest.lessonType == "Online") {
        UIs.push(
            <Text {...requestTextProps} key={"tutorWageOnline"}>
                Lesson Wage: {tutorWageOnline}
            </Text>
        );
        if (numSessions > 1) {
            UIs.push(
                <Text {...requestTextProps} key={"tutorWagesOnline"}>
                    Wage for all weekly lessons: {tutorWagesOnline}
                </Text>
            );
        }
    }
    else if (tutorWageInPerson != null && tutorWageOnline != null && lessonRequest.lessonType == "Either") {
        UIs.push(
            <Text {...requestTextProps} key={"tutorWageInPerson"}>
                Lesson Wage (If you choose in-person lesson): {tutorWageInPerson}
            </Text>,
            <Text {...requestTextProps} key={"tutorWageOnline"}>
                Lesson Wage (If you choose online lesson): {tutorWageOnline}
            </Text>
        );
        if (numSessions > 1) {
            UIs.push(
                <Text {...requestTextProps} key={"tutorWagesInPerson"}>
                    Wage for all weekly lessons (If you choose in-person lesson): {tutorWagesInPerson}
                </Text>,
                <Text {...requestTextProps} key={"tutorWagesOnline"}>
                    Wage for all weekly lessons (If you choose online lesson): {tutorWagesOnline}
                </Text>
            );
        }
    }
    UIs.push(
        <Text {...requestTextProps} key={"subject"}>
            Subject: {subject}
        </Text>,
        <Text {...requestTextProps} key={"lessonType"}>
            Lesson type: {lessonType}
        </Text>
    );
    if (extraProps.lessonType != "Online") {
        UIs.push(
            <Text {...requestTextProps} key={"postcode"}>
                Postcode: {postcode}
            </Text>
        );
    }
    let studentAbilityTitle = "Student ability:";
    if (extraProps.schoolSet != null && typeof(extraProps.schoolSet) != "string") {
        const schoolSetArray = Object.keys(extraProps.schoolSet);
        const numSets = schoolSetArray.length;
        if (numSets > 1) {
            studentAbilityTitle = "Student abilities:";
        }
    }
    UIs.push(
        <Text {...requestTextProps} key={"numSessions"}>
            Lessons per week: {numSessions}
        </Text>,
        <Text {...requestTextProps} key={"sessionLength"}>
            Session length: {sessionLength}
        </Text>,
        <Text {...requestTextProps} key={"requestedStartDate"}>
            Requested start date: {requestedStartString}
        </Text>,
        <Text {...requestTextProps} key={"studentYearGroup"}>
            {studentAbilityTitle} {studentAbility}
        </Text>,
    );
    if (studentSet != null) {
        UIs.push(
            <Text {...requestTextProps} key={"studentSet"}>
                Student set: {studentSet}
            </Text>
        );
    }
    UIs.push(
        <Flex direction={"column"} alignItems={"flex-start"} key={"availability"}>
            {availabilityUI}
        </Flex>
    );
    if (lessonRequest.notes != null) {
        UIs.push(
            <Text {...requestTextProps} key={"notes"}>
                Notes: {lessonRequest.notes}
            </Text>
        );
    }
    
    let arrowDirection = MdArrowDropDown;
    let showDetailsText = "Hide details";
    if (showDetails == false) {
        UIs = null;
        arrowDirection = MdArrowRight;
        showDetailsText = "Show details";
    }
    const showDetailsArrow = <Flex
        onClick={() => {
            setShowDetails(!showDetails);
        }}
        style={{cursor:"pointer"}}
        borderRadius={"10px"}
        backgroundColor={"#ffffff"}
        alignItems={"center"}
        width={"230px"}
        height={"35px"}
        position={"relative"}
    >
        <Text 
            {...standardTextProps}
            position={"absolute"}
            left={"10px"}
        >{showDetailsText}</Text>
        <Icon
            key={"editButton"}
            width={"30px"}
            height={"30px"}
            as={arrowDirection}
            right={"10px"}
            position={"absolute"}
        />
    </Flex>

    let requestWidth = "30vw";
    if (width < widthThreshold) {
        requestWidth = "90vw"
    }
    const requestFlex = <Flex 
        direction={"column"} 
        alignItems={"flex-start"} 
        width={requestWidth}
        columnStart={"1"}
        rowStart={"1"}
    >
        {showDetailsArrow}
        {UIs}
    </Flex>

    
    const submitAnswers = async function (answers) {
        try {
            const details = answers.newLessons.lessons;
            for (const lesson of details) {
                lesson.requestedStartDate = requestedStartDate;
                if (lessonRequest.lessonType == "Either") {
                    lesson.lessonType = answers.lessonType;
                }
            }
            await APIFunctions.respondToTutorRequest(lessonRequest.id, "Yes", details);
        }
        catch (error) {
            console.log(error);
            throw "Error responding to tutoring request";
        }
    }

    const exitForm = function () {
        setRedirect(<Navigate to={"/Tutor/LessonRequests"} />);
    }

    // let tutorDistance = distanceKm + " Km";
    // if (extraProps.lessonType != "Online" && distanceUnit == "imperial") {
    //     const distanceMiles = Math.round((distanceKm * 0.621371) * 100) / 100;
    //     tutorDistance = distanceMiles + " miles";
    // }

    let createLessonForm = null;

    const lessonQuestions = [];

    const currentDateObject = new Date();
    const requestedStartDateObject = new Date(requestedStartDate);
    let startPointDate = new Date(requestedStartDateObject).toDateString();
    if (requestedStartDateObject.valueOf() < currentDateObject.valueOf()) {
        startPointDate = currentDateObject.toDateString();
    }

    lessonQuestions.push({
        id: "newLessons", 
        label: "Choose lesson(s) day/time",
        type: "createlessontime", 
        required: true,
        extraProps: {
            sessionLength: sessionLength,
            numSessions: numSessions,
            availability: validatedStudentAvailability,
            matchingAvailability: lessonRequest.matchingAvailability,
            enforceNumSessions: true,
            startPointDate: startPointDate
        }
    });

    if (lessonRequest.lessonType == "Either") {
        lessonQuestions.push({
            id: "lessonType", 
            label: "Lesson type:",
            type: "radio", 
            required: true,
            options: ["Online", "In-Person"],
        });
    }

    const softSubmitChecks = [];
    softSubmitChecks.push({
        questionID: "newLessons",
        answerObjectChild: "lessons",
        length: numSessions,
        message: "Number of weekly is not what was requested. Are you sure you want to continue?"
    });

    //console.log(props);
    createLessonForm = <FormTemplate
        submitAnswers={submitAnswers} 
        questions={lessonQuestions}
        okButtonFunction={exitForm}
        submitPopUp={false}
        softSubmitChecks={softSubmitChecks}
        {...props}
    />
    
    const titleText = <Text {...titleTextProps}>View lesson request</Text>
    

    const backButton = <Link to={"/Tutor/LessonRequest"} style={{textDecoration: 'none'}} state={{...lessonRequest}}><Button>Back</Button></Link>

    let direction = "row";
    if (width < 1000) {
        direction = "column";
    }

    let embeddedMap = null;
    let mapWidth = 30*width/100
    if (width < 1000) {
        mapWidth = 80*width/100
    }
    if (lessonType != "Online") {
        embeddedMap = <MapTemplate
            {...props}
            type={"directions"} 
            origin={tutorModel.address.postcode} 
            destination={postcode} 
            width={mapWidth}
            height={"400px"}
            distanceUnit={userSettings.distanceUnit}
            mode={"walking"}
            //directDistance={selectedTutor.distance}
            key={"Map" + mapReload}
        />
    }
    
    let possibleTutorRow = "1";
    let possibleTutorColumn = "2";
    let mapRow = "1";
    let mapColumn = "3";
    if (extraProps.lessonType == "Online") {
        //possibleTutorColumn = "3";
    }
    if (width < widthThreshold) {
        possibleTutorRow = "2";
        possibleTutorColumn = "1";
        mapRow = "3";
        mapColumn = "1";
    }

    return (
        <Flex direction={"column"} gap={"4vh"}>
            {redirect}
            <Flex direction={"column"} gap={"4vh"} alignItems={"center"}>
                {titleText}
                <Flex gap={"4vw"}>
                    {backButton}
                </Flex>
            </Flex>
            <Grid rowGap={"4vh"} columnGap={"1vw"} position={"relative"} templateColumns={"1fr 1fr 1fr"}>
                {requestFlex}
                <Flex
                    rowStart={mapRow}
                    columnStart={mapColumn}
                    direction={"row"}
                    justifyContent={"center"}
                    //width={mapWidth}
                >
                    {embeddedMap}
                </Flex>
                <Flex 
                    direction={"column"} 
                    alignItems={"center"}
                    columnStart={possibleTutorColumn}
                    rowStart={possibleTutorRow}
                    //backgroundColor={"#888888"}
                >
                    {/* <Flex direction={"column"}>
                        {timeOptionText}
                    </Flex> */}
                    {createLessonForm}
                </Flex>
                
            </Grid>
        </Flex>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default TutorLessonRequestAccept;