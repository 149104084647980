import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import "@aws-amplify/ui-react/styles.css";
import { createTheme, ThemeProvider } from "@aws-amplify/ui-react";

// import 'core-js/stable';
// import 'regenerator-runtime/runtime';
//import 'core-js/stable/globalThis';
//import "es.global-this"


import './index.css';
import App from './App';

const appDomain = window.location.origin;
const currentPath = window.location.pathname;
let authDomain = "auth.nextdoortutor.co.uk";

const apiArray = awsconfig.aws_cloud_logic_custom;
const api1 = apiArray[0];
const api1Endpoint = api1.endpoint;
const envStart = api1Endpoint.lastIndexOf("/");
const environment = api1Endpoint.slice(envStart + 1);
if (environment === "testing") { 
  authDomain = "testauth.nextdoortutor.co.uk";
}

console.log(awsconfig.oauth);
console.log(appDomain + currentPath);

Amplify.configure({
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    redirectSignIn: appDomain + currentPath,
    redirectSignOut: appDomain,
    domain: authDomain
  }
});

const runApp = function() {
  //console.log("RunApp Function");

  const root = ReactDOM.createRoot(document.getElementById('root'));

  //console.log("Root:");
  //console.log(root);
  
  // const myTheme = createTheme({
  //   tokens: {
  //     colors: {
  //       brand: {
  //         primary: {
  //           "80": {
  //             value: "#01a8f7"
  //           }
  //         }
          
  //       },
  //       font: {
  //         primary: {
  //           value: "{colors.brand.primary.80.value}"
  //         },
  //       }
  //     }
  //   }
  // })


  root.render(
    <ThemeProvider>
      <App />
    </ThemeProvider>
  );

}
runApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
