import React, { useEffect, useState } from "react";
import {NavigationBarTemplate, TextFieldTemplate} from '../../custom-ui-components';
import { Button, CheckboxField, Divider, Flex, Icon, Text } from "@aws-amplify/ui-react";
import PopUpTemplate from "../../custom-ui-components/form-components/PopUpTemplate";
import { LoadingSpinnerPage } from "../LoadingPage";
import { ErrorLoadingPage } from "../ErrorLoadingPage";
import { priceValuetoString } from "../../App";
import { DayPicker } from "react-day-picker";
import { MdClose, MdModeEdit} from 'react-icons/md';
import { Navigate, useLocation } from "react-router-dom";

const ViewPreviousLessons = function(props) {
	const standardTextProps = props.standardTextProps;
	const titleTextProps = props.titleTextProps;
	const width = props.width;
	const title = <Text {...titleTextProps}>View Prevous Lessons</Text>;

	const lessons = props.adminLessons;

	const location = useLocation();
    const extraProps = location.state || {};
	const defaultDateRange = extraProps.dateRange || {};
	let defaultStartDate = defaultDateRange.from;
	let defaultEndDate = defaultDateRange.to || new Date();
	if (defaultStartDate == null) {
		defaultStartDate = new Date(new Date(defaultEndDate).setDate(new Date().getDate() - 7));
	}

	const [dateRange, setDateRange] = useState({
		from: defaultStartDate,
		to: defaultEndDate
	});
	const [dateUI, setDateUI] = useState(null);
	const [editDates, setEditDates] = useState(false);
	const [previousLessonsUI, setPreviousLessonsUI] = useState(null);
	const [redirect, setRedirect] = useState(null);

	useEffect(() => {
		if (lessons == null || lessons == "loading" || lessons == "error") {
			return;
		}
		if (dateRange == null || (dateRange.to == null && dateRange.from == null)) {
			return;
		}
		let testToDate = new Date(dateRange.to);
		let testFromDate = new Date(dateRange.from);
		if (dateRange.to == null) {
			testToDate = testFromDate
		}
		else if (dateRange.from == null) {
			testFromDate = testToDate;
		}
		testToDate = new Date(new Date(testToDate).setHours(23)).setMinutes(59);

		const lessonEventTextProps = {...standardTextProps, fontSize: "16px", textAlign: "left"};

		let totalLessonCost = 0;
		const lessonUIs = [];
		for (const lesson of lessons) {
			const history = lesson.lessonHistory || [];
			const previousLessonsUIs = [];
			for (const event of history) {
				if (event.type == "deductStudent") {
					const date = new Date(event.date);
					if (new Date(date) >= new Date(testFromDate) && date <= testToDate) {
						totalLessonCost = totalLessonCost + event.lessonCost;
						previousLessonsUIs.push(
							<Flex 
								direction={"column"} 
								gap={"0px"} 
								backgroundColor={"#40e66c"} 
								key={lesson.id + "," + event.date} 
								alignItems={"flex-start"}
								borderRadius={"10px"}
								padding={"5px"}
							>
								<Text {...lessonEventTextProps}>Date: {event.lessonDate}</Text>
								<Text {...lessonEventTextProps}>Lesson cost: {priceValuetoString(event.lessonCost)}</Text>
								<Text {...lessonEventTextProps}>Tutor paid: {event.paidTutor && "Yes" || "No"}</Text>
								<Text {...lessonEventTextProps}>Disputed: {event.disputed && "Yes" || "No"}</Text>
							</Flex>
						);
					}
				}
			}
			if (previousLessonsUIs.length > 0) {
				const lessonUI = <Flex 
					direction={"column"} 
					gap={"2vh"} 
					backgroundColor={"#32a875"} 
					key={lesson.id} 
					borderRadius={"10px"} 
					padding={"5px"}
					onClick={() => {
						setRedirect(<Navigate to={"/Admin/ViewLesson"} state={{...lesson, backLink: "/Admin/ViewPreviousLessons", dateRange: dateRange}}/>);
					}}
					style={{cursor: "pointer"}}
				>
					{lesson.id}
					{previousLessonsUIs}
				</Flex>
				lessonUIs.push(lessonUI);
			}
		}
		setPreviousLessonsUI(
			<Flex direction={"column"}>
				<Text {...standardTextProps}>Total lesson cost for parents/students: {priceValuetoString(totalLessonCost)}</Text>
				{lessonUIs}
			</Flex>
		);
	}, [lessons, dateRange]);


	useEffect(() => {
		if (dateRange == null) {
			return;
		}
		const cross = <Icon
			key={"closeButton"}
			width={"30px"}
			height={"30px"}
			as={MdClose}
			color={"ff0000"}
			position={"absolute"}
			top={"5px"}
			right={"5px"}
			style={{cursor: "pointer"}}
			onClick={() => {
				setEditDates(false);
			}}
		/>

		const datePicker = <Flex 
			backgroundColor={"#eeeeee"} 
			borderRadius={"10%"} 
			direction={"column"} 
			gap={"0px"} 
			position={"absolute"} 
			border={"solid"} 
			top={"56px"}
		>
			<Text {...standardTextProps} fontSize={"19px"} marginBottom={"-10px"} marginTop={"10px"}>Edit Date Range</Text>
			<DayPicker
				key={"endDatePicker"}
				mode="range"
				selected={dateRange}
				onSelect={(newDateAnswer)=> {
					setDateRange(newDateAnswer);
				}}
				toDate={new Date()}
			/>
			{cross}
		</Flex>
		
		const newDateUI = <Flex
			position={"relative"} 
			justifyContent={"center"}
		>
			<Flex 
				
				alignItems={"center"}
				backgroundColor={"#63c3f2"}
				borderRadius={"10px"}
				padding={"5px"}
				style={{cursor: "pointer"}}
				border={"solid"}
				onClick={() => {
					setEditDates(!editDates);
				}}
			>
				<Text {...standardTextProps}>Date range:</Text>
				<Flex
					borderRadius={"10px"}
					paddingLeft={"5px"}
					paddingRight={"5px"}
					alignItems={"center"}
				>
					<Text {...standardTextProps}>{dateRange.from != null && new Date(dateRange.from).toDateString() || "Provide start date"}</Text>
				</Flex>
				<Text {...standardTextProps}>-</Text>
				<Flex
					borderRadius={"10px"}
					paddingLeft={"5px"}
					paddingRight={"5px"}
					alignItems={"center"}
				>
					<Text {...standardTextProps}>{dateRange.to != null && new Date(dateRange.to).toDateString() || "Provide end date"}</Text>
				</Flex>
				<Icon
					key={"editButton"}
					width={"25px"}
					height={"25px"}
					as={MdModeEdit}
				/>
			</Flex>
			{editDates && datePicker || null}
		</Flex>

		setDateUI(newDateUI);
		
	}, [dateRange, editDates]);

	if (previousLessonsUI == null) {
		return (
			<LoadingSpinnerPage />
		);
	}

  	return (
		<Flex direction={"column"} gap={"2vh"}>
			{title}
			{dateUI}
			{previousLessonsUI}
			{redirect}
		</Flex>
  	);
}

export default ViewPreviousLessons;