import * as React from "react";
import { Flex, SelectField, SwitchField, TextField, PhoneNumberField, Label } from "@aws-amplify/ui-react";
import { useState, useEffect } from 'react';
import { validateField } from "./form-components/FormValidation";
import OptionsTemplate from "./form-components/OptionsTemplate";
import { isJSON } from "../App";

const fieldTypes = {
	dropdown: {
		component: SelectField,
		options: true,
		defaultValue: null
		
	},
	switch: {
		component: SwitchField,
		eventValue: "checked",
		componentValue: "isChecked",
		defaultValue: false
	},
	text: {
		component: TextField,
		defaultValue: ""
	}
};

const FieldTemplate = function(props) {
	const [fieldType, setFieldType] = useState(null);
	const [fieldHasOptions, setFieldHasOptions] = useState(false);
	const [options, setOptions] = useState(null);
	const [questionValidations, setQuestionValidations] = useState(false);

	const givenFieldType = props.fieldType || props.type;
	const disabled = props.disabled || props.isDisabled || props.answersSuccessfullySubmitted;
	const hidden = props.hidden;
	const value = props.value || props.isChecked;
	const placeholder = props.placeholder || props.placeHolder;
	const fieldTypeProps = fieldTypes[fieldType || givenFieldType] || {} && console.error("Invalid field type: " + givenFieldType);
	
	const [answer, setAnswer] = useState(props.defaultValue || fieldTypeProps.defaultValue);
	

	const blankHandleChange = function() {
		console.error(fieldType + ": handleChange function not passed to component");
	};
	const onChange = props.onChange || props.handleChange || blankHandleChange;

	//Set field type
	useEffect(() => {
		if (givenFieldType == null) {
			console.error("Field type not given");
			return;
		}
		if (typeof(givenFieldType) != "string") {
			console.error("Invalid field type: " + givenFieldType);
			return;
		}
		const lowerCaseFieldType = givenFieldType.toLowerCase();
		const fieldProperties = fieldTypes[lowerCaseFieldType];
		if (fieldProperties == null) {
			console.error("Invalid field type: " + givenFieldType);
			return;
		}
		setFieldType(lowerCaseFieldType);
		if (fieldProperties.options == true) {
			setFieldHasOptions(true);
		}
	}, [givenFieldType]);

	//Set question validations
	useEffect(() => {
		const newValidations = [];
		if (Array.isArray(props.validations)) {
			for (const validation of props.validations) {
				if (isJSON(validation) && validation.hasOwnProperty("type")) {
					newValidations.push(validation);
				}
				else {
					console.error("Invalid validation: " + JSON.stringify(validation));
				}
			}
		}
		setQuestionValidations(newValidations);
	}, [props.validations]);

	//Set question options and set selected answer to default value
	useEffect(() => {
		if (fieldHasOptions == null || fieldHasOptions == false) {
			return;
		}
		const givenOptions = props.options || props.children;
		if (!Array.isArray(givenOptions) || givenOptions.length == 0) {
			console.log("No options given for " + fieldType);
			return;
		}
		const newOptionsArray = OptionsTemplate(givenOptions, fieldType);
		setOptions(newOptionsArray);
	}, [fieldHasOptions, fieldType, props.options, props.children]);

	//Set the answer to the first option if answer is null and placeholder is not given and options are given
	useEffect(() => {
		if (options == null) {
			return;
		}

		setAnswer((prev) => {
			let answerInOptions = false;
			try {
				answerInOptions = options.find(option => option.props.value == prev) != null;
			}
			catch {
				answerInOptions = options.includes(prev);
			}
			
			if ((prev == null && placeholder == null) || (prev != null && !answerInOptions)) {
				let firstOption = options[0];
				if (typeof(firstOption) == "object" && firstOption.hasOwnProperty("props")) {
					firstOption = firstOption.props.value;
				}
				return firstOption;
			}
			else {
				return prev;
			}
		});
	}, [options, placeholder]);

	//Pass the new answer to the parent component
	useEffect(() => {
		if (questionValidations == null) {
			return;
		}

		let validationResult = null;
		if (questionValidations.length > 0) {
			validationResult = validateField(answer, questionValidations)
		}
		onChange(answer, validationResult);
	}, [answer, questionValidations]);

	//Set the selected answer to the value passed in
	useEffect(() => {
		setAnswer((prev) => {
			if (prev != value) {
				if (value == null && fieldTypeProps.defaultValue != null) {
					return fieldTypeProps.defaultValue;
				}
				return value;
			}
			else {
				return prev;
			}
		});
	}, [value]);

	// if (props.id == "weeklyHoursWanted") {
	// 	console.log(props.validations);
	// }

	if (hidden) {
		return;
	}
	
	const fieldProps = {
		className: "QuestionField",
		size: props.size,
		label: props.label,
		labelHidden: props.labelHidden,
		placeholder: placeholder,
		isDisabled: disabled,
		hidden: props.hidden,
		children: options,
		width: props.fieldWidth || "100%",
		style: {
			backgroundColor: props.backgroundColor || "#0000",
		},
		...props.style,
		[fieldTypeProps.componentValue || "value"]: answer,
		onChange: function(event) {
			const eventValue = fieldTypeProps.eventValue || "value";
			const newValue = event.target[eventValue];
			if (answer != newValue) {
				if (answer == "") {
					if (fieldTypeProps.defaultValue != "") {
						setAnswer(fieldTypeProps.defaultValue);
					}
				}
				else if (answer == null) {
					if (fieldTypeProps.defaultValue != null) {
						setAnswer(fieldTypeProps.defaultValue);
					}
				}
				setAnswer(newValue);
			}
		}
	};

	const Component = fieldTypeProps.component;
	return <Component 
		{...fieldProps} 
		key={props.key || props.id || fieldType}
	/>;
}

export default FieldTemplate;