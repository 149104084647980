import React, { useEffect, useState } from "react";
import { Button, Flex, Grid, Icon, Text, Image, TextField } from "@aws-amplify/ui-react";
import { MdDone, MdClose } from 'react-icons/md';
import {GrSend} from 'react-icons/gr';
import {ReactComponent as SendIcon} from "../../Images/sendIcon.svg"
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
	MainContainer,
	Sidebar,
	Search,
	ConversationList,
	Conversation,
	ConversationHeader,
	ChatContainer,
	MessageList,
	Message,
	MessageGroup,
	MessageInput,
	MessageSeparator,
	Avatar,
	TypingIndicator,
} from "@chatscope/chat-ui-kit-react";

//import {ReactComponent as LogoSVG} from "../../Images/NextDoorTutorLogoExport.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TextFieldTemplate from "../../custom-ui-components/form-components/TextFieldTemplate";
import { shortDateString } from "../../App";

const Chats = function(props) {
	let defaultSideBarChat = "sideBar";
	let defaultSelectedUser = null;
	const location = useLocation();
	const extraProps = location.state || {};
	if (extraProps.recipientID != null) {
		defaultSideBarChat = "chat";
		defaultSelectedUser = extraProps.recipientID
	}

	const [userNames, setUserNames] = useState(null);
	const [userChats, setUserChats] = useState(null);
	const [selectedUser, setSelectedUser] = useState(defaultSelectedUser);
	const [conversationListUI, setConversationListUI] = useState(null);
	const [conversationHeaderUI, setConversationHeaderUI] = useState(null);
	const [messageListUI, setMessageListUI] = useState(null);
	const [messageInputUI, setMessageInputUI] = useState(null);
	const [justSentMessages, setJustSentMessages] = useState([]);
	const [existingMessageIDs, setExistingMessageIDs] = useState({});
	const [sideBar, setSideBar] = useState(null);
	const [sideBarOrChat, setSideBarOrChat] = useState(defaultSideBarChat);
	const [loading, setLoading] = useState(true);
	const [unreadMessages, setUnreadMessages] = useState({});
	const [tickIncrement, setTickIncrement] = useState(10000);
	const [tick, setTick] = useState(0);
	const [currentlyTyping, setCurrentlyTyping] = useState(false);
	const [delayedTyping, setDelayedTyping] = useState(false);
	const [numSent, setNumSent] = useState(0);
	const [typingFocused, setTypingFocused] = useState(false);
	const [amendLessonPopup, setAmendLessonPopup] = useState(null);
	const [loadedFromURL, setLoadedFromURL] = useState(false);
	const [loadSavedText, setLoadSavedText] = useState(false);
	const [showReadTime, setShowReadTime] = useState(null);

	//Ask for notification permissions and reset global redirect if the user was redirected here
	useEffect(() => {
		try {
			if (Notification.permission == "default") {
				Notification.requestPermission().then(function (permission) {
					console.log(permission);
				});
			}
		} catch {}

		if (props.appRedirect != null) {
			props.setAppRedirect(null);
		}
	}, []);

	//Redirect to the correct chat if required
	useEffect(() => {
		if (props.messageRedirect != null && props.messageRedirect != selectedUser) {
			setSelectedUser(props.messageRedirect);
			props.setMessageRedirect(null);
		}
	}, [props.messageRedirect]);
	
	//Add event listener for enter key
	useEffect(() => {
		document.addEventListener("keydown", keyPress);
		return () => {
			document.removeEventListener("keydown", keyPress);
		};
	}, [typingFocused, numSent, selectedUser, props.chatsToSend]);

	const width = props.width;
	const titleTextProps = props.titleTextProps;
	const standardTextProps = props.standardTextProps;
	const chats = props.chats;
	const usersLastOnline = props.usersLastOnline;
	const usersTyping = props.usersTyping;
	const userID = props.userID;
	const APIFunctions = props.APIFunctions;
	const webSocket = props.webSocket;
	const pageVisibility = props.pageVisibility;

	const userGroups = props.userGroups;

	const sendMessageRequest = function () {
		if (props.chatsToSend == null || selectedUser == null || props.chatsToSend[selectedUser] == null || props.chatsToSend[selectedUser] == "") {
			return;
		}
		sendMessage(props.chatsToSend[selectedUser], selectedUser);
		APIFunctions.sendTyping(selectedUser, false);
		setCurrentlyTyping(false);
		setNumSent(numSent + 1);
		props.setChatsToSend({...props.chatsToSend, [selectedUser]: null});
	}

	const keyPress = function (keyEvent) {
		const key = keyEvent.code;
		if (key == "Enter") {
			if (typingFocused) {
				sendMessageRequest();
			}
		}
	}

	useEffect(() => {
		const updateTick = async function() {
			const sleep = async function () {
				return new Promise(resolve => setTimeout(resolve, tickIncrement));
			}
			await sleep();
			setTick(tick + 1);
		}
		updateTick();
	}, [tick, tickIncrement]);

	const amendLessonKeyWords = [
		"amend",
		"chang",
		"schedul",
		"cancel",
		"postpon",
		"mov",
		"alter",
		"adjust",
		"shift",
		"modify",
		"arrang",
		"request",
		"delay",
		"pushback",
		"updat"
	];

	const checkForKeyWords = function (input) {
		if (typeof(input) != "string") {
			return false;
		}
		input = input.toLowerCase();
		input = input.split(" ").join("");
		for (const keyWord of amendLessonKeyWords) {
			if (input.includes(keyWord)) {
				return true;
			}
		}
		return false;
	}

	//Set the user based on the URL
	useEffect(() => {
        if (loadedFromURL == true) {
            return;
        }

        const queryParams = new URLSearchParams(window.location.search);
        const userParam = queryParams.get("recipient");
		if (userParam != null) {
			setSelectedUser(userParam);
		}

        setLoadedFromURL(true);
    }, [loadedFromURL, window.location.search]);
	const navigate = useNavigate();

	//Updates the URL with the current search parameters
    useEffect(() => {
        if (loadedFromURL == false) {
            return;
        }
        const queryParams = new URLSearchParams();
		if (selectedUser != null) {
        	queryParams.set("recipient", selectedUser);
		}
		navigate({search: queryParams.toString()});
    }, [loadedFromURL, selectedUser]);

	//Show a popup if the user is trying to amend a lesson
	useEffect(() => {
		if (selectedUser == null || userNames == null || props.chatsToSend == null) {
			return;
		}

		const containsKeyword = checkForKeyWords(props.chatsToSend[selectedUser]);
		if (amendLessonPopup == false) {
			if (!containsKeyword) {
				setAmendLessonPopup(null);
			}
			else {
				return;
			}
		}
		if (containsKeyword && amendLessonPopup == null) {
			const currentUserType = userNames[selectedUser].currentUserType;
			const lessonIDs = userNames[selectedUser].lessonIDs;
			let link = null;
			if (lessonIDs.length == 1) {
				link = "/" + currentUserType + "/ViewLesson?lessonID=" + lessonIDs[0];
			}
			else {
				const messageUserType = userNames[selectedUser].messageUserType;
				link = "/" + currentUserType + "/MyLessons?" + messageUserType + "=" + selectedUser;
			}
			setAmendLessonPopup(
				<Flex
					position={"absolute"}
					backgroundColor={"#b0d4f077"}
					bottom={"120px"}
					alignSelf={"center"}
					width={"70%"}
					borderRadius={"20px"}
					direction={"column"}
					alignItems={"center"}
					paddingLeft={"10px"}
					paddingRight={"10px"}
					paddingTop={"10px"}
					paddingBottom={"10px"}
					style={{
						backdropFilter:"blur(5px)", 
						WebkitBackdropFilter:"blur(5px)",
						boxShadow:"5px 5px 30px rgba(30, 30, 30, 0.3)",
						borderStyle: "solid",
						borderWidth: "3px",
						borderColor: "#c1ddf3"
					}}
					gap={"5px"}
				>
					<Flex 
						onClick={() => {
							setAmendLessonPopup(false);
						}}
						alignItems={"center"}
						position={"absolute"}
						right={"0px"}
						top={"0px"}
						margin={"-10px"}
						style={{cursor: "pointer"}}
						backgroundColor={"#c7c7c7"}
						borderRadius={"100%"}
					>
						<Icon
							key={"closeButton"}
							width={"30px"}
							height={"30px"}
							as={MdClose}
							color={"ff0000"}
						/>
					</Flex>
					<Text>Are you trying to change the arrangements of the lesson?</Text>
					<Text>Feel free to discuss them here in the chat</Text>
					<Text>But if you want to actually make a change, use the <Link to={link}>lesson page</Link></Text>
				</Flex>
			);
		}
	}, [props.chatsToSend, amendLessonPopup, userNames, selectedUser]);

	//Set delayed typing to the current typing state after 5 seconds
	useEffect(() => {
		const updateTypingTick = async function() {
			const returnLater = async function (input) {
				const sleep = async function () {
					return new Promise(resolve => setTimeout(resolve, 5000));
				}
				await sleep();
				return input;
			}
			const oldCurrentlyTyping = await returnLater(currentlyTyping);
			setDelayedTyping(oldCurrentlyTyping)
		}

		updateTypingTick();
	}, [currentlyTyping]);

	//Send a message to say that the user is or isn't typing
	useEffect(() => {
		if (currentlyTyping != false && currentlyTyping == delayedTyping) {
			APIFunctions.sendTyping(selectedUser, false);
			setCurrentlyTyping(false);
		}
	}, [currentlyTyping, delayedTyping, selectedUser, APIFunctions]);

	const generateMessageID = function () {
		let foundValidNewKey = false;
		let messageID = null;
		while (foundValidNewKey == false) {
			let potentialKey = "";
			for (let index1 = 0; index1 < 5; index1 = index1 + 1) {
					let index2Max = 4;
					if (index1 == 0) {
							index2Max = 8;
					}
					else if (index1 > 0 && index1 < 4) {
							index2Max = 4;
					}
					else if (index1 == 4) {
							index2Max = 12;
					}
					for (let index2 = 0; index2 < index2Max; index2 = index2 + 1) {
							let randomNum = Math.floor(Math.random() * 16);
							if (randomNum < 0) {
									randomNum = 0;
							}
							else if (randomNum > 15) {
									randomNum = 15;
							}
							const randomHex = randomNum.toString(16);
							potentialKey = potentialKey + randomHex;
					}
					if (index1 < 4) {
							potentialKey = potentialKey + "-";
					}
				}
			
			if (existingMessageIDs[potentialKey] == null) {
				foundValidNewKey = true;
				messageID = potentialKey;
			}
		}
		return messageID;
	};
	
	const sendMessage = async function (input, recipientID) {
		const messageID = generateMessageID();
		try {
			const newJustSentMessages = [...justSentMessages];
			newJustSentMessages.push({
				message: input,
				time: new Date().toISOString(),
				sender: userID,
				read: false,
				emailed: false,
				delivered: false,
				id: messageID
			});
			setJustSentMessages(newJustSentMessages);
			await APIFunctions.sendMessage(input, recipientID, messageID);
		}
		catch (error) {
			console.log("Error sending message: " + error);
		}
	};

	const getTimeDifferenceString = function (differenceMS) {
		if (differenceMS < 0) {
			differenceMS = 0
		}
		const SecondsDifference = Math.round(differenceMS / 1000);
		const secondsRemainder = (SecondsDifference % 60);
		const minutesDifference = Math.floor(SecondsDifference / 60);
		const minutesRemainder = (minutesDifference % 60);
		const hoursDifference = Math.floor(minutesDifference / 60);
		const hoursRemainder = (hoursDifference % 24);
		const daysDifference = Math.floor(hoursDifference / 24);

		let daysString = daysDifference;
		if (daysDifference == 0) {
			daysString = "";
		}

		let hoursString = hoursRemainder;
		if (hoursRemainder == 0) {
			hoursString = "";
		}

		let minutesString = minutesRemainder;
		if (minutesRemainder == 0) {
			minutesString = "";
		}

		const secondsString = secondsRemainder;

		let differenceString = "";
		if (daysString != "") {
			differenceString = daysString + " days";
			setTickIncrement(3600000);
		}
		else if (hoursString != "") {
			differenceString = hoursString + " hours";
			setTickIncrement(3600000);
		}
		else if (minutesString != "") {
			differenceString = minutesString + " minutes";
			setTickIncrement(60000);
		}
		else {
			differenceString = secondsString + " seconds";
			setTickIncrement(5000);
		}

		return differenceString;
	};

	//Get all messageable recipient names and IDs from the lessons that the user is part of
	useEffect(() => {
		if (userGroups.userIsStudent == null || userGroups.userIsParent == null || userGroups.userIsTutor == null) {
			return;
		}
		const allNames = {};
		let userIsLoaded = false;
		if (userGroups.userIsStudent == true) {
			userIsLoaded = false;
			if (props.studentLessons != null && props.studentLessons != "loading" && props.studentLessons != "error") {
				userIsLoaded = true;
				for (const lesson of props.studentLessons) {
					const tutorDetails = lesson.tutorDetails || {};
					const currentLessonIDs = (allNames[lesson.tutorID] || {}).lessonIDs || [];
					allNames[lesson.tutorID] = {
						firstNames: tutorDetails.firstNames,
						lastName: tutorDetails.lastName,
						messageUserType: "Tutor",
						currentUserType: "Student",
						lessonIDs: [...currentLessonIDs, lesson.id]
					};
				}
			}
		}
		if (userGroups.userIsParent == true) {
			userIsLoaded = false;
			if (props.parentLessons != null && props.parentLessons != "loading" && props.parentLessons != "error") {
				userIsLoaded = true;
				for (const lesson of props.parentLessons) {
					const tutorDetails = lesson.tutorDetails || {};
					const currentLessonIDs = (allNames[lesson.tutorID] || {}).lessonIDs || [];
					allNames[lesson.tutorID] = {
						firstNames: tutorDetails.firstNames,
						lastName: tutorDetails.lastName,
						messageUserType: "Tutor",
						currentUserType: "Parent",
						lessonIDs: [...currentLessonIDs || [], lesson.id]
					};
				}
			}
		}
		if (userGroups.userIsTutor == true) {
			userIsLoaded = false;
			if (props.tutorLessons != null && props.tutorLessons != "loading" && props.tutorLessons != "error") {
				userIsLoaded = true;
				for (const lesson of props.tutorLessons) {
					const messageDetails = lesson.messageDetails || {};
					const currentLessonIDs = (allNames[messageDetails.userID] || {}).lessonIDs || [];
					allNames[messageDetails.userID] = {
						firstNames: messageDetails.firstNames,
						lastName: messageDetails.lastName,
						messageUserType: messageDetails.userType,
						currentUserType: "Tutor",
						lessonIDs: [...currentLessonIDs || [], lesson.id]
					};
				}
			}
		}
		if (userIsLoaded) {
			setUserNames(allNames);
		}
	}, [props.studentLessons, props.parentLessons, props.tutorLessons, userGroups]);

	//Set the users chat messages to either a blank array or the existing messages. Also sort existing messages by date/time
	useEffect(() => {
		if (userNames != null && chats != null) {
			const newUserChats = {};
			const recipientIDArray = Object.keys(userNames);
			const foundMessageIDs = {};
			const newUnreadMessages = {};
			for (const recipientID of recipientIDArray) {
				if (chats[recipientID] == null) {
					newUserChats[recipientID] = [];
				}
				else {
					newUserChats[recipientID] = chats[recipientID].sort((a, b) => {
						return new Date(a.time).valueOf() - new Date(b.time).valueOf();
					});
				}
				let userUnreadMessages = 0;
				for (const message of newUserChats[recipientID]) {
					foundMessageIDs[message.id] = true;
					if (message.sender != userID && message.read == null) {
						userUnreadMessages = userUnreadMessages + 1;
					}
				}
				newUnreadMessages[recipientID] = userUnreadMessages;
			}

			setUnreadMessages(newUnreadMessages);
			setUserChats(newUserChats);
			setExistingMessageIDs(foundMessageIDs);
		}
	}, [userNames, chats]);

	//Generate the conversation list side panel from the available users to message and the existing messages
	useEffect(() => {
		if (userNames != null && userChats != null) {
			const conversationUIs = [];
			const userArray = Object.entries(userChats);

			const sortedUserArray = userArray.sort((a, b) => {
				const aID = a[0];
				const aMessages = a[1];
				const bID = b[0];
				const bMessages = b[1];
				let aLastMessage = null;
				if (aMessages.length > 0) {
					aLastMessage = aMessages[aMessages.length - 1];
				}
				let bLastMessage = null;
				if (bMessages.length > 0) {
					bLastMessage = bMessages[bMessages.length - 1];
				}

				if (aLastMessage == null && bLastMessage == null) {
					const aFirstNames = userNames[aID].firstNames;
					const bFirstNames = userNames[bID].firstNames;
					if (aFirstNames < bFirstNames) {
						return -1;
					}
					else {
						return 1
					}
				}
				else if (aLastMessage == null) {
					return 1;
				}
				else if (bLastMessage == null) {
					return -1;
				}
				else {
					const aLastMessageTime = new Date(aLastMessage.time).valueOf();
					const bLastMessageTime = new Date(bLastMessage.time).valueOf();
					return bLastMessageTime - aLastMessageTime;
				}
			});

			for (const recipientObject of sortedUserArray) {
				const recipientID = recipientObject[0];
				const userNameObject = userNames[recipientID];
				const userName = userNameObject.firstNames + " " + userNameObject.lastName;
				let lastMessage = {};
				let lastSenderName = null;
				if (userChats[recipientID].length > 0) {
					lastMessage = userChats[recipientID][userChats[recipientID].length - 1];
					
					if (lastMessage.sender == recipientID) {
						lastSenderName = userNameObject.firstNames;
					}
					else {
						lastSenderName = "You";
					}
				}
				
				let activeConversation = false;
				if (recipientID == selectedUser) {
					activeConversation = true;
				}
				const conversationUI = <Conversation
					key={"conversation:" + recipientID}
					name={userName}
					lastSenderName={lastSenderName} 
					info={lastMessage.message}
					unreadCnt={unreadMessages[recipientID]}
					active={activeConversation}
					onClick={() => {
						setJustSentMessages([]);
						setAmendLessonPopup(null);
						if (selectedUser != recipientID) {
							setSelectedUser(recipientID);
							setSideBarOrChat("chat");
						}
						else {
							setSelectedUser(null);
							setConversationHeaderUI(null);
							setMessageInputUI(null);
							setSideBarOrChat("sideBar");
						}
					}}
					style={{
						padding: "10px",
						width: "100%"
					}}
				/>

				conversationUIs.push(conversationUI);
			}
			
			const newConversationList = <ConversationList>
				{conversationUIs}
			</ConversationList>
			setConversationListUI(newConversationList)
		}
	}, [userNames, userChats, unreadMessages, selectedUser, props.chatsToSend]);

	//Generate the conversation header and message input based on the current conversation
	useEffect(() => {
		if (selectedUser == null || userChats == null || userNames == null || props.chatsToSend == null || userNames[selectedUser] == null) {
			return;
		}
		const userName = userNames[selectedUser].firstNames + " " + userNames[selectedUser].lastName;
		let messageUserType = "(" + userNames[selectedUser].messageUserType + ")";
		if (selectedUser == userID) {
			messageUserType = "(You)";
		}
		const userNameField = userName + " " + messageUserType;

		let backButton = <ConversationHeader.Back onClick={() => {
			setSelectedUser(null);
			setConversationHeaderUI(null);
			setMessageInputUI(null);
			setSideBarOrChat("sideBar")
		}}/>;
		if (width < 500 && sideBarOrChat == "chat") { 
			backButton = <ConversationHeader.Back onClick={() => {
				setSelectedUser(null);
				setConversationHeaderUI(null);
				setMessageInputUI(null);
				setSideBarOrChat("sideBar")
			}}/>
		}

		const userLastOnline = usersLastOnline[selectedUser];
		let lastOnlineText = "Active now";
		if (userLastOnline == null) {
			lastOnlineText = null;
		}
		else if (userLastOnline != "now") {
			const lastOnlineDate = new Date(userLastOnline);
			const currentDate = new Date();
			const timeDifferenceMS = currentDate.valueOf() - lastOnlineDate.valueOf();
			lastOnlineText = "Active " + getTimeDifferenceString(timeDifferenceMS) + " ago";
		}
		const newConversationHeaderUI = <ConversationHeader >
			{backButton}
			<ConversationHeader.Content userName={userNameField} info={lastOnlineText} />
		</ConversationHeader>
		setConversationHeaderUI(newConversationHeaderUI);

		const newMessageInputUI = <Flex 
			alignSelf={"center"} 
			direction={"column"} 
			width={"100%"}
			paddingLeft={"15px"}
			paddingRight={"60px"}
			paddingTop={"5px"}
			paddingBottom={"10px"}
			position={"relative"}
			style={{
				borderTopStyle: "solid",
				borderWidth: "2px",
				borderColor: "#c1ddf3"
			}}
		>
			<TextField
				key={"MessageInput:" + selectedUser + ":" + numSent}
				placeholder="Type message here"
				defaultValue={props.chatsToSend[selectedUser] || ""}
				//value={props.chatsToSend[selectedUser] || ""}
				inputStyles={{fontSize: "14px", paddingTop: "6px", paddingBottom: "6px"}}
				onFocus={() => {
					setTypingFocused(true);
				}}
				onBlur={() => {
					setTypingFocused(false);
				}}
				autoFocus={true}

				onChange={(event) => {
					const input = event.target.value;
					props.setChatsToSend({...props.chatsToSend, [selectedUser]: input});
					if (input != "" && input != null) {
						if (currentlyTyping == false) {
							APIFunctions.sendTyping(selectedUser, true);
						}
						setCurrentlyTyping(input);
					}
					else if ((input == "" || input == null) && currentlyTyping != false) {
						APIFunctions.sendTyping(selectedUser, false);
						setCurrentlyTyping(false);
					}
				}}
				style={{fontSize: "16px"}}
			/>
			<Flex 
				position={"absolute"} 
				right={"18px"} 
				top={"13px"}
				style={{cursor:"pointer"}}
				onClick={() => {
					sendMessageRequest();
				}}
			>
				<SendIcon 
					width={"25px"} 
					height={"25px"}
				/>
			</Flex>
		</Flex>
		setMessageInputUI(newMessageInputUI);
	}, [userChats, selectedUser, userNames, usersLastOnline, width, tick, currentlyTyping, typingFocused, numSent, loadSavedText, props.chatsToSend, userID, APIFunctions]);

	//Generate the messages and typing indicator
	useEffect(() => {
		if (userChats != null && selectedUser != null && userChats[selectedUser] != null) {
			const newJustSentMessages = [...justSentMessages];
			for (let justSentMessageIndex = 0; justSentMessageIndex < justSentMessages.length; justSentMessageIndex = justSentMessageIndex + 1) {
				const checkMessage = justSentMessages[justSentMessageIndex];
				const checkMessageID = checkMessage.id;
				for (let deliveredMessageIndex = (userChats[selectedUser].length - 1); deliveredMessageIndex >= 0; deliveredMessageIndex = deliveredMessageIndex - 1) {
					const deliveredMessage = userChats[selectedUser][deliveredMessageIndex];
					const deliveredMessageID = deliveredMessage.id;
					if (deliveredMessageID == checkMessageID) {
						newJustSentMessages.splice(justSentMessageIndex, 1);
						break;
					}
				}
			}
			if (newJustSentMessages.length != justSentMessages.length) {
				setJustSentMessages(newJustSentMessages);
			}

			const userMessages = [...userChats[selectedUser], ...newJustSentMessages];
			const messageUIs = [];


			for (let messageIndex = 0; messageIndex < userMessages.length; messageIndex = messageIndex + 1) {
				const message = userMessages[messageIndex];
				const messageTime = message.time;
				const sender = message.sender;
				const messageText = message.message;
				const messageHidden = message.hidden || {};
				if (messageText == null) {
					continue;
				}
				if (messageHidden[userID] == true) {
					continue;
				}

				let direction = "incoming";
				if (message.sender == userID) {
					direction = "outgoing";
				}

				const messageTimeObject = new Date(messageTime)
				let hours = messageTimeObject.getHours();
				if (hours < 10) {
					hours = "0" + hours;
				}
				let minutes = messageTimeObject.getMinutes();
				if (minutes < 10) {
					minutes = "0" + minutes;
				}
				const timeString = hours + ":" +  minutes;
				let messageSeparator = null;

				let showMessageSeparator = false;
				if (messageIndex > 0) {
					const previousMessage = userMessages[messageIndex - 1];
					const previousMessageTimeObject = new Date(previousMessage.time);
					if (messageTimeObject.toDateString() != new Date(previousMessageTimeObject).toDateString()) {
						showMessageSeparator = true;
					}
				}
				else if (messageIndex == 0 && messageTimeObject.toDateString() != new Date().toDateString()) {
					showMessageSeparator = true;
				}

				if (showMessageSeparator) {
					messageSeparator = <MessageSeparator
						key={"separator:" + messageTime + messageText}
						style={{marginBottom: "10px"}}
					>
						{messageTimeObject.toDateString()}
					</MessageSeparator>
				}

				let readReceipt = <Flex gap={"0px"}>
					<Icon
						key={"blueTick1"}
						width={"10px"}
						height={"10px"}
						as={MdDone}
						color={"#2176ff"}
					/>
					<Icon
						key={"blueTick2"}
						width={"10px"}
						height={"10px"}
						as={MdDone}
						color={"#2176ff"}
						marginLeft={"-5px"}
					/>
				</Flex>

				if (message.read == null) {
					readReceipt = <Flex gap={"0px"}>
						<Icon
							key={"blueTick1"}
							width={"10px"}
							height={"10px"}
							as={MdDone}
							color={"#c9c9c9"}
						/>
						<Icon
							key={"blueTick2"}
							width={"10px"}
							height={"10px"}
							as={MdDone}
							color={"#c9c9c9"}
							marginLeft={"-5px"}
						/>
					</Flex>
				}

				if (message.delivered == false || direction == "incoming") {
					readReceipt = null;
				}

				let readTime = null;
				if (direction == "outgoing" && showReadTime != null && showReadTime.id == message.id) {
					const readTimeObject = new Date(showReadTime.read);
					let hours = readTimeObject.getHours();
					if (hours < 10) {
						hours = "0" + hours;
					}
					let minutes = readTimeObject.getMinutes();
					if (minutes < 10) {
						minutes = "0" + minutes;
					}
					let string = hours + ":" + minutes;
					if (messageTimeObject.toDateString() != new Date().toDateString()) {
						let dateString = "Today";
						if (readTimeObject.toDateString() != new Date().toDateString()) {
							dateString = shortDateString(readTimeObject);
							const currentDate = new Date().getDate();
							const yesterday = new Date().setDate(currentDate - 1);
							if (new Date(yesterday).toDateString() == readTimeObject.toDateString()) {
								dateString = "Yesterday";
							}
						}
						string = string + " - " + dateString;
					}
					string = "Read: " + string;
					readTime = <Text
						position={"absolute"}
						alignSelf={"center"}
						right={"0px"}
						fontSize={"10px"} 
						style={{pointerEvents: "none", textWrap:"nowrap"}}
					>
						{string}
					</Text>

					if (message.message != null && message.message.length < 30) {
						let messageLength = message.message.length;
						if (messageLength < 11) {
							messageLength = 11;
						}
						readTime = <Flex
							backgroundColor={"#c6e3fa"}
							paddingTop={"2px"}
							paddingBottom={"2px"}
							paddingLeft={"5px"}
							paddingRight={"5px"}
							borderRadius={"5px"}
							position={"absolute"}
							alignSelf={"center"}
							right={(((messageLength) * 7) - 20) + "px"}
						>
							<Text
								fontSize={"10px"} 
								style={{pointerEvents: "none", textWrap:"nowrap"}}
							>
								{string}
							</Text>
						</Flex>
					}
				}
				
				let marginBottom = "5px";
				if (messageIndex == userMessages.length - 1) {
					marginBottom = "30px";
				}
				let messageUI = <Message
					key={messageTime + messageText}
					model={{
						sender: sender,
						direction: direction
					}}
					style={{marginBottom: marginBottom}}
				>
					<Message.CustomContent>
						<Flex alignItems={"center"} position={"relative"} minWidth={"80px"}>
							<Flex alignSelf={"center"} position={"absolute"} left={"-13px"} top={"-8px"} width={"110%"} height={"150%"} style={{opacity: 0, zIndex: 0}}/>
							<Text fontSize={"15px"} marginTop={"-5px"} marginBottom={"7px"}>{messageText}</Text>
							<Flex 
								alignItems={"center"} 
								position={"absolute"} 
								bottom={"-5px"} 
								right={"-5px"} 
								gap={"7px"}
								onMouseEnter={() => {
									if (message.read == null) {
										return;
									}
									setShowReadTime({
										id: message.id,
										read: message.read
									});
								}}
								onMouseLeave={() => {
									if (message.read == null) {
										return;
									}
									setShowReadTime(null);
								}}
							>
								<Flex 
									backgroundColor={"#cccccc00"}
									width={"70px"}
									position={"absolute"}
									right={"-10px"}
									bottom={"-4px"}
									overflow={"visible"}
									height={"20px"}
								/>
								<Flex position={"relative"} alignSelf={"center"}>
									{readTime}
								</Flex>
								<Text fontSize={"10px"}>{timeString}</Text>
								{readReceipt}
							</Flex>
						</Flex>
					</Message.CustomContent>
				</Message>

				messageUIs.push(messageSeparator, messageUI);
			}

			const userTyping = usersTyping[selectedUser];
			let typingIndicator = null;
			if (userTyping) {
				typingIndicator = <TypingIndicator content={userNames[selectedUser].firstNames + " is typing"} style={{backgroundColor: "#00000000"}}/>
			}
			const newMessageListUI = <MessageList
				key={messageUIs.length}
				typingIndicator={typingIndicator} 
			>
				{messageUIs}
			</MessageList>
			setMessageListUI(newMessageListUI);

			let foundUnread = false
			for (const message of userMessages) {
				if (message.read == null && message.sender != userID) {
					foundUnread = true;
					break;
				}
			}
			if (foundUnread && pageVisibility == "visible") {
				APIFunctions.readMessage(selectedUser);
			}
		}
		else if (userChats != null && selectedUser == null) {
			setMessageListUI(null);
		}
	}, [selectedUser, userChats, justSentMessages, existingMessageIDs, pageVisibility, usersTyping, userNames, userID, APIFunctions, showReadTime]);

	//Generate the side bar
	useEffect(() => {
		const sideBarTitle = <Text 
			textAlign={"center"}
			fontWeight={"600"}
			paddingTop={"20px"}
			paddingBottom={"21px"}
			width={"100%"}
			backgroundColor={"#f6fbff"}
			style={{
				borderBottomStyle: "solid",
				borderWidth: "1px",
				borderColor: "#c1ddf3"
			}}
		>
			Chats
		</Text>
		let newSideBar = <Sidebar 
			position="left" 
			loading={loading}
			style={{
				width: "100%",
			}}
		>
			{sideBarTitle}
			{/* <Search placeholder="Search..." /> */}
			{conversationListUI}
		</Sidebar>

		if (width < 500 && sideBarOrChat == "chat" && selectedUser != null) {
			newSideBar = null;
		}
		else if (width < 500) {
			newSideBar = <Sidebar 
				position="left" 
				loading={loading}
				style= {{
					display: "flex",
					flexBasis: "auto",
					width: "100%",
					maxWidth: "100%",
				}}
			>
				{sideBarTitle}  
				{/* <Search placeholder="Search..." /> */}
				{conversationListUI}
			</Sidebar>
		}
		setSideBar(newSideBar);
	}, [width, selectedUser, sideBarOrChat, conversationListUI]);

	//Check that everything has loaded
	useEffect(() => {
		let newLoading = false;
		if (userGroups.userIsStudent == null) {
			newLoading = true;
		}
		if (userGroups.userIsParent == null) {
			newLoading = true;
		}
		if (userGroups.userIsTutor == null) {
			newLoading = true;
		}
		if (userGroups.userIsStudent == true) {
			if (props.studentLessons == null || props.studentLessons == "loading") {
				newLoading = true;
			}
		}
		if (userGroups.userIsParent == true) {
			if (props.parentLessons == null || props.parentLessons == "loading") {
				newLoading = true;
			}
		}
		if (userGroups.userIsTutor == true) {
			if (props.tutorLessons == null || props.tutorLessons == "loading") {
				newLoading = true;
			}
		}
		if (props.studentLessons == "error" || props.parentLessons == "error" || props.tutorLessons == "error") {
			newLoading = true;
		}
		if (userNames == null) {
			newLoading = true;
		}
		if (webSocket == null || webSocket.readyState == 3) {
			newLoading = true;
		}
		
		if (newLoading != loading) {
			setLoading(newLoading);
		}
	}, [userGroups, props.studentLessons, props.parentLessons, props.tutorLessons, userNames, webSocket, loading]);

	let chatHeight = "80vh";
	if (width < 500) {
		chatHeight = "60vh";
	} 

	let chatContainer = <Flex 
		direction={"column"} 
		maxHeight={chatHeight} 
		gap={"0px"}
		style={{
			borderStyle: "solid",
			borderWidth: "1px",
			borderColor: "#c1ddf3"
		}}
		width={"100%"}
		position={"relative"}
	>
		{conversationHeaderUI}
		{messageListUI}
		{amendLessonPopup}
		{messageInputUI}
	</Flex>

	let sideBarWidth = "30vw";
	let sideBarMaxWidth = "300px"
	if ((width < 500 && sideBarOrChat == "sideBar") || selectedUser == null) {
		chatContainer = null;
		sideBarWidth = "95vw";
	}
	if (width < 500 && sideBarOrChat == "sideBar") {
		sideBarMaxWidth = "500px";
	}

	let sideBarUI = <Flex 
		width={sideBarWidth} 
		maxWidth={sideBarMaxWidth} 
		style={{
			borderStyle: "solid",
			borderWidth: "1px",
			borderColor: "#c1ddf3"
		}} 
	>
		{sideBar}
	</Flex>

	if (sideBar == null) {
		sideBarUI = null;
	}

	return (
		<Flex 
			style={{
				width:"95vw", 
				height: chatHeight, 
				borderStyle: "solid",
				borderWidth: "1px",
				borderColor: "#c1ddf3"
			}} 
			gap={"0px"}
			backgroundColor={"#ffffff"}
		>
			{sideBarUI}
			{chatContainer}
		</Flex>
	);
}

export default Chats;