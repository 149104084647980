import React from "react";
import {Outlet, Navigate} from "react-router-dom";
import HomeNavbar from "./HomeNavbar";
import EmailNotVerified from '../EmailNotVerified';
import { Flex } from "@aws-amplify/ui-react";
import FooterTemplate from "../../custom-ui-components/FooterTemplate";


const Layout = function(props) {
  const height = props.height;
  const sideMarginSize = props.sideMarginSize;


  return (
    <div className="Page" style={{minHeight:height}}>
      <Flex direction={"column"} marginLeft={sideMarginSize} marginRight={sideMarginSize} alignItems={"center"}>
        <Flex style={{zIndex:2}} marginBottom={"50px"}>  
          <HomeNavbar {...props} pageType={"home"}/>
        </Flex>
        <Flex style={{zIndex:1}}>  
          <Outlet />
        </Flex>
        <br />
        <FooterTemplate {...props}/>
      </Flex>
    </div>
  );
}

export default Layout;