import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { Button, Flex, Grid, Icon, Text } from "@aws-amplify/ui-react";
import { LoadingSpinner, LoadingSpinnerPage } from "../LoadingPage";
import { AppNavButton, FormTemplate } from "../../custom-ui-components";
import BackButton from "../../custom-ui-components/BackButton";

const VerifyID = function(props) {
  const height = props.height;
  const titleTextProps = props.titleTextProps;
  const standardTextProps = props.standardTextProps;
  const userIsParent = props.userIsParent;
  const userIsStudent = props.userIsStudent;
  const userIsTutor = props.userIsTutor;

  const APIFunctions = props.APIFunctions;

  const [verificationSession, setVerificationSession] = useState(null);

  const getVerificationSession = async function () {
    const retrievedVerificationSession = await APIFunctions.getStripeVerificationSession();
    setVerificationSession(retrievedVerificationSession)
  }

  useEffect(() => {
    if (verificationSession == null) {
      getVerificationSession();
    }
  }, [APIFunctions]);

  if (!userIsParent && !userIsStudent && !userIsTutor) {
    return <Navigate to="/" /> 
  }


  const titleText = <Text {...titleTextProps}>ID Verification</Text>


  const descriptionText1 = <Text {...standardTextProps}>ID Verification is necessary in order to ensure the safety of everyone involved in our lessons.</Text>
  const descriptionText2 = <Text {...standardTextProps}>We partner with Stripe to automate this process.</Text>

  const descriptionFlex = <Flex direction={"column"} gap={"0.5vh"}>
    {descriptionText1}
    {descriptionText2}
  </Flex>

  let statusText = null;
  let refreshButton = null;
  let verifyButton = null;
  if (verificationSession != null) {
    const lastError = verificationSession.last_error;
    let status = "You have not currently been verified";
    verifyButton = <Link to={verificationSession.url}><Button>Verify Now</Button></Link>;
    if (verificationSession.status == "processing") {
      status = "Your ID is currently being processed, this usually doesn't take longer than a minute, please check back later";
      verifyButton = null;
      refreshButton = <Button
        key={"RefreshButton"}
        onClick={() => {
          setVerificationSession(null);
          getVerificationSession();
        }}
      > 
        <Text {...standardTextProps}>Refresh</Text>
      </Button>
    }
    else if (verificationSession.status == "verified") {
      status = "Your ID has successfully been verified!";
      verifyButton = null;
    }
    else if (lastError != null) {
      if (lastError.reason != null) {
        status = "There has been an error verifying your ID, please try again";
      }
    }

    statusText = <Text {...standardTextProps}>{status}</Text>
  }
  else {
    statusText = <LoadingSpinner {...props} />
  }

  const backButton = <BackButton {...props} defaultBack={"/Tutor"} />

  const submitAnswers = async function (answers) {
    try {
      console.log(answers);
    }
    catch (error) {
      throw error;
    }
  }

  
  const getTutorFormQuestions = function () {
    const formQuestions = [];
    formQuestions.push({
      id: "criminalOffences", 
      label: "Have you ever been convicted of any criminal offences?", 
      type: "radio",
      options: ["No", "Yes"],
      required: true,
    });

    formQuestions.push({
      id: "criminalOffenceDetails", 
      //label: "Please supply details of any criminal convictions, cautions, reprimands or final warnings.[this includes traffic offences] Note: You are advised that under the provisions of the Rehabilitation of Offenders Act 1974 (Exceptions) Order 1975 as amended by the Rehabilitation of Offenders Act 1974 (Exceptions) (Amendment) Order 1986 you should declare all convictions including ‘spent’ convictions, cautions, warnings and reprimands.", 
      label: "Please supply details",
      type: "text",
      extraProps: {
        textType: "paragraph"
      },
      required: true, 
      onlyShow: {id: "criminalOffences", answers: ["Yes"]}
    });

    formQuestions.push({
      id: "riskToChildren", 
      label: "Are you a person known to any Children Services Department, or to the police as being an actual or potential risk to children?", 
      type: "radio",
      options: ["No", "Yes"],
      required: true, 
    });

    formQuestions.push({
      id: "riskToChildrenDetails", 
      label: "Please supply details:", 
      type: "text",
      extraProps: {
        textType: "paragraph"
      },
      required: true, 
      onlyShow: {id: "riskToChildren", answers: ["Yes"]}
    });

    formQuestions.push({
      id: "disciplinaryInvestigations", 
      label: "Have you ever had any disciplinary investigations or sanction by any organisation due to concerns about your behaviour towards children?", 
      type: "radio",
      options: ["No", "Yes"],
      required: true, 
    });

    formQuestions.push({
      id: "disciplinaryInvestigationsDetails", 
      label: "Please supply details:", 
      type: "text",
      extraProps: {
        textType: "paragraph"
      },
      required: true, 
      onlyShow: {id: "disciplinaryInvestigations", answers: ["Yes"]}
    });

    formQuestions.push({
      id: "declaration", 
      type: "checkbox",
      checkboxTextProps: {...standardTextProps},
      options: [
        "I declare that to the best of my knowledge the information given above is correct and understand that any misleading statements or deliberate omission may be sufficient grounds for disciplinary action or for cancelling my lessons.",
        "I understand that I may be asked to apply for a Criminal Records Disclosure and consent to do so if required.",
        "I understand that the information contained in this form and in the Disclosure may be disclosed, where strictly necessary, to regulatory bodies and/or third parties who have an interest in child welfare issues, in circumstances where this is considered necessary to safeguard children."
      ],
      required: true, 
      validations: [{type: "AllChecked"}]
    });
  }

  let formQuestions = null;
  let selfDisclosureForm = null;
  if (userIsTutor) {
    formQuestions = getTutorFormQuestions();
  }
  
  if (formQuestions != null) {
    selfDisclosureForm = <FormTemplate {...props} submitAnswers={submitAnswers} questions={formQuestions} okButtonFunction={function(){}} />
  }

  return (
      <Flex direction={"column"} gap={"3vh"} alignItems={"center"}>
        {backButton}
        {titleText}
        {descriptionFlex}
        {statusText}
        {refreshButton}
        {verifyButton}
        {selfDisclosureForm}
      </Flex>
  );
}

export default VerifyID;