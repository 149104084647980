import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Grid, RadioGroupField, SliderField, Text } from "@aws-amplify/ui-react";
import { useState, useEffect } from 'react';
import { validateField } from "./FormValidation";
import OptionsTemplate from "./OptionsTemplate";

const SliderTemplate = function(props) {
  try {
    const labelTextProps = props.questionLabelProps || {};
    const errorTextProps = props.questionErrorProps || {};

    //Set question variables from passed properties
    const questionID = props.id;
    const questionLabel = props.label;
    const questionDescription = props.description;
    const options = props.options
    const defaultValue = props.defaultValue;
    const required = props.required;
    const requiredAsterisk = props.requiredAsterisk;
    const questionValidations = props.validations || [];
    const answersSuccessfullySubmitted = props.answersSuccessfullySubmitted;
    let minValue = props.minValue;
    if (minValue == null) {
      minValue = 1;
    }
    let maxValue = props.maxValue;
    if (maxValue == null) {
      maxValue = 10;
    }
    const step = props.step || 1;
    let textValues = props.textValues || [];
    if (textValues == "value" || textValues == "valuePercent") {
      const newTextValues = [];
      for (let i = minValue; i <= maxValue; i = i + step) {
        let value = i;
        if (textValues == "valuePercent") {
          value = value + "%";
        }
        newTextValues.push(value);
      }
      textValues = newTextValues;
    }

    //Set state variables used to store the current state and any errors
    const [selectedAnswer, setSelectedAnswer] = useState(defaultValue);
    const [errors, setErrors] = useState({questionError:""});

    //console.log(selectedAnswer);

    useEffect(() => {
      const validationResult = validateField(selectedAnswer, questionValidations);
      const questionHasError = validationResult.hasError;
      props.handleChange(questionID, selectedAnswer, questionHasError);
    }, []);

    let labelColour = labelTextProps.color || "#000000";
    if (props.validationError != null && props.validationError != null) {
      labelColour = props.validationErrorColour || "#ff0000";
    }
    //Get the text for the question label
    const labelText = <Text {...labelTextProps} color={labelColour}>
      {questionLabel}
    </Text>

    const descriptionText = <Text {...labelTextProps} fontSize={"14px"} fontStyle={"italic"}>
      {questionDescription}
    </Text>
    
    const errorText = <Text {...errorTextProps}>
      {errors.questionError}
    </Text>

    let renderedRequiredAsterisk = null;
    if (required == true) {
      renderedRequiredAsterisk = requiredAsterisk;
    }

    //Ensure that selectedAnswer has an ASCII value to display
    if (selectedAnswer == null) {
      setSelectedAnswer("");
    }
    
    if (errors.errorMessage === null) {
      setErrors({
        questionError:""
      });
    }

    //Generate the radio button field component
    const sliderField = <SliderField
      placeholder={"Choose a value"}
      min={minValue}
      max={maxValue}
      step={step}
      value={selectedAnswer}
      size={"small"}
      isDisabled={answersSuccessfullySubmitted}
      isValueHidden={true}

      //Runs when it detects a change in the input field
      onChange={(e) => {
        const currentValue = e;
        setSelectedAnswer(currentValue);

        const validationResult = validateField(currentValue, questionValidations);
        const questionHasError = validationResult.hasError;
        const questionErrorMessage = validationResult.errorMessage;
        props.handleChange(questionID, currentValue, questionHasError);
        setErrors({
          questionHasError:questionHasError, 
          questionError:questionErrorMessage
        });
      }} 
    />

    //Return text field component to calling function (Most likely a Form Template)
    return (
      <Flex direction={"column"}>
        <Flex alignItems={"center"} >
          {renderedRequiredAsterisk}
          {labelText}
        </Flex>
        {descriptionText}
        <Flex direction={"column"} alignItems={"center"}>
          <Grid>
            <Text {...labelTextProps} fontSize={"14px"} column={1} row={1}>{props.minValueText}</Text>
            <Text {...labelTextProps} fontSize={"14px"} column={3} row={1}>{props.maxValueText}</Text>
            <Flex column={2} row={2}>
              {sliderField}
            </Flex>
          </Grid>
          <Text>{textValues[(selectedAnswer - minValue) / step]}</Text>
        </Flex>
        <Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
          {errorText}
        </Flex>
      </Flex>
    );
  }
  catch (error) {
    throw "SliderTemplate Error: " + error;
  }
}

export default SliderTemplate;