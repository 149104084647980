import React, { useEffect, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { Button, Flex, Grid, Icon, Text } from "@aws-amplify/ui-react";
import { LoadingSpinner, LoadingSpinnerPage } from "../LoadingPage";
import { AppNavButton, FormTemplate } from "../../custom-ui-components";
import BackButton from "../../custom-ui-components/BackButton";

const ReportIssue = function(props) {
	const height = props.height;
	const titleTextProps = props.titleTextProps;
	const standardTextProps = props.standardTextProps;

	const APIFunctions = props.APIFunctions;

	const location = useLocation();
	const state = location.state || {};
	const defaultFormType = state.defaultFormType || null;
	const passedError = state.error || {};

	const [formType, setFormType] = useState(defaultFormType);
	const [featureRequestButton, setFeatureRequestButton] = useState(null);
	const [bugReportButton, setBugReportButton] = useState(null);
	const [form, setForm] = useState(null);
	const [error, setError] = useState(passedError);

	const backButton = <BackButton {...props} defaultBack={"/"} />;

	const submit = async function (answers) {
		try {
			console.log(answers);
			let issue = {};
			if (typeof(error) == "object") {
				issue = {...error};
			}
			issue.type = formType;
			issue.details = answers.details;

			const reply = await APIFunctions.reportIssue(issue);
			console.log(reply);
			setError(null);
		}
		catch (error) {
			throw "Error reporting issue: " + error;
		}
	};

	useEffect(() => {
		const selectedColour = "#6ea4f0";
		setFeatureRequestButton(<Button 
			{...standardTextProps} 
			onClick={() => {
				if (formType != "feature") {
					setFormType("feature");
				}
				else {
					setFormType(null);
				}
			}}
			backgroundColor={formType == "feature" && selectedColour}
		>
			Make a feature request
		</Button>);

		setBugReportButton(<Button 
			{...standardTextProps} 
			onClick={() => {
				if (formType != "bug") {
					setFormType("bug");
				}
				else {
					setFormType(null);
				}
			}}
			backgroundColor={formType == "bug" && selectedColour}
		>
			Report a bug
		</Button>)
	}, [formType]);

	useEffect(() => {
		if (formType == null) {
			setForm(null);
		}
		else if (formType != null) {
			const formQuestions = [];
			if (formType == "feature") {
				formQuestions.push({
					id: "details",
					label: "What feature would you like to see added?",
					type: "text",
					placeholder: "Feature request details",
					extraProps: {
						textType: "paragraph",
						rows: 6
					},
					validations: [{type: "Required", questionType: "text"}]
				});
			}
			else if (formType == "bug") {
				formQuestions.push({
					id: "details",
					label: "Bug report details",
					type: "text",
					description: "Please tell us everything about the bug you've found in as much detail as possible, ideally including how it can be reproduced",
					placeholder: "Bug report",
					extraProps: {
						textType: "paragraph",
						rows: 8
					},
					validations: [{type: "Required", questionType: "text"}]
				});
			}
			setForm(<Flex
				width={"90vw"}
				maxWidth={"500px"}
			>
				<FormTemplate 
					{...props}
					questions={formQuestions}
					noClearButton={true}
					submitAnswers={submit}
					submitPopUpText={"Thank you for your feedback!"}
					okButtonFunction = {() => {setFormType(null);}}
				/>
			</Flex>);
		}
	}, [formType, error]);
	

	return (
		<Flex direction={"column"} gap={"3vh"} alignItems={"center"}>
			{backButton}
			{featureRequestButton}
			{bugReportButton}
			{form}
		</Flex>
	);
}

export default ReportIssue;