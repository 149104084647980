import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Text, Autocomplete, Flex } from "@aws-amplify/ui-react";
import { useState, useEffect } from 'react';
import { validateField } from "./FormValidation";
import OptionsTemplate from "./OptionsTemplate";

const AutoCompleteTemplate = function(props) {
  try {
    const labelTextProps = props.questionLabelProps || {};
    const errorTextProps = props.questionErrorProps || {};

    //Set question variables from passed properties
    const questionID = props.id;
    const questionLabel = props.label;
    const questionDescription = props.description;
    const required = props.required;
    const requiredAsterisk = props.requiredAsterisk;
    const placeHolder = props.placeHolder;
    let questionValidations = props.validations;
    if (questionValidations == null) {
      questionValidations = [];
    }
    let flexDirection = props.direction;
    if (flexDirection == null) {
      flexDirection = "column";
    }
    
    const givenOptions = props.options;
    const answersSuccessfullySubmitted = props.answersSuccessfullySubmitted || false;

    let autoCompleteValidationExists = false;
    for (let validation of questionValidations) {
      if (validation.type === "AutoComplete") {
        autoCompleteValidationExists = true;
      }
    }
    if (autoCompleteValidationExists === false) {
      questionValidations.push({"type":"AutoComplete"});
    }

    //Set state variables used to store the current state and any errors
    const [textAnswer, setTextAnswer] = useState({});
    const [warning, setWarning] = useState(null);
    const [errors, setErrors] = useState({questionError:"", questionHasError: false});
    const [options, setOptions] = useState([]);
    const [optionIDs, setOptionIDs] = useState([]);
    const [defaultValue, setDefaultValue] = useState(props.defaultValue);
    const [IDLookUp, setIDLookUp] = useState({});

    if (warning != props.warning) {
      setWarning(props.warning);
    }

    useEffect(() => {
      const templateOptions = OptionsTemplate(givenOptions, "autocomplete");
      setOptions(templateOptions);

      const newOptionIDs = [];
      for (const option of templateOptions) {
        const optionID = option.id;
        const optionLabel = option.label;
        newOptionIDs.push(optionID);
        IDLookUp[optionLabel] = optionID;
      }
      setOptionIDs([...newOptionIDs])

      let newDefaultValue = {};
      if (defaultValue == null) {
        newDefaultValue.id = null;
        newDefaultValue.label = "";
      }
      else if (typeof(defaultValue) == "string") {
        newDefaultValue.id = defaultValue;
        newDefaultValue.label = defaultValue
      }
      else if (typeof(defaultValue) == "object") {
        newDefaultValue = {...defaultValue};
      }
      newDefaultValue.options = newOptionIDs;
      setDefaultValue(newDefaultValue);
      setTextAnswer(newDefaultValue);

      const validationResult = validateField(newDefaultValue, questionValidations);
      const questionHasError = validationResult.hasError;
      const questionErrorMessage = validationResult.errorMessage;
      if (questionErrorMessage == "Must be a value from the list") {
        setErrors({
          questionHasError:questionHasError, 
          questionError:questionErrorMessage
        });
      }
      props.handleChange(questionID, newDefaultValue, questionHasError);
    }, []);

    let labelColour = labelTextProps.color || "#000000";
    if (props.validationError != null && props.validationError != null) {
      labelColour = props.validationErrorColour || "#ff0000";
    }
    //Get the text for the question label

    const labelText = <Text {...labelTextProps} color={labelColour}>
      {questionLabel}
    </Text>

    const descriptionText = <Text {...labelTextProps} fontSize={"14px"} fontStyle={"italic"}>
      {questionDescription}
    </Text>


    let errorText = null;
    if (errors.questionError != null && errors.questionError != "") {
      errorText = <Text {...errorTextProps} top={"0px"}>
        {errors.questionError}
      </Text>
    }

    const placeholderText = <Text {...labelTextProps} fontSize={"14px"} fontStyle={"italic"}>
      {placeHolder}
    </Text>

    let renderedRequiredAsterisk = null;
    if (required == true) {
      renderedRequiredAsterisk = requiredAsterisk;
    }
    

    const getOptionID = function (label) {
      try {
        return IDLookUp[label];
      }
      catch {
        return null;
      }
    };

    if (textAnswer.label == null) {
      setTextAnswer({
        id: textAnswer.id,
        label: "",
      });
    }

    //Generate the text field component
    const autoCompleteField = <Autocomplete
      size={"medium"}
      placeholder={placeHolder}
      errorMessage={errors.questionError}
      options={options}
      value={textAnswer.label}
      disabled={answersSuccessfullySubmitted}

      //Runs when it detects a change in the input field
      onChange={(e) => {
        const currentValue = e.target.value;
        const currentAnswerID = getOptionID(currentValue)
        const answerValue = {
          id: currentAnswerID,
          label: currentValue,
        };
        setTextAnswer(answerValue);
        
        const validationResult = validateField(answerValue, questionValidations);
        const questionHasError = validationResult.hasError;
        const questionErrorMessage = validationResult.errorMessage;
        props.handleChange(questionID, answerValue, questionHasError);
        setErrors({
          questionHasError:questionHasError, 
          questionError:questionErrorMessage
        });
      }}  

      onSelect={(option) => {
        const answerValue = {
          id: option.id,
          label: option.label,
        };
        setTextAnswer(answerValue);

        const validationResult = validateField(answerValue, questionValidations);
        const questionHasError = validationResult.hasError;
        const questionErrorMessage = validationResult.errorMessage;
        props.handleChange(questionID, answerValue, questionHasError);
        setErrors({
          questionHasError:questionHasError, 
          questionError:questionErrorMessage
        });
      }}

      onClear={() => {
        if (answersSuccessfullySubmitted == false) {
          const answerValue = {
            id: null,
            label: "",
            options: optionIDs
          };
          setTextAnswer(answerValue);
          
          const validationResult = validateField(answerValue, questionValidations);
          const questionHasError = validationResult.hasError;
          const questionErrorMessage = validationResult.errorMessage;
          props.handleChange(questionID, answerValue, questionHasError);
          setErrors({
            questionHasError:questionHasError, 
            questionError:questionErrorMessage
          });
        }
      }}
    ></Autocomplete>
    
    if (errors.questionHasError == false && errors.questionError != warning) {
      const newErrors = {...errors}
      newErrors.questionError = warning
      setErrors(newErrors);
    }

    //Return text field component to calling function (Most likely a Form Template)
    return (
      textAnswer,
      <div className="SearchField">
        <Flex direction={flexDirection}>
          <Flex alignItems={"center"} justifyContent={"center"}>
            {renderedRequiredAsterisk}
            {labelText}
          </Flex>
          {descriptionText}
          {autoCompleteField}
          <Flex direction={"column"} alignItems={"center"} justifyContent={"center"} position={"relative"}>
            {errorText}
          </Flex>
        </Flex>
      </div>
    );
  }
  catch (error) {
    throw "AutoCompleteTemplate Error";
  }
}

export default AutoCompleteTemplate;