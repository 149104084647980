import React, { useEffect, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { Flex, Text } from "@aws-amplify/ui-react";
import { LoadingSpinner, LoadingSpinnerPage } from "../LoadingPage";
import { AppNavButton } from "../../custom-ui-components";
import { ErrorLoadingPage } from "../ErrorLoadingPage";


const TeachingResources = function(props) {
  const height = props.height;
  const titleTextProps = props.titleTextProps;
  const standardTextProps = props.standardTextProps;
  const APIFunctions = props.APIFunctions;
  const userIsTutor = props.userIsTutor;
  const userIsAdmin = props.userIsAdmin;
  const userType = props.userType;

  const location = useLocation();
  const state = location.state;

  const teachingResources = props.teachingResources;
  const teachingResourcesSubject = teachingResources[subject] || {};
  const [specificTeachingResources, setSpecificTeachingResources] = useState(teachingResourcesSubject[yearGroup] || null);
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });
  const [hoverDetails, setHoverDetails] = useState(null);
  const [teachingResourcesUIs, setTeachingResourcesUIs] = useState(null);
  const [UIStatus, setUIStatus] = useState(null);

  useEffect(() => {
    const updateMousePosition = function (event) {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };
    window.addEventListener('mousemove', updateMousePosition);

    let alreadyLoaded = false
    const subjectTeachingResources = teachingResources[subject];
    if (subjectTeachingResources != null) {
      const subjectYearGroupTeachingResources = subjectTeachingResources[yearGroup];
      if (subjectYearGroupTeachingResources != null) {
        alreadyLoaded = true;
        setSpecificTeachingResources([...subjectYearGroupTeachingResources])
      }
    }

    const loadTeachingResources = async function () {
      const loadedResource = await APIFunctions.getTeachingResources(subject, yearGroup);
      if (loadedResource == "error") {
        setSpecificTeachingResources("error");
      }
      else {
        setSpecificTeachingResources([...loadedResource]);
      }
    }

    if (alreadyLoaded == false) {
      loadTeachingResources();
    }
  }, [APIFunctions]);

  if (userType == "tutor" && !userIsTutor) {
    return <Navigate to="/" /> 
  }
  else if (userType == "admin" && !userIsAdmin) {
    return <Navigate to="/" /> 
  }
  else if (userType != "tutor" && userType != "admin") {
    return <Navigate to="/" /> 
  }
  
  let returnLink = "/Tutor"
  if (userType == "admin") {
    returnLink ="/Admin"
  }
  if (state == null) {
    return <Navigate to={returnLink} />
  }
  const subject = state.subject;
  if (subject == null) {
    return <Navigate to={returnLink} />
  }
  const yearGroup = state.yearGroup;
  if (yearGroup == null) {
    return <Navigate to={returnLink} />
  }

  if (UIStatus != "loading" && specificTeachingResources == null) {
    setUIStatus("loading");
    setTeachingResourcesUIs(<LoadingSpinner />);
  }
  else if (specificTeachingResources == null) {}
  else if (UIStatus != "error" && (specificTeachingResources == "error" || !Array.isArray(specificTeachingResources))) {
    setUIStatus("error");
    setTeachingResourcesUIs(<Text {...standardTextProps}>Error loading resources for {yearGroup} {subject}, please contact support if this issue continues</Text>);
  }
  else if (specificTeachingResources.length == 0 && UIStatus != "loaded") {
    setUIStatus("loaded");
    setTeachingResourcesUIs(<Text {...standardTextProps}>No resources found for {yearGroup} {subject}</Text>);
  }
  else if (UIStatus != "loaded") {
    setUIStatus("loaded");
    let tutors = null
    if (props.tutors != null && props.tutors != "loading" && props.tutors != "error") {
      const tutorsList = props.tutors;
      tutors = {};
      for (const tutor of tutorsList) {
        tutors[tutor.id] = tutor;
      }
    }
    const UIArray = []; 
    for (let resourceIndex = 0; resourceIndex < specificTeachingResources.length; resourceIndex = resourceIndex + 1) {
      const resource = specificTeachingResources[resourceIndex];
      const submittedBy = resource.submittedBy;
      let submittedByName = null;
      if (submittedBy != null && tutors != null) {
        if (tutors[submittedBy] != null) {
          submittedByName = tutors[submittedBy].firstNames + " " + tutors[submittedBy].lastName;
        }
      }

      const resourceUI = 
        <Link
          //Link to the given URL
          to={resource.link} 
          //Open in a new tab
          target={"_blank"} 
          key={subject + "," + yearGroup + ",resource:" + resourceIndex}
          //Set the context box to show when the mouse is over it
          onMouseEnter={() => {
            setHoverDetails({
              title: resource.title,
              link: resource.link,
              notes: resource.notes,
              topic: resource.topic,
              subject: resource.subject,
              yearGroup: resource.yearGroup,
              submittedBy: resource.submittedBy,
              submittedByName: submittedByName,
              approvedBy: resource.approvedBy
            });
          }}
          onMouseLeave={() => {
            setHoverDetails(null);
          }}
        >
          <Text {...standardTextProps} style={{cursor:"pointer"}}>{resource.link}</Text>
        </Link>
      UIArray.push(resourceUI);
      setTeachingResourcesUIs(UIArray);
    }
  }

  let hoverUI = null;
  if (hoverDetails != null) {
    let contextText = hoverDetails.subject + " > " + hoverDetails.yearGroup;
    if (hoverDetails.topic != null) {
      contextText = contextText + " > " + hoverDetails.topic;
    }
    let approvedByUI = null;
    let submittedByUI = null;
    if (userType == "admin") {
      let submittedByText = null;
      if (hoverDetails.submittedBy == props.user.username) {
        submittedByText = "Submitted by: You";
      }
      else if (hoverDetails.submittedByName != null) {
        submittedByText = "Submitted by: " + hoverDetails.submittedByName + " (" + hoverDetails.submittedBy + ")";
      }
      else if (hoverDetails.submittedBy) {
        submittedByText = "Submitted by: " + hoverDetails.submittedBy;
      }
      if (submittedByText != null) {
        submittedByUI = <Text {...standardTextProps} fontSize={"13px"}>{submittedByText}</Text>;
      }
      const approvedBy = hoverDetails.approvedBy
      let approvedByText = approvedBy;
      if (approvedBy == props.user.username) {
        approvedByText = "You";
      }
      approvedByUI= <Text {...standardTextProps} fontSize={"13px"}>Approved by: {approvedByText}</Text>;
    }

    hoverUI = <Flex 
      direction={"column"}
      position={"fixed"}
      left={mousePosition.x + 20}
      top={mousePosition.y - 10}
      backgroundColor={"#58d698"}
      borderRadius={"10px"}
      paddingLeft={"10px"}
      paddingRight={"10px"}
      paddingTop={"5px"}
      paddingBottom={"5px"}
      maxWidth={"30vw"}
    >
      <Text {...standardTextProps} fontSize={"17px"}>{hoverDetails.title}</Text>
      <Text {...standardTextProps} fontSize={"15px"}>{hoverDetails.notes}</Text>
      <Flex direction={"column"} gap={"0vh"}>
        {submittedByUI}
        {approvedByUI}
      </Flex>
      <Flex direction={"column"} gap={"0vh"}>
        <Text {...standardTextProps} fontSize={"13px"}>{contextText}</Text>
        <Text {...standardTextProps} fontSize={"13px"} style={{wordWrap: "break-word"}}>({hoverDetails.link})</Text>
      </Flex>
    </Flex>
  }

  const titleText = <Text {...titleTextProps}>Teaching Resources for Tutors</Text>
  const subtitleText = subject + " > " + yearGroup
  const subtitle = <Text {...standardTextProps}>{subtitleText}</Text>

  let backButtonlink = "/Tutor/TeachingResourcesYearGroup"
  if (userType == "admin") {
    backButtonlink = "/Admin/TeachingResourcesYearGroup"
  }
  const backButton = <Link to={backButtonlink} style={{textDecoration: "none"}} key={"Back button"} state={{subject: subject}}><AppNavButton text={"Back"}/></Link>


  return (
      <Flex direction={"column"} gap={"3vh"} alignItems={"center"}>
        <Flex direction={"column"} gap={"1vh"} alignItems={"flex-start"}>
          {titleText}
          <Flex gap={"4vw"} alignItems={"center"}>
            {backButton}
            {subtitle}
          </Flex>
        </Flex>
        {teachingResourcesUIs}
        {hoverUI}
      </Flex>
  );
}

export default TeachingResources;