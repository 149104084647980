import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, SelectField, Text } from "@aws-amplify/ui-react";
import { useState, useEffect } from 'react';
import { validateField } from "./FormValidation";
import OptionsTemplate from "./OptionsTemplate";
import DropDownTemplate from "./DropDownTemplate";

const DropDownQuestionTemplate = function(props) {
	try {
		const labelTextProps = props.questionLabelProps || {};
		const errorTextProps = props.questionErrorProps || {};
		//Set question variables from passed properties
		const questionID = props.id;
		const questionLabel = props.label;
		const questionDescription = props.description;
		const options = props.options
		const defaultValue = props.defaultValue;
		const placeholder = props.placeHolder || props.placeholder;
		let questionValidations = props.validations;
		if (questionValidations == null) {
			questionValidations = [];
		}
		let flexDirection = props.direction;
		if (flexDirection == null) {
			flexDirection = "column";
		}
		const required = props.required;
		const requiredAsterisk = props.requiredAsterisk || <Text fontSize={"20px"} color={"#ff0000"}>*</Text>;
		const answersSuccessfullySubmitted = props.answersSuccessfullySubmitted

		//Set state variables used to store the current state and any errors
		const [selectedAnswer, setSelectedAnswer] = useState(defaultValue);
		const [errors, setErrors] = useState({questionError: ""});

		useEffect(() => {
			const validationResult = validateField(selectedAnswer, questionValidations);
			const questionHasError = validationResult.hasError;
			props.handleChange(questionID, selectedAnswer, questionHasError);
		}, []);

		let labelColour = labelTextProps.color || "#000000";
		if (props.validationError != null && props.validationError != null) {
			labelColour = props.validationErrorColour || "#ff0000";
		}
		//Get the text for the question label
		let labelText = <Text {...labelTextProps} color={labelColour}>
			{questionLabel}
		</Text>
		if (questionLabel == null || questionLabel == "") {
			labelText = null;
		}

		let descriptionText = <Text {...labelTextProps} fontSize={"14px"} fontStyle={"italic"}>
			{questionDescription}
		</Text>
		if (questionDescription == "" || questionDescription == null) {
			descriptionText = null
		}
		
		let errorText = <Flex direction={"column"} alignItems={"center"} justifyContent={"center"} position={"relative"} >
			<Text {...errorTextProps} position={"absolute"} top={"0px"}>
				{errors.questionError}
			</Text>
		</Flex>
		if (errors.questionError == null || errors.questionError == "") {
			errorText = null;
		}

		let renderedRequiredAsterisk = null;
		if (required == true) {
			renderedRequiredAsterisk = requiredAsterisk;
		}
		
		if (errors.errorMessage === null) {
			setErrors({
				questionError:""
			});
		}

		//Generate the dropdown field component
		const dropDownField = <DropDownTemplate
			key={"SelectField"}
			placeholder={placeholder}
			value={selectedAnswer}
			size={props.size}
			disabled={answersSuccessfullySubmitted}
			style={{
				marginTop: "-8px",
				width: "fit-content",
				alignSelf: "center"
			}}
			

			//Runs when it detects a change in the input field
			onChange={(newValue) => {
				setSelectedAnswer(newValue);
				const validationResult = validateField(newValue, questionValidations);
				const questionHasError = validationResult.hasError;
				const questionErrorMessage = validationResult.errorMessage;
				props.handleChange(questionID, newValue, questionHasError);

				setErrors({
					questionHasError:questionHasError, 
					questionError:questionErrorMessage
				});
			}} 
		>
		{options}
	</DropDownTemplate>

		//flexDirection = "column";

		let alignItems = null;
		if (flexDirection == "row") {
			alignItems="center"
		}

		if (errors.questionHasError == false && errors.questionError != props.warning) {
			const newErrors = {...errors}
			newErrors.questionError = props.warning
			setErrors(newErrors);
		}

		let labelFlex = <Flex alignItems={"center"} alignSelf={"center"}>
			{renderedRequiredAsterisk}
			{labelText}
		</Flex>
		if (labelText == null && renderedRequiredAsterisk == null) {
			labelFlex = null;
		}

		//Return text field component to calling function (Most likely a Form Template)
		return (
			<Flex direction={flexDirection} alignItems={alignItems} >
				{labelFlex}
				{descriptionText}
				{dropDownField}
				{errorText}
			</Flex>
		);
	}
	catch (error) {
		throw "DropDownTemplate Error: " + error;
	}
}

export default DropDownQuestionTemplate;