import { Flex } from "@aws-amplify/ui-react";
import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import AdminNavbar from "./AdminNavbar";
import Navbar from "../home/HomeNavbar";
import FooterTemplate from "../../custom-ui-components/FooterTemplate";
import { LoadingSpinnerPage } from "../LoadingPage";


const AdminLayout = function(props) {
  const height = props.height;
  const sideMarginSize = props.sideMarginSize;

  const userIsAdmin = props.userIsAdmin;

  let pageContent = <LoadingSpinnerPage {...props} />
  if (userIsAdmin) {
    pageContent = <Outlet />
  }
  else if (userIsAdmin != null) {
    return <Navigate to={"/"} />
  }

  return (
    <div className="Page" style={{minHeight:height}}>
      <Flex direction={"column"} marginLeft={sideMarginSize} marginRight={sideMarginSize} alignItems={"center"} >
        <Flex style={{zIndex:2}} marginBottom={"50px"}>  
          <Navbar {...props} pageType={"admin"}/>
        </Flex>
        <Flex style={{zIndex:1}}> 
          {pageContent}
        </Flex>
        <br />
        <FooterTemplate {...props}/>
      </Flex>
    </div>
  );
}

export default AdminLayout;