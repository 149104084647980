import React from "react";
import { Navigate } from "react-router-dom";
import { Button, Flex, Grid, Text } from "@aws-amplify/ui-react";
import { LoadingSpinnerPage } from "../LoadingPage";

const ThankYouPurchase = function(props) {
  const height = props.height;
  const titleTextProps = props.titleTextProps;
  const standardTextProps = props.standardTextProps;
  const userIsParent = props.userIsParent;
  const userIsStudent = props.userIsStudent;
  const parentModel = props.parentModel;
  const studentModel = props.studentModel

  if (!userIsParent && !userIsStudent) {
    return <Navigate to="/" /> 
  }

  if (userIsParent) {
    if (parentModel == null || parentModel == "loading") {
      return <LoadingSpinnerPage />
    }
  }
  else if (userIsStudent) {
    if (studentModel == null || studentModel == "loading") {
      return <LoadingSpinnerPage />
    }
  }

  let userType = null;
  let userModel = null;
  if (userIsParent) {
    userType = "parent";
    userModel = parentModel;
  }
  else if (userIsStudent) {
    userType = "student";
    userModel = studentModel;
  }

  const titleText = <Text {...titleTextProps}>Thank You for you Purchase!</Text>

  let currentLessonCredits = userModel.lessonCredits;
  if (currentLessonCredits == null) {
    currentLessonCredits = 0
  }

  let suffix = "s";
  if (currentLessonCredits == 1) {
    suffix = "";
  }

  const descriptionText1 = <Text {...standardTextProps}>Lesson credits are used to pay for lessons.</Text>
  const descriptionText2 = <Text {...standardTextProps}>You can purchase more lesson credits at any point using all common forms of online payment.</Text>

  const descriptionFlex = <Flex direction={"column"} gap={"0.5vh"}>
    {descriptionText1}
    {descriptionText2}
  </Flex>


  return (
      <Flex direction={"column"} gap={"3vh"} alignItems={"center"}>
        {titleText}
        {descriptionFlex}
      </Flex>
  );
}

export default ThankYouPurchase;