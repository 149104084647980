import {React, useState, useEffect } from 'react';
import { Divider, Flex, Grid, Text } from "@aws-amplify/ui-react";


const CalendarTemplate = function(props) {
  const [hoverMenu, setHoverMenu] = useState({title: null, description: null, startTime: null, endTime: null});

  const standardTextProps = props.standardTextProps;

  const useMousePosition = () => {
    const [mousePosition, setMousePosition] = useState({ x: null, y: null });
    useEffect(() => {
      const updateMousePosition = ev => {
        setMousePosition({ x: ev.clientX, y: ev.clientY });
      };
      window.addEventListener('mousemove', updateMousePosition);
      return () => {
        window.removeEventListener('mousemove', updateMousePosition);
      };
    }, []);
    return mousePosition;
  };

  const calendarItems = props.items || {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: []
  };
  const width = props.width;

  const timeLabels = ["00:00", "01:00","02:00","03:00","04:00","05:00","06:00","07:00","08:00","09:00", "10:00", "11:00", "12:00"];
  timeLabels.push("13:00","14:00","15:00","16:00","17:00","18:00","19:00", "20:00", "21:00", "22:00", "23:00");

  //const timeLabels = ["07:00","08:00","09:00", "10:00", "11:00", "12:00", "13:00","14:00","15:00","16:00","17:00","18:00","19:00", "20:00"];

  const calendarRenders = [];
  const calendarEventItems = [];
  const columnDividers = [];
  const dividerProps = {
    backgroundColor: "#101010",
  }
  const dayNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  for (let dayIndex = 0; dayIndex < dayNames.length; dayIndex = dayIndex + 1) {
    const day = dayNames[dayIndex];
    const dayItems = calendarItems[day];

    let backgroundColour = "#ffffff";
    if (dayIndex % 2 == 0) {
      backgroundColour = "#dddddd"
    }

    const dayBackground = <Flex
      rowStart={1}
      rowEnd={26}
      column={dayIndex + 2}
      key={day + "Background"}
      backgroundColor={backgroundColour}
      justifyContent={"center"}
      //alignItems={"center"}
    >
    </Flex>

    let dayText = day;
    if (width < 800) {
      dayText = day[0];
    }

    const dayName = <Flex
      rowStart={1}
      column={dayIndex + 2}
      key={day + "Name"}
      backgroundColor={backgroundColour}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {dayText}
    </Flex>
    const divider = <Flex
      rowStart={1}
      rowEnd={timeLabels.length + 2}
      columnStart={dayIndex + 2}
      key={"ColumnDivider" + dayIndex}
      width={"2px"}
      style={{
        zIndex:4
      }}
      {...dividerProps}
    />
    columnDividers.push(divider);

    calendarRenders.push(dayBackground, dayName);

    
    for (let eventIndex = 0; eventIndex < dayItems.length; eventIndex = eventIndex + 1) {
      const event = dayItems[eventIndex];
      const title = event.title;
      const description = event.description;

      const titleText = <Text fontSize={"14px"} textAlign={"center"}>{title}</Text>
      const descriptionText = <Text fontSize={"1.4vw"}>{description}</Text>
      
      const startTime = event.startTime;
      const startHour = parseInt(startTime.split(":")[0]);
      const startMinute = parseInt(startTime.split(":")[1]);

      const endTime = event.endTime;
      const endHour = parseInt(endTime.split(":")[0]);
      const endMinute = parseInt(endTime.split(":")[1]);

      const timeText = <Text fontSize={"1.4vw"}>{startTime} - {endTime}</Text>
      
      
      const startTimeValue = startHour + (startMinute/60);
      const endTimeValue = endHour + (endMinute/60);

      const startRow = 2 + Math.floor(startTimeValue);
      const endRow = 3 + Math.floor(endTimeValue);

      const eventDuration = endTimeValue - startTimeValue;
      const currentIntDuration = endRow - startRow;

      const height = (eventDuration/currentIntDuration) * 100;

      const heightOffset = (startTimeValue - startHour)*50;

      const widthPercentage = (1/(7 - dayIndex)) * 100;

      const passingToClickProps = event.passingToClickProps;

      const eventFlex = <Flex
        rowStart={startRow}
        rowEnd={endRow}
        columnStart={dayIndex + 2}
        key={day + "Event" + eventIndex}
        backgroundColor={"#666699"}
        direction={"column"}
        height={height + "%"}
        position={"absolute"}
        marginTop={heightOffset + "px"}
        width={widthPercentage + "%"}
        style={{
          cursor: "pointer",
        }}
        onMouseEnter={() => {
          setHoverMenu({title: title, description: description, startTime: startTime, endTime: endTime});
        }}
        onMouseLeave={() => {
          setHoverMenu({title: null, description: null, startTime: startTime, null: null});
        }}
        onClick={() => {
          try {
            const fromLocation = "calendar"
            props.onClick(passingToClickProps, fromLocation);
          }
          catch (error) {
            console.log(error);
          }
        }}
      >
        {/* {titleText} */}
      </Flex>

      calendarEventItems.push(eventFlex);
    }
  } 

  for (const label of timeLabels) {
    const startTime = label;
    const startHour = parseInt(startTime.split(":")[0]);
    const startMinute = parseInt(startTime.split(":")[1]);
    const startRow = startHour + 2;

    const labelFlex = <Flex
      rowStart={startRow}
      column={1}
      key={"label" + label}
      direction={"row"}
      minHeight={"50px"}
      justifyContent={"center"}
      marginLeft={"10px"}
      marginRight={"10px"}
    >
      {label}
    </Flex>

    const divider = <Flex
      rowStart={startRow}
      columnStart={1}
      columnEnd={9}
      key={"Divider" + label}
      height={"1px"}
      {...dividerProps}
    />

    calendarRenders.push(labelFlex, divider);
  }

  const leftDivider = <Flex
    rowStart={1}
    rowEnd={timeLabels.length + 2}
    columnStart={1}
    key={"LeftDivider"}
    width={"2px"}
    {...dividerProps}
  />
  const rightDivider = <Flex
    rowStart={1}
    rowEnd={timeLabels.length + 2}
    columnStart={10}
    key={"RightDivider"}
    width={"2px"}
    {...dividerProps}
  />
  const topDivider = <Flex
    rowStart={1}
    columnStart={1}
    columnEnd={9}
    key={"TopDivider"}
    height={"2px"}
    {...dividerProps}
  />
  const bottomDivider = <Flex
    rowStart={timeLabels.length + 2}
    columnStart={1}
    columnEnd={9}
    key={"BottomDivider"}
    height={"2px"}
    {...dividerProps}
  />
  columnDividers.push(leftDivider, rightDivider, topDivider, bottomDivider);

  calendarRenders.push(...calendarEventItems);
  calendarRenders.push(...columnDividers);

  const calendarGrid = <Grid
    rowGap={"0px"}
    backgroundColor={"#ffffff"}
    templateColumns="2fr 5fr 5fr 5fr 5fr 5fr 5fr 5fr"
    templateRows="2fr 1fr"
    position={"relative"}
  >
    {calendarRenders}
  </Grid>

  const hoverTitleText = <Text {...standardTextProps} fontSize={"15px"} textAlign={"center"}>{hoverMenu.title}</Text>
  
  let descriptionTexts = null;
  if (typeof(hoverMenu.description) == "object" && hoverMenu.description != null) {
    descriptionTexts = [];
    let index = 0
    for (const textItem of hoverMenu.description) {
      const hoverDescriptionText = <Text key={"description" + index} {...standardTextProps} fontSize={"15px"}>{textItem}</Text>
      descriptionTexts.push(hoverDescriptionText)
      index = index + 1
    }
  }
  else if (typeof(hoverMenu.description) == "string" && hoverMenu.description != null) {
    descriptionTexts = hoverMenu.description;
  }
  
  const hoverTimeText = <Text {...standardTextProps} fontSize={"15px"}>{hoverMenu.startTime} - {hoverMenu.endTime}</Text>

  const hoverMenuComponent = <Flex 
    backgroundColor={"#069dd4"} 
    borderRadius={"1vh"}
    width={"fit-content"}
    paddingLeft={"1vw"}
    paddingRight={"1vw"}
    paddingTop={"1vh"}
    paddingBottom={"1vh"}
    height={"fit-content"}
    position="fixed"
    top={useMousePosition().y - 30}
    left={useMousePosition().x + 10}
    style={{
      zIndex:5
    }}
    direction={"column"}
  >
    {hoverTitleText}
    {hoverTimeText}
    {descriptionTexts}
  </Flex>

  return (
    <Flex
      borderRadius={"100px"}
    >
      {calendarGrid}
      {(hoverMenu.title != null) && hoverMenuComponent}
    </Flex>
  );
}

export default CalendarTemplate;