import { React } from 'react';
import { Navigate } from 'react-router-dom';
import { signOut } from '../App';


const SignOut = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    console.log("Signing Out User");
    signOut();
    return (
        <Navigate to="/" state={{"signOutUser":true}} /> 
    );
}

export default SignOut;