import { Flex, Icon, Text } from "@aws-amplify/ui-react";
import {React, useState} from 'react';
import { MdClose} from 'react-icons/md';
import { calculateLessonCost, calculateLessonWage, getSessionEndTime } from "../App";
import { Link } from "react-router-dom";

const AmendmentTemplate = function(props) {
    const standardTextProps = {...props.standardTextProps};
    const standardTitleProps = {...props.titleTextProps};
    const amendmentFont = {...standardTextProps};
    amendmentFont.fontSize = "15px";

    const amendment = props.amendment;
    const amendmentIndex = props.amendmentIndex;
    const removeAmendment = props.removeAmendment;
    const amendmentType = amendment.type;
    const lessonProps = props.lessonProps || {};
    const showLessonDetails = props.showLessonDetails || false;
    const userType = props.userType;

    const width = window.innerWidth;

    let errorText = null;
    if (amendment == null) { 
        errorText = "Null Amendment";
    }
    if (removeAmendment != null && amendmentIndex == null) {
        errorText = "Invalid Amendment (No index)";
    }
    if (amendmentType == null) {
        errorText = "No amendment type";
    }
    if (errorText != null) {
        return (
            <Flex
                justifyContent={"center"}
                backgroundColor={"#00ff00"}
                borderRadius={"10px"}
                padding={"10px"}
                direction={"column"}
                alignItems={"flex-start"}
                gap={"0vh"}
            >
                <Text {...amendmentFont}>{errorText}</Text>
            </Flex>
        );
    }

    const generateAvailabilityStringFromArray = function (availabilityArray) {
        try {
            let availabilityString = "";
            const availabilityWindows = availabilityArray.sort((a, b) => {
                const aStartTime = a.startTime;
                const aStartHour = parseInt(aStartTime.split(":")[0]);
                const aStartMinute = parseInt(aStartTime.split(":")[1]);
                const aStartTimeInt = (aStartHour * 60) + aStartMinute;
                const bStartTime = b.startTime;
                const bStartHour = parseInt(bStartTime.split(":")[0]);
                const bStartMinute = parseInt(bStartTime.split(":")[1]);
                const bStartTimeInt = (bStartHour * 60) + bStartMinute;
                return aStartTimeInt - bStartTimeInt;
            });
            for (let availabilityWindowIndex = 0; availabilityWindowIndex < availabilityWindows.length; availabilityWindowIndex = availabilityWindowIndex + 1) {
                const availabilityWindow = availabilityWindows[availabilityWindowIndex];
                const availabilityWindowText = availabilityWindow.startTime + "-" + availabilityWindow.endTime;
                availabilityString = availabilityString + availabilityWindowText;
                if (availabilityWindowIndex < availabilityWindows.length - 1) {
                    availabilityString = availabilityString + ", ";
                }
            }
            return availabilityString;
        }
        catch (error) {
            return "Error generating availability string";
        }
    }
    
    let dayAvailability = amendment.dayAvailability;
    let dayAvailabilityWindowsText = null;
    if (dayAvailability != null) {
        dayAvailabilityWindowsText = generateAvailabilityStringFromArray(dayAvailability);
    }

    let weekAvailability = amendment.weekAvailability;
    let weekAvailabilityUIs = null;
    if (weekAvailability != null) {
        const availabilityStrings = [];
        const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        for (const day of days) {
            const dayAvailability = weekAvailability[day];
            if (Array.isArray(dayAvailability) && dayAvailability.length > 0) {
                const dayAvailabilityString = generateAvailabilityStringFromArray(dayAvailability);
                availabilityStrings.push({
                    day: day,
                    string: dayAvailabilityString
                });
            }
        }

        weekAvailabilityUIs = [];
        for (const dayObject of availabilityStrings)  {
            const dayUI = <Text {...amendmentFont} key={"addScheduledAvailability" + dayObject.day} >{dayObject.day}: {dayObject.string}</Text>
            weekAvailabilityUIs.push(dayUI);
        }
    }

    const amendmentText = [];
    let backgroundColour = "#34eb9e";
    if (amendment.date != null) {
        const currentDate = new Date();
        const amendmentDate = new Date(amendment.date);
        if (amendment.startTime != null) {
            const startTimeHour = parseInt(amendment.startTime.split(":")[0], 10);
            const startTimeMinute = parseInt(amendment.startTime.split(":")[1], 10)
            amendmentDate.setHours(startTimeHour);
            amendmentDate.setMinutes(startTimeMinute);
        }
        let endDate = new Date(lessonProps.startDate);
        endDate.setUTCFullYear(endDate.getUTCFullYear() + 1);
        if (lessonProps.endDate != null) {
            endDate = new Date(lessonProps.endDate);
        }
        if ((amendmentDate < currentDate || amendmentDate > endDate) && amendmentType != "setLastDate") {
            backgroundColour = "#e8e8e8";
            amendmentFont.color = "#a8a8a8";
        }
    }
    try {
        if (amendmentType == "addOneOff") {
            if (amendment.date == null) {
                throw "Invalid date";
            }
            const date = new Date(amendment.date);

            let timeText = null;
            if (amendment.confirmed == true) {
                const startTime = amendment.startTime;
                let endTime = amendment.endTime;
                if (endTime == null) {
                    const sessionLength = amendment.sessionLength;
                    endTime = getSessionEndTime(startTime, amendment.sessionLength);
                }
                timeText = startTime + "-" + endTime;
                amendmentText.push(
                    <Text {...amendmentFont} key={"addOneOffMain"} >Add one-off lesson: {date.toDateString()}, {timeText}</Text>
                );
            }
            else {
                amendmentText.push(
                    <Text {...amendmentFont} key={"addOneOffMain"} >Add one-off lesson: {date.toDateString()}, {amendment.sessionLength}</Text>,
                    <Text {...amendmentFont} key={"addOneOffAvailability"}>Availability: {dayAvailabilityWindowsText}</Text>
                );
            }
        }
        else if (amendmentType == "addScheduled") {
            let addressText = null;
            if (amendment.lessonType != "Online") {
                addressText = <Text {...amendmentFont} key={"addScheduledAddress"} >Address: {amendment.address.address1stLine}, {amendment.address.postcode}</Text>;
            }
            amendmentText.push(
                <Text {...amendmentFont} key={"addScheduledMain"} >New scheduled lesson: {amendment.sessionLength}, {amendment.lessonType}</Text>,
                addressText,
                //<Text {...amendmentFont} key={"addScheduledAvailability"} >Availability:</Text>,
                weekAvailabilityUIs
            );
        }
        else if (amendmentType == "cancelSingle") {
            amendmentText.push(
                <Text {...amendmentFont} key={"cancelSingleMain"} >No lesson: {amendment.date}, {amendment.startTime} - {amendment.endTime}</Text>
            );
        }
        else if (amendmentType == "cancelAll") {
            amendmentText.push(
                <Text {...amendmentFont} key={"cancelAllMain"} >Cancel all future lessons</Text>
            );
        }
        else if (amendmentType == "setLastDate") {
            amendmentText.push(
                <Text {...amendmentFont} key={"setLastDateMain"} >Final lesson: {amendment.date}</Text>
            );
        }
        else if (amendmentType == "changeType") {
            let addressString = null;
            if (amendment.lessonType == "In-Person") {
                addressString = "- " + amendment.address.address1stLine + ", " + amendment.address.postcode;
            }
            amendmentText.push(
                <Text {...amendmentFont} key={"changeTypeMain"} >New lesson type: {amendment.lessonType} {addressString}</Text>
            );
        }
        else if (amendmentType == "changeInPerson") {
            let addressString = "";
            if (amendment.address != null) {
                addressString = ": " + amendment.address.address1stLine + ", " + amendment.address.postcode;
            }
            amendmentText.push(
                <Text {...amendmentFont} key={"changeInPerson"} >Change to in-person lessons {addressString}</Text>
            );
        }
        else if (amendmentType == "changeLocation") {
            amendmentText.push(
                <Text {...amendmentFont} key={"changeLocationMain"} >New lesson location: {amendment.address.address1stLine}, {amendment.address.postcode}</Text>
            );
        }
        else if (amendmentType == "changeDayTime") {
            amendmentText.push(
                <Text {...amendmentFont} key={"changeDayTimeMain"} >Change lesson day/time:</Text>,
                weekAvailabilityUIs
            );
        }
        else {
            amendmentText.push(
                <Text {...amendmentFont} key={"invalidAmendment"} >Invalid amendment type</Text>
            );
        }
    }
    catch (error) {
        console.log("Error generating amendment UI: " + error);
        amendmentText.push(
            <Text {...amendmentFont}>Invalid amendment</Text>
        );
    }

    
    let cross = null;
    if (removeAmendment != null) {
        let right = "-40px";
        let top = null;
        if (width < 430) {
            right = "-5px";
            top = "-5px"
        }
        backgroundColour = "#00ff00"
        cross = <Flex 
            onClick={() => {
                removeAmendment(amendmentIndex);
            }}
            alignItems={"center"}
            position={"relative"}
            margin={"-10px"}
            style={{cursor: "pointer"}}
        >
            <Icon
                key={"closeButton"}
                width={"30px"}
                height={"30px"}
                as={MdClose}
                color={"ff0000"}
                position={"absolute"}
                right={right}
                top={top}
            />
        </Flex>
    }

    if (amendment.rejectedBy != null) {
        backgroundColour = "#ff0000"
    }

    const lessonDetails = [];
    let notification = null;
    if (showLessonDetails) {
        lessonDetails.push(
            <Text {...amendmentFont} fontSize={"17px"}  key={"LessonDetails"} >Current Lesson Details:</Text>
        );
        if (userType != "tutor") {
            lessonDetails.push(
                <Text {...amendmentFont} key={"tutor"} >Tutor: {lessonProps.tutorFirstNames} {lessonProps.tutorLastName}</Text>
            );
        }
        if (userType != "student") {
            lessonDetails.push(
                <Text {...amendmentFont} key={"student"} >Student: {lessonProps.studentNames}</Text>
            );
        }
        lessonDetails.push(
            <Text {...amendmentFont} key={"subject"} >Subject: {lessonProps.subject}</Text>
        );
        lessonDetails.push(
            <Text {...amendmentFont} key={"date/time"} >{lessonProps.lessonDay}s: {lessonProps.startTime}-{lessonProps.endTime}</Text>
        );
        lessonDetails.push(
            <Text {...amendmentFont} key={"lessonType"} >Lesson type: {lessonProps.lessonType}</Text>
        );
        if (lessonProps.lessonType == "In-Person") {
            lessonDetails.push(
                <Text {...amendmentFont} key={"address"} >{lessonProps.address.address1stLine},</Text>
            );
            lessonDetails.push(
                <Text {...amendmentFont} key={"postcode"} >{lessonProps.address.postcode}</Text>
            );
        }
        const currentDate = new Date(new Date().toDateString());
        const lessonStartDate = new Date(lessonProps.startDate);
        if (currentDate <= lessonStartDate) {
            lessonDetails.push(
                <Text {...amendmentFont} key={"startDate"}>Start date: {lessonProps.startDate}</Text>
            );
        }
        let endDateString = "N/A";
        if (lessonProps.endDate != null) {
            endDateString = lessonProps.endDate;
        }
        lessonDetails.push(
            <Text {...amendmentFont} key={"endDate"}>End date: {endDateString}</Text>
        );
        // lessonDetails.push(
        //     <Text {...amendmentFont} key={"tutorLevel"}>Tutor level: {lessonProps.tutorLevel}</Text>
        // );

        // if (userType != "tutor") {
        //     lessonDetails.push(
        //         <Text {...amendmentFont} key={"tutorCost"}>Lesson cost: {calculateLessonCost(lessonProps.tutorLevel, lessonProps.startTime, lessonProps.endTime, lessonProps.lessonType, true)}</Text>
        //     );
        // }
        // if (userType != "parent" && userType != "student") {
        //     lessonDetails.push(
        //         <Text {...amendmentFont} key={"tutorCost"}>Lesson wage: {calculateLessonWage(lessonProps.tutorLevel, lessonProps.startTime, lessonProps.endTime, lessonProps.lessonType, true)}</Text>
        //     );
        // }


        lessonDetails.push(
            <Text {...amendmentFont} fontSize={"17px"} key={"AmendmentDetails"} marginTop={"20px"}>New Amendment Details:</Text>
        );

        notification = <Flex 
            borderRadius={"100px"} 
            backgroundColor={"#fc3903"} 
            width={"25px"} 
            height={"25px"}
            alignItems={"center"}
            justifyContent={"center"}
            fontSize={"15px"}
            color={"#ffffff"}
            position={"absolute"}
            right="-10px" 
            top="-10px"
        >
            {1}
        </Flex>
    }

    let amendmentUI = <Flex
        key={amendmentText + amendmentIndex}
        justifyContent={"center"}
        backgroundColor={backgroundColour}
        borderRadius={"10px"}
        padding={"10px"}
        direction={"column"}
        alignItems={"flex-start"}
        gap={"0vh"}
        position={"relative"}
    >   
        {lessonDetails}
        {amendmentText}
        {notification}
    </Flex>

    if (showLessonDetails) {
        let link = null;
        const state = {...amendment, lesson:{...lessonProps}, userType: userType}
        if (userType == "student") { 
            link = "/Student/RespondToAmendment";
        }
        else if (userType == "parent") { 
            link = "/Parent/RespondToAmendment";
        }
        else if (userType == "tutor") { 
            link = "/Tutor/RespondToAmendment";
        }
        amendmentUI = <Link to={link} style={{textDecoration: 'none'}} state={state}>{amendmentUI}</Link>
    }

    
    return (
        <Flex>
            {amendmentUI}
            {cross}
        </Flex>
    );
}

export default AmendmentTemplate;