import {useEffect, useState, React} from 'react';
import { LoadingSpinner, LoadingSpinnerPage } from '../LoadingPage';
import { Button, CheckboxField, Flex, Grid, Icon, ScrollView, SliderField, Text, TextAreaField, TextField } from '@aws-amplify/ui-react';
import {Link, Navigate, useLocation} from "react-router-dom";
import {MdArrowDropDown, MdArrowRight, MdCached, MdModeEdit, MdMoreHoriz, MdSearch} from 'react-icons/md';
import LessonDateTemplate from '../../custom-ui-components/LessonDateTemplate';
import PopUpTemplate from '../../custom-ui-components/form-components/PopUpTemplate';
import { isValidPrice, priceValuetoString } from '../../App';
import MapTemplate from '../../custom-ui-components/EmbededMapTemplate';
import { AppNavButton, DropDownQuestionTemplate, FormTemplate, OptionsTemplate, TextFieldTemplate } from '../../custom-ui-components';
import RadioButtonsTemplate from '../../custom-ui-components/form-components/RadioButtonsTemplate';
import BackButton from '../../custom-ui-components/BackButton';
import CheckBoxesTemplate from '../../custom-ui-components/form-components/CheckBoxesTemplate';

const ViewUser = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    const userTextProps = {...standardTextProps}
    userTextProps.textAlign = "left";
    delete userTextProps.maxWidth;
    //Get the current user to pass to subsequent functions
    const user = props.user;
    const APIFunctions = props.APIFunctions;
    const userDictionary = props.userDictionary;
    const lessonDictionary = props.adminLessonDictionary;
    const studentDictionary = props.studentDictionary;
    const tutorDictionary = props.tutorDictionary;
    const parentDictionary = props.parentDictionary;

    const [userUIs, setUserUIs] = useState(null);
    const [userID, setUserID] = useState(null);
    const [queryParameters, setQueryParameters] = useState(null);
    const [userProps, setUserProps] = useState(null);
    const [noUser, setNoUser] = useState(false);
    const [editCredit, setEditCredit] = useState(false);
    const [newCreditSubmitted, setNewCreditSubmitted] = useState(false);
    const [existingCredit, setExistingCredit] = useState(false);
    const [existingModelCredit, setExistingModelCredit] = useState({
        tutor: null,
        student: null,
        parent: null
    });
    const [userTypeLessons, setUserTypeLessons] = useState({
        tutor: null,
        student: null,
        parent: null,
        parentStudents: {}
    });
    const [newCreditValue, setNewCreditValue] = useState(null);
    const [relativeCredit, setRelativeCredit] = useState(null);
    const [notifyUserCreditChange, setNotifyUserCreditChange] = useState(false);
    const [creditChangeNotes, setCreditChangeNotes] = useState("");
    const [creditUserType, setCreditUserType] = useState("");
    const [userInfoUI, setUserInfoUI] = useState(null);
    const [shownDatesSessions, setShownDatesSessions] = useState({});
    const [activitySearchParams, setActivitySearchParams] = useState({
        activitySearchLimit: 10,
        enableSearchByActivity: false,
        searchActivity: "/"
    });
    const [previousSearchParams, setPreviousSearchParams] = useState({});
    const [activitySearchUI, setActivitySearchUI] = useState(null);
    const blankUserRoles = {
        userIsTutor: false,
        userIsStudent: false,
        userIsParent: false,
        userIsManagedStudent: false,
        userGroups: []
    };
    const [userRoles, setUserRoles] = useState(blankUserRoles);

    const location = useLocation();
    const extraProps = {...location.state} || {};

    const lessons = props.adminLessons;
    const adminUsersInfo = props.adminUsersInfo;

    const getUIs = function () {
        const IDText = <Text {...userTextProps} key={"userID"}>ID: {userProps.id}</Text>;
        let emailText = null;
        if (userProps.email != null) {
            emailText = <Text {...userTextProps} key={"userEmail"}>Email: {userProps.email}</Text>
        }
        let nameText = null;
        if (userProps.firstNames != null) {
            nameText = <Text {...userTextProps} key={"userName"}>Name: {userProps.firstNames + " " + userProps.lastName}</Text>
        }
        let genderText = null;
        if (userProps.gender != null) {
            genderText = <Text {...userTextProps} key={"userGender"}>Gender: {userProps.gender}</Text>
        }
        let phoneNumberText = null;
        if (userProps.phoneNumber != null) {
            phoneNumberText = <Text {...userTextProps} key={"phoneNumber"}>Phone Number: {userProps.phoneNumber}</Text>
        }
        let userGroupsText = null;
        if (userProps.groups != null) {
            let typeSuffix = "";
            if (userProps.groups.length > 1) {
                typeSuffix = "s"
            }
            let groupsString = "";
            for (let groupIndex = 0; groupIndex < userProps.groups.length; groupIndex = groupIndex + 1) {
                const groupName = userProps.groups[groupIndex];
                groupsString = groupsString + groupName;
                if (groupIndex < userProps.groups.length - 1) {
                    groupsString = groupsString + ", ";
                }
            }
            userGroupsText = <Text {...userTextProps} key={"userGroups"}>User type{typeSuffix}: {groupsString}</Text>
        }
        let createdAtText = null;
        if (userProps.createdAt != null) {
            const createdDate = new Date(userProps.createdAt);
            createdAtText = <Text {...userTextProps} key={"createdAt"}>User created: {createdDate.toDateString() + ", " + createdDate.toLocaleTimeString()}</Text>
        }
        let updatedAtText = null;
        if (userProps.modifiedAt != null) {
            const modifiedDate = new Date(userProps.modifiedAt);
            updatedAtText = <Text {...userTextProps} key={"updatedAt"}>User last updated: {modifiedDate.toDateString() + ", " + modifiedDate.toLocaleTimeString()}</Text>
        }
        let signUpWhereFromText = null;
        if (userProps.signUpWhereFrom != null) {
            signUpWhereFromText = <Text {...userTextProps} key={"signUpWhereFrom"}>How they found us: {userProps.signUpWhereFrom}</Text>
        }

        const tutorModel = userProps.tutorModel;
        const studentModel = userProps.studentModel;
        const parentModel = userProps.parentModel;
        const address = userProps.address;

        const getAvailabilityText = function (availability) {
            let availabilityText = [];
            const availabilityArray = Object.entries(availability);

            const availabilityTextArray = ["", "", "", "", "", "", ""];
            const dayOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
            
            //Iterate through every day of the student availability (will include days with no availability)
            for (const dayAvailability of availabilityArray) {
                const dayName = dayAvailability[0];
                const availability = dayAvailability[1];
                //Only proceed if the availability for the current day is not empty
                if (availability.length > 0) {
                    let dayIndex = null;
                    //Find the index of the day of the week (so that it can later be inserted into the right place)
                    for (let possibleDayIndex = 0; possibleDayIndex < dayOrder.length; possibleDayIndex = possibleDayIndex + 1) {
                        const possibleDay = dayOrder[possibleDayIndex];
                        if (dayName == possibleDay) {
                            dayIndex = possibleDayIndex;
                        }
                    }
                    //Sort the availability into a new array based on start time
                    const availabilityWindowArray = [];
                    for (const availabilityWindow of availability) {
                        const startTime = availabilityWindow.startTime;
                        const startTimeHour = parseInt(startTime.split(":")[0], 10);
                        const startTimeMinute = parseInt(startTime.split(":")[1], 10);
                        const startTimeInt = (startTimeHour * 60) + startTimeMinute;
                        availabilityWindow.startTimeInt = startTimeInt;
                        let inserted = false;
                        const availabilityWindowArrayLength = availabilityWindowArray.length;
                        for (let existingWindowIndex = 0; existingWindowIndex < availabilityWindowArrayLength; existingWindowIndex = existingWindowIndex + 1) {
                            const existingWindow = availabilityWindowArray[existingWindowIndex];
                            const existingStartTimeInt = existingWindow.startTimeInt;
                            if (startTimeInt < existingStartTimeInt && inserted == false) {
                                availabilityWindowArray.splice(existingWindowIndex, 0, availabilityWindow);
                                inserted = true;
                            }
                        }
                        if (inserted == false) {
                            availabilityWindowArray.push(availabilityWindow);
                        }
                    }
                    //Create a day availability string from the sorted day availabilty array
                    let dayAvailabilityText = dayName + ": ";
                    for (const availabilityWindow of availabilityWindowArray) {
                        const startTime = availabilityWindow.startTime;
                        const endTime = availabilityWindow.endTime;
                        const windowString = startTime + " - " + endTime;
                        dayAvailabilityText = dayAvailabilityText + windowString + ", ";
                    }
                    //Remove the comma and space from the last entry 
                    dayAvailabilityText = dayAvailabilityText.substring(0, dayAvailabilityText.length - 2);
                    //Add this day availability string to the week availability array in the index we calculated earlier
                    availabilityTextArray[dayIndex] = dayAvailabilityText;
                }
            }
            //Build the full availability string from the completely ordered array of day availability strings
            for (let dayTextIndex = 0; dayTextIndex < availabilityTextArray.length; dayTextIndex = dayTextIndex + 1) {
                const dayString = availabilityTextArray[dayTextIndex];
                if (dayString != "") {
                    availabilityText.push(<Text {...standardTextProps} key={dayString}>{dayString}</Text>)
                }
            }
            return availabilityText;
        }

        let totalCredit = 0;
        let tutorCredit = null;
        let studentCredit = null;
        let parentCredit = null;
        let availabilityText = null;
        let tutorPostcodeText = null;
        let sessionsText = null;
        let tutorLevelText = null;
        let schoolText = null;
        let previousExperienceText = null;
        let otherJobsText = null;
        let referralCodeText = null;
        let teachesSubjectsText = null;
        let tutorLessonsText = null;
        if (tutorModel != null) {
            const tutorAvailability = tutorModel.availability;
            const availabilityTextArray = getAvailabilityText(tutorAvailability);
            availabilityText = <Flex direction={"column"} alignItems={"flex-start"} gap={"0vh"} key={"availabilityText"}>
                <Text {...standardTextProps}>Availability:</Text>
                <Flex direction={"column"} alignItems={"flex-start"} marginLeft={"50px"} gap={"0vh"} >
                    {availabilityTextArray}
                </Flex>
            </Flex>
            tutorPostcodeText = <Text {...standardTextProps} textAlign={"left"} key={"postcode"}>Postcode: {tutorModel.address.postcode}</Text>
            sessionsText = <Text {...standardTextProps} textAlign={"left"} key={"numSessions"}>Num sessions: {tutorModel.numExistingLessons}/{tutorModel.numSessionsWanted}</Text>
            tutorLevelText = <Text {...standardTextProps} textAlign={"left"} key={"tutorLevel"}>Tutor Level: {tutorModel.level}</Text>
            tutorLessonsText = <Text {...standardTextProps} textAlign={"left"} key={"tutorLessons"}><Link to={"/Admin/Lessons?showFilters=true&Tutor=" + tutorModel.id}>Tutor lessons: {userTypeLessons.tutor}</Link></Text>
            const tutorEducation = tutorModel.education || {};
            let school = tutorEducation.school
            let year = tutorEducation.schoolYear;
            if (tutorEducation.university != null) {
                school = tutorEducation.university;
            }
            if (tutorEducation.universityYear != null) {
                year = tutorEducation.universityYear;
            }
            schoolText = <Text {...standardTextProps} textAlign={"left"} key={"school"}>Studies at: {school} ({year})</Text>

            teachesSubjectsText = <Text {...standardTextProps} textAlign={"left"} key={"subjects"}>{tutorModel.subjectText}</Text>
            if (tutorModel.previousExperience != null) {
                previousExperienceText = <Text {...standardTextProps} textAlign={"left"} maxWidth={"60vw"} key={"previousExperience"}>Previous experience: {tutorModel.previousExperience}</Text>
            }
            if (tutorModel.otherJobs != null) {
                otherJobsText = <Text {...standardTextProps} textAlign={"left"} maxWidth={"60vw"} key={"otherJobs"}>Other jobs: {tutorModel.otherJobs}</Text>
            }
            if (tutorModel.referralCode != null) {
                referralCodeText = <Text {...standardTextProps} textAlign={"left"} maxWidth={"60vw"} key={"referralCode"}>Referral Code: {tutorModel.referralCode}</Text>
            }
            tutorCredit = tutorModel.credit || 0;
            totalCredit = totalCredit + parseFloat(tutorCredit, 10);
        }
        const extraTutorProps = [tutorPostcodeText, sessionsText, tutorLessonsText, teachesSubjectsText, tutorLevelText, schoolText, previousExperienceText, otherJobsText, referralCodeText];

        const extraParentProps = [];
        let parentsStudentsText = null;
        let parentLessonsText = null;
        if (userRoles.userIsParent) {
            const parentModel = userProps.parentModel;
            const parentsStudent = parentModel.students;
            const studentsText = [];
            for (const studentID of parentsStudent) {
                const student = studentDictionary[studentID];
                if (student == null) {
                    console.log("Student not found: " + studentID);
                    continue;
                }
                let lessonsText = <Text {...standardTextProps} alignSelf={"flex-start"} textAlign={"left"} key={"studentTextLessons:" + student.id}>
                    No lessons yet
                </Text>
                if (userTypeLessons.parentStudents[studentID] > 0) {
                    lessonsText = <Text {...standardTextProps} alignSelf={"flex-start"} textAlign={"left"}key={"studentTextLessons:" + student.id}>
                        <Link to={"/Admin/Lessons?showFilters=true&Student=" + studentID}>{userTypeLessons.parentStudents[studentID]} lessons</Link>
                    </Text>
                }

                studentsText.push(
                    <Flex alignItems={"center"} key={"studentText:" + student.id}>
                        <Text {...standardTextProps} alignSelf={"flex-start"} textAlign={"left"} marginLeft={"50px"}>
                            <Link 
                                to={"/Admin/ViewUser?id=" + student.id} 
                                key={"student:" + student.id}
                            >
                                {student.firstNames} {student.lastName}
                            </Link>
                        </Text>
                        -
                        {lessonsText}
                    </Flex>
                );
            }
            parentLessonsText = <Text {...standardTextProps} textAlign={"left"} key={"parentLessons"}><Link to={"/Admin/Lessons?showFilters=true&Parent=" + userID}>Parent lessons: {userTypeLessons.parent}</Link></Text>
            if (studentsText.length > 0) {
                parentsStudentsText = <Flex direction={"column"} gap={"0px"} key={"childrenFlex"}>
                    <Text {...standardTextProps} alignSelf={"flex-start"} key={"children"}>Children:</Text>
                    {studentsText}
                </Flex>
            }
            
            parentCredit = parentModel.lessonCredits || 0;
            totalCredit = totalCredit + parseFloat(parentCredit, 10);
            extraParentProps.push(parentLessonsText, parentsStudentsText);
        }

        const extraStudentProps = [];
        if (studentModel != null) {
            let studentCreditsText = null;
            let parentText = null;
            let parentEmailText = null;
            let yearGroupText = null;
            if (studentModel.availability != null) {
                const studentAvailability = studentModel.availability;
                const availabilityTextArray = getAvailabilityText(studentAvailability);
                availabilityText = <Flex direction={"column"} alignItems={"flex-start"} gap={"0vh"} key={"availabilityText"}>
                    <Text {...standardTextProps}>Default Availability:</Text>
                    <Flex direction={"column"} alignItems={"flex-start"} marginLeft={"50px"} gap={"0vh"} >
                        {availabilityTextArray}
                    </Flex>
                </Flex>
            }
            if (studentModel.yearGroup != null) {
                yearGroupText = <Text {...standardTextProps} textAlign={"left"} key={"yearGroup"}>{studentModel.yearGroup}</Text>
            }

            if (userRoles.userIsManagedStudent) {
                const parentModel = parentDictionary[studentModel.parentID];
                const parentUserModel = userDictionary[parentModel.id];
                const parentAttributes = parentUserModel.Attributes;
                let parentEmail = null;
                for (const attribute of parentAttributes) {
                    
                    if (attribute.Name == "email") {
                        parentEmail = attribute.Value;
                    }
                }
                parentText = <Link 
                    to={"/Admin/ViewUser?id=" + parentModel.id} 
                    key={"viewParentLink"}
                >
                    <Text {...standardTextProps} alignSelf={"flex-start"}>Parent: {parentModel.firstNames} {parentModel.lastName} ({parentModel.id})</Text>
                </Link>
                parentEmailText = <Text {...standardTextProps} alignSelf={"flex-start"} key={"parentEmail"}>Parent Email: {parentEmail}</Text>
            }
            else { 
                studentCredit = studentModel.lessonCredits || 0;
                totalCredit = totalCredit + parseFloat(studentCredit, 10);
            }
            extraStudentProps.push(yearGroupText, parentText, parentEmailText, studentCreditsText);
        }

        let creditText = null;
        if (!userRoles.userIsManagedStudent) {
            creditText =  <Flex key={"credit"} alignItems={"center"} gap={"7px"}>
                <Text 
                    {...standardTextProps} 
                    alignSelf={"flex-start"} 
                >
                    Credit:
                </Text>
                <Text 
                    {...standardTextProps} 
                    alignSelf={"flex-start"} 
                    onClick={() => {
                        setEditCredit(true);
                    }}
                    style={{cursor: "pointer"}}
                >
                    {priceValuetoString(totalCredit)}
                </Text>
                <Icon
                    width={"30px"}
                    height={"30px"}
                    as={MdModeEdit}
                    onClick={() => {
                        setEditCredit(true);
                    }}
                    style={{cursor: "pointer"}}
                    color={"#0000ff"}
                />
            </Flex>
        }

        setExistingCredit(totalCredit);
        setExistingModelCredit({
            tutor: tutorCredit,
            student: studentCredit,
            parent: parentCredit
        });
        const UIs = [];
        UIs.push(IDText, nameText, genderText, emailText, phoneNumberText, userGroupsText, creditText, ...extraParentProps, ...extraStudentProps, ...extraTutorProps, availabilityText, signUpWhereFromText, createdAtText, updatedAtText);
        return UIs;
    }

    //Get all users if they are not already loaded
    useEffect(() => {
        if (userDictionary == null) {
            props.APIFunctions.adminGetUsers();
        }
        else {
            //setUserProps(allUsers[userID]);
        }
    }, [userDictionary, APIFunctions]);

    //Set the query parameters
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParameters == null || queryParams.toString() != queryParameters.toString()) {
            setQueryParameters(queryParams);
        }
    }, [window.location.search, queryParameters]);

    //Set the user ID
    useEffect(() => {
        if (queryParameters == null) {
            return;
        }

        let newUserID = queryParameters.get("id");
        if (newUserID == null || newUserID == "") {
            setNoUser(true);
            return;
        }
        if (newUserID != null && userID != newUserID) {
            setUserProps(null);
            setUserRoles({...blankUserRoles});
            setUserID(newUserID);
        }
    }, [queryParameters, userID]);

    //Set the user props
    useEffect(() => {
        if (userDictionary == null || tutorDictionary == null || studentDictionary == null || parentDictionary == null || userID == null) {
            return;
        }

        let newUserProps = {
            id: userID
        };

        const user = userDictionary[userID];
        if (user != null) {
            newUserProps = {...newUserProps, ...user};
            const userAttributes = user.Attributes || user.attributes || [];
            for (const attribute of userAttributes) {
                const attributeName = attribute.Name || attribute.name;
                const attributeValue = attribute.Value || attribute.value;
                newUserProps[attributeName] = attributeValue;
            }
        }

        const userGroups = [];
        const tutorModel = tutorDictionary[userID];
        if (tutorModel != null) {
            newUserProps.userIsTutor = true;
            userGroups.push("Tutor");
            newUserProps.gender = tutorModel.gender;
            newUserProps.phoneNumber = tutorModel.phoneNumber;
            newUserProps.firstNames = tutorModel.firstNames;
            newUserProps.lastName = tutorModel.lastName;
            newUserProps.signUpWhereFrom = tutorModel.signUpWhereFrom;
            newUserProps.address = tutorModel.address;
            const tutorQualifications = tutorModel.qualifications || {};
            let subjectText = "Teaches: ";
            for (const [subjectName, subjectQualifications] of Object.entries(tutorQualifications)) {
                for (const [qualificationType, qualificationDetails] of Object.entries(subjectQualifications)) {
                    subjectText = subjectText + subjectName + " (" + qualificationType + ": " + qualificationDetails.result + "), ";
                }
            }
            tutorModel.subjectText = subjectText;
            newUserProps.tutorModel = tutorModel;
        }

        const studentModel = studentDictionary[userID];
        if (studentModel != null) {
            newUserProps.studentModel = studentModel;
            newUserProps.firstNames = studentModel.firstNames;
            newUserProps.lastName = studentModel.lastName;
            if (studentModel.parentID != null) {
                newUserProps.userIsManagedStudent = true;
                userGroups.push("Managed Student");
            }
            else {
                newUserProps.userIsStudent = true;
                userGroups.push("Student");
                newUserProps.phoneNumber = studentModel.phoneNumber;
                newUserProps.signUpWhereFrom = studentModel.signUpWhereFrom;
            }
        }

        const parentModel = parentDictionary[userID];
        if (parentModel != null) {
            newUserProps.parentModel = parentModel;
            newUserProps.userIsParent = true;
            userGroups.push("Parent");
            newUserProps.phoneNumber = parentModel.phoneNumber;
            newUserProps.firstNames = parentModel.firstNames;
            newUserProps.lastName = parentModel.lastName;
            newUserProps.signUpWhereFrom = parentModel.signUpWhereFrom;
        }

        newUserProps.groups = userGroups;

        setUserProps(newUserProps);
    }, [userDictionary, tutorDictionary, studentDictionary, parentDictionary, userID]);

    //Set the user type lessons
    useEffect(() => {
        if (lessons == null || userID == null || userRoles == null) {
            return;
        }

        let tutorLessons = 0;
        let studentLessons = 0;
        let parentLessons = 0;
        let parentStudentLessons = {};
        
        for (const lesson of lessons) {
            if (userRoles.userIsTutor && lesson.tutorID == userID) {
                tutorLessons = tutorLessons + 1;
            }
            if (userRoles.userIsStudent && lesson.studentID == userID) {
                studentLessons = studentLessons + 1;
            }
            if (userRoles.userIsParent && lesson.parentID == userID) {
                parentLessons = parentLessons + 1;
                parentStudentLessons[lesson.studentID] = parentStudentLessons[lesson.studentID] + 1 || 1;
            }
        }

        setUserTypeLessons({
            tutor: tutorLessons,
            student: studentLessons,
            parent: parentLessons,
            parentStudents: parentStudentLessons
        });
    }, [userID, lessons, userRoles]);

    //Set the user roles
    useEffect(() => {
        if (userProps == null) {
            return;
        }
        const newUserIsStudent = userProps.userIsStudent;
        const newUserIsParent = userProps.userIsParent;
        const newUserIsTutor = userProps.userIsTutor;
        const newUserIsManagedStudent = userProps.userIsManagedStudent;
        const newUserGroups = userProps.groups;

        setUserRoles({
            userIsTutor: newUserIsTutor,
            userIsStudent: newUserIsStudent,
            userIsParent: newUserIsParent,
            userIsManagedStudent: newUserIsManagedStudent,
            userGroups: newUserGroups
        });
    }, [userProps]);

    //Set the user UIs
    useEffect(() => {
        if (userProps == null || adminUsersInfo == null) {
            setUserUIs(<LoadingSpinnerPage {...props}/>);
        }
        else if (adminUsersInfo[userID] == null && !userRoles.userIsManagedStudent) {
            setUserUIs(<LoadingSpinnerPage {...props}/>);
        }
        else {
            //console.log("Loaded test");
            setUserUIs(getUIs());
        }
        setEditCredit(false);
    }, [userDictionary, adminUsersInfo, userProps, userID, userRoles, userTypeLessons]);

    const getUserActivity = async function () {
        const searchParams = {
            userID: userID,
            activityStartKey: activitySearchParams.activityStartKey != "loading" && activitySearchParams.activityStartKey || null,
            activitySearchLimit: activitySearchParams.activitySearchLimit,
            searchActivity: activitySearchParams.enableSearchByActivity && activitySearchParams.searchActivity || null
        };
        if (previousSearchParams.searchActivity != searchParams.searchActivity) {
            searchParams.activityStartKey = null;
        }
        setActivitySearchParams((prev) => {
            return {...prev, activityStartKey: "loading"};
        });
        console.log(searchParams);
        await APIFunctions.adminGetUserInfo(searchParams);
        setPreviousSearchParams(searchParams);
    }

    // Get the user's info if it is not already loaded
    useEffect(() => {
        if (userID == null) {
            return;
        }
        if (adminUsersInfo != null && adminUsersInfo[userID] != null) {
            if (adminUsersInfo[userID].activityExclusiveStartKey != null) {
                setActivitySearchParams((prev) => {
                    return {...prev, activityStartKey: adminUsersInfo[userID].activityExclusiveStartKey};
                });
            }
            return;
        }
        const getUserInfo = async function () {
            console.log("Getting user info for user: " + userID);
            const searchParams = {
                userID: userID
            };
            await APIFunctions.adminGetUserInfo(searchParams);
        }

        getUserInfo();
    }, [adminUsersInfo, APIFunctions, userID]);

    //Create the user info UI
    useEffect(() => {
        if (userID == null || adminUsersInfo == null) {
            return;
        }
        const userInfo = adminUsersInfo[userID];
        if (userInfo == null) {
            return;
        }

        let lastOnlineText = null;
        if (userInfo.lastOnline != null) {
            lastOnlineText = <Text {...standardTextProps} key={"LastOnline"}>Last online: {userInfo.lastOnline}</Text>
        }
        const userActivityTitle = <Text {...titleTextProps} key={"UserActivityTitle"}>User Activity</Text>

        if (activitySearchParams.activityStartKey == "loading") {
            setUserInfoUI(<Flex direction={"column"} marginTop={"3vh"} alignItems={"flex-start"} >
                {lastOnlineText}
                {userActivityTitle}
                {activitySearchUI}
            </Flex>);
            return;
        }

        let activityUI = null;
        let loadMoreActivities = <Flex
            className="HoverBackground"
            key={"LoadMoreActivities"}
            onClick={function () {
                if (activitySearchParams.activityStartKey != "loading") {
                    console.log("Load more activities");
                    getUserActivity();
                }
            }}
            
            border={"1px solid black"}
            borderRadius={"10px"}
            marginLeft={"30px"}
            paddingLeft={"5px"}
            paddingRight={"5px"}
            gap={"0px"}
        >
            {userInfo.activity != null && [<Text>
                Load more
            </Text>,
            <Icon
                
                alignItems={"center"}
                justifyContent={"center"}
                as={activitySearchParams.activityStartKey != "loading" && MdMoreHoriz || MdCached}
                width={"35px"}
                height={"25px"}
            />] || <Text>
                Get user activity
            </Text>
            }
        </Flex>

        if (userInfo.activity == null) {
            loadMoreActivities = null;
        }

        if (userInfo.activity != null && Array.isArray(userInfo.activity)) {
            //An array of objects, each object contains a session start time and an array of activities
            let previousSessionStart = null;
            //An array of objects, each object contains a date and an array of sessions which in turn contain an array of activities
            const dateSessionActivities = [];
            let previousDate = null;
            for (const activityObject of userInfo.activity) {
                if (activityObject.activity == "disconnected") {
                    continue;
                }   
                const sessionStart = activityObject.sessionStart;
                const individualActivityUI = <Text 
                    {...standardTextProps} 
                    fontSize={"17px"} 
                    textAlign={"left"} 
                    key={"SessionActivity:" + sessionStart + "/" + activityObject.time}
                >
                    {new Date(activityObject.time).toLocaleTimeString()}: {activityObject.activity}
                </Text>

                //Check if it's part of a new session
                if (sessionStart == previousSessionStart) {
                    //If it's the same as the previous session, add it to the current session
                    const currentDateSessions = dateSessionActivities[dateSessionActivities.length - 1].sessions;
                    dateSessionActivities[dateSessionActivities.length - 1].sessions[currentDateSessions.length - 1].activities.push(individualActivityUI);
                }
                else {
                    //If it's a new session, check if it's a session on the same day as the previous session
                    previousSessionStart = sessionStart;
                    const date = new Date(sessionStart).toDateString();
                    if (date == previousDate) {
                        //If it's a session on the same day, add it to the current session activities array
                        dateSessionActivities[dateSessionActivities.length - 1].sessions.push({
                            sessionStart: sessionStart,
                            activities: [individualActivityUI]
                        });
                    }
                    else {
                        //If it's a new session on a new day, push the previous days sessions to the date sessions array
                        dateSessionActivities.push({
                            date: date,
                            sessions: [{
                                sessionStart: sessionStart,
                                activities: [individualActivityUI]
                            }]
                            
                        });
                        previousDate = date;
                    }
                }
            }

            console.log(dateSessionActivities);
            if (dateSessionActivities != null && dateSessionActivities.length != 0) {
                const currentDateSessions = dateSessionActivities[dateSessionActivities.length - 1].sessions;
                dateSessionActivities[dateSessionActivities.length - 1].sessions[currentDateSessions.length - 1].activities.push(loadMoreActivities)
            }

            const dateUIs = [];
            for (let dateIndex = 0; dateIndex < dateSessionActivities.length; dateIndex = dateIndex + 1) {
                const dateSessionsObject = dateSessionActivities[dateIndex];
                const date = dateSessionsObject.date;
                const toggleDateActivity = function () {
                    setShownDatesSessions((prev) => {
                        const newShownDatesSessions = {...prev};
                        if (newShownDatesSessions[date] == true || newShownDatesSessions[date] == null) {
                            newShownDatesSessions[date] = false;
                        }
                        else {
                            newShownDatesSessions[date] = true;
                        }
                        return newShownDatesSessions;
                    });
                }

                const dateUI = <Text 
                    {...standardTextProps} 
                    fontSize={"19px"}
                    key={"Date:" + date}
                    onClick={toggleDateActivity}
                    style={{cursor: "pointer"}}
                >
                    <u>{date}</u>
                </Text>

                const dateSessions = dateSessionsObject.sessions;
                const sessionUIs = [];
                for (const sessionActivityObject of dateSessions) {
                    const sessionStart = sessionActivityObject.sessionStart;
                    const toggleSessionActivity = function () {
                        setShownDatesSessions((prev) => {
                            const newShownDatesSessions = {...prev};
                            if (newShownDatesSessions[sessionStart] == true || newShownDatesSessions[sessionStart] == null) {
                                newShownDatesSessions[sessionStart] = false;
                            }
                            else {
                                newShownDatesSessions[sessionStart] = true;
                            }
                            return newShownDatesSessions;
                        });
                    }
                    const sessionStartUI = <Text 
                        {...standardTextProps} 
                        fontSize={"19px"}
                        key={"SessionStart:" + sessionStart}
                        onClick={toggleSessionActivity}
                        style={{cursor: "pointer"}}
                    >
                        <u>Session start: {new Date(sessionStart).toLocaleTimeString()}</u>
                    </Text>
                    const sessionActivities = sessionActivityObject.activities.reverse();
                    let arrowIcon = MdArrowRight;
                    if (shownDatesSessions[sessionStart] != false) {
                        arrowIcon = MdArrowDropDown;
                    }
                    const sessionActivitiesUI = <Flex 
                        direction={"column"} 
                        gap={"0px"} 
                        alignItems={"flex-start"} 
                        key={"SessionActivities:" + sessionStart}
                    >
                        <Flex
                            direction={"column"} 
                            gap={"5px"} 
                            alignItems={"flex-start"} 
                            padding={"10px"}
                            style={{
                                border:"3px solid red", 
                                borderRadius: "10px"
                            }}
                        >
                            <Flex>
                                {sessionStartUI}
                                <Icon 
                                    as={arrowIcon}
                                    width={"30px"}
                                    height={"30px"}
                                    className="HoverBackground"
                                    borderRadius={"10px"}
                                    border={"1px solid black"}
                                    onClick={toggleSessionActivity}
                                />
                            </Flex>
                            {shownDatesSessions[sessionStart] != false && sessionActivities}
                        </Flex> 
                    </Flex>
                    sessionUIs.push(sessionActivitiesUI);
                }

                let arrowIcon = MdArrowRight;
                if (shownDatesSessions[date] != false) {
                    arrowIcon = MdArrowDropDown;
                }
                const daySessionsUI = <Flex 
                    direction={"column"} 
                    gap={"5px"} 
                    alignItems={"flex-start"} 
                    key={"DateSessions:" + date}
                >
                    <Flex 
                        direction={"column"} 
                        gap={"5px"} 
                        alignItems={"flex-start"} 
                        padding={"10px"} 
                        style={{
                            border:"3px solid blue", 
                            borderRadius: "10px"
                        }}
                    >
                        <Flex>
                            {dateUI}
                            <Icon 
                                as={arrowIcon}
                                width={"30px"}
                                height={"30px"}
                                className="HoverBackground"
                                borderRadius={"10px"}
                                border={"1px solid black"}
                                onClick={toggleDateActivity}
                            />
                        </Flex>
                        {shownDatesSessions[date] != false && sessionUIs}
                        {dateIndex == dateSessionActivities.length - 1 && loadMoreActivities}
                    </Flex>
                </Flex>
                dateUIs.push(daySessionsUI);
            }
            
            //console.log(sessionActivityList);
            //console.log(sessionActivityList.reverse());
            activityUI = <Flex 
                direction={"column"} 
                gap={"5px"} 
                alignItems={"flex-start"} 
                key={"User activity"}
            >
                <Flex 
                    direction={"column"} 
                    alignItems={"flex-start"}
                    //maxHeight={"40vh"}
                    gap={"5px"}
                >
                    {dateUIs}
                </Flex>
            </Flex>
        }

        setUserInfoUI(<Flex direction={"column"} marginTop={"3vh"} alignItems={"flex-start"} >
            {lastOnlineText}
            {userActivityTitle}
            {activitySearchUI}
            {activityUI}
            {loadMoreActivities}
        </Flex>);
    }, [userID, adminUsersInfo, shownDatesSessions, activitySearchParams, activitySearchUI]);

    //User activity search
    useEffect(() => {
        const handleSearchChange = function (questionID, value) {
            setActivitySearchParams((prev) => {
                const newSearchParams = {...prev};
                if (questionID == "activitySearchLimit") {
                    if (value == "" || value == null) {
                        value = 10;
                    }
                    value = parseInt(value);
                }
                else if (questionID == "searchActivity") {
                    if (value == "" || value == null) {
                        value = "/";
                    }
                }
                console.log(questionID + ": " + value);
                newSearchParams[questionID] = value;
                return newSearchParams;
            });
        }

        const activitySearchLimitUI = <DropDownQuestionTemplate
            id={"activitySearchLimit"}
            options={[20, 50, 100]}
            value={activitySearchParams.activitySearchLimit}
            defaultValue={10}
            placeHolder={10}
            handleChange={handleSearchChange}
            size="small"
        />

        const toggleSearchByActivityUI = <CheckboxField
            checked={activitySearchParams.enableSearchByActivity}
            //placeHolder={"Search page"}
            defaultValue={{"Search by activity": false}}
            onChange={(event) => {
                const currentValue = event.target.checked;
                handleSearchChange("enableSearchByActivity", currentValue);
            }}
            options={["Search by activty"]}
            size="large"
        />

        const searchActivityUI = <TextFieldTemplate
            id={"searchActivity"}
            value={activitySearchParams.searchActivity}
            placeHolder={"Search activity"}
            defaultValue={""}
            handleChange={handleSearchChange}
        />

        const searchButton = <Button
            className="HoverBackground"
            width={"30px"}
            height={"30px"}
            border={"1px solid black"}
            borderRadius={"10px"}
            onClick={getUserActivity}
        >
            <Icon 
                as={MdSearch}
            />
        </Button>

        setActivitySearchUI(<Flex 
            //gap={"px"}
            justifyContent={"center"} 
            alignItems={"center"}
            //backgroundColor={"#bbb"}
        >
            <Flex 
            // marginTop={"-8px"}
            >
                <Flex 
                    //marginTop={"8px"} 
                    gap={"5px"} 
                    className={"HoverBackground"}
                    style={{
                        "--backgroundColour": "#5fd8ed",
                        "--hoverBackgroundColour": "#3c9dde",
                    }}
                    borderRadius={"10px"}
                    border={"1px solid black"}
                    padding={"5px"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    onClick={() => {
                        setActivitySearchParams((prev) => {
                            const newSearchParams = {...prev};
                            if (newSearchParams.enableSearchByActivity == null) {
                                newSearchParams.enableSearchByActivity = false;
                            }
                            else {
                                newSearchParams.enableSearchByActivity = !newSearchParams.enableSearchByActivity;
                            }
                            return newSearchParams;
                        });
                    }}
                >
                    <Text >Search by activity</Text>
                    {toggleSearchByActivityUI}
                </Flex>
                {activitySearchParams.enableSearchByActivity && searchActivityUI}
            </Flex>
            <Flex
            >
                {activitySearchLimitUI}
            </Flex>
            {searchButton}
        </Flex>)
    }, [activitySearchParams, previousSearchParams]);

    // console.log(activitySearchParams);

    if (noUser) {
        return <Navigate to={"/Admin/ViewUsers"} />
    }
    
    if (userProps == null) {
        return <LoadingSpinnerPage {...props}/>
    }
    
    const titleText = <Text {...titleTextProps}>View User</Text>
    const backButton = <BackButton {...props} defaultBack={"/Admin/ViewUsers"} />

    const disableUser = function () {
        console.log("Disabling user: " + userID);
    }  

    const diableUserButton = <AppNavButton onClickFunction={disableUser} text={"Disable user account"} width={props.width}/>

    // let embeddedMap = null;
    // const width = props.width;
    // let mapWidth = 40*width/100
    // let mapColumn = 2;
    // if (width < 1200) {
    //     mapWidth = 80*width/100
    //     mapColumn = 1;
    // }
    // if (lessonProps.lessonType != "Online") {
    //     embeddedMap = <MapTemplate
    //         {...props}
    //         type={"directions"} 
    //         origin={lessonProps.tutorPostcode} 
    //         destination={lessonProps.address.postcode} 
    //         width={mapWidth}
    //         height={"400px"}
    //         distanceUnit={"miles"}
    //         mode={"walking"}
    //         key={"Map" + mapReload}
    //     />
    // }

    const submitCreditEdit = async function () {
        try {
            if (!newCreditSubmitted) {
                setNewCreditSubmitted(true);
                const creditDifference = newCreditValue - existingCredit;

                if ((userRoles.userGroups.length == 1 && userRoles.userGroups[0] == "Tutor") || creditUserType == "Tutor") {
                    const existingTutorCredit = existingModelCredit.tutor || 0;
                    const newTutorCredit = parseFloat(existingTutorCredit, 10) + parseFloat(creditDifference, 10);
                    const newModel = {
                        updateCredit: {
                            newCredit: newTutorCredit,
                            existingCredit: existingTutorCredit,
                            sendEmail: notifyUserCreditChange,
                            notes: creditChangeNotes
                        }
                    };
                    await APIFunctions.updateTutorModel(newModel, "admin", userID);
                }
                else if ((userRoles.userGroups.length == 1 && userRoles.userGroups[0] == "Parent") || creditUserType == "Parent") {
                    const existingParentCredit = existingModelCredit.parent || 0;
                    const newParentCredit = parseFloat(existingParentCredit, 10) + parseFloat(creditDifference, 10);
                    const newModel = {
                        updateCredit: {
                            newCredit: newParentCredit,
                            existingCredit: existingParentCredit,
                            sendEmail: notifyUserCreditChange,
                            notes: creditChangeNotes
                        }
                    };
                    await APIFunctions.updateParentModel(newModel, "admin", userID);
                }
                else if ((userRoles.userGroups.length == 1 && userRoles.userGroups[0] == "Student") || creditUserType == "Student") {
                    const existingStudentCredit = existingModelCredit.student || 0;
                    const newStudentCredit = parseFloat(existingStudentCredit, 10) + parseFloat(creditDifference, 10);
                    const newModel = {
                        updateCredit: {
                            newCredit: newStudentCredit,
                            existingCredit: existingStudentCredit,
                            sendEmail: notifyUserCreditChange,
                            notes: creditChangeNotes
                        }
                    };
                    await APIFunctions.updateStudentModel(newModel, "admin", userID);
                }
                else {
                    console.log("Invalid user type");
                }
                setEditCredit(false);
                setNewCreditSubmitted(false);
            }
        }
        catch (error) {
            console.log("Error updating credit: " + error);
        }
    };

    let editCreditFlex = null;
    if (editCredit) {
        let creditValue = newCreditValue;
        if (newCreditValue == null) {
            let validExistingCredit = priceValuetoString(existingCredit);
            validExistingCredit = validExistingCredit.split("£").join("")
            setNewCreditValue(validExistingCredit);
            creditValue = validExistingCredit;
        }
        const newCreditTextQuestion = <TextField
            id={"newCreditText"}
            required={true}
            value={"£" + newCreditValue}
            width={"150px"}
            onChange={(e) => {
                let newValue = e.target.value
                newValue = newValue.split("£").join("");
                const validValue = isValidPrice(newValue);
                if (validValue == true && newValue >= 0) {
                    setNewCreditValue(newValue);
                }
                else if (newValue == "") {
                    setNewCreditValue("");
                }
            }}
            onBlur={() => {
                let properString = priceValuetoString(newCreditValue);
                if (newCreditValue == "") {
                    properString = "0.00";
                }
                setNewCreditValue(properString.split("£").join(""));
            }}
        />

        let relativeCreditValue = relativeCredit;
        if (relativeCredit == null) {
            let defaultRelativeCredit = priceValuetoString(12.5);
            defaultRelativeCredit = defaultRelativeCredit.split("£").join("")
            setRelativeCredit(defaultRelativeCredit);
            relativeCreditValue = defaultRelativeCredit;
        }

        const relativeCreditText = <TextField
            id={"relativeCreditText"}
            value={"£" + relativeCreditValue}
            width={"139px"}
            onChange={(e) => {
                let newValue = e.target.value
                newValue = newValue.split("£").join("");
                const validValue = isValidPrice(newValue);
                if (validValue == true && newValue >= 0) {
                    setRelativeCredit(newValue);
                }
                else if (newValue == "") {
                    setRelativeCredit("");
                }
            }}
            onBlur={() => {
                let properString = priceValuetoString(relativeCredit);
                if (relativeCredit == "") {
                    properString = "0.00";
                }
                setRelativeCredit(properString.split("£").join(""));
            }}
        />

        const plusButton = <Button
            width={"35px"}
            height={"25px"}
            onClick={() => {
                const newValue = parseFloat(newCreditValue, 10) + parseFloat(relativeCredit, 10);
                let newValidValue = priceValuetoString(newValue);
                newValidValue = newValidValue.split("£").join("")
                setNewCreditValue(newValidValue);
            }}
            alignItems={"center"}
            justifyContent={"center"}
            direction={"column"}
            position={"absolute"}
            right={"-50px"}
            top={"-3px"}
        >
            <Text
                fontSize={"20px"}
                textAlign={"center"}
                marginTop={"-5px"}
            >
                +
            </Text>
        </Button>

        const minusButton = <Button
            width={"35px"}
            height={"25px"}
            onClick={() => {
                const newValue = parseFloat(newCreditValue, 10) - parseFloat(relativeCredit, 10);
                let newValidValue = priceValuetoString(newValue);
                newValidValue = newValidValue.split("£").join("")
                setNewCreditValue(newValidValue);
            }}
            alignItems={"center"}
            justifyContent={"center"}
            direction={"column"}
            position={"absolute"}
            right={"-50px"}
            bottom={"-8px"}
        >
            <Text
                fontSize={"20px"}
                textAlign={"center"}
                marginTop={"-5px"}
            >
                -
            </Text>
        </Button>

        const resetButton = <Button
            width={"40px"}
            height={"30px"}
            onClick={() => {
                let newValidValue = priceValuetoString(existingCredit);
                newValidValue = newValidValue.split("£").join("")
                setNewCreditValue(newValidValue);
            }}
            alignItems={"center"}
            justifyContent={"center"}
            direction={"column"}
            position={"absolute"}
            right={"-50px"}
            top={"13px"}
        >
            <Icon
                key={"refreshIcon"}
                width={"25px"}
                height={"25px"}
                as={MdCached}
            />
        </Button>

        const originalCreditText = <Text {...standardTextProps} fontSize={"14px"}>(Current credit: {priceValuetoString(existingCredit)})</Text>

        const newCreditTitle = <Text {...standardTextProps} fontSize={"18px"}>New credit:</Text>

        const popUpTitle = <Text {...standardTextProps} fontSize={"23px"}>Edit user credit:</Text>

        const relativeCreditTitle = <Text 
            {...standardTextProps} 
            fontSize={"18px"} 
        >
            Adjust relatively:
        </Text>

        const sendEmailCheckBox = <CheckboxField 
            // checked={notifyUserCreditChange} 
            label={<Text {...standardTextProps} fontSize={"15px"}>Notify user by email</Text>}
            size={"medium"}
            onChange={(e) => {
                setNotifyUserCreditChange(e.target.checked);
            }}
        />

        const notesText = <TextAreaField
            label={<Text {...standardTextProps} fontSize={"15px"}>Notes:</Text>}
            onChange={(e) => {
                setCreditChangeNotes(e.target.value);
            }}
        />
        
        const creditDifference = newCreditValue - existingCredit;
        let creditDifferenceString = priceValuetoString(creditDifference);
        if (creditDifference >= 0) {
            creditDifferenceString = "+" + creditDifferenceString;
        }
        const changeText = <Text {...standardTextProps} fontSize={"15px"}>({creditDifferenceString})</Text>

        let userTypeSelector = null;

        if (userRoles.userGroups.length > 1) {
            userTypeSelector = <Flex direction={"column"} gap={"0px"}>
                <Text {...standardTextProps} fontSize={"15px"} maxWidth={"200px"}>User is registered for multiple roles, which would you like to update?</Text>
                <RadioButtonsTemplate
                    options={userRoles.userGroups}
                    handleChange={(questionID, input) => {
                        setCreditUserType(input);
                    }}
                />
            </Flex>
        }

        const editCreditForm = <Flex direction={"column"} gap={"2vh"} marginTop={"-60px"} alignItems={"center"}>
            {popUpTitle}
                <Flex direction={"column"} gap={"0px"} position={"relative"}>
                    {relativeCreditTitle}
                    <Flex position={"relative"}>
                        {relativeCreditText}
                        {plusButton}
                        {minusButton}
                    </Flex>
                </Flex>
            <Flex direction={"column"} gap={"0vh"}> 
                {newCreditTitle}
                {changeText}
                <Flex alignItems={"center"} position={"relative"}>
                    {newCreditTextQuestion}
                    {resetButton}
                </Flex>
            </Flex>
            {originalCreditText}
            {sendEmailCheckBox}
            {notesText}
            {userTypeSelector}
        </Flex>

        editCreditFlex = <PopUpTemplate 
            setPopUpVisibility={setEditCredit} 
            showXButton={true}
            form={editCreditForm}
            customButtons={[{
                text: "Submit new user credit",
                onClickFunction: submitCreditEdit,
                isDisabled: ((userRoles.userGroups.length > 1 && (creditUserType == "" || creditUserType == null)) || creditDifference == 0 || newCreditSubmitted)
            }]}
            top={"15vh"}
        />
    }
    else {
        if (newCreditValue != null) {
            setNewCreditValue(null);
        }
        if (relativeCredit != null) {
            setRelativeCredit(null);
        }
        if (creditChangeNotes != null) {
            setCreditChangeNotes(null);
        }
        if (notifyUserCreditChange != false) {
            setNotifyUserCreditChange(false);
        }
        if (creditUserType != "") {
            setCreditUserType("");
        }
    }

    return (
        <Flex direction={"column"} gap={"4vh"} alignItems={"center"}> 
            <Flex direction={"column"} gap={"4vh"} alignItems={"center"}>
                {titleText}
                <Flex gap={"4vw"}>
                    {backButton}
                </Flex>
            </Flex>
            <Grid alignItems={"flex-start"} columnGap={"2vw"} rowGap={"2vh"}>
                <Flex direction={"column"} gap={"1vh"} alignItems={"left"} column={1}>                
                    {userUIs}
                    <Flex alignSelf={"center"}>{diableUserButton}</Flex>
                    {userInfoUI}
                </Flex>
                {/* <Flex column={mapColumn}>
                    {embeddedMap}
                </Flex> */}
            </Grid>
            {editCreditFlex}
        </Flex>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default ViewUser;