import { Flex, Text } from '@aws-amplify/ui-react';
import React from 'react';
import { LoadingSpinnerPage } from '../LoadingPage';
import { ErrorLoadingPage } from '../ErrorLoadingPage';
import AmendmentTemplate from '../../custom-ui-components/AmendmentTemplate';

const RespondToAmendments = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    //Get the current user to pass to subsequent functions
    const lessons = props.lessons;

    if (lessons == null || lessons == "loading") {
        return <LoadingSpinnerPage {...props} />
    }
    else if (lessons == "error") {
        return <ErrorLoadingPage {...props} />
    }

    const userType = props.userType;

    const amendmentRequests = [];

    let numRequestedAmendments = 0;
    for (const lesson of lessons) {
        const lessonAmendments = lesson.amendments;
        for (const amendment of lessonAmendments) {
            if (amendment.confirmed != true && amendment.rejectedBy == null) {
                const requestedBy = amendment.requestedBy;
                if (requestedBy.userType != userType) {
                    numRequestedAmendments = numRequestedAmendments + 1;
                    const amendmentFlex = <AmendmentTemplate 
                        key={"AmendmentFlex:" + numRequestedAmendments} 
                        amendment={amendment} 
                        lessonProps={lesson} 
                        showLessonDetails={true}
                        userType={userType}
                    />
                    amendmentRequests.push(amendmentFlex);
                }
            }
        }
    }

    let numRequestsString = "You have no new lesson amendment requests to respond to";
    if (numRequestedAmendments > 0) {
        let plural = "request";
        if (numRequestedAmendments > 1) {
            plural = "requests";
        }
        numRequestsString = "You have " + numRequestedAmendments + " new lesson amendment " + plural + " to respond to";
    }
    const numRequestsText = <Text {...standardTextProps}>{numRequestsString}</Text>


    const titleText = <Text {...titleTextProps}>Respond to Lesson Amendment Requests</Text>
    
    return (
        <Flex direction={"column"} alignItems={"center"}>
            {titleText}
            {numRequestsText}
            {amendmentRequests}
        </Flex>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default RespondToAmendments;