import { Flex } from "@aws-amplify/ui-react";
import React from "react";
import {Outlet, Navigate} from "react-router-dom";
import Navbar from "../home/HomeNavbar";
import { LoadingSpinnerPage } from "../LoadingPage";
import FooterTemplate from "../../custom-ui-components/FooterTemplate";


const ParentLayout = function(props) {
  const parentModel = props.parentModel;
  const height = props.height;
  const user = props.user;
  const sideMarginSize = props.sideMarginSize;

  const userIsParent = props.userIsParent;

  let pageContent = <LoadingSpinnerPage {...props} />
  if (userIsParent) {
    pageContent = <Outlet />
  }
  else if (userIsParent != null) {
    return <Navigate to={"/"} />
  }

  if (parentModel === null) {
    return (
      <div className="Page" style={{minHeight:height}}>
        <Navbar {...props} pageType={"parent"}/>
        <LoadingSpinnerPage {...props} height={height} />
      </div>
    )
  } 

  return (
    <div className="Page" style={{minHeight:height}}>
      <Flex direction={"column"} marginLeft={sideMarginSize} marginRight={sideMarginSize} alignItems={"center"}>
        <Flex style={{zIndex:2}} marginBottom={"50px"}>
          <Navbar {...props} />
        </Flex>
        <Flex style={{zIndex:1}}>
          {pageContent}
        </Flex>
        <br />
        <FooterTemplate {...props}/>
      </Flex>
    </div>
  );
}

export default ParentLayout;