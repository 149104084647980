import {React, useEffect, useState } from 'react';
import { Flex, Text } from '@aws-amplify/ui-react';
import { FormTemplate } from '../../custom-ui-components';
import { Navigate } from 'react-router-dom';
import { posterLocations, studentLeafletLocations, throwError, whereFromOptions } from '../../App';

const RegisterAsParent = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    const APIFunctions = props.APIFunctions;
    const user = props.user;
    const userIsParent = props.userIsParent;
    const userIsStudent = props.userIsStudent;

    const [questionAnswers, setQuestionAnswers] = useState({});
    const [redirect, setRedirect] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (!submitted && (userIsStudent || userIsParent)) {
            setRedirect(<Navigate to={"/"}/>);
        }
    }, [userIsParent, userIsStudent]);

    const submitAnswers = async function(returnedAnswers) {
        setQuestionAnswers(returnedAnswers);

        const parentAttributes = {...returnedAnswers};
        parentAttributes.address = {
            postcode: parentAttributes.address
        }

        try {
            setSubmitted(true);
            const newParentModel = await APIFunctions.createParentModel(parentAttributes);
            console.log("Successfully registered as a parent");
            console.log(newParentModel);
        }
        catch (error) {
            setSubmitted(false);
            throwError(null, error);
        }
    }

    const exitForm = function () {
        let fromLocation = props.fromLocation;
        if (fromLocation == null) {
            const queryParams = new URLSearchParams(window.location.search);
            fromLocation = queryParams.get("from");
        }

        if (fromLocation != null) {
            const state = {
                from: fromLocation,
                userType: "parent"
            };
            setRedirect(<Navigate to={"/Parent/StartGuide?from=" + fromLocation} state={state} />);
            //setRedirect(<Navigate to={"/"} />);
        }
        else {
            setRedirect(<Navigate to={"/Parent/StartGuide"} />);
        }
    }


    const titleText = <Text {...titleTextProps}  >
        Register to be a parent
    </Text>
    
    const registerAsParentForm = function () {
        const questions = [];
    
        questions.push({
            id: "firstNames", 
            label: "First Name(s):", 
            type: "text", 
            required: true, 
            validations: [{type: "Name"}], 
            defaultValue: null, 
            placeholder: "Enter name"
        });

        questions.push({
            id: "lastName", 
            label: "Surname:", 
            type: "text", 
            required: true, 
            validations: [{type: "Name"}], 
            defaultValue: null, 
            placeholder: "Enter name"
        });
    
        questions.push({
            id: "phoneNumber", 
            label: "Contact Number:", 
            type: "text", 
            required: true, 
            validations: [{type: "UKMobPhone"}], 
            defaultValue: null, 
            placeholder: "Enter phone number"
        });

        questions.push({
            id: "address", 
            label: "Postcode:", 
            type: "text", 
            required: true, 
            validations: [{type: "ValidPostcode"}], 
            defaultValue: null, 
            placeholder: "Enter postcode",
            description: "This is the postcode used for calculating your distance from tutors."
        });

        questions.push({
            id: "whereFrom", 
            label: "Where did you hear about us?", 
            type: "dropdown", 
            required: true, 
            defaultValue: null, 
            options: whereFromOptions
        });

        questions.push({
            id: "whereFromPoster", 
            label: "Where was the poster located?", 
            type: "autocomplete", 
            required: true, 
            defaultValue: null, 
            options: posterLocations,
            onlyShow: {id: "whereFrom", answers: ["Our posters"]}
        });

        questions.push({
            id: "whereFromLeaflet", 
            label: "Where was the leaflet from?", 
            type: "autocomplete", 
            required: true, 
            defaultValue: null, 
            options: studentLeafletLocations,
            onlyShow: {id: "whereFrom", answers: ["Our leaflets"]}
        });

        questions.push({
            id: "whereFromOther", 
            label: "More details on how you heard about us:",
            type: "text", 
            required: false, 
            defaultValue: null, 
            onlyShow: {id: "whereFrom", answers: ["Other"]}
        });

        questions.push({
            id: "checks",
            label: "Terms of use",
            type: "checkbox", 
            required: true, 
            defaultValue: null, 
            options: ["I confirm I am aged 13 or older",
                "I have read and accept the Next Door Tutor Terms of Service and Privacy Policy",
                "I agree to only schedule sessions and/or complete financial transactions with my tutors via Next Door Tutor Ltd"
            ],
            optionLinks: {
                1: [
                    {
                        startCharIndex: 43,
                        endCharIndex: 58,
                        link: "https://nextdoortutor.co.uk/TermsOfService"
                    },
                    {
                        startCharIndex: 64,
                        endCharIndex: 77,
                        link: "https://nextdoortutor.co.uk/PrivacyPolicy"
                    }
                ]
            },
            validations: [{type: "AllChecked"}], 
        });
    
        const registerAsParentForm = <FormTemplate {...props} submitAnswers={submitAnswers} existingAnswers={questionAnswers} questions={questions} okButtonFunction={exitForm} />
    
        return registerAsParentForm;
    }

    return (
        <div className='RegisterAsParentForm'>
            <Flex 
                direction={"column"}
                alignItems={"center"}
            >   
                {titleText}
                {registerAsParentForm()}
                {redirect}
            </Flex>
        </div>    
    );
}

export default RegisterAsParent;