import { React, useState, useEffect }  from "react";
import {Outlet, Navigate} from "react-router-dom";
import TutorNavbar from "./TutorNavbar";
import { getNeededTutorAttributes, getTutorModelAPI } from '../../App';
import { Flex, Loader } from "@aws-amplify/ui-react";
import {LoadingSpinner, LoadingSpinnerPage} from "../LoadingPage";
import Navbar from "../home/HomeNavbar";
import { ErrorLoadingPage } from "../ErrorLoadingPage";
import FooterTemplate from "../../custom-ui-components/FooterTemplate";



const TutorLayout = function(props) {
  const tutorModel = props.tutorModel;

  const height = props.height;
  const user = props.user;
  const userAttributes = user.attributes;
  const sideMarginSize = props.sideMarginSize;

  const userIsTutor = props.userIsTutor;

  let pageContent = <LoadingSpinnerPage {...props} />
  if (userIsTutor) {
    pageContent = <Outlet />
  }
  else if (userIsTutor != null) {
    return <Navigate to={"/"} />
  }

  if (tutorModel === null || tutorModel == "loading") {
    return (
      <div className="Page" style={{minHeight:height}}>
        <Navbar {...props} pageType={"tutor"}/>
        <LoadingSpinnerPage {...props} height={height} />
      </div>
    )
  }
  else if (tutorModel == "error") {
    return (
      <div className="Page" style={{minHeight:height}}>
        <Navbar {...props} pageType={"tutor"}/>
        <ErrorLoadingPage {...props}/>
      </div>
    )
  }

  const tutorNeededAttributes = getNeededTutorAttributes(tutorModel);

  if (tutorNeededAttributes.length !== 0) {
    return <Navigate to="/AddTutorDetails" state={{tutorNeededAttributes}} />
  }


  return (
    <div className="Page" style={{minHeight:height}}>
    {/* <TutorNavbar /> */}
      <Flex 
        direction={"column"} 
        marginLeft={sideMarginSize} 
        marginRight={sideMarginSize} 
        alignItems={"center"}
      >
        <Flex style={{zIndex:2}} marginBottom={"50px"}>
          <Navbar {...props} pageType={"tutor"}/>
        </Flex>
        <Flex style={{zIndex:1}}>
          {pageContent}
        </Flex>
        <br />
        <FooterTemplate {...props}/>
      </Flex>
    </div>
  );
}

export default TutorLayout;
