import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, RadioGroupField, Text, TextAreaField } from "@aws-amplify/ui-react";
import { useState, useEffect } from 'react';
import { validateField } from "./FormValidation";
import OptionsTemplate from "./OptionsTemplate";

const RadioThenDetailsTemplate = function(props) {
  try {
    const labelTextProps = props.questionLabelProps || {};
    const errorTextProps = props.questionErrorProps || {};

    //Set question variables from passed properties
    const questionID = props.id;
    const questionLabel = props.label;
    const questionDescription = props.description;
    const required = props.required;
    const requiredAsterisk = props.requiredAsterisk;
    let options = props.options
    if (options == null) {
      options = ["Yes", "No"];
    }
    let extraOption = props.extraOption;
    if (extraOption == null) {
      extraOption = "Yes";
    }
    let extraDetailsText = props.extraDetailsText;
    if (extraDetailsText == null) {
      extraDetailsText = "Tell us more";
    }
    const defaultValue = props.defaultValue;
    const questionValidations = props.validations;
    const radioValidations = [];
    const answersSuccessfullySubmitted = props.answersSuccessfullySubmitted;

    //Set state variables used to store the current state and any errors
    const [selectedAnswer, setSelectedAnswer] = useState(defaultValue);
    const [questionAnswer, setQuestionAnswer] = useState(defaultValue);
    const [errors, setErrors] = useState({questionError:""});

    useEffect(() => {
      const validationResult = validateField(selectedAnswer, questionValidations);
      const questionHasError = validationResult.hasError;
      props.handleChange(questionID, questionAnswer, questionHasError);
    }, []);

    let labelColour = labelTextProps.color || "#000000";
    if (props.validationError != null && props.validationError != null) {
      labelColour = props.validationErrorColour || "#ff0000";
    }
    //Get the text for the question label
    const labelText = <Text {...labelTextProps} color={labelColour}>
      {questionLabel}
    </Text>

    const descriptionText = <Text {...labelTextProps} fontSize={"14px"} fontStyle={"italic"}>
      {questionDescription}
    </Text>
    
    const errorText = <Text {...errorTextProps}>
      {errors.questionError}
    </Text>

    const extraDetailsTextObject = <Text {...labelTextProps}>
      {extraDetailsText}
    </Text>

    let renderedRequiredAsterisk = null;
    if (required == true) {
      renderedRequiredAsterisk = requiredAsterisk;
    }

    //Ensure that selectedAnswer has an ASCII value to display
    if (selectedAnswer == null) {
      setSelectedAnswer("");
    }
    
    if (errors.errorMessage === null) {
      setErrors({
        questionError:""
      });
    }
    
    const localHandleChange = function (input, answer) {
      if (input == "radio") {
        const validationResult = validateField(answer, questionValidations);
        const questionHasError = validationResult.hasError;
        const questionErrorMessage = validationResult.errorMessage;
        setErrors({
          questionHasError:questionHasError, 
          questionError:questionErrorMessage
        });
        props.handleChange(questionID, answer, questionHasError);
      }
      else {
        if (((answer == "" || answer == null))) {
          console.log(selectedAnswer);
          const validationResult = validateField(selectedAnswer, questionValidations);
          const questionHasError = validationResult.hasError;
          const questionErrorMessage = validationResult.errorMessage;
          setErrors({
            questionHasError:questionHasError, 
            questionError:questionErrorMessage
          });
          props.handleChange(questionID, selectedAnswer, questionHasError);
        }
        else {
          const validationResult = validateField(answer, questionValidations);
          const questionHasError = validationResult.hasError;
          const questionErrorMessage = validationResult.errorMessage;
          setErrors({
            questionHasError:questionHasError, 
            questionError:questionErrorMessage
          });
          props.handleChange(questionID, answer, questionHasError);
        }
      }
    }

    const submitChange = function (option) {
      if (selectedAnswer === option) {
        setSelectedAnswer(null);
        localHandleChange("radio", null);
      }
    }

    const optionComponents = OptionsTemplate(options, "radio", submitChange);

    //Generate the radio button field component
    const radioGroupField = <RadioGroupField
      placeholder={"-Select an option-"}
      value={selectedAnswer}
      size={"small"}
      isDisabled={answersSuccessfullySubmitted}

      //Runs when it detects a change in the input field
      onChange={(e) => {
        const currentValue = e.target.value;
        setSelectedAnswer(currentValue);
        localHandleChange("radio", currentValue);
      }} 
    >
    {optionComponents}
  </RadioGroupField>

    let extraDetailsField = <TextAreaField
        placeholder={extraDetailsText}
        value={questionAnswer}
        size={"medium"}
        width={"90%"}
        isDisabled={answersSuccessfullySubmitted}

        //Runs when it detects a change in the input field
        onChange={(e) => {
          const currentValue = e.target.value;
          setQuestionAnswer(currentValue);
          localHandleChange("text", currentValue);
        }}
      />

    if (selectedAnswer !== extraOption) {
      extraDetailsField = null;
      if (questionAnswer != defaultValue && questionAnswer != selectedAnswer) {
        setQuestionAnswer(defaultValue);
      }
    }

    //Return text field component to calling function (Most likely a Form Template)
    return (
      selectedAnswer,
      <div className="RadioGroupField">
          <Flex direction={"column"}>
            <Flex alignItems={"center"} >
              {renderedRequiredAsterisk}
              {labelText}
            </Flex>
            {descriptionText}
            <Flex direction={"column"} alignItems={"center"}>
              {radioGroupField}
              {extraDetailsField}
            </Flex>
            <Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
              {errorText}
            </Flex>
          </Flex>
      </div>
    );
  }
  catch (error) {
    throw "RadioThenDetailsTemplate Error: " + error;
  }
}

export default RadioThenDetailsTemplate;