import { Badge, Flex, Grid, Icon, Text } from "@aws-amplify/ui-react";
import { Link, Navigate } from "react-router-dom";
import {React, useState} from 'react';
import {MdOutlineClear} from 'react-icons/md';

const LessonDateTemplate = function(props) {
    const [redirect, setRedirect] = useState(null);
    const lessonTextProps = {...props.lessonTextProps};

    const lessonProps = props.lesson;

    if (lessonProps == null) {
        return (
            <Flex position={"relative"} alignItems={"center"} gap={"0vw"}>
                <Flex alignItems={"center"}>
                    <Text {...lessonTextProps}>Error loading lesson date</Text>
                </Flex>
            </Flex>
        );
    }

    const date = new Date(lessonProps.date);

    const iconSize = "20";

    let deleteButton = null;
    if (props.showCross) {
        deleteButton = <Flex 
            color={"#ff0000"}
            width={iconSize + "px"}
            height={iconSize + "px"}
            alignItems={"center"}
            position={"absolute"}
            right={"-2.3vw"}
        >
            <Icon
                key={"deleteButton"}
                width={"30px"}
                height={"30px"}
                as={MdOutlineClear}
            />
        </Flex>
    }

    const dayNames = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];
    let dateString = date.toLocaleDateString();
    const day = date.getDay();
    const dayName = dayNames[(day % 7)];
    dateString = dayName + " - " + dateString

    const timeString = lessonProps.startTime + "-" + lessonProps.endTime

    let lessonString = dateString + ", " + timeString

    const lessonArrangementType = lessonProps.type;
    let amendmentText = null;
    if (lessonArrangementType == "extra") {
        const amendmentString = "(extra lesson)";
        amendmentText = <Text {...lessonTextProps} color={"#bd1111"}>{amendmentString}</Text>
    }

    const lessonText = <Text {...lessonTextProps} >{lessonString}</Text>

    const backgroundColour = props.backgroundColour;

    return (
        <Flex 
            alignItems={"center"} 
            position={"relative"}
        >
            <Flex 
                alignItems={"center"} 
                gap={"1vw"}
                backgroundColor={backgroundColour}
                borderRadius={"10px"}
                paddingLeft={"10px"}
                paddingRight={"10px"}
                paddingTop={"2px"}
                paddingBottom={"2px"}
            >
                {lessonText}
                {amendmentText}
            </Flex>
            {deleteButton}
        </Flex>
    );
}

export default LessonDateTemplate;