import React from 'react';

const TutorStudents = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    //Get the current user to pass to subsequent functions
    const user = props.user;

    return (
        <div className='MyStudentsPage'>
            <h1>My Students</h1>
        </div>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default TutorStudents;