import { React, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Flex, Icon, Text } from '@aws-amplify/ui-react';
import TextFiles from "../../TextFiles.json";

const HelpPlanAndDeliver = function(props) {
    const [textFlex, setTextFlex] = useState(null);
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    const width = props.width;

    useEffect(() => {
        const textObjectArray = TextFiles.planAndDeliverText;
        const textArray = [];
        const globalTextProps = {...standardTextProps, fontSize: "20px", textAlign: "left"};
        if (width < 800) {
            globalTextProps.fontSize = "17px";
        }
        for (let lineIndex = 0; lineIndex < textObjectArray.length; lineIndex = lineIndex + 1) {
            const textObject = textObjectArray[lineIndex];
            const rawText = textObject.text;
            let textProps = {...globalTextProps};
            if (textObject.title) {
                textProps = {...titleTextProps, fontSize: "25px"}
            }
            let text = <Text {...textProps} key={"Text" + lineIndex}>{rawText}</Text>
            if (textObject.bullet) {
                text = <Flex marginTop={"-25px"} marginBottom={"-25px"} key={"Text" + lineIndex}><ul><li>{text}</li></ul></Flex>
            }
            if (rawText == "") {
                text = <Flex marginBottom={"20px"} key={"Text" + lineIndex}/>
            }
            textArray.push(text);
        }
        
        setTextFlex(<Flex 
            direction={"column"}
            marginLeft={"8vw"}
            marginRight={"8vw"}
            gap={"20px"}
        >
            {textArray}
        </Flex>)
    }, []);

    const welcomeText = <Text {...titleTextProps}>
        Guidance for planning and delivering lessons
    </Text>

    let backButton = <Flex position={"absolute"} left={"8vw"} top={"85px"}><Link to={"/Tutor/Help"} style={{textDecoration: 'none'}}><Button>Back</Button></Link></Flex>
    if (width < 800) {
        backButton = <Flex marginTop={"-4vh"} marginBottom={"-4vh"}><Link to={"/Tutor/Help"} style={{textDecoration: 'none'}}><Button>Back</Button></Link></Flex>
    }

    return (
        <Flex direction={"column"} gap={"5vh"} alignItems={"center"} position={"relative"}>
                {welcomeText}
                {backButton}
                {textFlex}
        </Flex>
    );  
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default HelpPlanAndDeliver;