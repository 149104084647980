import { Button, Divider, Flex, Icon, Image, Text } from '@aws-amplify/ui-react';
import { React, useEffect, useRef, useState } from 'react';
import { LoadingSpinner, LoadingSpinnerPage } from '../LoadingPage';
import {MdModeEdit, MdDelete, MdAdd, MdArrowRight, MdArrowDropDown, MdNorth, MdSouth} from 'react-icons/md';
import PopUpTemplate from '../../custom-ui-components/form-components/PopUpTemplate';
import { DropDownQuestionTemplate, FormTemplate } from '../../custom-ui-components';
import CheckBoxesTemplate from '../../custom-ui-components/form-components/CheckBoxesTemplate';

const AppIssues = function(props) {
    const APIFunctions = props.APIFunctions;
    const standardTextProps = props.standardTextProps;

    const [issues, setIssues] = useState(null);
    const [issuePages, setIssuePages] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [searchParams, setSearchParams] = useState({});
    const [searchParamsFlex, setSearchParamsFlex] = useState(null);
    const [approvedIssues, setApprovedIssues] = useState(null);
    const [userIssues, setUserIssues] = useState(null);
    const [showUserIssues, setShowUserIssues] = useState({show: false, arrow: null});
    const [selectedIssue, setSelectedIssue] = useState(null);
    const [issueForm, setIssueForm] = useState(null);
    const [submitStatus, setSubmitStatus] = useState(0);

    const retrieveIssues = async function (params) {
        const retrievedIssues = await APIFunctions.adminGetIssues(params);
        setIssues(retrievedIssues);
    };
    //Load issues on page load
    useEffect(() => {
        // if (issues == null) {
        //     retrieveIssues();
        //     return;
        // }
        retrieveIssues(searchParams);
    }, [searchParams]);

    useEffect(() => {
        setIssues(issuePages[currentPage] || []);
    }, [currentPage, issuePages]);
    
    //Sort by
    useEffect(() => {
        const mainFilterTextProps = {...standardTextProps}
        const filterTextProps = {...mainFilterTextProps}
        mainFilterTextProps.fontSize = "20px"
        filterTextProps.fontSize = "18px";

        const sortByDropDown = <DropDownQuestionTemplate
            id = "sortBy"
            options = {["Sort by priority", "Sort by due date"]}
            defaultValue = {"Sort by priority"}
            placeHolder = {"-Sort by-"}
            handleChange = {(questionID, selectedAnswer, errors) => {
                if (selectedAnswer != null) {
                    setSearchParams((currentSearchParams) => {
                        return {
                            ...currentSearchParams,
                            sortBy: selectedAnswer,
                            sortDirection: (selectedAnswer == "Sort by due date") && 1 || -1
                        }
                    });
                }
            }}
        />
    
        const iconSize = "40";
        let iconMD = MdSouth;
        let directionText = {
            "Sort by priority": {
                [1]: "Lowest first",
                [-1]: "Highest first"
            },
            "Sort by due date": {
                [1]: "Soonest first",
                [-1]: "Latest first"
            }
        };
        if (searchParams.sortDirection == -1) {
            iconMD = MdNorth;
        }

        const sortDirection = <Button 
            style={{cursor:"pointer"}} 
            onClick={()=> {
                setSearchParams((currentSearchParams) => {
                    return {
                        ...currentSearchParams,
                        sortDirection: (currentSearchParams.sortDirection || 1) * -1
                    }
                });
            }}
            //width={iconSize + "px"}
            //height={iconSize + "px"}
            justifyContent={"center"}
            alignItems={"center"}
            
        >
            <Text>{(directionText[searchParams.sortBy] || {})[searchParams.sortDirection || 1]}</Text>
            <Icon
                key={"orderChangeButton"}
                width={"30px"}
                height={"30px"}
                as={iconMD}
            />
        </Button>

        setSearchParamsFlex(<Flex alignItems={"center"} gap={"1vh"} direction={"column"}>
            {/* {filterMenu} */}
            <Flex alignItems={"center"}>
                {sortByDropDown}
                {sortDirection}
            </Flex>
        </Flex>);
    }, [searchParams]);

    //Generate user issues arrow
    useEffect(() => {
        if (showUserIssues == null || showUserIssues.show == null) {
            setShowUserIssues({show: false});
            return;
        }

        let arrowIcon = MdArrowRight;
        if (showUserIssues.show == true) {
            arrowIcon = MdArrowDropDown;
        }
        setShowUserIssues((currentState) => {
            return {
                ...currentState,
                arrow: <Flex
                    position={"relative"}
                    style={{"cursor": "pointer"}}
                    onClick={() => {
                        setShowUserIssues((currentState) => {
                            return {
                                ...currentState,
                                show: !currentState.show
                            };
                        });
                    }}
                >
                    {(!showUserIssues.show && userIssues != null && userIssues.length > 0) && <Flex 
                        borderRadius={"100px"} 
                        backgroundColor={"#fc3903"} 
                        width={"25px"} 
                        height={"25px"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        fontSize={"15px"}
                        color={"#ffffff"}
                        position={"absolute"}
                        right="-10px" 
                        top="-10px"
                        children={userIssues != null && userIssues.length}
                    />}
                    <Icon 
                        as={arrowIcon}
                        width={"30px"}
                        height={"30px"}
                        className="HoverBackground"
                        borderRadius={"10px"}
                        border={"1px solid black"}
                    />
                </Flex>
            }
        });
    }, [userIssues, showUserIssues.show]);

    //Generate issue list
    useEffect(() => {
        if (issues == null) {
            setApprovedIssues(<LoadingSpinner {...props}/>);
            setUserIssues(<LoadingSpinner {...props}/>);
            return;
        }

        const newApprovedIssues = [];
        const newUserIssues = [];
        for (const issue of issues) {
            if (issue.priority == 0) {
                newUserIssues.push(<UserIssue issue={issue} key={issue.submittedBy + issue.createdAt}/>);
            }
            else if (issue.priority > 0) {
                newApprovedIssues.push(<ApprovedIssue issue={issue} key={issue.submittedBy + issue.createdAt}/>);
            }
        }

        setApprovedIssues(newApprovedIssues);

        setUserIssues(newUserIssues);

    }, [issues, selectedIssue]);

    //Generate issue form
    useEffect(() => {
        if (selectedIssue == null || selectedIssue.issue == null || selectedIssue.function == null) {
            setIssueForm(null);
            return;
        }

        const issue = selectedIssue.issue;
        let submitDate = "N/A";
        if (issue.createdAt != null) {
            submitDate = new Date(issue.createdAt).toLocaleString();
        }
        if (selectedIssue.function == "delete") {
            const deleteFlex = <Flex
                direction={"column"}
                maxWidth={"500px"}
            >
                <Text {...props.titleTextProps}>Are you sure you want to remove this issue?</Text>
                <Divider />
                <Text {...props.standardTextProps} textAlign={"left"}>{issue.title || "Issue details:"}</Text>
                <Text {...props.standardTextProps} textAlign={"left"}>{issue.details}</Text>
                <Divider />
                <Text {...props.standardTextProps} textAlign={"left"} marginBottom={"-15px"}>Submission date: {submitDate}</Text>
                <Text {...props.standardTextProps} textAlign={"left"}>Submitted by: {issue.submittedBy}</Text>
                <Flex 
                    justifyContent={"center"}
                    alignItems={"center"}
                    wrap={"wrap"}
                    position={"relative"}
                >
                    {submitStatus != 2 && <Button
                        children={"Cancel"}
                        onClick={endIssueFunction}
                        disabled={submitStatus != 0}
                    />}
                    <Button
                        onClick={() => {
                            if (submitStatus == 0) {
                                deleteIssue();
                            }
                            else if (submitStatus == 2) {
                                endIssueFunction();
                            }
                        }}
                        disabled={submitStatus == 1}
                    >
                        <Flex 
                            gap={"10px"}
                            
                        >
                            <Text>{submitStatus == 0 && "Delete" || submitStatus == 1 && "Deleting..." || submitStatus == 2 && "Ok"}</Text>
                            {submitStatus == 0 && <Icon
                                width={"25px"}
                                height={"25px"}
                                as={MdDelete}
                            />}
                        </Flex>
                    </Button>
                    <Text
                        position={"absolute"}
                        bottom={"-30px"}
                    >
                        {submitStatus == 2 && "Successfully deleted"}
                    </Text>
                </Flex>
            </Flex>
            const confirmDeletePopup = <PopUpTemplate 
                form={deleteFlex}
                showXButton={submitStatus != 1 && true}
                setPopUpVisibility={endIssueFunction}
            />
            setIssueForm(confirmDeletePopup);
        }
        else if (selectedIssue.function == "add") {
            const addIssueQuestions = [];
            addIssueQuestions.push({
                id: "title",
                type: "text",
                label: "Title:",
                placeholder: "Title",
                required: true,
                defaultValue: issue.title
            });
            addIssueQuestions.push({
                id: "details",
                type: "text",
                label: "Issue details:",
                placeholder: "Details...",
                defaultValue: issue.details,
                extraProps: {
                    textType: "paragraph",
                    rows: 6
                }
            });
            addIssueQuestions.push({
                id: "type",
                type: "radio",
                label: "Issue type:",
                required: true,
                defaultValue: issue.type || "feature",
                options: [
                    {
                        id: "bug",
                        label: "Bug"
                    },
                    {
                        id: "feature",
                        label: "Feature request"
                    }
                ]
            });
            addIssueQuestions.push({
                id: "priority",
                type: "slider",
                description: "Higher number -> higher priority",
                minValue: 1,
                maxValue: 10,
                step: 1,
                label: "Priority:",
                required: true,
                defaultValue: issue.priority || 1,
                textValues: "value"
            });
            addIssueQuestions.push({
                id: "progress",
                type: "slider",
                minValue: 0,
                maxValue: 100,
                step: 1,
                label: "Current progress:",
                required: true,
                defaultValue: issue.progress || 0,
                textValues: "valuePercent"
            });
            addIssueQuestions.push({
                id: "due",
                type: "date",
                step: 1,
                label: "Due date:",
                required: false,
                defaultValue: issue.due || "blank"
            });


            const addIssueForm = <Flex 
                width={"90%"}
                alignSelf={"center"}
                maxWidth={"400px"}
                marginTop={"-40px"}
            >
                <FormTemplate
                    {...props}
                    questions={addIssueQuestions} 
                    submitAnswers={(answers) => {
                        addIssue(answers, issue.id);
                    }}
                    okButtonFunction={endIssueFunction}
                    submitPopUpText={"Successfully approved issue"}
                />
            </Flex>

            const approveList = [
                <Flex key={"titleText"} justifyContent={"space-between"}>
                    <Text {...props.standardTextProps} textAlign={"left"}>{issue.title || "Issue details:"}</Text>
                    <Text {...props.standardTextProps} textAlign={"left"}>Priority: {issue.priority}</Text>
                </Flex>,
                <Text {...props.standardTextProps} textAlign={"left"} key={"issueDetails"}>{issue.details}</Text>,
                <Divider key={"approveDivider"}/>,
                issue.due != null && <Text {...props.standardTextProps} textAlign={"left"} marginBottom={"-15px"} key={"dueDate"}>Due date: {new Date(issue.due).toDateString()}</Text>,
                <Text {...props.standardTextProps} textAlign={"left"} marginBottom={"-15px"} key={"submissionDate"}>Submission date: {submitDate}</Text>,
                <Text {...props.standardTextProps} textAlign={"left"} key={"submittedBy"}>Submitted by: {issue.submittedBy}</Text>,
            ]

            const addFlex = <Flex
                direction={"column"}
                maxWidth={"500px"}
                //style={{wordBreak: "break-word"}}
            >
                <Text {...props.titleTextProps}>{issue.id == null && "Submit issue" || issue.priority == 0 && "Approve issue" || "Update issue"}</Text>
                <Divider />
                {issue.id != null && approveList}
                {addIssueForm}
            </Flex>
            const confirmDeletePopup = <PopUpTemplate 
                form={addFlex}
                showXButton={submitStatus != 1 && true}
                setPopUpVisibility={endIssueFunction}
            />
            setIssueForm(confirmDeletePopup);
        }
    }, [selectedIssue, submitStatus]);

    const startIssueFunction = function (issue, functionName) {
        setSelectedIssue((currentSelectedIssue) => {
            if (currentSelectedIssue != null && currentSelectedIssue.id == issue.id) {
                return {
                    ...currentSelectedIssue,
                    function: functionName
                }
            }
            else {
                return {
                    id: issue.id,
                    issue: issue,
                    function: functionName
                }
            
            }
        });
    };

    const endIssueFunction = function () {
        setSelectedIssue((currentSelectedIssue) => {
            return {
                ...currentSelectedIssue,
                function: null
            };
        });
        setSubmitStatus(0);
        setSelectedIssue(null);
    };

    const deleteIssue = async function () {
        try {
            setSubmitStatus(1);
            await APIFunctions.reportIssue({id: selectedIssue.id}, "delete");
            setSubmitStatus(2);
            //retrieveIssues();
            setIssues(null);
        }
        catch (error) {
            setSubmitStatus(0);
        }
    };

    const addIssue = async function (issue, id) {
        try {
            setSubmitStatus(1);
            if (id == null) {
                await APIFunctions.reportIssue(issue, "add");
            }
            else {
                issue.id = id;
                await APIFunctions.reportIssue(issue, "update");
            }
            setSubmitStatus(2);
            //retrieveIssues();
            setIssues(null);
        }
        catch (error) {
            setSubmitStatus(0);
            throw "Error approving issue: " + error;
        }
    };

    const ProgressBar = function (props) {
        const progress = props.progress;
        const competionPercent = progress + "%";

        return <Flex 
            marginTop={"5vh"}
            marginBottom={"5vh"}
            width={props.width || "100%"} 
            height={props.height || "35px"} 
            backgroundColor={props.backgroundColor || "#dddddd"}
            alignItems={"center"}
            borderRadius={"1vh"}
            position={"relative"}
            direction={"column"}
            justifyContent={"center"}
            alignSelf={"center"}
        >
            <Flex 
                width={competionPercent} 
                height={"100%"}
                borderRadius={"1vh"}
                position={"absolute"}
                left={"0px"}
                top={"0px"}
                backgroundColor={"#00ff00"} 
            />
            <Flex 
                width={"100%"} 
                height={"100%"}
                position={"absolute"}
                left={"0px"}
                top={"0px"}
                style={{borderStyle: "solid", borderWidth: "2px", borderColor: "#000000"}}
                borderRadius={"1vh"}
            />
            <Text {...standardTextProps} fontSize={props.fontSize || "15px"} position={"absolute"} alignSelf={"center"}>{competionPercent}{props.word}</Text>
        </Flex>;
    };
    
    const ApprovedIssue = function (props) {
        const issue = props.issue;
        if (issue == null) {
            return null;
        }
        const details = issue.details;
        if (details == null) {
            return null;
        }

        const issueType = issue.type || "N/A";

        const getPriorityColour = function (priority) {
            if (priority == 1) {
                return "#cf3b17";
            }
            else if (priority == 2) {
                return "#d66127";
            }
            else if (priority == 3) {
                return "#d68127";
            }
            else if (priority == 4) {
                return "#d6ad27";
            }
            else if (priority == 5) {
                return "#d6c727";
            }
            else if (priority == 6) {
                return "#b6d627";
            }
            else if (priority == 7) {
                return "#8ad627";
            }
            else if (priority == 8) {
                return "#6de324";
            }
            else if (priority == 9) {
                return "#4ae324";
            }
            else if (priority == 10) {
                return "#24e331";
            }
        }


        return <Flex
            width={"100%"}
            position={"relative"}
        >
            <Flex
                width={"100%"}
                borderRadius={"15px"}
                paddingLeft={"15px"}
                paddingRight={"15px"}
                paddingTop={"5px"}
                paddingBottom={"5px"}
                border={"1px solid black"}
                className={"HoverBackground"}
                direction={"column"}
                gap={"5px"}
                style={
                    issueType == "bug" && {
                        "--backgroundColour":  "#e3be36",
                        "--hoverBackgroundColour": "#fad161"
                    } || {
                        "--backgroundColour":  "#5da5e8",
                        "--hoverBackgroundColour": "#75b2eb"
                    }
                }
                onClick={() => {
                    if (selectedIssue != null && selectedIssue.id == issue.id) {
                        setSelectedIssue(null);
                    }
                    else {
                        setSelectedIssue({
                            id: issue.id,
                            issue: issue,
                            expand: true,
                            function: null
                        });
                    }
                }}
            >
                <Flex 
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Flex
                        direction={"column"}
                        gap={"0px"}
                        alignItems={"flex-start"}
                    >
                        <Text 
                            {...standardTextProps} 
                            textAlign={"left"}
                        >
                            {issue.title || "Issue details:"}
                        </Text>
                        <Flex
                            height={"14px"}
                            // width={"50%"}
                            // maxWidth={"100px"}
                            width={"100px"}
                        >
                            <ProgressBar 
                                progress={issue.progress || 0} 
                                height={"14px"}
                                fontSize={"11px"}
                            />
                        </Flex>
                    </Flex>
                    <Flex
                        justifyContent={"center"}
                        alignItems={"center"}
                        borderRadius={"50%"}
                        backgroundColor={getPriorityColour(issue.priority)}
                        padding={"12px"}
                        width={"30px"}
                        height={"30px"}
                        marginRight={"10px"}
                        border={"1px solid black"}
                    >
                        <Text 
                            {...standardTextProps} 
                            textAlign={"center"} 
                            fontSize={"12px"}
                            color={"#000"}
                        >
                            {issue.priority}
                        </Text>
                    </Flex>
                </Flex>
                {selectedIssue != null && selectedIssue.id == issue.id && selectedIssue.expand == true && <Flex
                    direction={"column"}
                    gap={"0px"}
                >
                    <Divider />
                    {details != null && <Text {...standardTextProps} textAlign={"left"}>{details}</Text>}
                    {details != null && <Divider />}
                    {issue.due != null && <Text {...standardTextProps} textAlign={"left"}>Due date: {new Date(issue.due).toDateString()}</Text>}
                    {issue.progress > 0 && <Text {...standardTextProps} textAlign={"left"}>Current progress: {issue.progress}%</Text>}
                    <Text {...standardTextProps} textAlign={"left"}>Created: {new Date(issue.createdAt).toLocaleString()}</Text>
                    {issue.updatedAt != issue.createdAt && <Text {...standardTextProps} textAlign={"left"}>Updated: {new Date(issue.updatedAt).toLocaleString()}</Text>}
                    <Text {...standardTextProps} textAlign={"left"}>Submitted by: {issue.submittedBy}</Text>
                </Flex>}
            </Flex>
            <Icon
                width={"30px"}
                height={"30px"}
                as={MdDelete}
                borderRadius={"50%"}
                padding={"2px"}
                position={"absolute"}
                top={"30px"}
                right={"-15px"}
                border={"1px solid black"}
                className={"HoverBackground"}
                style={{
                    "--backgroundColour": "#3085c7",
                    "--hoverBackgroundColour": "#8fc7ff"
                }}
                onClick={() => {
                    startIssueFunction(issue, "delete");
                }}
            />
            <Icon
                width={"30px"}
                height={"30px"}
                as={MdModeEdit}
                borderRadius={"50%"}
                padding={"4px"}
                position={"absolute"}
                top={"-10px"}
                right={"-15px"}
                border={"1px solid black"}
                className={"HoverBackground"}
                style={{
                    "--backgroundColour": "#3085c7",
                    "--hoverBackgroundColour": "#8fc7ff"
                }}
                onClick={() => {
                    startIssueFunction(issue, "add");
                }}
            />
        </Flex>;
    };

    const UserIssue = function (props) {
        const issue = props.issue;
    
        const issueType = issue.type || "N/A";

        const [isTruncated, setIsTruncated] = useState(false);
        const textRef = useRef(null);
        

        useEffect(() => {
            const element = textRef.current;
            if (element) {
                // Check if text is truncated
                const textTruncated = element.scrollHeight > element.clientHeight;
                if (!textTruncated) {
                    if (selectedIssue == null || selectedIssue.id != issue.id || selectedIssue.expand != true) {
                        setIsTruncated(textTruncated);
                    }
                    else {
                        setIsTruncated(true);
                    }
                }
                else {
                    setIsTruncated(textTruncated);
                }
            }
        }, [details]);

        if (issue == null) {
            return null;
        }
        const details = issue.details;
        if (details == null) {
            return null;
        }

        const lineCutoffProps = {
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 2,
        };

        return <Flex
            width={"100%"}
            position={"relative"}
        >
            <Flex
                width={"100%"}
                borderRadius={"15px"}
                paddingLeft={"15px"}
                paddingRight={"15px"}
                paddingTop={"5px"}
                paddingBottom={"5px"}
                border={"1px solid black"}
                className={isTruncated && "HoverBackground"}
                backgroundColor={!isTruncated && (issueType == "bug" && "#e3be36" || "#5da5e8")}
                style={
                    issueType == "bug" && {
                        "--backgroundColour":  "#e3be36",
                        "--hoverBackgroundColour": "#fad161"
                    } || {
                        "--backgroundColour":  "#5da5e8",
                        "--hoverBackgroundColour": "#75b2eb"
                    }
                }
                onClick={() => {
                    if (!isTruncated) {
                        return;
                    }
                    if (selectedIssue != null && selectedIssue.id == issue.id) {
                        setSelectedIssue(null);
                    }
                    else {
                        setSelectedIssue({
                            id: issue.id,
                            issue: issue,
                            expand: true,
                            function: null
                        });
                    }
                }}
            >
                <Text 
                    {...standardTextProps} 
                    textAlign={"left"}
                    width={"100%"}
                    style={(selectedIssue == null || selectedIssue.id != issue.id || selectedIssue.expand != true) && lineCutoffProps}
                    ref={textRef}
                >
                    {details}
                </Text>
            </Flex>
            <Icon
                width={"30px"}
                height={"30px"}
                as={MdDelete}
                borderRadius={"50%"}
                padding={"2px"}
                position={"absolute"}
                top={"30px"}
                right={"-15px"}
                border={"1px solid black"}
                className={"HoverBackground"}
                style={{
                    "--backgroundColour": "#3085c7",
                    "--hoverBackgroundColour": "#8fc7ff"
                }}
                onClick={() => {
                    startIssueFunction(issue, "delete");
                }}
            />
            <Icon
                width={"30px"}
                height={"30px"}
                as={MdAdd}
                borderRadius={"50%"}
                padding={"2px"}
                position={"absolute"}
                top={"-10px"}
                right={"-15px"}
                border={"1px solid black"}
                className={"HoverBackground"}
                style={{
                    "--backgroundColour": "#3085c7",
                    "--hoverBackgroundColour": "#8fc7ff"
                }}
                onClick={() => {
                    startIssueFunction(issue, "add");
                }}
            />
        </Flex>;
    };
   
    return (
        <Flex 
            direction="column" 
            alignItems={"center"} 
            gap={"50px"} 
            width={"90vw"}
            maxWidth={"500px"}
        >
            <Text {...props.titleTextProps}>App Issues</Text>
            <Flex 
                direction="column" 
                alignItems={"center"} 
                gap={"30px"} 
                width={"90%"}
            >   
                <Flex direction={"column"} alignItems={"center"} gap={"0px"}>
                    <Flex alignItems={"center"}>
                        <Text {...props.titleTextProps}>Current issues:</Text>
                        <Icon 
                            as={MdAdd}
                            width={"30px"}
                            height={"30px"}
                            className="HoverBackground"
                            borderRadius={"10px"}
                            border={"1px solid black"}
                            onClick={() => {
                                try {
                                    startIssueFunction({}, "add");
                                }
                                catch (error) {
                                    throw error;
                                }
                            }}
                        />
                    </Flex>
                    {searchParamsFlex}
                </Flex>
                {approvedIssues}
            </Flex>
            <Flex 
                direction="column" 
                alignItems={"center"} 
                gap={"30px"}
                width={"90%"}
            >
                <Flex alignItems={"center"}>
                    <Text {...props.titleTextProps}>User submitted issues:</Text>
                    {showUserIssues.arrow}
                </Flex>
                {showUserIssues.show && userIssues}
            </Flex>
            {issueForm}
        </Flex>
    );
}

export default AppIssues;