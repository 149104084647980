import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { TextField, Text, Flex } from "@aws-amplify/ui-react";
import { useState, useEffect } from 'react';
import { validateField } from "./FormValidation";

const TimeFieldTemplate = function(props) {
  try {
    const labelTextProps = props.questionLabelProps || {};
    const errorTextProps = props.questionErrorProps || {};

    //Set question variables from passed properties
    const questionID = props.id;
    const questionLabel = props.label;
    const questionDescription = props.description;
    const required = props.required;
    const requiredAsterisk = props.requiredAsterisk;
    const placeHolder = props.placeHolder;
    const defaultValue = props.defaultValue;
    const questionValidations = props.validations;
    const answersSuccessfullySubmitted = props.answersSuccessfullySubmitted;

    //Set state variables used to store the current state and any errors
    const [timeAnswer, setTimeAnswer] = useState(defaultValue);
    const [errors, setErrors] = useState({questionError:""});
    const [timeObject, setTimeObject] = useState({"target": {"value":""}});

    useEffect(() => {
      const validationResult = validateField(timeAnswer, questionValidations);
      const questionHasError = validationResult.hasError;
      props.handleChange(questionID, timeAnswer, questionHasError);
    }, []);

    let labelColour = labelTextProps.color || "#000000";
    if (props.validationError != null && props.validationError != null) {
      labelColour = props.validationErrorColour || "#ff0000";
    }
    //Get the text for the question label
    const labelText = <Text {...labelTextProps} color={labelColour}>
      {questionLabel}
    </Text>

    const descriptionText = <Text {...labelTextProps} fontSize={"14px"} fontStyle={"italic"}>
      {questionDescription}
    </Text>
    
    const errorText = <Text {...errorTextProps}>
      {errors.questionError}
    </Text>

    let renderedRequiredAsterisk = null;
    if (required == true) {
      renderedRequiredAsterisk = requiredAsterisk;
    }

    //Ensure that timeAnswer has an ASCII value to display
    if (timeAnswer == null) {
      setTimeAnswer("");
    }
    
    if (errors.errorMessage === null) {
      setErrors({
        questionError:""
      });
    }

    //Generate the text field component
    const timeField = <TextField
      //color={myTheme.tokens.colors.font.primary.value}
      size={"medium"}
      placeholder={placeHolder}
      value={timeAnswer}
      type="time"
      disabled={answersSuccessfullySubmitted}

      //Runs when it detects a change in the input field
      onChange={(e) => {
        const currentValue = e.target.value;
        setTimeAnswer(currentValue);

        //Validate the current value
        const validationResult = validateField(currentValue, questionValidations);

        const questionHasError = validationResult.hasError;
        const questionErrorMessage = validationResult.errorMessage;

        props.handleChange(questionID, currentValue, questionHasError);

        setErrors({
          questionHasError:questionHasError, 
          questionError:questionErrorMessage
        });
      }}
    ></TextField>

    //Return text field component to calling function (Most likely a Form Template)
    return (
      timeAnswer,
      <Flex direction={"column"}>
          <Flex alignItems={"center"}>
            {renderedRequiredAsterisk}
            {labelText}
          </Flex>
          {descriptionText}
          {timeField}
          <Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
            {errorText}
          </Flex>
      </Flex>
    );
  }
  catch (error) {
    throw "TimeFieldTemplate Error: " + error;
  }
}

export default TimeFieldTemplate;