import { React, useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { LoadingSpinnerPage } from '../LoadingPage';
import { Button, Flex, Icon, Text } from '@aws-amplify/ui-react';
import CalendarTemplate from '../../custom-ui-components/CalendarViewTemplate';
import { ErrorLoadingPage } from '../ErrorLoadingPage';
import { AppNavButton } from '../../custom-ui-components';
import {MdOutlineMoreTime, MdSchool, MdMenuBook, MdHelp} from 'react-icons/md';

const TutorHelpList = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    const width = props.width;
    const APIFunctions = props.APIFunctions;

    const welcomeText = <Text {...titleTextProps}>
        Tutor Help
    </Text>

    const  buttonList = [
        {text: "Next Door Tutor Start Guide", link: "/Tutor/StartGuide"},
        {text: "Plan and Deliver Lessons", link: "/Tutor/Help/PlanAndDeliver"}, 
    ];
    
    const buttons = [];
    for (const button of buttonList) {
        const buttonText = button.text;
        const buttonFontSize = "20px";
        const width = props.width;
        const buttonLink = button.link;
        const buttonNumNotifications = button.notifications || 0;

        let buttonNotification = <Flex 
            borderRadius={"100px"} 
            backgroundColor={"#fc3903"} 
            width={"25px"} 
            height={"25px"}
            alignItems={"center"}
            justifyContent={"center"}
            fontSize={"15px"}
            color={"#ffffff"}
            position={"absolute"}
            right="-10px" 
            top="-10px"
        >
            {buttonNumNotifications}
        </Flex>

        if (buttonNumNotifications == 0) {
            buttonNotification = null;
        }

        const buttonElement = <Flex position={"relative"}>
            {buttonNotification}
            <AppNavButton text={buttonText} fontSize={buttonFontSize} width={width}/>
        </Flex>
        const linkedButton = <Link to={buttonLink} style={{textDecoration: 'none'}} key={buttonText}>{buttonElement}</Link>;


        const buttonFlex = <Flex direction={"column"} gap={"1vh"} alignItems={"center"} key={buttonText}>
            {linkedButton}
        </Flex>

        buttons.push(buttonFlex);
    }

    return (
        <Flex direction={"column"} gap={"6vh"} alignItems={"center"}>
                {welcomeText}
                {buttons}
        </Flex>
    );  
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default TutorHelpList;