import { Button, Flex, Icon, Text } from '@aws-amplify/ui-react';
import {React, useState, useEffect } from 'react';
import { Link, Navigate, useLocation } from "react-router-dom";
import { FormTemplate } from '../../custom-ui-components';
import { ErrorLoadingPage } from '../ErrorLoadingPage';
import { LoadingSpinner } from '../LoadingPage';
import ParentChooseStudentsTemplate from '../../custom-ui-components/form-components/ParentChooseStudentsTemplate';
import { blankAvailability, compareDayAvailability, compareObjects, getMatchingAvailabilityWindows, getSessionLength, getSessionLengthMins, subjects } from '../../App';
import {MdArrowRight, MdArrowDropDown, MdCollectionsBookmark} from 'react-icons/md';
import BackButton from '../../custom-ui-components/BackButton';

const CreateNewLesson = function(props) {
    const widthThreshold = 1000;
    let defaultShowDetails = true;
    if (props.width < widthThreshold) {
        defaultShowDetails = false
    }
    const [showDetails, setShowDetails] = useState(defaultShowDetails);
    const [questionAnswers, setQuestionAnswers] = useState({});
    const [redirect, setRedirect] = useState(null);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [selectedTutor, setSelectedTutor] = useState(null);
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [selectedTimeRange, setSelectedTimeRange] = useState(null);
    const [selectedDay, setSelectedDay] = useState(null);
    const [multipleParents, setMultipleParents] = useState(null);
    const [tutorLessonTimes, setTutorLessonTimes] = useState({});
    const [studentLessonTimes, setStudentLessonTimes] = useState({});
    const [tutorSubjectWarning, setTutorSubjectWarning] = useState(null);
    const [tutorAvailabilityWarning, setTutorAvailabilityWarning] = useState(null);
    const [studentLessonTimesWarning, setStudentLessonTimesWarning] = useState(null);
    const [tutorLessonTimesWarning, setTutorLessonTimesWarning] = useState(null);
    const [studentsParentWarning, setStudentsParentWarning] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false);
    
    const students = props.students;
    const tutors = props.tutors;
    const tutorDictionary = props.tutorDictionary

    const lessons = props.adminLessons;

    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    const APIFunctions = props.APIFunctions;

    const requestTextProps = {...standardTextProps}
    requestTextProps.textAlign = "left";
    delete requestTextProps.maxWidth;

    const createNewLessonTitle = <Text {...titleTextProps} >
        Create a new lesson
    </Text>

    let requestedLesson = null;
    try {
        const location = useLocation();
        requestedLesson = location.state;
    } catch {}

    if (tutors == null || students == null || tutorDictionary == null || lessons == null) {
        return <LoadingSpinner height={props.height} />
    }
    if (tutors == "loading" || students == "loading" || tutorDictionary == "loading" || lessons == "loading") {
        return <LoadingSpinner height={props.height} />
    }
    if (tutors == "error" || students == "error" || tutorDictionary == "error" || lessons == "error") {
        return <ErrorLoadingPage {...props}/>
    }

    const tutorOptions = [];
    for (const tutor of tutors) {
        const blankTutorOption = {};
        blankTutorOption.id = tutor.id;
        blankTutorOption.label = tutor.firstNames + ", " + tutor.lastName;
        tutorOptions.push(blankTutorOption);
    }

    const studentOptions = [];
    for (const student of students) {
        const blankStudentOption = {};
        blankStudentOption.id = student.id;
        blankStudentOption.label = student.firstNames + ", " + student.lastName;
        studentOptions.push(blankStudentOption);
    }

    const submitAnswers = async function(returnedAnswers) {
        try {
            setFormSubmitted(true);
            setQuestionAnswers(returnedAnswers);
            const studentIDs = [];
            for (const selectedStudent of selectedStudents) {
                studentIDs.push(selectedStudent.id);
            }
            const newLesson = {};
            newLesson.studentIDs = studentIDs;
            newLesson.tutorID = returnedAnswers.tutor.id;
            newLesson.subject = returnedAnswers.subject;
            newLesson.lessonDay = returnedAnswers.day;
            newLesson.startTime = returnedAnswers.lessonTime.startTime;
            newLesson.endTime = returnedAnswers.lessonTime.endTime;
            newLesson.lessonType = returnedAnswers.lessonType;
            newLesson.startDate = returnedAnswers.startDate;
            if (returnedAnswers.lessonType != "Online") {
                newLesson.address = {
                    address1stLine: returnedAnswers.lessonLocation.address1stLine,
                    postcode: returnedAnswers.lessonLocation.postcode
                };
            }

            if (requestedLesson != null) {
                newLesson.lessonRequestID = requestedLesson.id;
            }
            console.log(newLesson);
            const newLessonModel = await APIFunctions.createLesson(newLesson);
            console.log(newLessonModel);
        }
        catch (error) {
            setFormSubmitted(false);
            console.log(error);
            throw {
                message: "Error creating new lesson",
                error: error
            };
        }
    }

    const exitForm = function () {
        setRedirect(<Navigate to={"/Admin"} />);
    }

    const handleQuestionChange = async function(questionID, newAnswer, errors) {
        let newTutorModel = {...selectedTutor};
        let newSelectedStudents = [...selectedStudents];
        let newSubject = selectedSubject;
        let newLessonTime = selectedTimeRange;
        let newDay = selectedDay;
        let newTutorLessonTimes = tutorLessonTimes;
        let newStudentLessonTimes = studentLessonTimes;
        let newMultipleParents = multipleParents;
        if (questionID == "tutor") {
            if (errors == false) {
                newTutorLessonTimes = blankAvailability();
                newTutorModel = tutorDictionary[newAnswer.id];
                const calculateNewTutorLessonTimes = async function (lesson) {
                    const lessonTutorID = lesson.tutorID;
                    if (lessonTutorID == newTutorModel.id) {
                        newTutorLessonTimes[lesson.lessonDay].push({
                            startTime: lesson.startTime,
                            endTime: lesson.endTime
                        });
                    }
                }
                
                const lessonPromises = [];
                for (const lesson of lessons) {
                    lessonPromises.push(calculateNewTutorLessonTimes(lesson));
                }
                await Promise.all(lessonPromises);
            }
            else {
                newTutorModel = null;
            }
            setSelectedTutor(newTutorModel);
            setTutorLessonTimes(newTutorLessonTimes);
        }
        else if (questionID == "student") {
            if (errors == false) {
                newSelectedStudents = [];
                newStudentLessonTimes = {};
                newMultipleParents = false;
                const foundParents = {};
                for (const newStudent of newAnswer) {
                    const studentID = newStudent.id;
                    for (const student of students) {
                        if (student.id == studentID) {
                            newSelectedStudents.push(student);
                            let parentID = student.parentID;
                            if (parentID == null) {
                                parentID = "No Parent";
                                if (foundParents["No Parent"] != null) {
                                    multipleParents = true;
                                }
                            }
                            foundParents[parentID] = true;
                        }
                    }
                    const calculateNewStudentLessonTimes = async function (lesson) {
                        let lessonStudentIDs = lesson.studentIDs;
                        if (lessonStudentIDs == null) {
                            lessonStudentIDs = [lesson.studentID];
                        }
                        for (const lessonStudentID of lessonStudentIDs) {
                            if (lessonStudentID == studentID) {
                                if (newStudentLessonTimes[studentID] == null) {
                                    newStudentLessonTimes[studentID] = blankAvailability();
                                }
                                newStudentLessonTimes[studentID][lesson.lessonDay].push({
                                    startTime: lesson.startTime,
                                    endTime: lesson.endTime
                                });
                            }
                        }
                    };
                    
                    const lessonPromises = [];
                    for (const lesson of lessons) {
                        lessonPromises.push(calculateNewStudentLessonTimes(lesson));
                    }
                    await Promise.all(lessonPromises);
                }
                
                const parentsArray = Object.keys(foundParents);
                if (parentsArray.length > 1) {
                    newMultipleParents = true;
                }
            }
            setSelectedStudents(newSelectedStudents);
            setStudentLessonTimes(newStudentLessonTimes);
            setMultipleParents(newMultipleParents);
        }
        else if (questionID == "subject") {
            if (errors == false) {
                newSubject = newAnswer;
            }
            else {
                newSubject = null;
            }
            setSelectedSubject(newSubject);
        }
        else if (questionID == "lessonTime") {
            if (errors == false) {
                newLessonTime = {...newAnswer};
            }
            else {
                newLessonTime = null;
            }
            setSelectedTimeRange(newLessonTime);
        }
        else if (questionID == "day") {
            if (errors == false) {
                newDay = newAnswer;
            }
            else {
                newDay = null;
            }
            setSelectedDay(newDay);
        }

        let newStudentsParentWarning = null;
        if (newMultipleParents == true) {
            let studentNames = "";
            for (let nameIndex = 0; nameIndex < newSelectedStudents.length; nameIndex = nameIndex + 1) {
                const name = newSelectedStudents[nameIndex].firstNames;
                studentNames = studentNames + name;
                if (nameIndex < newSelectedStudents.length - 2) {
                    studentNames = studentNames + ", ";
                }
                else if (nameIndex < newSelectedStudents.length - 1) {
                    studentNames = studentNames + " and ";
                }
            }
            newStudentsParentWarning = "Warning: " + studentNames + " don't share the same parent";
        }
        if (studentsParentWarning != newStudentsParentWarning) {
            setStudentsParentWarning(newStudentsParentWarning);
        }

        let newTutorAvailabilityWarning = null;
        let newTutorLessonTimesWarning = null;
        let newStudentLessonTimesWarning = null;
        if (newLessonTime != null && newDay != null) {
            const startTime = newLessonTime.startTime;
            const endTime = newLessonTime.endTime;
            if (newTutorModel != null && !compareObjects(newTutorModel, {})) {
                const tutorAvailability = newTutorModel.availability;
                const tutorDayAvailability = tutorAvailability[newDay];
                const neededAvailability = [newLessonTime];
                const sessionLength = getSessionLength(startTime, endTime, false);
                const matchingAvailability = compareDayAvailability(neededAvailability, sessionLength, tutorDayAvailability);
                if (matchingAvailability.length == 0) {
                    let pronoun = "they are"
                    if (newTutorModel.gender == "Male") {
                        pronoun = "he is";
                    }
                    else if (newTutorModel.gender == "Female") {
                        pronoun = "she is";
                    }
                    newTutorAvailabilityWarning = "Warning: " + newTutorModel.firstNames + " hasn't said " + pronoun + " available from " + startTime + " to " + endTime + " on " + newDay + "s";
                }

                const tutorLessonTimesDay = newTutorLessonTimes[newDay];
                const newLessonWindow = [newLessonTime]
                const overlappingWindows = getMatchingAvailabilityWindows(tutorLessonTimesDay, newLessonWindow);
                if (overlappingWindows.length > 0) {
                    newTutorLessonTimesWarning = "Warning: " + newTutorModel.firstNames + " has a lesson that overlaps with " + startTime + " to " + endTime + " on " + newDay + "s";
                }

            }
            if (newStudentLessonTimes != null && !compareObjects(newStudentLessonTimes, {})) {
                const studentLessonTimesArray = Object.entries(newStudentLessonTimes);
                const newLessonWindow = [newLessonTime]
                let overlap = [];
                for (const individualStudentLessonTimesObject of studentLessonTimesArray) {
                    const studentID = individualStudentLessonTimesObject[0];
                    const individualStudentLessonTimes = individualStudentLessonTimesObject[1];
                    const individualStudentLessonTimesDay = individualStudentLessonTimes[newDay];
                    const overlappingWindows = getMatchingAvailabilityWindows(individualStudentLessonTimesDay, newLessonWindow);
                    if (overlappingWindows.length > 0) {
                        overlap.push(studentID);
                    }
                }
                if (overlap.length > 0) {
                    let studentNamesArray = [];
                    let studentNames = "";
                    for (const overlapStudent of overlap) {
                        let studentModel = null 
                        for (const student of newSelectedStudents) {
                            if (overlapStudent == student.id) {
                                studentModel = {...student};
                                break;
                            }
                        }
                        const studentName = studentModel.firstNames;
                        studentNamesArray.push(studentName);
                    }
                    for (let nameIndex = 0; nameIndex < studentNamesArray.length; nameIndex = nameIndex + 1) {
                        const name = studentNamesArray[nameIndex];
                        studentNames = studentNames + name;
                        if (nameIndex < studentNamesArray.length - 2) {
                            studentNames = studentNames + ", ";
                        }
                        else if (nameIndex < studentNamesArray.length - 1) {
                            studentNames = studentNames + " and ";
                        }
                    }
                    let hasGrammar = "has";
                    if (studentNamesArray.length > 1) {
                        hasGrammar = "have";
                    }

                    newStudentLessonTimesWarning = "Warning: " + studentNames + " " + hasGrammar + " a lesson that overlaps with " + startTime + " to " + endTime + " on " + newDay + "s";

                }
            }
        }
        if (tutorAvailabilityWarning != newTutorAvailabilityWarning) {
            setTutorAvailabilityWarning(newTutorAvailabilityWarning);
        }
        if (tutorLessonTimesWarning != newTutorLessonTimesWarning) {
            setTutorLessonTimesWarning(newTutorLessonTimesWarning);
        }
        if (studentLessonTimesWarning != newStudentLessonTimesWarning) {
            setStudentLessonTimesWarning(newStudentLessonTimesWarning);
        }
        
        let tutorTeachesSubject = true;
        if (newTutorModel != null && !compareObjects(newTutorModel, {}) && newSubject != null && newSubject != "") {
            const tutorSubjects = Object.keys(newTutorModel.qualifications || {});
            let foundSubject = false;
            for (const tutorSubject of tutorSubjects) {
                if (tutorSubject == newSubject) {
                    foundSubject = true;
                    break;
                }
            }
            if (foundSubject == false) {
                tutorTeachesSubject = false;
            }
            else {
                tutorTeachesSubject = true
            }

            if (tutorTeachesSubject != true) {
                let pronoun = "they"
                if (newTutorModel.gender == "Male") {
                    pronoun = "he";
                }
                else if (newTutorModel.gender == "Female") {
                    pronoun = "she";
                }
                const newTutorSubjectsWarning = "Warning: " + newTutorModel.firstNames + " hasn't said " + pronoun + " would like to teach " + newSubject;
                if (tutorSubjectWarning != newTutorSubjectsWarning) {
                    setTutorSubjectWarning(newTutorSubjectsWarning);
                }
            }
            else {
                setTutorSubjectWarning(null);
            }
        }
        else {
            setTutorSubjectWarning(null);
        }
    };

    const selectStudentLabel = "Student(s):";

    let defaultStudentValue = null;
    let studentDisabled = false;
    
    if (requestedLesson != null) {
        let requestedStudentIDs = requestedLesson.studentIDs
        if (requestedStudentIDs == null) {
            requestedStudentIDs = [requestedLesson.studentID];
        }
        defaultStudentValue = [];
        for (const studentID of requestedStudentIDs) {
            let label = null;
            for (const studentOption of studentOptions) {
                if (studentID == studentOption.id) {
                    label = studentOption.label;
                }
            }
            defaultStudentValue.push({
                id: studentID,
                label: label
            });
        }
        studentDisabled = true;
    }

    const availableStudentOptions = [...studentOptions];
    for (let optionIndex = 0; optionIndex < availableStudentOptions.length; optionIndex = optionIndex + 1) {
        const option = availableStudentOptions[optionIndex];
        for (const selectedStudent of selectedStudents) {
            if (option.id == selectedStudent.id) {
                availableStudentOptions.splice(optionIndex, 1);
                optionIndex = optionIndex - 1;
            }
        }
    }

    
    let studentWarning = null;
    let numStudentWarnings = 0;
    if (studentsParentWarning != null) {
        studentWarning = studentsParentWarning;
    }
    if (studentLessonTimesWarning != null) {
        studentWarning = studentLessonTimesWarning;
    }
    if (numStudentWarnings > 1) {
        studentWarning = "Warning 1/" + numStudentWarnings + ": " + studentWarning.substring(9, studentWarning.length); 
    }

    const errorTextProps = {...standardTextProps};
    errorTextProps.fontSize = "14px";
    errorTextProps.position = "absolute";
    errorTextProps.color = "#610000";
    errorTextProps.maxWidth = "400px";

    const studentDropDown = <ParentChooseStudentsTemplate 
        id={"student"}
        required={true}
        handleChange={handleQuestionChange}
        answersSuccessfullySubmitted={formSubmitted}
        studentOptions={availableStudentOptions}
        questionLabelProps={standardTextProps}
        questionErrorProps={errorTextProps}
        label={selectStudentLabel}
        extraProps={{
            selectType:"autocomplete"
        }}
        disabled={studentDisabled}
        defaultSelectedStudents={defaultStudentValue}
        warning={studentWarning}
    />

    const createLessonForm = function () {
        const questions = [];

        // questions.push({
        //     id: "student", 
        //     label: "Student:",
        //     description: "Which student is the lesson for",
        //     type: "autocomplete",
        //     options: studentOptions,
        //     required: true, 
        //     defaultValue: defaultStudentValue,
        //     disabled: studentDisabled,
        //     onChange: handleStudentChange,
        //     clearQuestions: ["lessonLocation"]
        // });


        let tutorWarning = null;
        let numTutorWarnings = 0;
        if (tutorAvailabilityWarning != null) {
            tutorWarning = tutorAvailabilityWarning;
            numTutorWarnings = numTutorWarnings + 1;
        }
        if (tutorLessonTimesWarning != null) {
            tutorWarning = tutorLessonTimesWarning;
            numTutorWarnings = numTutorWarnings + 1;
        }
        if (tutorSubjectWarning != null) {
            tutorWarning = tutorSubjectWarning;
            numTutorWarnings = numTutorWarnings + 1
        }
        if (numTutorWarnings > 1) {
            tutorWarning = "Warning 1/" + numTutorWarnings + ": " + tutorWarning.substring(9, tutorWarning.length); 
        }

        questions.push({
            id: "tutor", 
            label: "Tutor:",
            type: "autocomplete",
            options: tutorOptions,
            required: true, 
            //validations: [{type: "ValidTutor"}], 
            defaultValue: null,
            warning: tutorWarning
        });
        
        let defaultSubjectValue = null
        let subjectDisabled = false;
        if (requestedLesson != null) {
            defaultSubjectValue = requestedLesson.subject;
            subjectDisabled = true;
        }
        questions.push({
            id: "subject", 
            label: "Subject:",
            type: "dropdown",
            options: [...subjects],
            required: true, 
            validations: [{type: "ValidSubject"}],
            defaultValue: defaultSubjectValue,
            disabled: subjectDisabled,
            warning: tutorSubjectWarning
        });
        
        let dayWarning = null;
        let numDayWarnings = 0;
        if (tutorAvailabilityWarning != null) {
            dayWarning = tutorAvailabilityWarning;
            numDayWarnings = numDayWarnings + 1;
        }
        if (studentLessonTimesWarning != null) {
            dayWarning = studentLessonTimesWarning;
            numDayWarnings = numDayWarnings + 1
        }
        if (tutorLessonTimesWarning != null) {
            dayWarning = tutorLessonTimesWarning;
            numDayWarnings = numDayWarnings + 1;
        }
        if (numDayWarnings > 1) {
            dayWarning = "Warning 1/" + numDayWarnings + ": " + dayWarning.substring(9, dayWarning.length); 
        }

        questions.push({
            id: "day", 
            label: "Lesson Day:",
            type: "radio",
            options: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
            required: true, 
            validations: [{type: "ValidDay"}],
            warning: dayWarning
        });

        let desiredDurationMinutes = null;
        if (requestedLesson != null) {
            if (requestedLesson.sessionLength != null) {
                desiredDurationMinutes = getSessionLengthMins(requestedLesson.sessionLength);
            }
        }

        questions.push({
            id: "startDate", 
            label: "Lesson start date:",
            type: "startdate", 
            required: true,
            defaultValue: new Date(),
            extraProps: {
                specificDate: true,
            },
            onlyShow: {
                id: "day",
                answers: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
            }
        });
        
        questions.push({
            id: "lessonTime", 
            label: "Lesson Time:",
            type: "timerange",
            required: true, 
            validations: [{type: "ValidTimeRange", desiredDurationMinutes: desiredDurationMinutes}],
            warning: dayWarning
        });

    
        let defaultLessonType = null
        let lessonTypeDisabled = false;
        if (requestedLesson != null && requestedLesson.lessonType != "Either") {
            defaultLessonType = requestedLesson.lessonType;
            lessonTypeDisabled = true;
        }
        
        questions.push({
            id: "lessonType", 
            label: "Lesson Type:",
            type: "radio",
            options: ["In-Person", "Online"],
            required: true, 
            validations: [{type: "Radio"}],
            defaultValue: defaultLessonType,
            disabled: lessonTypeDisabled
        });


        let locationOptions = [];
        let locationDefaultValue = null;
        let locationDisabled = false;
        if (requestedLesson != null) {
            if (requestedLesson.address.label == null ) {
                requestedLesson.address.label = "Requested Location"
            }
            if (requestedLesson.address.address1stLine != null && requestedLesson.address.postcode != null) {
                locationOptions.push(requestedLesson.address);
            }
            locationDisabled = true;
        }
        for (const selectedStudent of selectedStudents) {
            if (selectedStudent.addressList != null) {
                for (const addressListItem of selectedStudent.addressList) {
                    let alreadyFound = false;
                    for (const loctionOption of locationOptions) {
                        if (loctionOption.postcode == addressListItem.postcode) {
                            alreadyFound = true;
                        }
                    }
                    if (alreadyFound == false) {
                        locationOptions.push(...selectedStudent.addressList);
                    }
                }
            }
        }
        if (locationOptions.length > 0) {
            locationDefaultValue = locationOptions[0];
        }
        
        const locationKey = "location" + JSON.stringify(selectedStudents);
        questions.push({
            id: "lessonLocation", 
            label: "Lesson Location:",
            type: "existingornewaddress",
            options: locationOptions,
            required: true,
            defaultValue: locationDefaultValue,
            onlyShow: {id: "lessonType", answers: ["In-Person"]},
            disabled: locationDisabled,
            key: {locationKey}
        });

        const defaultAdminOverrides = {}
        // defaultAdminOverrides["Don't notify tutor"] = false;
        // defaultAdminOverrides["Don't notify parent/student"] = false;

        const adminOverridesOptions = [];
        // adminOverridesOptions.push("Don't notify tutor"),
        // adminOverridesOptions.push("Don't notify parent/student");

        // if (requestedLesson != null) {
        //     adminOverridesOptions.push("Don't delete lesson request");
        //     if (requestedLesson.numSessions > 1) {
        //         defaultAdminOverrides["Don't delete lesson request"] = true;
        //     }
        //     else {
        //         defaultAdminOverrides["Don't delete lesson request"] = false;
        //     }
        // }

        if (tutorSubjectWarning != null) { 
            adminOverridesOptions.push("Ignore tutor's teaching subjects");
            defaultAdminOverrides["Ignore tutor's teaching subjects"] = false;
        }

        if (tutorAvailabilityWarning != null) { 
            adminOverridesOptions.push("Ignore tutor's existing availability");
            defaultAdminOverrides["Ignore tutor's existing availability"] = false;
        }

        if (tutorLessonTimesWarning != null) { 
            adminOverridesOptions.push("Ignore tutor's existing lesson times");
            defaultAdminOverrides["Ignore tutor's existing lesson times"] = false;
        }

        if (studentLessonTimesWarning != null) { 
            adminOverridesOptions.push("Ignore student existing lesson times");
            defaultAdminOverrides["Ignore student existing lesson times"] = false;
        }

        if (studentsParentWarning != null) {
            adminOverridesOptions.push("Ignore students' relationship (not all students share the same parent)");
            defaultAdminOverrides["Ignore students' relationship (not all students share the same parent)"] = false;
        }
        
        if (adminOverridesOptions.length > 0) {
            questions.push({
                key: "adminOverrides" + JSON.stringify(adminOverridesOptions),
                id: "adminOverrides", 
                label: "Admin Overrides:",
                type: "checkbox",
                options: adminOverridesOptions,
                required: false, 
                defaultValue: defaultAdminOverrides,
                disabled: formSubmitted,
                validations: [{type: "ValidLessonAdminOverrideValidations"}],
                showErrorImmediately: true
            });
        }
        
        const submitDisabled = selectedStudents == null || selectedStudents.length < 1;

        const createNewLessonForm = <FormTemplate 
            {...props} 
            submitAnswers={submitAnswers} 
            existingAnswers={questionAnswers} 
            questions={questions} 
            okButtonFunction={exitForm} 
            returnAnswersEarly={{
                handleChange: handleQuestionChange,
                questions: ["tutor", "subject", "lessonTime", "day"]
            }}
            submitDisabled={submitDisabled}
        />
    
        return createNewLessonForm;
    }

    let requestUIs = [];
    let lessonRequestTitle = null;
    if (requestedLesson != null) {
        lessonRequestTitle = <Text {...titleTextProps} >
            Requested lesson details:
        </Text>

        requestUIs.push(
            <Text {...requestTextProps} key={"numSessions"}>
                Lessons per week: {requestedLesson.numSessions}
            </Text>,
            <Text {...requestTextProps} key={"sessionLength"}>
                Session length: {requestedLesson.sessionLength}
            </Text>
        );

        let requestedStartDate = requestedLesson.requestedStartDate;
        if (requestedLesson.requestedStartDate == new Date().toDateString()) {
            requestedStartDate = "As soon as possible";
        }
        requestUIs.push(
            <Text {...requestTextProps} key={"requestedStartDate"}>
                Requested start date: {requestedStartDate}
            </Text>,
        );

        const availabilityUI = [];
        const availabilityDays = Object.entries(requestedLesson.availability);
        const availabilityTitleText = <Text {...standardTextProps} key={"availabilityTitleText"}>
            Given availability:
        </Text>
        availabilityUI.push(availabilityTitleText);
        const dayOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        const dayFlexes = [];
        for (const day of availabilityDays) {
            const dayName = day[0];
            let dayIndex = null;
            for (let validDayIndex = 0; validDayIndex < dayOrder.length; validDayIndex = validDayIndex + 1) {
            const validDay = dayOrder[validDayIndex];
            if (validDay == dayName) {
                dayIndex = validDayIndex;
            }
            }
            const dayAvailability = day[1];

            let dayAvailabilityText = "";
            const dayAvailabilityTextArray = [];
            for (let availabilityIndex = 0; availabilityIndex < dayAvailability.length; availabilityIndex = availabilityIndex + 1) {
                const availability = dayAvailability[availabilityIndex];
                const startTime = availability.startTime;
                const endTime = availability.endTime;
                
                let inserted = false;
                const startTimeHour = parseInt(startTime.split(":")[0]);
                const startTimeMinute = parseInt(startTime.split(":")[1]);
                const startTimeInt = (startTimeHour * 60) + startTimeMinute;
                const windowObject = {
                    startTime: startTime,
                    endTime: endTime,
                    startTimeInt: startTimeInt
                };
                for (let windowIndex = 0; windowIndex < dayAvailabilityTextArray.length; windowIndex = windowIndex + 1) {
                    const window = dayAvailabilityTextArray[windowIndex];
                    if (startTimeInt < window.startTimeInt && inserted == false) {
                        dayAvailabilityTextArray.splice(windowIndex, 0, windowObject)
                        inserted = true;
                    }
                }
                if (inserted == false) {
                    dayAvailabilityTextArray.push(windowObject);
                }
            }
            const timesFlex = [];
            timesFlex.push(<Text {...standardTextProps} key={"DayName:" + dayName}>{dayName}:</Text>);
            for (const window of dayAvailabilityTextArray) {
                dayAvailabilityText = dayAvailabilityText + (window.startTime + " - " + window.endTime) + ",\n";
                const timeText = <Text {...standardTextProps} fontSize={"18px"} key={dayName + window.startTime}>{window.startTime} - {window.endTime}</Text>
                timesFlex.push(timeText);
            }
            let dayAvailabilityFlex = null;
            if (dayAvailabilityText != "") {
                dayAvailabilityFlex = <Flex 
                    {...requestTextProps} 
                    key={dayName + "Flex"} 
                    direction={"column"} 
                    textAlign={"start"} 
                    alignItems={"flex-start"}
                    gap={"0vh"}
                    marginTop={"-25px"}
                    marginLeft={"30px"}
                >
                    {timesFlex}
                </Flex>
            }
            dayFlexes[dayIndex] = dayAvailabilityFlex;
        }
        for (const dayFlex of dayFlexes) {
            if (dayFlex != null) {
                availabilityUI.push(dayFlex);
            }
        }

        requestUIs.push(
            <Flex direction={"column"} alignItems={"flex-start"} key={"availability"}>
                {availabilityUI}
            </Flex>,
        );

        const tutorLevels = [
            //"Only want an A-Level tutor",
            "Would much rather an A-Level tutor",
            "Would rather an A-Level tutor",
            "A-Level tutor would be slightly preferable",
            "I don't mind",
            "University tutor would be slightly preferable",
            "Would rather a university tutor",
            "Would much rather a university tutor",
            //"Only want a university tutor",
        ];

        requestUIs.push(
            <Text {...requestTextProps} key={"tutorLevelValue"}>
                Requested tutor level: {tutorLevels[requestedLesson.tutorLevelValue - 1]}
            </Text>
        );
        
        if (requestedLesson.notes != null) {
            requestUIs.push(
                <Text {...requestTextProps} key={"notes"}>
                    Notes: {requestedLesson.notes}
                </Text>
            );
        }
    }

    let arrowDirection = MdArrowDropDown;
    let showDetailsText = "Hide details";
    if (showDetails == false) {
        requestUIs = null;
        lessonRequestTitle = null;
        arrowDirection = MdArrowRight;
        showDetailsText = "Show details";
    }

    let showDetailsArrow = null;
    if (requestedLesson != null) {
        showDetailsArrow = <Flex
            onClick={() => {
                setShowDetails(!showDetails);
            }}
            style={{cursor:"pointer"}}
            borderRadius={"10px"}
            backgroundColor={"#ffffff"}
            alignItems={"center"}
            width={"230px"}
            height={"35px"}
            position={"relative"}
            alignSelf={"center"}
        >
            <Text 
                {...standardTextProps}
                position={"absolute"}
                left={"10px"}
            >{showDetailsText}</Text>
            <Icon
                key={"editButton"}
                width={"30px"}
                height={"30px"}
                as={arrowDirection}
                right={"10px"}
                position={"absolute"}
            />
        </Flex>
    }

    const backButton = <BackButton {...props} defaultBack={"/Admin"} />


    if (props.userIsAdmin) {
        return (
            <Flex direction={"column"} gap={"3vh"} alignItems={"center"}>
                {backButton}
                <Flex direction={"column"} gap={"1vh"}>
                    {showDetailsArrow}
                    {lessonRequestTitle}
                    {requestUIs}
                </Flex>
                <Flex direction={"column"} gap={"1vh"}>
                    {createNewLessonTitle}
                    {studentDropDown}
                    {createLessonForm()}
                </Flex>
                {redirect}
                
            </Flex>
        );
    }
    else {
        return <Navigate to="/NotAnAdmin" />
    }
    
}

export default CreateNewLesson;