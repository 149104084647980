import {useEffect, useState, React} from 'react';
import { Button, Flex, Icon, Text } from '@aws-amplify/ui-react';
import { LoadingSpinner } from '../pages/LoadingPage';
import {MdDirectionsBike, MdDirectionsCar, MdDirectionsWalk} from 'react-icons/md';

const MapTemplate = function(props) {
    const width = props.width;
    const height = props.height;
    const user = props.user;
    const userAttributes = user.attributes;
    const userSettings = userAttributes.settings || {};
    let mapType = props.type || "view";
    if (mapType != "place" && mapType != "directions" && mapType != "search") {
        mapType = "place";
    }
    const origin = props.origin || {};
    let originGeocode = origin.geocode;
    if (origin.geocode == null && origin != null && typeof(origin) == "string") {
        originGeocode = origin;
    }
    const destination = props.destination || {};
    let destinationGeocode = destination.geocode;
    if (destination.geocode == null && destination != null && typeof(destination) == "string") {
        destinationGeocode = destination;
    }
    const locations = props.locations || [];
    const currentOrigin = window.location.origin;
    
    const directDistance = props.directDistance;

    const [embeddedMapURL, setEmbeddedMapURL] = useState(null);
    const [mapError, setMapError] = useState(null);
    const [transportMode, setTransportMode] = useState(null);
    const [distanceUnit, setDistanceUnit] = useState(null);

    const getMap = async function (directionsMode = "walking", unit = "imperial") {
        try {
            if (mapType == "directions") {
                let address1LocationString = originGeocode;
                if (typeof(originGeocode) == "object") {
                    address1LocationString = originGeocode.latitude + "," + originGeocode.longitude;
                }
                let address2LocationString = destinationGeocode;
                if (typeof(destinationGeocode) == "object") {
                    address2LocationString = destinationGeocode.latitude + "," + destinationGeocode.longitude;
                }
                let zoomLevel = null;
                if (directDistance != null) {
                    if (directDistance < 1) {
                        zoomLevel = 15;
                    }
                    else if (directDistance < 2) {
                        zoomLevel = 14;
                    }
                    else if (directDistance < 10) {
                        zoomLevel = 13;
                    }
                    else if (directDistance < 20) {
                        zoomLevel = 11;
                    }
                    if (width < 1000) {
                        if (directDistance < 1) {
                            zoomLevel = 14;
                        }
                        else if (directDistance < 2) {
                            zoomLevel = 13;
                        }
                        else if (directDistance < 10) {
                            zoomLevel = 12;
                        }
                        else if (directDistance < 20) {
                            zoomLevel = 11;
                        }
                    }
                }
                let zoomString = "";
                if (zoomLevel != null) {
                    zoomString = "&zoom=" + zoomLevel
                }
                
                try {
                    if (currentOrigin == "https://app.nextdoortutor.co.uk") {
                        const URL = "https://www.google.com/maps/embed/v1/" + mapType + "?key=AIzaSyAu_Q0lCtxElbujso1db_-XdQ_S54Ncb20&mode=" + directionsMode + "&units=" + unit +"&origin=" + address1LocationString + "&destination=" + address2LocationString;
                        const actualMap = await fetch(URL);
                        if (actualMap.status != 200) {
                            throw "App domain not authorised";
                        }
                        setEmbeddedMapURL(URL);
                    }
                    else if (currentOrigin == "http://localhost:3000") {
                        const URL = "https://www.google.com/maps/embed/v1/" + mapType + "?key=AIzaSyDRCmDmqhN_d_s5Lth5UGZAt5EzmhODuDQ" + "&mode=" + directionsMode + zoomString + "&units=" + unit + "&origin=" + address1LocationString + "&destination=" + address2LocationString;
                        //const URL = "https://www.google.com/maps/embed/v1/" + mapType + "?key=AIzaSyDfE-zGlHibERtS2O0RZukxa6_SDhAhazM&mode=" + directionsMode + zoomString + "&units=" + distanceUnit + "&origin=" + address1LocationString + "&destination=" + address2LocationString;
                        const backUpMap = await fetch(URL);
                        if (backUpMap.status != 200) {
                            throw "IP not authorised";
                        }
                        setEmbeddedMapURL(URL);
                    }
                    else {
                        throw "Invalid App Domain"
                    }
                }
                catch (error) {
                    console.log(error);
                    //throw error;
                    setEmbeddedMapURL("https://www.google.com/maps/embed?pb=!4v1676773260570!6m8!1m7!1sCAoSLEFGMVFpcE9Hc1d5LWJTdVVHbXd0U3FqMWd0UTU4UUtCa2lMbXd1QWNkU21j!2m2!1d45.9648933!2d9.2023888!3f169.69813445828473!4f-2.414805518708434!5f0.7820865974627469");
                }
            }
            else if (mapType == "place") {
                let locationString = originGeocode;
                if (typeof(originGeocode) == "object") {
                    locationString = originGeocode.latitude + "," + originGeocode.longitude;
                }
                try {
                    if (currentOrigin == "https://app.nextdoortutor.co.uk") {
                        const URL = "https://www.google.com/maps/embed/v1/" + "place" + "?key=AIzaSyAu_Q0lCtxElbujso1db_-XdQ_S54Ncb20" + "&q=" + locationString + "&zoom=15";
                        const actualMap = await fetch(URL);
                        if (actualMap.status != 200) {
                            throw "App IP not authorised";
                        }
                        setEmbeddedMapURL(URL);
                    }
                    else if (currentOrigin == "http://localhost:3000") {
                        const URL = "https://www.google.com/maps/embed/v1/" + "place" + "?key=AIzaSyDRCmDmqhN_d_s5Lth5UGZAt5EzmhODuDQ" + "&q=" + locationString + "&zoom=15";
                        const backUpMap = await fetch(URL);
                        if (backUpMap.status != 200) {
                            throw "IP not authorised";
                        }
                        setEmbeddedMapURL(URL);
                    }
                    else {
                        throw "Invalid App Domain"
                    }
                }
                catch (error) {
                    console.log(error);
                    //throw error;
                    setEmbeddedMapURL("https://www.google.com/maps/embed?pb=!4v1676773260570!6m8!1m7!1sCAoSLEFGMVFpcE9Hc1d5LWJTdVVHbXd0U3FqMWd0UTU4UUtCa2lMbXd1QWNkU21j!2m2!1d45.9648933!2d9.2023888!3f169.69813445828473!4f-2.414805518708434!5f0.7820865974627469");
                }
            }
            else if (mapType == "search") {
                try {
                    if (currentOrigin == "https://app.nextdoortutor.co.uk") {
                        const URL = "https://www.google.com/maps/embed/v1/" + mapType + "?key=AIzaSyAu_Q0lCtxElbujso1db_-XdQ_S54Ncb20" + "&q=London";
                        const actualMap = await fetch(URL);
                        if (actualMap.status != 200) {
                            console.log(URL);
                            throw "App IP not authorised";
                        }
                        setEmbeddedMapURL(URL);
                    }
                    else if (currentOrigin == "https://app.nextdoortutor.co.uk") {
                        const URL = "https://www.google.com/maps/embed/v1/" + mapType + "?key=AIzaSyDRCmDmqhN_d_s5Lth5UGZAt5EzmhODuDQ" + "&q=London";
                        const actualMap = await fetch(URL);
                        if (actualMap.status != 200) {
                            console.log(URL);
                            throw "App IP not authorised";
                        }
                        setEmbeddedMapURL(URL);
                    }
                }
                catch (error) {
                    console.log(error);
                    //throw error;
                    setEmbeddedMapURL("https://www.google.com/maps/embed?pb=!4v1676773260570!6m8!1m7!1sCAoSLEFGMVFpcE9Hc1d5LWJTdVVHbXd0U3FqMWd0UTU4UUtCa2lMbXd1QWNkU21j!2m2!1d45.9648933!2d9.2023888!3f169.69813445828473!4f-2.414805518708434!5f0.7820865974627469");
                }
            }
        }
        catch (error) {
            console.log(error);
            setMapError("Error loading map");
            setMapError(error);
        }
    }

    useEffect(() => {
        let mode = props.mode || "walking";
        if (mode == "cycling") {
            mode = "bicycling";
        }
        if (mode != "walking" && mode != "driving" && mode != "bicycling") {
            mode = "walking";
        }

        let unit = props.distanceUnit;
        if (props.distanceUnit == null) {
            unit = userSettings.distanceUnit || "imperial";
        }
        if (unit != "metric" && unit != "imperial") {
            unit = "imperial";
        }

        setTransportMode(mode);
        setDistanceUnit(unit);
        getMap(mode, unit);
    }, []);

    if (mapError != null) {
        return <Flex>
            <Text>
                {mapError}
            </Text>
        </Flex>
    }

    const updateMode = function (newMode) {
        if (mapType != "directions") {
            return;
        }
        if (newMode != "walking" && newMode != "bicycling" && newMode != "driving") {
            return;
        }
        if (transportMode != newMode) {
            setTransportMode(newMode);
            getMap(newMode, distanceUnit);
        }
    }
    const updateUnits = function (newUnit) {
        if (mapType != "directions") {
            return;
        }
        if (newUnit != "metric" && newUnit != "imperial" && newUnit != "miles" && newUnit != "km" && newUnit != "kilometers") {
            return;
        }
        if (newUnit == "km" || newUnit == "kilometers") {
            newUnit = "metric";
        }
        if (newUnit == "miles") {
            newUnit = "imperial";
        }
        if (distanceUnit != newUnit) {
            setDistanceUnit(newUnit);
            getMap(transportMode, newUnit);
        }
    }   

    let walkingButton = null;
    let drivingButton = null;
    let cyclingButton = null;
    let unitsButton = null;
    if (mapType == "directions") {
        walkingButton = <Button
            onClick = {() => {
                updateMode("walking")
            }}
            backgroundColor={"#ffffff"}
            position={"absolute"}
            right={"95px"}
            top={"5px"}
            width={"40px"}
            height={"40px"}
        >
            <Icon
                key={"walkingButton"}
                width={"30px"}
                height={"30px"}
                as={MdDirectionsWalk}
            />
        </Button>

        drivingButton = <Button
            onClick = {() => {
                updateMode("driving")
            }}
            backgroundColor={"#ffffff"}
            position={"absolute"}
            right={"50px"}
            top={"5px"}
            width={"40px"}
            height={"40px"}
        >
            <Icon
                key={"drivingButton"}
                width={"30px"}
                height={"30px"}
                as={MdDirectionsCar}
            />
        </Button>

        cyclingButton = <Button
            onClick = {() => {
                updateMode("bicycling")
            }}
            backgroundColor={"#ffffff"}
            position={"absolute"}
            right={"5px"}
            top={"5px"}
            width={"40px"}
            height={"40px"}
        >
            <Icon
                key={"cyclingButton"}
                width={"30px"}
                height={"30px"}
                as={MdDirectionsBike}
            />
        </Button>

        let newUnit = "metric";
        let newUnitText = "Km";
        if (distanceUnit == "metric") {
            newUnit = "imperial"
            newUnitText = "Mi";
        }
        unitsButton = <Button
            onClick = {() => {
                updateUnits(newUnit);
            }}
            backgroundColor={"#ffffff"}
            position={"absolute"}
            right={"5px"}
            top={"50px"}
            width={"40px"}
            height={"40px"}
        >
            {newUnitText}
        </Button>
    }

    if (embeddedMapURL != null) {
        let map = <iframe
            name={"embeddedMap"}
            id={"embeddedMap"}
            width={width}
            height={height}
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade"
            src={embeddedMapURL}
            //srcDoc={embeddedMapURL}
        />

        return <Flex position={"relative"}>
            {walkingButton}
            {drivingButton}
            {cyclingButton}
            {unitsButton}
            {map}
        </Flex>;
    }
    else {
        return <LoadingSpinner />
    }
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default MapTemplate;