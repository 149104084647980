import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, RadioGroupField, SelectField, Text, TextField } from "@aws-amplify/ui-react";
import { useState, useEffect } from 'react';
import { validateField } from "./FormValidation";
import OptionsTemplate from "./OptionsTemplate";
import DropDownQuestionTemplate from "./DropDownQuestionTemplate";
import { DayPicker } from "react-day-picker";
import RadioButtonsTemplate from "./RadioButtonsTemplate";

const StartDateTemplate = function(props) {
  try {
    const labelTextProps = props.questionLabelProps || {};
    const errorTextProps = props.questionErrorProps || {};

    //Set question variables from passed properties
    const questionID = props.id;
    const questionLabel = props.label;
    const questionDescription = props.description;
    const required = props.required;
    const requiredAsterisk = props.requiredAsterisk;

    const currentDate = new Date().toDateString();
    const defaultValue = props.defaultValue || currentDate;
    let defaultSelected = "As soon as possible";
    if (new Date(defaultValue).toDateString() != currentDate) {
      defaultSelected = "On/after a future date";
    }
    const defaultDate = new Date (defaultValue);

    const questionValidations = props.validations;
    let addressValidationExists = false;
    for (let validation of questionValidations) {
      if (validation.type === "ValidDate") {
        addressValidationExists = true;
      }
    }
    
    const answersSuccessfullySubmitted = props.answersSuccessfullySubmitted;

    const extraProps = props.extraProps;
    const specificDate = extraProps.specificDate;

    const onlyDay = props.day;

    
    //Set state variables used to store the current state and any errors
    const [selectedAnswer, setSelectedAnswer] = useState(defaultSelected);
    const [questionAnswer, setQuestionAnswer] = useState(new Date(defaultDate));
    const [errors, setErrors] = useState({questionError:""});

    useEffect(() => {
      const validationResult = validateField(questionAnswer, questionValidations);
      const questionHasError = validationResult.hasError;
      setErrors({
        questionHasError: questionHasError,
        questionError: validationResult.errorMessage
      });
      props.handleChange(questionID, new Date(questionAnswer).toDateString(), questionHasError);
    }, []);

    let labelColour = labelTextProps.color || "#000000";
    if (props.validationError != null && props.validationError != null) {
      labelColour = props.validationErrorColour || "#ff0000";
    }
    //Get the text for the question label
    const labelText = <Text {...labelTextProps} color={labelColour}>
      {questionLabel}
    </Text>

    const descriptionText = <Text {...labelTextProps} fontSize={"14px"} fontStyle={"italic"}>
      {questionDescription}
    </Text>
    
    let errorText = <Text {...errorTextProps}>
      {errors.questionError}
    </Text>

    let renderedRequiredAsterisk = null;
    if (required == true) {
      renderedRequiredAsterisk = requiredAsterisk;
    }

    
    if (errors.errorMessage === null) {
      setErrors({
        questionError:""
      });
    }

    const localHandleChange = function (input, answer, error) {
      if (input == "radio") {
        setSelectedAnswer(answer);
        if (answer == "As soon as possible") {
          setQuestionAnswer(new Date(currentDate));
          const validationResult = validateField(currentDate, questionValidations);
          const questionHasError = validationResult.hasError;
          const questionErrorMessage = validationResult.errorMessage;
          setErrors({
            questionHasError:questionHasError, 
            questionError:questionErrorMessage,
          });
          props.handleChange(questionID, currentDate, questionHasError);
        }
      }
      else if (input == "date") {
        const newDate = new Date(answer).toDateString();
        setQuestionAnswer(answer);
        const validationResult = validateField(newDate, questionValidations);
        const questionHasError = validationResult.hasError;
        const questionErrorMessage = validationResult.errorMessage;
        setErrors({
          questionHasError:questionHasError, 
          questionError:questionErrorMessage,
        });
        props.handleChange(questionID, newDate, questionHasError);
      }
    }

    //Generate the radio button field component
    let radioButtons = <RadioButtonsTemplate
      id={"radio"}
      answersSuccessfullySubmitted={answersSuccessfullySubmitted}
      //Runs when it detects a change in the input field
      handleChange={localHandleChange}
      options={["As soon as possible", "On/after a future date"]}
      defaultValue={defaultSelected}
      required={required}
      questionLabelProps={labelTextProps}
      questionErrorProps={errorTextProps}
    />

    if (specificDate == true) {
      radioButtons = null;
    }


    let datePicker = null;
    if (selectedAnswer == "On/after a future date" || specificDate == true) {
      let disabled = [];
      let onlyDayNum = null;
      if (onlyDay != null) {
        if (onlyDay == "Monday") {
          onlyDayNum = 1;
        }
        else if (onlyDay == "Tuesday") {
          onlyDayNum = 2;
        }
        else if (onlyDay == "Wednesday") {
          onlyDayNum = 3;
        }
        else if (onlyDay == "Thursday") {
          onlyDayNum = 4;
        }
        else if (onlyDay == "Friday") {
          onlyDayNum = 5;
        }
        else if (onlyDay == "Saturday") {
          onlyDayNum = 6;
        }
        else if (onlyDay == "Sunday") {
          onlyDayNum = 0;
        }
        disabled.push(
          (date) => {
            const dateDay = date.getDay();
            if (dateDay != onlyDayNum) {
              return true;
            }
            else {
              return false;
            }
          }
        );

        if (questionAnswer != null && questionAnswer.getDay() != onlyDayNum) {
          setQuestionAnswer(null);
        }
      }


      if (answersSuccessfullySubmitted) {
        disabled = true;
      }

      datePicker = <Flex backgroundColor={"#eeeeee50"} borderRadius={"10px"}><DayPicker
        key={"dayPicker" + onlyDay}
        disabled={disabled}
        mode="single"
        selected={questionAnswer}
        onSelect={(newDateAnswer)=> {
          localHandleChange("date", newDateAnswer);
        }}
        fromDate={new Date()}
        styles={{
            //caption: { color: 'blue' },
            //head: { color: 'red' },
            //button: { color: 'red' },
            //button: {color: 'black', backgroundColor: 'red' },  
        }}
      /></Flex>
    }

    //Return text field component to calling function (Most likely a Form Template)
    return (
      selectedAnswer,
      <div className="RadioGroupField">
          <Flex direction={"column"} gap={"1vh"}>
            <Flex direction={"column"} gap={"0.5vh"}>
              <Flex alignItems={"center"}>
                {renderedRequiredAsterisk}
                {labelText}
              </Flex>
              {descriptionText}
              <Flex direction={"column"} alignItems={"center"} gap={"1vh"}> 
                {radioButtons}
                {datePicker}
              </Flex>
            </Flex>
            <Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
              {errorText}
            </Flex>
          </Flex>
      </div>
    );
  }
  catch (error) {
    throw "StartDateTemplate Error: " + error;
  }
}

export default StartDateTemplate;