import * as React from "react";
import { Flex, SelectField, Text } from "@aws-amplify/ui-react";
import { useState, useEffect } from 'react';
import { validateField } from "./FormValidation";
import OptionsTemplate from "./OptionsTemplate";
import { isJSON } from "../../App";

const DropDownTemplate = function(props) {
	try {
		const [selectedAnswer, setSelectedAnswer] = useState(null);
		const [options, setOptions] = useState(null);
		const [questionValidations, setQuestionValidations] = useState(null);

		const disabled = props.disabled;
		const placeholder = props.placeHolder || props.placeholder;
		const value = props.value;

		const blankHandleChange = function() {
			console.error("DropDownTemplate: handleChange function not passed to component");
		};
		const onChange = props.onChange || props.handleChange || blankHandleChange;

		//Set question validations
		useEffect(() => {
			const newValidations = [];
			if (Array.isArray(props.validations)) {
				for (const validation of props.validations) {
					if (isJSON(validation) && validation.hasOwnProperty("type")) {
						newValidations.push(validation);
					}
					else {
						console.error("Invalid validation: " + JSON.stringify(validation));
					}
				}
			}
			setQuestionValidations(newValidations);
		}, [props.validations]);

		//Set question options and set selected answer to default value
		useEffect(() => {
			const givenOptions = props.options || props.children;
			if (!Array.isArray(givenOptions) || givenOptions.length == 0) {
				console.log("No options given for dropdown");
				return;
			}
			if (givenOptions[0].hasOwnProperty("props")) {
				setOptions(givenOptions);
				return;
			}
			const newOptionsArray = OptionsTemplate(givenOptions, "dropdown");
			setOptions(newOptionsArray);
		}, [props.options, props.children]);

		//Pass the new answer to the parent component
		useEffect(() => {
			if (questionValidations == null) {
				return;
			}

			let questionHasError = false;
			let errorMessage = "";
			if (questionValidations.length > 0) {
				const validationResult = validateField(selectedAnswer, questionValidations);
				questionHasError = validationResult.hasError;
				errorMessage = validationResult.errorMessage;
			}
			onChange(selectedAnswer, questionHasError, errorMessage);
		}, [selectedAnswer, questionValidations]);

		//Set the selected answer to the value passed in
		useEffect(() => {
			setSelectedAnswer(value);
		}, [value]);

		useEffect(() => {
			if (options == null) {
				return;
			}
			
			if (selectedAnswer == null && placeholder == null) {
				setSelectedAnswer(options[0].props.value);
			}
		}, [options, selectedAnswer, placeholder]);

		return <SelectField
			key={"SelectField"}
			placeholder={placeholder}
			value={selectedAnswer}
			size={props.size}
			label={props.label}
			isDisabled={disabled}
			{...props.style}

			//Runs when it detects a change in the input field
			onChange={(e) => {
				const currentValue = e.target.value;
				setSelectedAnswer(currentValue);
			}} 
		>
			{options}
		</SelectField>;
	}
	catch (error) {
		throw "DropDownTemplate Error: " + error;
	}
}

export default DropDownTemplate;