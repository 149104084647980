import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Button, Text, Menu, MenuItem, Divider } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";
import {useEffect, useState} from 'react';


const HamburgerMenuTemplate = function(props) {
  const titleTextProps = props.titleTextProps;
  const standardTextProps = props.standardTextProps;
  const buttonList = props.buttons;
  let numButtons = 0
  if (buttonList != null) {
    numButtons = buttonList.length;
  }

  let signOutButton = false;
  const buttonRenderList = [];
  for (let buttonIndex = 0; buttonIndex < numButtons; buttonIndex = buttonIndex + 1) {
    signOutButton = false;
    //Get the passed properties of the button
    const buttonProps = buttonList[buttonIndex];
    //Get the button text

    let topBorderRadius = "0";
    let bottomBorderRadius = "0";
    if (buttonIndex == 0) {
      topBorderRadius = "1vh"
    }
    else if (buttonIndex == numButtons - 1) {
      bottomBorderRadius = "1vh"
    }

    const buttonText = <Text
      color="#08007d"
      fontSize={"4vw"}
      textAlign={"left"}
      >
        {buttonProps.text}
    </Text>

    //Get the link for the button
    const buttonLink = buttonProps.link;
    //Check if it's a sign out button
    if (buttonProps.signOutUser == true) {
      signOutButton = true;
    }

    //Create the button
    const button = <MenuItem 
      key={"menuButton:" + buttonText} 
      width={"100%"}
      justifyContent="left"
      style={{
        borderTopLeftRadius: topBorderRadius,
        borderTopRightRadius: topBorderRadius,
        borderBottomLeftRadius: bottomBorderRadius,
        borderBottomRightRadius: bottomBorderRadius
      }}
    >
      {buttonText}
    </MenuItem>

    //Add the link to the button
    const linkedButton = <Link to={buttonLink} style={{textDecoration: 'none'}} key={buttonProps.text}>{button}</Link>;

    //Add the linked button to the array of buttons that will be returned to be rendered
    buttonRenderList.push(linkedButton);

    if (buttonIndex < numButtons - 1) {
      const menuDivider = <Divider key={"Divider: " + buttonIndex}/>
      buttonRenderList.push(menuDivider)
    }
  }

  const hamburger = <Menu
    size="large"
    menuAlign="start"
    width={"80vw"}
    backgroundColor={"#e1ecfa"}
    borderRadius={"1vh"}
  >
    {buttonRenderList}
  </Menu>

  //Return full navigation bar
  return (
    <div className="HamburgerMenu">
      {hamburger}
    </div>
  );
}

export default HamburgerMenuTemplate;