import { Flex, Text } from '@aws-amplify/ui-react';
import React from 'react';
import {Link, Navigate} from "react-router-dom";
import {AppNavButton, FormTemplate} from '../../custom-ui-components'
import { useState } from 'react';
import * as Auth from 'aws-amplify/auth';

const UserNeedsAttributes = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    const APIFunctions = props.APIFunctions;
    const [questionAnswers, setQuestionAnswers] = useState({});

    //Get the current user to pass to subsequent functions
    const user = props.user;
    const userAttributes = user.attributes

    const neededAttributes = [];
    if (userAttributes["name"] == null) {
        neededAttributes.push("name");
    }
    if (userAttributes["custom:phoneNumber"] == null) {
        neededAttributes.push("phoneNumber");
    }
    if (neededAttributes.length === 0) {
        return <Navigate to="/" />
    }

    const width = props.width;
    const height = props.height;

    let displayUsername = user.attributes.email;
    if (user.attributes.name != null) {
        displayUsername = user.attributes.name;
    }

    //Check which attributes are needed
    let needsName = false;
    let needsPhoneNumber = false;
    for (let neededAttributeIndex = 0; neededAttributeIndex < neededAttributes.length; neededAttributeIndex = neededAttributeIndex + 1) {
        const neededAttribute = neededAttributes[neededAttributeIndex];
        if (neededAttribute === "name") {
            needsName = true;
        }
        else if (neededAttribute === "phoneNumber") {
            needsPhoneNumber = true;
        }
    }

    //Create an object for each needed question that is passed to the form template
    let questions = [];

    if (needsName) {
        questions.push({
            id: "name", 
            label: "Full Name:", 
            type: "text", 
            required: true, 
            validations: [{type: "Name"}], 
            defaultValue: null, 
            placeholder: "Enter name"
        });
    }
    if (needsPhoneNumber) {
        questions.push({
            id: "phoneNumber", 
            label: "UK Mobile Phone Number:", 
            type: "text", 
            required: true, 
            validations: [{type: "UKMobPhone"}], 
            defaultValue: null, 
            placeholder: "Enter phone number"
        });
    }
    
    const submitAnswers = async function(returnedAnswers) {
        setQuestionAnswers(returnedAnswers);
        console.log("Returned Answers");
        const answers = Object.entries(returnedAnswers);

        const newAttributes = [];
        for (const answer of answers) {
            const questionID = answer[0];
            const answerValue = answer[1];

            if (questionID === "name") {
                const newAttribute = {Name:"name", Value:answerValue};
                newAttributes.push(newAttribute)
            }
            else if (questionID === "phoneNumber") {
                const newAttribute = {Name:"custom:phoneNumber", Value:answerValue};
                newAttributes.push(newAttribute)
            }
        }

        try {
            const updateAttributesSuccess = await APIFunctions.updateUserAttributes(newAttributes);
            console.log(updateAttributesSuccess);
            console.log(Auth.getCurrentUser().attributes);

        }
        catch (error) {
            console.log("Error updating attributes. Given error:\n" + error);
        }
    }

    const exitForm = function () {

    }

    const dashboardText1 = <Text
        color="#08007d"
        fontWeight={"bold"}
        fontSize="30px"
        textAlign={"center"}
        marginBottom={"2vh"}
        marginLeft="20vw"
        marginRight="20vw"
    >
      Welcome to the Next Door Tutor App
    </Text>

    const dashboardText2 = <Text
        color="#08007d"
        fontWeight={"normal"}
        fontSize="20px"
        textAlign={"center"}
        marginLeft="10vw"
        marginRight="10vw"
    >
        Before you can access the full app, you'll need to fill in some details:
    </Text>

    const signOutButton = <Link to={"/SignOut"} style={{textDecoration: 'none'}}> 
    <AppNavButton text={"Sign Out"} width={width} />
    </Link>

    return (  
        <Flex direction={"column"} justifyContent="center" alignItems="center" gap="1vh" wrap={"wrap"}>
            {dashboardText1}
            <Flex marginBottom={"2vh"}>
                {signOutButton}
            </Flex>
            {dashboardText2}
            <FormTemplate {...props} submitAnswers={submitAnswers} existingAnswers={questionAnswers} questions={questions} okButtonFunction={exitForm} />
        </Flex>
    );
}


//Return the correct page back up to the app and index files (with authenticator to force login)
export default UserNeedsAttributes;