import { Badge, Flex, Grid, Icon, Text } from "@aws-amplify/ui-react";
import { Link, Navigate } from "react-router-dom";
import {React, useState, useEffect} from 'react';
import {MdModeEdit, MdDelete } from 'react-icons/md';
import { LoadingSpinner } from "../pages/LoadingPage";
import { ErrorLoadingPage } from "../pages/ErrorLoadingPage";
import { calculateLessonWage, getSessionLength, getSessionLengthMins, modelsStringBuilder } from "../App";

const LessonRequestTemplate = function(props) {
    const standardTextProps = {...props.standardTextProps};
    const standardTitleProps = {...props.titleTextProps};

    const width = props.width;
    const height = props.height;
    const pageType = props.pageType;
    const userGroups = props.userGroups;
    const tutorRequestDictionary = props.tutorRequestDictionary;
    const lessonRequestDictionary = pageType == "admin" && props.adminLessonRequestDictionary || props.lessonRequestDictionary;
    const studentModel = props.studentModel;
    const tutorModel = props.tutorModel;
    const studentDictionary = props.studentDictionary;
    const tutorDictionary = props.tutorDictionary;

    const [redirect, setRedirect] = useState(null);
    const [requestDictionary, setRequestDictionary] = useState(null);
    const [studentDetails, setStudentDetails] = useState(null);
    const [tutorDetails, setTutorDetails] = useState(null);
    const [requestProps, setRequestProps] = useState(null);
    const [noRequestModel, setNoRequestModel] = useState(false);
    const [userType, setUserType] = useState(null);
    

    //Set request dictionary
    useEffect(() => {
        if (pageType == null) {
            return;
        }
        if (pageType == "tutor") {
            if (tutorRequestDictionary == null) {
                return;
            }
            setRequestDictionary(tutorRequestDictionary);
        }
        else {
            if (lessonRequestDictionary == null) {
                return;
            }
            setRequestDictionary(lessonRequestDictionary);
        }
    }, [lessonRequestDictionary, tutorRequestDictionary, pageType]);
    
    //Set request props
    useEffect(() => {
        if (requestDictionary == null) {
            return;
        }

        //const queryParams = new URLSearchParams(window.location.search);
        //const requestID = queryParams.get("requestID");
        const requestID = props.requestID;
        if (requestID == null) {
            console.log("No request ID");
            setNoRequestModel(true);
            return;
        }
        const requestModel = requestDictionary[requestID];
        if (requestModel == null) {
            console.log("Request not found");
            setNoRequestModel(true);
            return;
        }

        const requestType = requestModel["_typename"];
        requestModel.requestType = requestType;

        setRequestProps(requestModel);
    }, [requestDictionary, props.requestID, window.location.search]);

    //Set user types
    useEffect(() => {
        if (props.userID == null || userGroups == null || requestProps == null || pageType == null) {
            return;
        }

        const studentIDs = requestProps.studentIDs || [];
        const tutorID = requestProps.tutorID;
        const parentID = requestProps.parentID;
        let isRequestStudent = false;
        let isRequestParent = false;
        let isRequestTutor = false;
        if (studentIDs.includes(props.userID)) {
            isRequestStudent = true;
        }
        if (parentID == props.userID) {
            isRequestParent = true;
        }
        if (tutorID == props.userID) {
            isRequestTutor = true;
        }

        setUserType({
            isRequestStudent: isRequestStudent,
            isRequestParent: isRequestParent,
            isRequestTutor: isRequestTutor,
            userIsAdmin: userGroups.userIsAdmin,
            userType: pageType
        });
    }, [props.userID, pageType, userGroups, requestProps]);

    //Get student details
    useEffect(() => {
        if (userType == null || requestProps == null) {
            return;
        }
        if (userType.isRequestStudent) {
            if (studentModel == null) {
                return;
            }
        }
        else if (userType.isRequestParent || userType.userIsAdmin) {
            if (studentDictionary == null) {
                return;
            }
        }
        else if (userType.isRequestTutor) {
            const requestStudentDetails = requestProps.studentDetails;
            if (requestStudentDetails == null) {
                console.log("No student details in request");
                setNoRequestModel(true);
                return;
            }
        }
        else {
            return;
        }

        let studentDetailsString = null;    
        let studentNamesString = null;
        let numStudents = null;
        if (userType.isRequestTutor) {
            studentDetailsString = requestProps.studentDetails.studentDetailsString;
            numStudents = requestProps.studentDetails.numStudents;
        }
        else {
            const studentIDs = requestProps.studentIDs;
            if (studentIDs == null) {
                console.log("No student IDs");
                setNoRequestModel(true);
                return;
            }
            //Get student models
            const studentModels = [];
            for (const studentID of studentIDs) {
                let requestStudentModel = null;
                if (userType.isRequestStudent) {
                    requestStudentModel = studentModel;
                }
                else {
                    requestStudentModel = studentDictionary[studentID];
                }
                if (requestStudentModel == null) {
                    console.log("Student model not found");
                    setNoRequestModel(true);
                    return;
                }
                studentModels.push(requestStudentModel);
            }
            
            //Get student details
            numStudents = studentIDs.length;
            const studentDetailsTemplate = [
                {type: "key", key: "firstNames"},
                {type: "string", string: " "},
                {type: "key", key: "lastName"},
                {type: "string", string: " (", }, 
                {type: "key", key: "yearGroup"}, 
                {type: "string", string: " - ", conditions: [{parameter: "key", type: "exists", key: "schoolSet"}]}, 
                {type: "key", key: "schoolSet", conditions: [{parameter: "key", type: "exists", key: "schoolSet"}]},
                {type: "string", string: ")"}
            ];
            studentDetailsString = modelsStringBuilder(studentModels, studentDetailsTemplate, ", ", ", ");

            const namesTemplate = [
                {type: "key", key: "firstNames"},
                {type: "string", string: " "},
                {type: "key", key: "lastName"},
            ];
            studentNamesString = modelsStringBuilder(studentModels, namesTemplate, ", ", ", ");
        }

        setStudentDetails({
            studentDetailsString: studentDetailsString,
            studentNamesString: studentNamesString,
            numStudents: numStudents
        });
    }, [requestProps, studentDictionary, studentModel, userType]);

    //Get tutor details
    useEffect(() => {
        if (userType == null || requestProps == null) {
            return;
        }
        if (userType.isRequestTutor) {
            if (tutorModel == null) {
                return;
            }
        }
        else if (userType.userIsAdmin) {
            if (tutorDictionary == null) {
                return;
            }
        }
        else {
            return;
        }

        if (requestProps.requestType == "TutorRequest") {
            const tutorID = requestProps.tutorID;
            if (tutorID == null) {
                console.log("No tutor ID");
                setNoRequestModel(true);
                return;
            }

            let requestTutorModel = null;
            if (userType.isRequestTutor) {
                requestTutorModel = tutorModel;
            }
            else {
                requestTutorModel = tutorDictionary[tutorID];
            }

            if (requestTutorModel == null) {
                console.log("Tutor model not found");
                setNoRequestModel(true);
                return;
            }

            const tutorName = requestTutorModel.firstNames + " " + requestTutorModel.lastName;
            const tutorLevel = requestTutorModel.level;
            setTutorDetails({
                tutorName: tutorName, 
                tutorLevel: tutorLevel
            });
        }
    }, [requestProps, studentDictionary, studentModel, userType]);

    standardTextProps.fontSize = "1.2vw";
    standardTitleProps.fontSize = "1.2vw";

    standardTextProps.fontSize = "17px";
    standardTitleProps.fontSize = "17px";


    if (noRequestModel) {
        return <Text>Request error</Text>
    }

    if (userType == null || requestProps == null) {
        return <LoadingSpinner {...props} />
    }
    if ((userType.isRequestStudent || userType.isRequestStudent || userType.userIsAdmin) && studentDetails == null) {
        return <LoadingSpinner {...props} />
    }
    if (requestProps.requestType == "TutorRequest" && (userType.isRequestTutor || userType.userIsAdmin) && tutorDetails == null) {
        return <LoadingSpinner {...props} />
    }
    
    let showAddress = props.showAddress;
    if (showAddress == null){
        showAddress = true;
    }

    const widthThreshold = 830;

    let attributesGap = "2vw";
    let attributeGap = "1vh";
    if (width < widthThreshold) {
        attributesGap = "1vh";
        attributeGap = "2vw"
        standardTitleProps.textAlign = "left";
        standardTextProps.textAlign = "left";
    }

    const getColumn = function(type, attributeNum) {
        if (width < widthThreshold) {
            if (type == "label") {
                return 1;
            }
            else {
                return 3;
            }
        }
        else {
            return attributeNum;
        }
    }

    const getRow = function(type, attributeNum) {
        if (width < widthThreshold) {
            return attributeNum;
        }
        else {
            if (type == "label") {
                return 1;
            }
            else {
                return 3;
            }
            
        }
    }


    const getRequestAttributesText = function () {
        let subject = requestProps.subject;
        if (subject == null) {
            subject = "No subject given";
        }
        let lessonType = requestProps.lessonType;
        if (lessonType == null) {
            lessonType = "No type given";
        }
        let address1stLine = "No address given";
        let postcode = "No postcode given";
        try {
            if (requestProps.address != null) {
                address1stLine = requestProps.address.address1stLine;
                postcode = requestProps.address.postcode;
            }
            else {
                postcode = requestProps.postcode
            }
        }
        catch {}

        let numSessions = requestProps.numSessions;
        if (numSessions == null) {
            numSessions = "No numSessions given";
        }
        let sessionLength = requestProps.sessionLength;
        if (sessionLength == null) {
            sessionLength = "No sessionLength given";
        }
        let availability = requestProps.availability;
        if (availability == null) {
            availability = "No availability given";
        }
        let tutorWageInPerson = null;
        let tutorWageOnline = null;
        if (tutorDetails != null) {
            const tutorLevel = tutorDetails.tutorLevel;
            console.log(requestProps.sessionLength);

            const lessonDuration = getSessionLengthMins(requestProps.sessionLength);
            tutorWageInPerson = calculateLessonWage(tutorLevel, null, null, "In-Person", true, lessonDuration);
            tutorWageOnline = calculateLessonWage(tutorLevel, null, null, "Online", true, lessonDuration);
        }
        let requestDate = null;
        if (requestProps.createdAt != null) {
            requestDate = new Date(requestProps.createdAt).toLocaleDateString();
        }
        let requestedStartDate = "ASAP";
        if (requestProps.requestedStartDate != new Date().toDateString()) {
            const requestedDate = new Date(requestProps.requestedStartDate);
            requestedStartDate = requestedDate.getUTCDate() + "/" + (requestedDate.getUTCMonth() + 1) + "/" + requestedDate.getUTCFullYear();
        }
        let status = null;
        if (requestProps.status != null) {
            if (requestProps.status == 0 || requestProps.status == 2) {
                status = "Looking for tutor";
            }
            else if (requestProps.status == 1) {
                status = "Awaiting tutor approval";
            }
            else if (requestProps.status == 3) {
                status = "Lesson created";
            }
        }

        const requestAttributesText = [];
        
        let attributeNum = 1;

        const requestDateLabel = 
            <Text {...standardTitleProps} key={"requestDateLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                Request date:
            </Text>
        const requestDateText = 
            <Text {...standardTextProps} key={"requestDate"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                {requestDate}
            </Text>
        requestAttributesText.push(requestDateLabel, requestDateText);
        attributeNum = attributeNum + 1;

        let studentTitle = "Student:";
        if (studentDetails != null) {
            if (studentDetails.numStudents > 1) {
                studentTitle = "Students:";
            }
            const studentNameLabel = 
                <Text {...standardTitleProps} key={"studentNameLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                    {studentTitle}
                </Text>
            const studentNameText = 
                <Text {...standardTextProps} key={"studentName"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                    {studentDetails.studentNamesString || studentDetails.studentDetailsString}
                </Text>
             if (userType.isRequestParent || userType.userIsAdmin || userType.isRequestTutor) {
                requestAttributesText.push(studentNameLabel, studentNameText);
                attributeNum = attributeNum + 1;
            }
        }

        // let studentYearTitle = "Student Year Group:";
        // if (numStudentYearGroups != null && numStudentYearGroups > 1) {
        //     studentYearTitle = "Student Year Groups:";
        // }
        // const studentYearLabel = 
        //     <Text {...standardTitleProps} key={"studentYearLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
        //         {studentYearTitle}
        //     </Text>
        // const studentYearText = 
        //     <Text {...standardTextProps} key={"studentYear"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
        //         {yearGroupsText}
        //     </Text>
        // if (userType == "tutor") {
        //     lessonAttributesText.push(studentYearLabel, studentYearText);
        //     attributeNum = attributeNum + 1;
        // }

        const subjectLabel = 
            <Text {...standardTitleProps} key={"subjectLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                Subject:
            </Text>
        const subjectText =
            <Text {...standardTextProps} key={"subject"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                {subject}
            </Text>
        requestAttributesText.push(subjectLabel, subjectText);
        attributeNum = attributeNum + 1;

        // const addressLabel = 
        //     <Text {...standardTitleProps} key={"addressLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
        //         Address:
        //     </Text>
        // const addressText = 
        //     <Text {...standardTextProps} key={"address"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
        //         {address1stLine}
        //     </Text>
        // if (showAddress) {
        //     lessonAttributesText.push(addressLabel, addressText);
        //     attributeNum = attributeNum + 1;
        // }
        const lessonTypeLabel = 
            <Text {...standardTitleProps} key={"lessonTypeLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                Lesson Type:
            </Text>
        const lessonTypeText = 
            <Text {...standardTextProps} key={"lessonType"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                {lessonType}
            </Text>
        requestAttributesText.push(lessonTypeLabel, lessonTypeText);
        attributeNum = attributeNum + 1;

        if (lessonType != "Online") {
            let postcodeLabelText = "Postcode:";
            if (showAddress = false) {
                postcodeLabelText = "Location:";
            }
            const postcodeLabel = 
                <Text {...standardTitleProps} key={"postcodeLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                    {postcodeLabelText}
                </Text>
            const postcodeText = 
                <Text {...standardTextProps} key={"postcode"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                    {postcode}
                </Text>
            requestAttributesText.push(postcodeLabel, postcodeText);
            attributeNum = attributeNum + 1;
        }

        const numSessionsLabel = 
            <Text {...standardTitleProps} key={"numSessionsLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                Weekly Sessions:
            </Text>
        const numSessionsText = 
            <Text {...standardTextProps} key={"numSessions"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                {numSessions}
            </Text>
        requestAttributesText.push(numSessionsLabel, numSessionsText);
        attributeNum = attributeNum + 1;

        const sessionLengthLabel = 
            <Text {...standardTitleProps} key={"sessionLengthLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                Session Length:
            </Text>
        const sessionLengthText = 
            <Text {...standardTextProps} key={"sessionLength"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                {sessionLength}
            </Text>
        requestAttributesText.push(sessionLengthLabel, sessionLengthText);
        attributeNum = attributeNum + 1;

        const requestedStartDateLabel = 
            <Text {...standardTitleProps} key={"requestedStartDateLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                Start:
            </Text>
        const requestedStartDateText = 
            <Text {...standardTextProps} key={"requestedStartDate"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                {requestedStartDate}
            </Text>
        requestAttributesText.push(requestedStartDateLabel, requestedStartDateText);
        attributeNum = attributeNum + 1;

        if (tutorWageInPerson != null && requestProps.lessonType == "In-Person") {
            const tutorWageLabel = 
                <Text {...standardTitleProps} key={"tutorWageLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                    Lesson Wage:
                </Text>
            const tutorWageText = 
                <Text {...standardTextProps} key={"tutorWage"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                    {tutorWageInPerson}
                </Text>
            requestAttributesText.push(tutorWageLabel, tutorWageText);
            attributeNum = attributeNum + 1;
        }
        else if (tutorWageOnline != null && requestProps.lessonType == "Online") {
            const tutorWageLabel = 
                <Text {...standardTitleProps} key={"tutorWageLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                    Lesson Wage:
                </Text>
            const tutorWageText = 
                <Text {...standardTextProps} key={"tutorWage"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                    {tutorWageOnline}
                </Text>
            requestAttributesText.push(tutorWageLabel, tutorWageText);
            attributeNum = attributeNum + 1;
        }
        else if (tutorWageInPerson != null && tutorWageOnline != null && requestProps.lessonType == "Either") {
            const tutorWageLabel = 
                <Text {...standardTitleProps} key={"tutorWageLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                    Lesson Wage:
                </Text>
            const tutorWageText = 
                <Text {...standardTextProps} key={"tutorWage"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                    {tutorWageInPerson}/{tutorWageOnline}
                </Text>
            requestAttributesText.push(tutorWageLabel, tutorWageText);
            attributeNum = attributeNum + 1;
        }

        if (status != null) {
            const statusLabel = 
                <Text {...standardTitleProps} key={"statusLabel"} columnStart={getColumn("label", attributeNum)} rowStart={getRow("label", attributeNum)}>
                    Request Status:
                </Text>
            const statusText = 
                <Text {...standardTextProps} key={"status"} columnStart={getColumn("attribute", attributeNum)} rowStart={getRow("attribute", attributeNum)}>
                    {status}
                </Text>
            requestAttributesText.push(statusLabel, statusText);
            attributeNum = attributeNum + 1;
        }

        return requestAttributesText;
    }

    let linkProps = {to: "/Student/MyLessonRequest", style:{textDecoration: 'none'}};
    if (userType.userType == "admin" && (requestProps.status == 0 || requestProps.status == 2)) {
        linkProps.to= "/Admin/LessonRequest?requestID=" + requestProps.id;
    }
    else if (userType.userType == "admin" && (requestProps.status == 1)) {
        linkProps.to= "/Admin/LessonRequests?requestID=" + requestProps.id;
    }
    else if (userType.userType == "admin" && (requestProps.status == 3)) {
        linkProps.to= "/Admin/Lessons?lessonID=" + requestProps.id;
    }
    else if (userType.userType == "parent") {
        linkProps.to= "/Parent/MyLessonRequest?requestID=" + requestProps.id;
    }
    else if (userType.userType == "parentStudent") {
        linkProps.to= "/Parent/MyLessonRequest?requestID=" + requestProps.id;
    }
    else if (userType.userType == "tutor") {
        linkProps.to= "/Tutor/LessonRequest?requestID=" + requestProps.id;
    }

    // console.log(linkProps.to);

    // const lessonRequestEditClick = function () {
    //     if (userType == "student") {
    //         setRedirect(<Navigate to={"/Student/EditLessonRequest"} state={{"lessonRequestProps":lessonRequestProps}}/>);
    //     }
    //     if (userType == "parent") {
    //         setRedirect(<Navigate to={"/Parent/EditLessonRequest"} state={{"lessonRequestProps":lessonRequestProps}}/>);
    //     }
    // }

    const iconSize = "40";
    // let editIcon = <Flex 
    //     style={{cursor:"pointer"}} 
    //     onClick={lessonRequestEditClick}
    //     backgroundColor={"#3085c7"} 
    //     borderRadius={iconSize + "px"}
    //     width={iconSize + "px"}
    //     height={iconSize + "px"}
    //     justifyContent={"center"}
    //     alignItems={"center"}
        
    // >
    //     <Icon
    //         key={"editButton"}
    //         width={"30px"}
    //         height={"30px"}
    //         as={MdModeEdit}
    //     />
    // </Flex>

    let deleteButton = <Flex 
        style={{cursor:"pointer"}} 
        onClick={async (event) => {
            props.deleteLessonRequest(requestProps, event);
        }}
        backgroundColor={"#3085c7"} 
        borderRadius={iconSize + "px"}
        width={iconSize + "px"}
        height={iconSize + "px"}
        justifyContent={"center"}
        alignItems={"center"}
    >
        <Icon
            key={"deleteButton"}
            width={(iconSize - 10) + "px"}
            height={(iconSize - 10) + "px"}
            as={MdDelete}
        />
    </Flex>

    // if (userType != "student" && userType != "parent") {
    //     editIcon = null;
    // }
    if (userType.isRequestTutor) {
        deleteButton = null;
    }

    const requestIDText = <Text {...standardTextProps} fontSize={"13px"} position={"absolute"}>{requestProps.id}</Text>

    const requestAttributesText = getRequestAttributesText();
    let backgroundColour = "#ffb845";
    if (requestProps.status == 1) {
        backgroundColour = "#defa82"
    }
    else if (requestProps.status == 3) {
        backgroundColour = "#36f78a"
    }
    else if (requestProps.status == 10) {
        backgroundColour = "#e8e8e8"
    }
    if (!(width < widthThreshold)) {
        return (
                <Flex borderRadius={"15px"} position={"relative"} width={"fit-content"}>
                    {redirect}
                    <Link {...linkProps} >
                        <Flex 
                            backgroundColor={backgroundColour} 
                            borderRadius={"15px"}
                            width={"fit-content"}
                            position={"relative"}
                            direction={"column"}
                            alignItems={"center"}
                        >
                            <Grid
                                columnGap={attributesGap}
                                rowGap={attributeGap}
                                marginLeft={"3vw"} 
                                marginRight={"3vw"} 
                                marginTop={"2vh"} 
                                marginBottom={"2vh"}
                            >
                                {requestAttributesText}
                            </Grid>
                            {requestIDText}
                        </Flex>
                    </Link>
                    <Flex position={"absolute"} right="-20px" bottom="-20px" borderRadius={iconSize + "px"}>
                        {deleteButton}
                    </Flex>
                    {/* <Flex position={"absolute"} right="-20px" top="-20px" borderRadius={iconSize + "px"}>
                        {editIcon}
                    </Flex> */}
                </Flex>
        );
    }
    else {
        return (
            <Flex borderRadius={"15px"} position={"relative"} width={"fit-content"}>
                {redirect}
                <Link {...linkProps} >
                    <Flex 
                        backgroundColor={backgroundColour} 
                        borderRadius={"15px"}
                        width={"fit-content"}
                        position={"relative"}
                    >
                        <Grid
                            columnGap={attributeGap}
                            rowGap={attributesGap}
                            marginLeft={"3vw"} 
                            marginRight={"3vw"} 
                            marginTop={"2vh"} 
                            marginBottom={"2vh"}
                        >
                            {requestAttributesText}
                        </Grid>
                    </Flex>
                </Link>
                <Flex position={"absolute"} right="-20px" bottom="-20px" borderRadius={iconSize + "px"}>
                    {deleteButton}
                </Flex>
                {/* <Flex position={"absolute"} right="-20px" top="-20px" borderRadius={iconSize + "px"}>
                    {editIcon}
                </Flex> */}
            </Flex>  
        );
    }
}

export default LessonRequestTemplate;