//import Reaptcha from 'reaptcha';
import React, { useRef, useState } from 'react';
import {ReactComponent as LogoSVG} from "../Images/NextDoorTutorLogoExport.svg";

import * as Auth from 'aws-amplify/auth';
import { Authenticator, Button, Flex, Heading, Text, useAuthenticator, useTheme, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
//import awsExports from '../aws-exports';
//Amplify.configure(awsExports);

export const authComponents = function () {

  // const validCaptcha = async function(returnedCaptchaToken) {
  //   console.log("Valid captcha");
  //   console.log(returnedCaptchaToken);
  // }

  // const captchaComponent = <Reaptcha 
  //   sitekey={"6LdHV-MkAAAAAFLs9avfjh4My0Crq3mvWoqqfHWm"} 
  //   onVerify={validCaptcha}
  // />

  // console.log(captchaComponent);

  return ({
    Header() {
      const { tokens } = useTheme();
  
      return (
          <View textAlign="center" padding={tokens.space.large} marginTop={"-5vh"}>
            <Flex direction={"column"} alignItems={"center"} >
              <Flex 
                position={"absolute"}
                right={"0px"}
                top={"0px"}
                width={"100%"}
                height={"105%"}
                backgroundColor={"#faf9f6"}
                style={{zIndex: -1}}
              />
              <Flex position={"relative"} alignItems={"center"}>
                {/* <Heading level={3}>Welcome to the Next Door Tutor App!</Heading> */}
                <Flex marginBottom={"-25px"}>
                  <LogoSVG
                    width={"15vh"} 
                    height={"15vh"}
                  />
                </Flex>
              </Flex>
            </Flex>
          </View>
      );
    },
  
    Footer() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.large} >
        </View>
      );
    },
  
    SignIn: {
      Header() {
        const { tokens } = useTheme();
  
        return (
          <Flex
            direction={"column"}
            alignItems={"center"}
          >
            
            <Heading
              level={3}
              textAlign={"center"}
              marginTop={"2vh"}
              paddingRight={"15px"}
              paddingLeft={"15px"}
            >
              Sign in to your account
            </Heading>
            </Flex>
        );
      },
      Footer() {
        const { toResetPassword } = useAuthenticator();
  
        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toResetPassword}
              size="small"
              variation="link"
            >
              Reset Password
            </Button>
          </View>
        );
      },
    },
  
    SignUp: {
      Header() {
        const { tokens } = useTheme();
  
        return (
            <Flex direction={"column"} alignItems={"center"}>
                <Heading
                    level={3}
                    textAlign={"center"}
                    marginTop={"2vh"}
                    paddingRight={"15px"}
                    paddingLeft={"15px"}
                >
                    Create a new account
                </Heading>
                {/* {captchaComponent} */}
            </Flex>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();
  
        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
            >
              Back to Sign In
            </Button>
          </View>
        );
      },
    },
    ConfirmSignUp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            level={3}
            textAlign={"center"}
            marginTop={"2vh"}
          >
            Enter Information:
          </Heading>
        );
      }
    },
    SetupTOTP: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            level={3}
            textAlign={"center"}
            marginTop={"2vh"}
          >
            Enter Information:
          </Heading>
        );
      }
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            level={3}
            textAlign={"center"}
            marginTop={"2vh"}
          >
            Enter Information:
          </Heading>
        );
      }
    },
    ResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            level={3}
            textAlign={"center"}
            marginTop={"2vh"}
          >
            Enter Information:
          </Heading>
        );
      }
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            level={3}
            textAlign={"center"}
            marginTop={"2vh"}
          >
            Enter Information:
          </Heading>
        );
      }
    }
  });
};
  
export const authFormFields = {
    signIn: {
      username: {
        placeholder: 'Enter your email',
      },
    },
    signUp: {
      password: {
        label: 'Password:',
        placeholder: 'Enter your Password:',
        isRequired: false,
        order: 2,
      },
      confirm_password: {
        label: 'Confirm Password:',
        order: 3,
      }
    },
    forceNewPassword: {
      password: {
        placeholder: 'Enter your Password:',
      },
    },
    resetPassword: {
      username: {
        placeholder: 'Enter your email:',
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        placeholder: 'Enter your Confirmation Code:',
        label: 'Confirmation Code:',
        isRequired: false,
      },
      confirm_password: {
        placeholder: 'Enter your Password Please:',
      },
    },
    setupTOTP: {
      QR: {
        totpIssuer: 'test issuer',
        totpUsername: 'amplify_qr_test_user',
      },
      confirmation_code: {
        label: 'Confirmation Code',
        placeholder: 'Enter your Confirmation Code:',
        isRequired: false,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: 'Confirmation Code',
        placeholder: 'Enter your Confirmation Code:',
        isRequired: false,
      },
    },
};

export function AuthenticatorWithEmail() {
    const services = {
      async handleSignUp(formData) {
        let { username, password, attributes } = formData;
        // custom username
        username = username.toLowerCase();
        attributes.email = attributes.email.toLowerCase();
        return Auth.signUp({
          username,
          password,
          attributes,
          autoSignIn: {
            enabled: true,
          },
        });
      },
    };
  
    return (
      <Authenticator services={services} initialState="signUp">
        {({ signOut }) => <button onClick={signOut}>Sign out</button>}
      </Authenticator>
    );
  }