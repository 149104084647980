import * as React from "react";
import { Flex, Grid, Image, Text } from "@aws-amplify/ui-react";
import { Link, Navigate } from "react-router-dom";
import {useEffect, useState} from 'react';
import AppNavButton from './AppNavButtons';
import HamburgerMenuTemplate from "./HamburgerMenuTemplate";
import {ReactComponent as LogoSVG} from "../Images/NextDoorTutorLogoExport.svg";
import {ReactComponent as TextSVG} from "../Images/Text.svg";
import userPicture from "../Images/TestUserImage.png";


const FooterTemplate = function(props) {
  const buttonList = props.buttons;
  let numButtons = 0
  if (buttonList != null) {
    numButtons = buttonList.length;
  }
 
  let width = props.width;
  let height = props.height;

  const imageSize = "20px"
  let imageLeft = "-20px";
  if (width < 700) {
    imageLeft = "-15px"
  }
  let appLogo = <Link to={"https://nextdoortutor.co.uk"} target="_blank" style={{textDecoration: 'none'}}>
    <Flex backgroundColor={"#ffffff"} borderRadius={"15px"} position={"absolute"} left={imageLeft}>
      <LogoSVG 
        width={imageSize} 
        height={imageSize}
      />
    </Flex>
  </Link>

  const privacyPolicyLink = <Link to={"https://nextdoortutor.co.uk/PrivacyPolicy"} target="_blank" style={{textDecoration:"none"}}><Text {...props.standardTextProps} fontSize={"14px"}>Privacy Policy</Text></Link>
  const termsOfServiceLink = <Link to={"https://nextdoortutor.co.uk/TermsOfService"} target="_blank" style={{textDecoration:"none"}}><Text {...props.standardTextProps} fontSize={"14px"}>Terms of Service</Text></Link>

  const websiteLink = <Link to={"https://nextdoortutor.co.uk"} target="_blank" style={{textDecoration:"none"}}><Text {...props.standardTextProps} fontSize={"14px"} style={{wordWrap: "break-word"}}>www.nextdoortutor.co.uk</Text></Link>
  const contactLink = <Text {...props.standardTextProps} fontSize={"14px"} style={{cursor:"pointer", wordWrap: "break-word"}} onClick={() => window.location ="mailto:contact@nextdoortutor.co.uk"}>contact@nextdoortutor.co.uk</Text>

  const reportButton = <Link to={"/ReportIssue"} style={{textDecoration:"none"}}><Text {...props.standardTextProps} fontSize={"14px"} style={{cursor:"pointer", wordWrap: "break-word"}}>Bug Report/Feature Request</Text></Link>

  let direction = "row";
  let gap = "2vw";
  let bigGap = "10vw";
  let bigDirection = "row";
  let heightOffset = "50px";
  if (width < 400) {
    bigDirection = "column";
    direction = "column";
    gap = "0px";
    bigGap = "1vh";
    heightOffset = "100px";
  }
  else if (width < 700) {
    direction = "column";
    gap = "0px";
    bigGap = "10vw";
    heightOffset = "70px";
  }
  return(
    <Flex style={{overflow:"hidden"}}>
      <Flex height={heightOffset}/>
      <Flex 
        width={"100%"} 
        backgroundColor={"#bfdcf1"} 
        position={"absolute"} 
        bottom={"0px"} 
        left={"0px"} 
        justifyContent={"center"}
        paddingTop={"5px"}
        paddingBottom={"5px"}
        gap={bigGap}
        direction={bigDirection}
        alignItems={"center"}
      >
        <Flex direction={direction} gap={gap}>
          <Flex position={"relative"}>
            {appLogo}
            {websiteLink}
          </Flex>
          {contactLink}
        </Flex>
        <Flex direction={direction} gap={gap}>
          {privacyPolicyLink}
          {termsOfServiceLink}
          {reportButton}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default FooterTemplate;