import {React, useState } from 'react';
import { LoadingSpinner, LoadingSpinnerPage } from '../LoadingPage';
import { Button, Flex, Text } from '@aws-amplify/ui-react';
import LessonRequestTemplate from '../../custom-ui-components/LessonRequestTemplate';
import PopUpTemplate from '../../custom-ui-components/form-components/PopUpTemplate';
import { Link } from 'react-router-dom';
import { ErrorLoadingPage } from '../ErrorLoadingPage';

const ParentLessonRequests = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    //Get the current user to pass to subsequent functions
    const user = props.user;
    let parentLessonRequests = props.parentLessonRequests;
    
    const APIFunctions = props.APIFunctions;

    const [deletePopUp, setDeletePopUp] = useState(false);
    const [deletingLessonRequest, setDeletingLessonRequest] = useState(false);
    const [deletedLessonRequest, setDeletedLessonRequest] = useState(false);
    const [currentLessonRequest, setCurrentLessonRequest] = useState(false);
    const [errorPopUp, setErrorPopUp] = useState(false);
    
    const askDeleteLessonRequest = function (currentLessonRequestProps, clickEvent) {
        if (deletePopUp == true) {
            setDeletePopUp(false);
        }
        else {
            setCurrentLessonRequest(currentLessonRequestProps);
            const controlClick = clickEvent.ctrlKey;
            if (controlClick != true) {
                setDeletePopUp(true);
            }
            else {
                deleteLessonRequest(currentLessonRequestProps);
            }
        }
    }

    if (parentLessonRequests == "error") {
        return <ErrorLoadingPage {...props}/>
    }

    let lessonRequests = null;
    if (parentLessonRequests != null && parentLessonRequests != "loading") {
        lessonRequests = [...parentLessonRequests];
    }
    

    let lessonRequestsUI = null;
    if (lessonRequests != null && lessonRequests != "loading" && props.parentsStudents != null && props.parentsStudents != "loading") {
        lessonRequestsUI = [];
        for (const request of lessonRequests) {
            if (request.status != 3 && request.status != 10) {
                const requestUI = <LessonRequestTemplate key={request.id} userType={"parent"} requestID={request.id} {...props} deleteLessonRequest={askDeleteLessonRequest} />
                lessonRequestsUI.push(requestUI);
            }
        }
    }
    else {
        lessonRequestsUI = <LoadingSpinner />
    }

    const titleText = <Text {...titleTextProps}>All lesson requests</Text>

    let requestGap = "5vh";
    if (props.width < 830) {
        requestGap = "7vh";
    }

    const deleteLessonRequest = async function (currentLessonRequestProps) {
        try {
            if (currentLessonRequestProps == null) {
                currentLessonRequestProps = currentLessonRequest;
            }
            setDeletingLessonRequest(true);
            const newLessonRequest = {};
            newLessonRequest.id = currentLessonRequestProps.id;
            newLessonRequest.deleted = true;
            const newLessonRequestModel = await APIFunctions.updateLessonRequest(newLessonRequest, "parent");
            console.log(newLessonRequestModel);
            setDeletingLessonRequest(false);
            setDeletedLessonRequest(true);
        }
        catch (error) {
            setDeletePopUp(false);
            setDeletingLessonRequest(false);
            setDeletedLessonRequest(false);
            try {
                if (error.response.data == "Error updating lesson request data model, given error: User does not have permission to edit this lesson request") {
                    setErrorPopUp("You don't have permission to edit this lesson request");
                }
                else {
                    setErrorPopUp(error.response.data);
                }
            }
            catch {
                if (typeof(error) == "string") {
                    setErrorPopUp(error);
                }
                else {
                    setErrorPopUp("Error deleting lesson request");
                }
            }
        }
    }

    const deleteText = "Are you sure you want to delete this lesson request?";
    const deletePopUpComponent = <PopUpTemplate text={deleteText} setPopUpVisibility={setDeletePopUp} yesButtonFunction={deleteLessonRequest} noButtonFunction={function(){}} showXButton={true} />
    const deletingRequestText = "Deleting lesson request...";
    const deletingLessonRequestPopUp = <PopUpTemplate text={deletingRequestText} setPopUpVisibility={setDeletingLessonRequest} showXButton={false} />
    const deletedLessonRequestText = "Lesson request deleted";
    const deletedLessonRequestPopUp = <PopUpTemplate text={deletedLessonRequestText} okButtonFunction={function(){}} setPopUpVisibility={setDeletedLessonRequest} showXButton={false} />

    const errorPopUpComponent = <PopUpTemplate text={errorPopUp} okButtonFunction={function(){}} setPopUpVisibility={setErrorPopUp} showXButton={true} />
    
    if (lessonRequests != null) {
        if (lessonRequests.length == 0) {
            lessonRequestsUI = [];
            lessonRequestsUI.push(<Text {...standardTextProps} key={"NoRequestsText"}>You don't have any outstanding lesson requests</Text>);
        }
    }
    else {
        lessonRequestsUI = <LoadingSpinner />
    }

    const createLessonRequestButton = <Link to={"/Parent/NewLessonRequest"} style={{textDecoration: 'none'}} key={"createRequestButton"}><Button>Make new lesson request</Button></Link>

    return (
        <div className='ParentLessonRequestsPage'>
            <Flex direction={"column"} alignItems={"center"} gap={"4vh"}>
                {titleText}
                <Flex direction={"column"} alignItems={"center"} gap={requestGap}>
                    {createLessonRequestButton}
                    {lessonRequestsUI}
                </Flex>
                {deletePopUp && deletePopUpComponent}
                {deletingLessonRequest && deletingLessonRequestPopUp}
                {deletedLessonRequest && deletedLessonRequestPopUp}
                {(errorPopUp != false) && errorPopUpComponent}
            </Flex>
        </div>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default ParentLessonRequests;