import React from 'react';
import { Button, Flex, Text } from '@aws-amplify/ui-react';
import { Navigate } from 'react-router-dom';
import { signOut } from '../App';

const EmailNotVerified = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;

    const text = <Text {...titleTextProps} >
        Please verify your email before you can use the app
    </Text>

    // const text = <Text {...titleTextProps} >
    //     Accounts created with Google are currently not available, please sign up with an email and password.
    // </Text>

    const button = <Button
        width={"150px"}
        onClick={() => {
            props.getNewCurrentUser();
        }}
    >
        I've Verified It (Button doesn't work)
    </Button>

    const signOutButton = <Button
        width={"150px"}
        onClick={() => {
            signOut();
        }}
    >
        Sign Out
    </Button>
    
    return (
        <div className='Page' style={{minHeight:"100vh"}}>
            <div className='EmailNotVerified'>
                <Flex direction={"column"} alignItems={"center"}>
                    <Flex marginTop={"30vh"}>
                    </Flex>
                    {text}
                    <Flex>
                        {/* {button} */}
                        {signOutButton}
                    </Flex>
                </Flex>
            </div>
        </div>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default EmailNotVerified;