import React, { useState } from 'react';
import { LoadingSpinnerPage } from '../LoadingPage';
import { Button, Flex, Grid, SliderField, Text, TextField } from '@aws-amplify/ui-react';
import {Link, Navigate, useLocation} from "react-router-dom";
import { isValidPrice, priceValuetoString } from '../../App';
import { AppNavButton, FormTemplate } from '../../custom-ui-components';
import PopUpTemplate from '../../custom-ui-components/form-components/PopUpTemplate';
import BackButton from '../../custom-ui-components/BackButton';

const ViewTransaction = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    const requestTextProps = {...standardTextProps}
    const APIFunctions = props.APIFunctions;
    requestTextProps.textAlign = "left";
    //requestTextProps.maxWidth;
    //Get the current user to pass to subsequent functions
    const user = props.user;
    
    const [showRefundForm, setShowRefundForm] = useState(false);
    const [redirect, setRedirect] = useState(null);
    const [askModifyRefundRequestPopUp, setAskModifyRefundRequestPopUp] = useState(false);
    const [modifyingRefundRequestPopUp, setModifyingRefundRequestPopUp] = useState(false);
    const [yesButtonFunction, setYesButtonFunction] = useState(function(){});
    const [forceRefund, setForceRefund] = useState(false);
    const [customRefundAmount, setCustomRefundAmount] = useState(0);

    const userType = props.userType;
    const location = useLocation();
    const extraProps = location.state;
    if (extraProps == null) {
        if (userType == "admin") {
            return <Navigate to={"/Admin/Transactions"} />
        }
        else {
            return <Navigate to={"/Transactions"} />
        }
    }

    if (extraProps.purchasedItems == null) {
        return <LoadingSpinnerPage {...props} />
    }
    const purchasedItems = extraProps.purchasedItems;
    let totalPriceValue = 0;
    for (const purchasedItem of purchasedItems) {
        totalPriceValue = totalPriceValue + purchasedItem.totalItemCost/100;
    }
    const priceString = priceValuetoString(totalPriceValue);

    const completed = extraProps.completed;
    const refunded = extraProps.refunded;
    const refundRequested = extraProps.refundRequested;
    let transactionValue = extraProps.transactionValue;
    if (transactionValue == null) {
        transactionValue = 0;
        const transactionItems = extraProps.purchasedItems;
        for (const item of transactionItems) {
            transactionValue = transactionValue + item.totalItemCost/100;
        }
    }
    let statusString = "Invalid Status";
    if (refunded == false) {
        if (refundRequested == true) {
            statusString = "Refund Requested";
        }
        else if (completed != true) {
            statusString = "Pending";
        }
        else if (completed == true) {
            statusString = "Completed";
        }
    }
    else if (refunded != false) {
        if (refunded != true && refunded < transactionValue) {
            statusString = "Partially Refunded (" + priceValuetoString(refunded) + ")";
            if (refundRequested == true) {
                statusString = "Refund Requested (already refunded " + priceValuetoString(refunded) + ")";
            }
        }
        else if (refunded == transactionValue || refunded == true) {
            statusString = "Refunded";
        }
    }

    const UIs = [];
    UIs.push(
        <Text {...requestTextProps} key={"Date"}>
            Date: {new Date(extraProps.createdAt).toUTCString()}
        </Text>,
        <Text {...requestTextProps} key={"Type"}>
            Transaction Type: {extraProps.type}
        </Text>,
        <Text {...requestTextProps} key={"Value"}>
            Value: {priceString}
        </Text>,
        <Text {...requestTextProps} key={"Status"}>
            Status: {statusString}
        </Text>
    );
    if (userType == "admin") {
        UIs.push(<Text {...requestTextProps} key={"Fee"}>
            Stripe Fee: {priceValuetoString(extraProps.stripeFee)}
        </Text>,)
    }
    if (refundRequested == true) {
        UIs.push(
            <Text {...requestTextProps} key={"refundReason"}>
                Refund Reason: {extraProps.refundReason}
            </Text>
        )
    }
    if (extraProps.type == "Credit Purchase" && userType == "admin") {
        UIs.push(
            <Text {...requestTextProps} key={"Email"}>
                User Email: {extraProps.userEmail}
            </Text>,
            <Text {...requestTextProps} fontSize={"12px"} key={"CheckOutSessionID"} style={{wordWrap: "break-word"}} >
                Check Out Session ID: {extraProps.checkOutSessionID}
            </Text>,
            <Text {...requestTextProps} fontSize={"12px"} key={"ChargeID"}>
                Charge ID: {extraProps.chargeID}
            </Text>,
            <Text {...requestTextProps} fontSize={"12px"} key={"PaymentIntentID"}>
                Payment Intent ID: {extraProps.paymentIntentID}
            </Text>
        );
    }

    const transactionFlex = <Flex direction={"column"} alignItems={"start"}>
        {UIs}
    </Flex>
    
    const titleText = <Text {...titleTextProps}>View transaction</Text>

    let refundButton = null;
    if ((statusString == "Completed" || statusString.substring(0, 18) == "Partially Refunded") && extraProps.type == "Credit Purchase" && userType == "customer") {
        refundButton = <AppNavButton text={"Request Refund"} onClickFunction={() => {
            setShowRefundForm(true);
        }}/>
    }

    if (statusString.length >= 16 && statusString.substring(0, 16) == "Refund Requested" && extraProps.type == "Credit Purchase" && userType == "customer") {
        refundButton = <AppNavButton text={"Cancel Refund Request"} onClickFunction={() => {
            setYesButtonFunction(() => cancelRefundRequest);
            setAskModifyRefundRequestPopUp("Are you sure you want to cancel your refund request for this transaction?");
        }}/>
    }

    const requestRefund = async function (answers) {
        try {
            const refundReason = answers.refundReason;
            await APIFunctions.requestRefund(extraProps, refundReason);
        }
        catch (error) {
            let errorMessage = error;
            try {
                errorMessage = error.response.data;
            } catch {};
            throw {
                message: errorMessage,
                error: error
            };
        }
    };

    const cancelRefundRequest = async function () {
        try {
            setModifyingRefundRequestPopUp("Cancelling Refund Request...");
            await APIFunctions.cancelRefundRequest(extraProps);
            setModifyingRefundRequestPopUp("Refund Request Cancelled");
        }
        catch (error) {
            let errorMessage = error;
            try {
                errorMessage = error.response.data;
            } catch {};
            if (typeof(errorMessage) != "string") {
                errorMessage = JSON.stringify(errorMessage);
            }
            setModifyingRefundRequestPopUp(errorMessage);
            throw errorMessage;
        }
    };

    const processFullRefund = async function () {
        try {
            setModifyingRefundRequestPopUp("Processing Full Refund...");
            console.log("Processing full refund");
            let force = false;
            if (forceRefund == "full") {
                force = true;
            }
            await APIFunctions.processRefund(extraProps, "full", force);
            setModifyingRefundRequestPopUp("Full Refund Issued");
        }
        catch (error) {
            let errorMessage = error;
            try {
                errorMessage = error.response.data;
            } catch {};
            setModifyingRefundRequestPopUp(errorMessage);
            if (errorMessage.substring(errorMessage.length - 25) == "try again to force refund") {
                setForceRefund("full");
            }
            else {
                setForceRefund(false);
            }
            throw errorMessage;
        }
    }
    const processNoFeesRefund = async function () {
        try {
            setModifyingRefundRequestPopUp("Processing Refund Subtracting Fees...");
            console.log("Processing no fees refund");
            let force = false;
            if (forceRefund == "noFees") {
                force = true;
            }
            await APIFunctions.processRefund(extraProps, "noFees", force);
            setModifyingRefundRequestPopUp("Refund Subtracting Fees Issued");
        }
        catch (error) {
            let errorMessage = error;
            try {
                errorMessage = error.response.data;
            } catch {};
            setModifyingRefundRequestPopUp(errorMessage);
            if (errorMessage.substring(errorMessage.length - 25) == "try again to force refund") {
                setForceRefund("noFees");
            }
            else {
                setForceRefund(false);
            }
            throw errorMessage;
        }
    }
    const processPartialRefund = async function () {
        try {
            setModifyingRefundRequestPopUp("Processing Partial Refund...");
            console.log("Processing partial refund");
            let force = false;
            if (forceRefund == "partial") {
                force = true;
            }
            const refundValue = parseFloat(customRefundAmount);
            console.log("Refunding: " + refundValue);
            await APIFunctions.processRefund(extraProps, refundValue, force);
            setModifyingRefundRequestPopUp("Partial Refund Issued");
        }
        catch (error) {
            let errorMessage = error;
            try {
                errorMessage = error.response.data;
            } catch {};
            setModifyingRefundRequestPopUp(errorMessage);
            if (errorMessage.substring(errorMessage.length - 25) == "try again to force refund") {
                setForceRefund("partial");
            }
            else {
                setForceRefund(false);
            }
            throw errorMessage;
        }
    }

    let askModifyRefundRequestPopUpObject = <PopUpTemplate 
        yesButtonFunction={yesButtonFunction} 
        noButtonFunction={function(){}} 
        text={askModifyRefundRequestPopUp} 
        setPopUpVisibility={setAskModifyRefundRequestPopUp} 
        showXButton={true} 
    />

    let maxValue = transactionValue;
    if (typeof(refunded) == "number") {
        maxValue = transactionValue - refunded;
    }
    let step = 0.1;
    if (maxValue <= 3) {
        step = 0.01;
    }
    const customRefundSliderQuestion = <SliderField
        id={"refundAmountSlider"}
        required={true}
        min={0}
        max={maxValue}
        step={step}
        value={customRefundAmount}
        isValueHidden={true}
        onChange={(e) => {
            let currentValue = customRefundAmount;
            try {
                currentValue = parseFloat(e);
            } catch {}
            const properString = priceValuetoString(currentValue);
            setCustomRefundAmount(properString.split("£").join(""));
        }}
    />
    
    const customRefundTextQuestion = <TextField
        id={"refundAmountText"}
        required={true}
        value={"£" + customRefundAmount}
        onChange={(e) => {
            let newValue = e.target.value
            newValue = newValue.split("£").join("");
            const validValue = isValidPrice(newValue);
            if (validValue == true && newValue >= 0 && newValue <= maxValue) {
                setCustomRefundAmount(newValue);
            }
            else if (newValue == "") {
                setCustomRefundAmount("");
            }
        }}
        onBlur={() => {
            let properString = priceValuetoString(customRefundAmount);
            if (customRefundAmount == "") {
                properString = "0.00";
            }
            setCustomRefundAmount(properString.split("£").join(""));
        }}
    />

    const customRefundQuestion = <Flex 
        direction={"column"} 
        position={"relative"} 
        //backgroundColor={"#ffffff"} 
        alignItems={"center"}
    >
        <Text {...standardTextProps} fontSize={"14px"} position={"absolute"} left={"1vw"} >{"£0"}</Text>
        <Text {...standardTextProps} fontSize={"14px"} position={"absolute"} right={"1vw"}>{priceValuetoString(maxValue)}</Text>
        <Flex 
            //backgroundColor={"#dddddd"} 
            direction={"column"} 
            marginTop={"1vh"}
        >
            {customRefundSliderQuestion}
        </Flex>
        {customRefundTextQuestion}
    </Flex>

    if (askModifyRefundRequestPopUp == "Custom refund amount:") {
        askModifyRefundRequestPopUpObject = <PopUpTemplate 
            text={askModifyRefundRequestPopUp} 
            setPopUpVisibility={setAskModifyRefundRequestPopUp} 
            showXButton={true}
            form={customRefundQuestion}
            customButtons={[{
                text: "Process Custom Refund",
                onClickFunction: processPartialRefund,
                isDisabled: (customRefundAmount == "" || customRefundAmount == 0)
            }]}
        />
    }

    const exitForm = function () {
        setRedirect(<Navigate to={backLocation} />);
    }

    let cancellingOkButtonFunction = exitForm;
    if (modifyingRefundRequestPopUp == "Cancelling Refund Request..." || modifyingRefundRequestPopUp == "Processing Full Refund..." || modifyingRefundRequestPopUp == "Processing Partial Refund..." || modifyingRefundRequestPopUp == "Processing Refund Subtracting Fees...") {
        cancellingOkButtonFunction = null;
    }
    if (modifyingRefundRequestPopUp != false && modifyingRefundRequestPopUp.substring(modifyingRefundRequestPopUp.length - 25) == "try again to force refund") {
        cancellingOkButtonFunction = function(){};
    }

    let backLocation = "/Transactions";
    if (userType == "admin") {
        backLocation = "/Admin/Transactions";
    }
    const backButton = <BackButton {...props} defaultBack={backLocation} />

    const modifyingRefundRequestPopUpObject = <PopUpTemplate 
        okButtonFunction={cancellingOkButtonFunction} 
        text={modifyingRefundRequestPopUp} 
        setPopUpVisibility={setModifyingRefundRequestPopUp} 
        showXButton={false} 
    />


    let refundForm = null;
    if (showRefundForm == true && (statusString == "Completed" || statusString.substring(0, 18) == "Partially Refunded") && extraProps.type == "Credit Purchase") {
        const refundQuestions = [];
        refundQuestions.push({
            id: "refundReason", 
            label: "Reason for refund:", 
            type: "text",
            //options: ["Credits were purchased accidentally", "Incorrect amount of credits were purchased"],
            required: true, 
            defaultValue: null,
            extraProps: {
                textType: "paragraph"
            }
        });
        refundForm = <FormTemplate {...props} submitAnswers={requestRefund} okButtonFunction={exitForm} questions={refundQuestions} submitPopUpText={"Refund successfully requested"} />
    }

    let processFullRefundButton = null;
    let processPartialRefundButton = null;
    let processNoFeesRefundButton = null;
    let fullyRefunded = false;
    if (extraProps.refunded == extraProps.transactionValue || extraProps.refunded == true) {
        fullyRefunded = true;
    }
    let allButFeesRefunded = false;
    if (extraProps.refunded >= extraProps.transactionValue - extraProps.stripeFee) {
        allButFeesRefunded = true;
    }

    if (userType == "admin" && fullyRefunded != true) {
        let fullRefundText = "Process full refund";
        if (refunded != false) {
            fullRefundText = "Process remaining full refund"
        }

        let refundAmountText = priceValuetoString(extraProps.transactionValue);
        if (refunded != false) {
            refundAmountText = priceValuetoString(extraProps.transactionValue - refunded);
        }
        processFullRefundButton = <AppNavButton text={fullRefundText} onClickFunction={()=>{
            setYesButtonFunction(() => processFullRefund);
            setAskModifyRefundRequestPopUp("Are you sure you want to process a full refund? (" + refundAmountText + ")");
        }} />
    }
    if (userType == "admin" && allButFeesRefunded == false) {
        let refundAmountText = priceValuetoString(extraProps.transactionValue - extraProps.stripeFee);
        if (refunded != false) {
            refundAmountText = priceValuetoString(extraProps.transactionValue - extraProps.stripeFee - refunded);
        }
        processNoFeesRefundButton = <AppNavButton text={"Process refund subtracting fees"} onClickFunction={()=>{
            setYesButtonFunction(() => processNoFeesRefund);
            setAskModifyRefundRequestPopUp("Are you sure you want to process a no fees refund? (" + refundAmountText + ")");
        }} />
    }
    if (userType == "admin" && fullyRefunded == false) {
        processPartialRefundButton = <AppNavButton text={"Process custom refund"} onClickFunction={()=>{
            setCustomRefundAmount(0);
            setYesButtonFunction(() => processPartialRefund);
            setAskModifyRefundRequestPopUp("Custom refund amount:");
        }} />
    }

    return (
        <Flex direction={"column"} gap={"4vh"} alignItems={"center"}>
            <Flex direction={"column"} gap={"4vh"} alignItems={"center"} >
                {titleText}
                <Flex gap={"4vw"}>
                    {backButton}
                </Flex>
            </Flex>
            <Grid alignItems={"flex-start"} columnGap={"2vw"} rowGap={"2vh"}>
                <Flex direction={"column"} gap={"4vh"} alignItems={"flex-start"} width={"fit-content"} column={1}>                
                    {transactionFlex}
                </Flex>
            </Grid>
            {refundButton}
            <Flex direction={"column"} gap={"2vh"}>
                {processFullRefundButton}
                {processNoFeesRefundButton}
                {processPartialRefundButton}
            </Flex>
            {(askModifyRefundRequestPopUp != false) && askModifyRefundRequestPopUpObject}
            {(modifyingRefundRequestPopUp != false) && modifyingRefundRequestPopUpObject}
            {refundForm}
            {redirect}
        </Flex>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default ViewTransaction;