import React from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { Button, Flex, Grid, Text } from "@aws-amplify/ui-react";
import { AppNavButton } from "../../custom-ui-components";


const TeachingResourcesYearGroup = function(props) {
  const height = props.height;
  const titleTextProps = props.titleTextProps;
  const standardTextProps = props.standardTextProps;
  const teachingResources = props.teachingResources;
  const userIsTutor = props.userIsTutor;
  const userIsAdmin = props.userIsAdmin;
  const userType = props.userType;

  const location = useLocation();
  const state = location.state;

  if (userType == "tutor" && !userIsTutor) {
    return <Navigate to="/" /> 
  }
  else if (userType == "admin" && !userIsAdmin) {
    return <Navigate to="/" /> 
  }
  else if (userType != "tutor" && userType != "admin") {
    return <Navigate to="/" /> 
  }
  
  let returnLink = "/Tutor/TeachingResourcesSubject"
  if (userType == "admin") {
    returnLink ="/Admin/TeachingResourcesSubject"
  }
  if (state == null) {
    return <Navigate to={returnLink} />
  }
  const subject = state.subject;
  if (subject == null) {
    return <Navigate to={returnLink} />
  }

  const titleText = <Text {...titleTextProps}>Teaching Resources for Tutors</Text>
  const subtitleText = subject + " >";
  const subtitle = <Text {...standardTextProps}>{subtitleText}</Text>

  const descriptionText = <Text {...standardTextProps}>Choose year group:</Text>

  const yearGroups = ["KS1", "KS2", "KS3", "KS4", "KS5", "University"];

  const yearGroupButtons = [];
  let link = "/Tutor/TeachingResources"
  if (userType == "admin") {
    link = "/Admin/TeachingResources"
  }
  for (const yearGroup of yearGroups) {
    const yearGroupButton = <Link to={link} style={{textDecoration: 'none'}} key={"YearGroup:" + yearGroup} state={{subject: subject, yearGroup: yearGroup}}><AppNavButton text={yearGroup}/></Link>
    yearGroupButtons.push(yearGroupButton);
  }

  let backButtonlink = "/Tutor/TeachingResourcesSubject"
  if (userType == "admin") {
    backButtonlink = "/Admin/TeachingResourcesSubject"
  }
  const backButton = <Link to={backButtonlink} style={{textDecoration: 'none'}} key={"Back button"}><AppNavButton text={"Back"}/></Link>

  return (
      <Flex direction={"column"} gap={"3vh"} alignItems={"center"}>
        <Flex direction={"column"} gap={"1vh"} alignItems={"flex-start"}>
          {titleText}
          <Flex gap={"4vw"} alignItems={"center"} >
            {backButton}
            {subtitle}
          </Flex>
        </Flex>
        
        {descriptionText}
        <Flex direction={"row"} alignItems={"center"} justifyContent={"center"} wrap={"wrap"} width={"80vw"} rowGap={"5vh"} columnGap={"5vw"}>
          {yearGroupButtons}
        </Flex>
      </Flex>
  );
}

export default TeachingResourcesYearGroup;