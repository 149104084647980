import { Button, Flex, Image, withAuthenticator } from "@aws-amplify/ui-react";
import { getOverrideProps, useAuthSignOutAction} from "@aws-amplify/ui-react/internal";

const AppNavButton = function(props) {
    const labelTextProps = props.questionLabelProps || {};
    const errorTextProps = props.questionErrorProps || {};

    const buttonText = props.text;
    const width = props.width;
    const height = props.height;
    const maxWidth = props.maxWidth;
    const maxHeight = props.maxHeight;
    const minWidth = props.minWidth;
    const minHeight = props.minHeight;
    const fontSize = props.fontSize;
    const activeButton = props.activeButton;
    const showOutline = props.showOutline || true;
    let onClickFunction = props.onClickFunction || props.onClick;
    if (onClickFunction == null) {
        onClickFunction = function () {};
    }

    let backgroundColour = null;
    if (props.backgroundColour != null) {
        backgroundColour = props.backgroundColour
    }
    let colour = null;
    if (props.colour != null) {
        colour = props.colour
    }

    if (activeButton == true) {
        backgroundColour = "#bfdee0";
    }
    
    let buttonDisabled = false;
    if (props.disabled == true) {
        buttonDisabled = true;
    }

    let style = null;
    if (showOutline) {
        style = {outline: 'none'}
    }

    const button = <Button
        borderRadius={width/200}
        shrink="0"
        size="large"
        isDisabled={buttonDisabled}
        variation="default"
        children={buttonText}
        fontSize={fontSize}
        backgroundColor={backgroundColour}
        color={colour}
        minWidth={minWidth}
        maxWidth={maxWidth}
        minHeight={minHeight}
        maxHeight={maxHeight}
        onClick = {() => {
            onClickFunction()
        }}
    />

    return button;
}

export default AppNavButton;