import { Button, Flex, Image, Text } from "@aws-amplify/ui-react";
import React from "react";
import techSupportEmail from "../Images/Emails/techsupport@nextdoortutor.co.uk.png"
import { signOut } from "../App";

export const SiteMaintenance = function(props) {
	const height = props.height;
	const width = props.width;

	const errorText = <Text fontSize={"25px"} color={"#08007d"} textAlign={"center"}>Web-App currently under maintenance</Text>

	const helpText = <Text fontSize={"25px"} color={"#08007d"} textAlign={"center"}>Maintenance should be complete by later this evening, for urgent requests, please email contact@nextdoortutor.co.uk</Text>

	const signOutButton = <Button 
		{...props.standardTextProps}
		maxWidth={"200px"} 
		onClick={() => {
			signOut();
		}}
	>
		Sign Out
	</Button>;

	return (
		<Flex direction={"column"} style={{minHeight:height}} gap={"10vh"} alignItems={"center"}>
			{errorText}
			{helpText}
			{signOutButton}
		</Flex>
	);
}

