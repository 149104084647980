import { Button, Flex } from "@aws-amplify/ui-react";
import React from "react";
import {Outlet, Navigate} from "react-router-dom";
import { ErrorLoadingPage } from "../ErrorLoadingPage";
import Navbar from "../home/HomeNavbar";
import { LoadingSpinnerPage } from "../LoadingPage";
import StudentNavbar from "./StudentNavbar";
import FooterTemplate from "../../custom-ui-components/FooterTemplate";


const StudentLayout = function(props) {
  const studentModel = props.studentModel;
  const height = props.height;
  const user = props.user;
  const sideMarginSize = props.sideMarginSize;
  const standardTextProps = props.standardTextProps;

  const userIsStudent = props.userIsStudent;

  let pageContent = <LoadingSpinnerPage {...props} />
  if (userIsStudent) {
    pageContent = <Outlet />
  }
  else if (userIsStudent != null) {
    return <Navigate to={"/"} />
  }

  if (studentModel == "error") {
    return (
      <div className="Page" style={{minHeight:height}}>
        <Navbar {...props} pageType={"student"} />
        <ErrorLoadingPage {...props}/>
      </div>
    )
  }

  const guideButton = <Button 
    {...standardTextProps}
    position={"fixed"}
    left={"5vw"}
    top={"5vh"}
    fontSize={"1vh"}
  >
    Next Door Tutor Guide
  </Button>

  return (
    <div className="Page" style={{minHeight:height}}>
      {/* <StudentNavbar /> */}
      <Flex direction={"column"} marginLeft={sideMarginSize} marginRight={sideMarginSize} alignItems={"center"}>
        <Flex style={{zIndex:2}} marginBottom={"50px"}>
          <Navbar {...props} pageType={"student"}/>
        </Flex>
        <Flex style={{zIndex:1}}>
          {pageContent}
          {/* {guideButton} */}
        </Flex>
        <br />
        <FooterTemplate {...props}/>
      </Flex>
    </div>
  );
}

export default StudentLayout;