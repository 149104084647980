import React from "react";
import { Outlet } from "react-router-dom";
import { Flex, Image } from "@aws-amplify/ui-react";
import logo from "../Images/NDTLogoWhite50DPI.png"


const LayoutNoNavbar = function(props) {
  const height = props.height;

  const appLogo = <Image
    width={"12vw"}
    height={"12vw"}
    minWidth={"100px"}  
    minHeight={"100px"}
    src={logo}
    alt="Next Door Tutor Logo"
    //src="https://nextdoortutorwebapp-storage220724-dev.s3.eu-west-2.amazonaws.com/public/TransparantNextDoorTutorClean.png"
  ></Image>

    return (
      <div className="Page" style={{minHeight:height}}>
          <Flex justifyContent="center">
            {appLogo}
          </Flex>
        <Outlet />
      </div>
    );
}

export default LayoutNoNavbar;