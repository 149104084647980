import { Button, Flex, Image, Text } from '@aws-amplify/ui-react';
import { React, useEffect, useState } from 'react';
import { LoadingSpinnerPage } from '../LoadingPage';

const Tests = function(props) {
    const APIFunctions = props.APIFunctions;
    
    const [buttons, setButtons] = useState(null);
    const [buttonUIs, setButtonUIs] = useState(null);
    

    useEffect(() => {
        const buttonsObject = {};

        const jsError = function () {
            const InvalidComponent = function () {
                throw "test";
            };

            setButtonUIs(<InvalidComponent />);
        };
        buttonsObject["JS Error"] = {function: jsError};
        
        const updateUser = async function () {
            const newButtons = {...buttonsObject};
            newButtons["Update User"].children = <Text>Updating user...</Text>;
            setButtons(newButtons);
            await APIFunctions.getNewCurrentUser();
            const newButtons2 = {...buttonsObject};
            newButtons2["Update User"].children = null;
            setButtons(newButtons2);
        };
        buttonsObject["Update User"] = {function: updateUser};

        const createStripeInvoice = async function () {
            const invoice = {

            };
            await APIFunctions.createStripeInvoice(invoice);
        }
        buttonsObject["Create Stripe Invoice"] = {function: createStripeInvoice};

        const loadTestLessons = async function () {
            await APIFunctions.loadAdmin(["tests"]);
        }
        buttonsObject["Load Test Lessons"] = {function: loadTestLessons};

        setButtons(buttonsObject);
    }, [APIFunctions]);

    useEffect(() => {
        if (buttons === null) {
            return;
        }
    
        const newButtonUIs = [];
        for (const [buttonName, button] of Object.entries(buttons)) {
            let buttonChildren = <Text>{buttonName}</Text>;
            if (button.children != null) {
                buttonChildren = button.children;
            }
            newButtonUIs.push(<Button key={buttonName + "Button"} children={buttonChildren} onClick={button.function} />);
        }
        setButtonUIs(newButtonUIs);
    }, [buttons]);

    if (buttonUIs === null) {
        return <LoadingSpinnerPage {...props} />;
    }
   
    return (
        <Flex direction="column" gap="2rem" padding="2rem">
            <Text {...props.titleTextProps}>Developer Tests</Text>
            {buttonUIs}
        </Flex>
    );
}

export default Tests;