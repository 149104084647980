import {useEffect, useState, React} from 'react';
import { LoadingSpinner, LoadingSpinnerPage } from '../LoadingPage';
import { Button, Flex, Grid, Icon, Text } from '@aws-amplify/ui-react';
import {Link, Navigate, useLocation} from "react-router-dom";
import {MdArrowRight, MdArrowDropDown} from 'react-icons/md';
import { AppNavButton, DropDownQuestionTemplate, FormTemplate } from '../../custom-ui-components';
import MapTemplate from '../../custom-ui-components/EmbededMapTemplate';
import { ErrorLoadingPage } from '../ErrorLoadingPage';
import { calculateLessonWage, getSessionLengthMins, priceValuetoString } from '../../App';
import BackButton from '../../custom-ui-components/BackButton';

const TutortutorRequestProps = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    const requestTextProps = {...standardTextProps}
    requestTextProps.textAlign = "left";
    delete requestTextProps.maxWidth;
    //Get the current user to pass to subsequent functions
    const userSettings = props.settings || {};
    const width = props.width;
    const APIFunctions = props.APIFunctions;
    let distanceUnit = userSettings.distanceUnit || "imperial";
    if (distanceUnit != "metric" && distanceUnit != "imperial") {
        distanceUnit = "imperial";
    }
    distanceUnit = "metric";
    const widthThreshold = 1000;
    const tutorModel = props.tutorModel;
    
    const [showDetails, setShowDetails] = useState(true);
    const [redirect, setRedirect] = useState(null);
    
    const [mapReload, setMapReload] = useState(0);
    const [pageState, setPageStage] = useState(null);
    const [pageContent, setPageContent] = useState(null);
    const [anyQuestionErrors, setAnyQuestionErrors] = useState(true);
    const [noTutorRequestModel, setNoTutorRequestModel] = useState(true);

    const tutorRequestDictionary = props.tutorRequestDictionary;
    const [tutorRequestProps, setTutorRequestProps] = useState(null);
    const [requestUI, setRequestUI] = useState(null);
    const [validatedStudentAvailability, setValidatedStudentAvailability] = useState(null);

    //Set request props
    useEffect(() => {
        if (tutorRequestDictionary == null) {
            return;
        }

        const queryParams = new URLSearchParams(window.location.search);
        console.log(queryParams);
        const requestID = queryParams.get("requestID");
        if (requestID == null) {
            console.log("No request ID");
            setNoTutorRequestModel(true);
            return;
        }
        const requestModel = tutorRequestDictionary[requestID];
        if (requestModel == null) {
            console.log("Request not found");
            setNoTutorRequestModel(true);
            return;
        }

        setTutorRequestProps(requestModel);
    }, [tutorRequestDictionary, window.location.search]);


    useEffect(() => {
        if (width < widthThreshold) {
            setShowDetails(false);
        }
    }, []);

    let possibleTutorRow = "1";
    let possibleTutorColumn = "2";
    let mapRow = "1";
    let mapColumn = "3";
    if (width < widthThreshold) {
        possibleTutorRow = "2";
        possibleTutorColumn = "1";
        mapRow = "3";
        mapColumn = "1";
    }

    useEffect(() => {
        if (tutorRequestProps == null) {
            return;
        }
        if (pageState == null) {
            const acceptButton = <AppNavButton width={width} text={"Accept"} backgroundColour={"#56f570"} onClick={() => {
                setPageStage("accept");
            }}/>
            const declineButton = <AppNavButton width={width} text={"Decline"} backgroundColour={"#e61010"} onClick={() => {
                setPageStage("decline");
            }}/>

            setPageContent(
                <Flex 
                    direction={"column"} 
                    alignItems={"center"}
                    columnStart={possibleTutorColumn}
                    rowStart={possibleTutorRow}
                >
                    {acceptButton}
                    {declineButton}
                </Flex>
            );
        }
        else if (pageState == "accept") {
            const lessonQuestions = [];
            const currentDateObject = new Date();
            const requestedStartDateObject = new Date(tutorRequestProps.requestedStartDate);
            let startPointDate = new Date(requestedStartDateObject).toDateString();
            if (requestedStartDateObject.valueOf() < currentDateObject.valueOf()) {
                startPointDate = currentDateObject.toDateString();
            }

            lessonQuestions.push({
                id: "newLessons", 
                label: "Choose lesson(s) day/time",
                type: "createlessontime", 
                required: true,
                extraProps: {
                    sessionLength: tutorRequestProps.sessionLength,
                    numSessions: tutorRequestProps.numSessions,
                    availability: validatedStudentAvailability,
                    matchingAvailability: tutorRequestProps.matchingAvailability,
                    enforceNumSessions: true,
                    startPointDate: startPointDate
                }
            });

            if (tutorRequestProps.lessonType == "Either") {
                lessonQuestions.push({
                    id: "lessonType", 
                    label: "Lesson type:",
                    type: "radio", 
                    required: true,
                    options: ["Online", "In-Person"],
                });
            }

            const softSubmitChecks = [];
            softSubmitChecks.push({
                questionID: "newLessons",
                answerObjectChild: "lessons",
                length: tutorRequestProps.numSessions,
                message: "Number of weekly is not what was requested. Are you sure you want to continue?"
            });

            const createLessonForm = <FormTemplate
                submitAnswers={submitAccept} 
                questions={lessonQuestions}
                okButtonFunction={exitForm}
                submitPopUp={false}
                softSubmitChecks={softSubmitChecks}
                {...props}
            />

            setPageContent(createLessonForm);
        }
        else if (pageState == "decline") {
            const lessonQuestions = [];
            const reasonOptions = [
                "Too many lessons", 
                "Too few lessons", 
                "Too long lessons", 
                "Too short lessons", 
                "No longer available at these times", 
                "Too young student",
                "Too old student",
                "No longer want to teach this subject"
            ];
            if (tutorRequestProps.lessonType == "Online") {
                reasonOptions.push("Would rather teach in person");
            }
            else if (tutorRequestProps.lessonType == "In-Person") {
                reasonOptions.push("Would rather teach online");
            }
            reasonOptions.push("Other");
        
            lessonQuestions.push({
                id: "reason", 
                label: "Reason for declining",
                type: "radio", 
                required: true,
                options: reasonOptions
            });
            lessonQuestions.push({
                id: "otherReason", 
                label: "Other reason",
                type: "text",
                required: true,
                onlyShow: {
                    id: "reason",
                    answers: ["Other"]
                }
            });
        
            const declineLessonForm = <FormTemplate
                submitAnswers={submitDecline} 
                questions={lessonQuestions}
                okButtonFunction={exitForm}
                submitPopUp={false}
                {...props}
            />
            setPageContent(declineLessonForm);
        }
    }, [pageState, tutorRequestProps, APIFunctions, validatedStudentAvailability, width, widthThreshold, showDetails]);

    useEffect(() => {
        if (tutorRequestProps == null || tutorModel == null) {
            return;
        }

        let subject = "No subject provided";
        if (tutorRequestProps.subject != null) {
            subject = tutorRequestProps.subject;
        }
        let lessonType = "No lesson type provided";
        if (tutorRequestProps.lessonType != null) {
            lessonType = tutorRequestProps.lessonType;
        }
        let postcode = null;
        if (lessonType != "Online") {
            postcode = "No postcode provided"
            if (tutorRequestProps.address.postcode != null) {
                postcode = tutorRequestProps.address.postcode;
            }
        }
        let studentAvailability = "No student availability provided";
        if (tutorRequestProps.availability != null) {
            studentAvailability = tutorRequestProps.availability;
        }

        const studentDetails = tutorRequestProps.studentDetails;
        
        let tutorWageInPerson = "Error calculating wage for lesson";
        let tutorWageOnline = "Error calculating wage for lesson";
        let tutorWagesInPerson = "Error calculating wages for lessons";
        let tutorWagesOnline = "Error calculating wages for lessons";
        if (tutorModel != null) {
            const tutorLevel = tutorModel.level;
            const lessonDuration = getSessionLengthMins(tutorRequestProps.sessionLength);
            const tutorWageInPersonValue = calculateLessonWage(tutorLevel, null, null, "In-Person", false, lessonDuration);
            const tutorWageOnlineValue = calculateLessonWage(tutorLevel, null, null, "Online", false, lessonDuration);
            tutorWageInPerson = priceValuetoString(tutorWageInPersonValue);
            tutorWageOnline = priceValuetoString(tutorWageOnlineValue);
            tutorWagesInPerson = priceValuetoString(tutorWageInPersonValue * tutorRequestProps.numSessions);
            tutorWagesOnline = priceValuetoString(tutorWageOnlineValue * tutorRequestProps.numSessions);
        }
        let numSessions = "No number of sessions provided";
        if (tutorRequestProps.numSessions != null) {
            numSessions = tutorRequestProps.numSessions;
        }
        let sessionLength = "No lesson length provided";
        if (tutorRequestProps.sessionLength != null) {
            sessionLength = tutorRequestProps.sessionLength;
        }
        let requestedStartString = "No requested start date provided";
        let requestedStartDate = new Date().toDateString();
        if (tutorRequestProps.requestedStartDate != null) {
            requestedStartDate = new Date(tutorRequestProps.requestedStartDate).toDateString();
            if (new Date(tutorRequestProps.requestedStartDate + "UTC").valueOf() < new Date().valueOf()) {
                requestedStartString = "As soon as possible";
            }
            else {
                requestedStartString = tutorRequestProps.requestedStartDate;
            }
        }
        
        const availabilityUI = [];
        const availabilityDays = Object.entries(studentAvailability);
        const availabilityTitleText = <Text {...standardTextProps} key={"availabilityTitleText"}>
            Given availability:
        </Text>
        availabilityUI.push(availabilityTitleText);
        const dayOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        const dayFlexes = [];
        const newValidatedStudentAvailability = {};
        const sessionLengthMins = getSessionLengthMins(sessionLength);
        for (const day of availabilityDays) {
            const dayName = day[0];
            newValidatedStudentAvailability[dayName] = [];
            let dayIndex = null;
            for (let validDayIndex = 0; validDayIndex < dayOrder.length; validDayIndex = validDayIndex + 1) {
                const validDay = dayOrder[validDayIndex];
                if (validDay == dayName) {
                    dayIndex = validDayIndex;
                }
            }
            const dayAvailability = day[1];

            let dayAvailabilityText = "";
            const dayAvailabilityTextArray = [];
            for (let availabilityIndex = 0; availabilityIndex < dayAvailability.length; availabilityIndex = availabilityIndex + 1) {
                const availability = dayAvailability[availabilityIndex];
                const startTime = availability.startTime;
                const endTime = availability.endTime;
                
                let inserted = false;
                const startTimeHour = parseInt(startTime.split(":")[0]);
                const startTimeMinute = parseInt(startTime.split(":")[1]);
                const startTimeInt = (startTimeHour * 60) + startTimeMinute;
                const endTimeHour = parseInt(endTime.split(":")[0]);
                const endTimeMinute = parseInt(endTime.split(":")[1]);
                const endTimeInt = (endTimeHour * 60) + endTimeMinute;
                const durationMins = endTimeInt - startTimeInt;
                if (durationMins >= sessionLengthMins) {
                    newValidatedStudentAvailability[dayName].push(availability);
                }

                const windowObject = {
                    startTime: startTime,
                    endTime: endTime,
                    startTimeInt: startTimeInt
                };
                for (let windowIndex = 0; windowIndex < dayAvailabilityTextArray.length; windowIndex = windowIndex + 1) {
                    const window = dayAvailabilityTextArray[windowIndex];
                    if (startTimeInt < window.startTimeInt && inserted == false) {
                        dayAvailabilityTextArray.splice(windowIndex, 0, windowObject)
                        inserted = true;
                    }
                }
                if (inserted == false) {
                    dayAvailabilityTextArray.push(windowObject);
                }
            }
            setValidatedStudentAvailability(newValidatedStudentAvailability);
            const timesFlex = [];
            timesFlex.push(<Text {...standardTextProps} key={"DayName:" + dayName}>{dayName}:</Text>);
            for (const window of dayAvailabilityTextArray) {
                const startTime = window.startTime;
                const endTime = window.endTime;
                const startTimeHour = parseInt(startTime.split(":")[0]);
                const startTimeMinute = parseInt(startTime.split(":")[1]);
                const startTimeInt = (startTimeHour * 60) + startTimeMinute;
                const endTimeHour = parseInt(endTime.split(":")[0]);
                const endTimeMinute = parseInt(endTime.split(":")[1]);
                const endTimeInt = (endTimeHour * 60) + endTimeMinute;
                const durationMins = endTimeInt - startTimeInt;
                dayAvailabilityText = dayAvailabilityText + (window.startTime + " - " + window.endTime) + ",\n";
                let tooShort = null;
                if (durationMins < sessionLengthMins) {
                    tooShort = " (Too short)";
                }
                const timeText = <Text {...standardTextProps} fontSize={"18px"} key={dayName + window.startTime}>{window.startTime} - {window.endTime} {tooShort}</Text>
                timesFlex.push(timeText);
                
            }
            let dayAvailabilityFlex = null;
            if (dayAvailabilityText != "") {
                dayAvailabilityFlex = <Flex 
                    {...requestTextProps} 
                    key={dayName + "Flex"} 
                    direction={"column"} 
                    textAlign={"start"} 
                    alignItems={"flex-start"}
                    gap={"0vh"}
                >
                    {timesFlex}
                </Flex>
            }
            dayFlexes[dayIndex] = dayAvailabilityFlex;
        }
        for (const dayFlex of dayFlexes) {
            if (dayFlex != null) {
                availabilityUI.push(dayFlex);
            }
        }

        let UIs = [];

        if (tutorWageInPerson != null && tutorRequestProps.lessonType == "In-Person" || (tutorWageInPerson == tutorWageOnline)) {
            UIs.push(
                <Text {...requestTextProps} key={"tutorWageInPerson"}>
                    Lesson Wage: {tutorWageInPerson}
                </Text>
            );
            if (numSessions > 1) {
                UIs.push(
                    <Text {...requestTextProps} key={"tutorWagesInPerson"}>
                        Wage for all weekly lessons: {tutorWagesInPerson}
                    </Text>
                );
            }
        }
        else if (tutorWageOnline != null && tutorRequestProps.lessonType == "Online") {
            UIs.push(
                <Text {...requestTextProps} key={"tutorWageOnline"}>
                    Lesson Wage: {tutorWageOnline}
                </Text>
            );
            if (numSessions > 1) {
                UIs.push(
                    <Text {...requestTextProps} key={"tutorWagesOnline"}>
                        Wage for all weekly lessons: {tutorWagesOnline}
                    </Text>
                );
            }
        }
        else if (tutorWageInPerson != null && tutorWageOnline != null && tutorRequestProps.lessonType == "Either") {
            UIs.push(
                <Text {...requestTextProps} key={"tutorWageInPerson"}>
                    Lesson Wage (If you choose in-person lesson): {tutorWageInPerson}
                </Text>,
                <Text {...requestTextProps} key={"tutorWageOnline"}>
                    Lesson Wage (If you choose online lesson): {tutorWageOnline}
                </Text>
            );
            if (numSessions > 1) {
                UIs.push(
                    <Text {...requestTextProps} key={"tutorWagesInPerson"}>
                        Wage for all weekly lessons (If you choose in-person lesson): {tutorWagesInPerson}
                    </Text>,
                    <Text {...requestTextProps} key={"tutorWagesOnline"}>
                        Wage for all weekly lessons (If you choose online lesson): {tutorWagesOnline}
                    </Text>
                );
            }
        }

        
            // <Text {...requestTextProps} key={"studentName"}>
            //     Student name: {studentModel.studentName}
            // </Text>,
            // <Text {...requestTextProps} key={"parentName"}>
            //     Parent name: {studentModel.parentName}
            // </Text>,
        
        UIs.push(
            <Text {...requestTextProps} key={"subject"}>
                Subject: {subject}
            </Text>,
            <Text {...requestTextProps} key={"lessonType"}>
                Lesson type: {lessonType}
            </Text>
        );
        if (tutorRequestProps.lessonType != "Online") {
            UIs.push(
                <Text {...requestTextProps} key={"postcode"}>
                    Postcode: {postcode}
                </Text>
            );
        }
        let studentAbilityTitle = "Student ability:";
        if (tutorRequestProps.schoolSet != null && typeof(tutorRequestProps.schoolSet) != "string") {
            const schoolSetArray = Object.keys(tutorRequestProps.schoolSet);
            const numSets = schoolSetArray.length;
            if (numSets > 1) {
                studentAbilityTitle = "Student abilities:";
            }
        }
        UIs.push(
            <Text {...requestTextProps} key={"numSessions"}>
                Lessons per week: {numSessions}
            </Text>,
            <Text {...requestTextProps} key={"sessionLength"}>
                Session length: {sessionLength}
            </Text>,
            <Text {...requestTextProps} key={"requestedStartDate"}>
                Requested start date: {requestedStartString}
            </Text>,
            <Text {...requestTextProps} key={"studentYearGroup"}>
                Student details: {studentDetails.studentDetailsString}
            </Text>
        );
        UIs.push(
            <Flex direction={"column"} alignItems={"flex-start"} key={"availability"}>
                {availabilityUI}
            </Flex>
        );
        if (tutorRequestProps.notes != null) {
            UIs.push(
                <Text {...requestTextProps} key={"notes"}>
                    Notes: {tutorRequestProps.notes}
                </Text>
            );
        }
        
        let arrowDirection = MdArrowDropDown;
        let showDetailsText = "Hide details";
        if (showDetails == false) {
            UIs = null;
            arrowDirection = MdArrowRight;
            showDetailsText = "Show details";
        }
        const showDetailsArrow = <Flex
            onClick={() => {
                setShowDetails(!showDetails);
            }}
            style={{cursor:"pointer"}}
            borderRadius={"10px"}
            backgroundColor={"#ffffff"}
            alignItems={"center"}
            width={"230px"}
            height={"35px"}
            position={"relative"}
        >
            <Text 
                {...standardTextProps}
                position={"absolute"}
                left={"10px"}
            >{showDetailsText}</Text>
            <Icon
                key={"editButton"}
                width={"30px"}
                height={"30px"}
                as={arrowDirection}
                right={"10px"}
                position={"absolute"}
            />
        </Flex>

        let requestWidth = "30vw";
        if (width < widthThreshold) {
            requestWidth = "90vw"
        }
        setRequestUI(<Flex 
            direction={"column"} 
            alignItems={"flex-start"} 
            width={requestWidth}
            columnStart={"1"}
            rowStart={"1"}
        >
            {showDetailsArrow}
            {UIs}
        </Flex>);
    }, [tutorRequestProps, tutorModel, APIFunctions, showDetails]);

    // if (noTutorRequestModel) {
    //     return <Navigate to={"/Tutor/LessonRequests"} />
    // }

    if (tutorModel == null || tutorRequestProps == null) {
        return <LoadingSpinnerPage {...props} />
    }
    else if (tutorModel == "error") {
        return <ErrorLoadingPage />
    }
    
    
    const titleText = <Text {...titleTextProps}>View lesson request</Text>
    
    let backButton = <BackButton {...props} defaultBack={"//Tutor/LessonRequests"} />

    if (pageState != null) {
        backButton = <Button onClick={() => {
            setPageStage(null);
        }}>Back</Button>
    }

    let direction = "row";
    if (width < 1000) {
        direction = "column";
    }

    let embeddedMap = null;
    let mapWidth = 30*width/100
    if (width < 1000) {
        mapWidth = 80*width/100
    }
    if (tutorRequestProps.lessonType != "Online") {
        embeddedMap = <MapTemplate
            {...props}
            type={"directions"} 
            origin={tutorModel.address.postcode} 
            destination={tutorRequestProps.address.postcode} 
            width={mapWidth}
            height={"400px"}
            distanceUnit={userSettings.distanceUnit}
            mode={"walking"}
            //directDistance={selectedTutor.distance}
            key={"Map" + mapReload}
        />
    }
    
    
    
    const passProps = {...tutorRequestProps};
    passProps.standardTextProps = props.standardTextProps;
    passProps.titleTextProps = props.titleTextProps;
    passProps.requestTextProps = requestTextProps

    const submitAccept = async function (answers) {
        try {
            const details = answers.newLessons.lessons;
            for (const lesson of details) {
                lesson.requestedStartDate = tutorRequestProps.requestedStartDate;
                if (tutorRequestProps.lessonType == "Either") {
                    lesson.lessonType = answers.lessonType;
                }
            }
            await APIFunctions.respondToTutorRequest(tutorRequestProps.id, "Yes", details);
        }
        catch (error) {
            console.log(error);
            throw "Error responding to tutoring request";
        }
    }

    const submitDecline = async function (answers) {
        try {
            const reason = answers.reason;
            await APIFunctions.respondToTutorRequest(tutorRequestProps.id, "No", reason);
        }
        catch (error) {
            console.log(error);
            throw {
                message: "Error responding to tutoring request",
                error: error
            };
        }
    }

    const exitForm = function () {
        setRedirect(<Navigate to={"/Tutor/LessonRequests"} />);
    }
    
    

    return (
        <Flex direction={"column"} gap={"4vh"}>
            {redirect}
            <Flex direction={"column"} gap={"4vh"} alignItems={"center"} >
                {titleText}
                <Flex direction={"row"}>
                    {backButton}
                </Flex>
            </Flex>
            <Grid 
                rowGap={"4vh"} 
                columnGap={"1vw"} 
                position={"relative"} 
                templateColumns={"1fr 1fr 1fr"} 
                //backgroundColor={"#eeeeee"}
            >
                {requestUI}
                <Flex
                    rowStart={mapRow}
                    columnStart={mapColumn}
                    direction={"row"}
                    justifyContent={"center"}
                    //width={mapWidth}
                >
                    {embeddedMap}
                </Flex>
                <Flex 
                    direction={"column"} 
                    columnStart={possibleTutorColumn}
                    rowStart={possibleTutorRow}
                >
                    {pageContent}
                </Flex>
            </Grid>
        </Flex>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default TutortutorRequestProps;