import * as React from "react";
import { Text, Flex, Button, Badge, Grid, Icon } from "@aws-amplify/ui-react";
import { useState, useEffect } from 'react';
import { validateField } from "./FormValidation";
import { compareObjects, educationTypes } from "../../App";
import FieldTemplate from "../FieldTemplate";
import QuestionTemplate from "../QuestionTemplate";

export const TutorEducationTemplate = function(props) {
	// try {
		const labelTextProps = props.questionLabelProps || {};
		const errorTextProps = props.questionErrorProps || {};

		//Set question variables from passed properties
		const questionID = props.id;
		const questionLabel = props.label;
		const questionDescription = props.description;
		const required = props.required;
		const requiredAsterisk = props.requiredAsterisk;
		const defaultValue = props.defaultValue;
		const questionValidations = props.validations || [];
		const answersSuccessfullySubmitted = props.answersSuccessfullySubmitted;
		const educationAnswerValidations = [{"type":"ValidTutorEducation"}];

		let educationValidationExists = false;
		for (let validation of questionValidations) {
			if (validation.type === "ValidTutorEducationList") {
				educationValidationExists = true;
			}
		}
		if (educationValidationExists === false) {
			questionValidations.push ({"type":"ValidTutorEducationList"});
		}

		//Define a blank education model for resetting to
		const blankEducation = {
			type: null,
			institution: null,
			graduated: null,
			currentYear: null,
			graduationYear: null,
		}

		//Set state variables used to store the current state and any errors
		const [educationAnswers, setEducationAnswers] = useState([]);
		const [currentEducationAnswer, setCurrentEducationAnswer] = useState(blankEducation);
		const [cancelButton, setCancelButton] = useState(null);
		const [addButton, setAddButton] = useState(null);
		const [addNewItemButton, setAddNewItemButton] = useState(null);
		const [badgeUIs, setBadgeUIs] = useState([]);
		const [errors, setErrors] = useState({questionHasError:false, questionError:"", currentEducationError:true});
		const [buttonOrEducation, setButtonOrEducation] = useState("button");
		const [institutionNameUI, setInstitutionNameUI] = useState(null);
		const [educationTypeUI, setEducationTypeUI] = useState(null);
		const [graduatedUI, setGraduatedUI] = useState(null);
		const [containsCurrentEducation, setContainsCurrentEducation] = useState(null);
		const [graduationYearOptions, setGraduationYearOptions] = useState(null);
		const [graduationYearUI, setGraduationYearUI] = useState(null);
		const [currentYearOptions, setCurrentYearOptions] = useState(null);
		const [currentYearUI, setCurrentYearUI] = useState(null);
		
		//Set the options
		useEffect(() => {
			const newGraduationYearOptions = [];
			const currentYear = new Date().getFullYear();
			for (let year = currentYear; year >= 1980; year = year - 1) {
				newGraduationYearOptions.push(year.toString());
			}
			setGraduationYearOptions(newGraduationYearOptions);

			const newCurrentYearOptions = {};
			for (const educationType of educationTypes) {
				let yearOptions = [];
				if (educationType == "Home Education" || educationType == "Secondary School" || educationType == "Sixth Form/College") {
					yearOptions = [
						"Year 12",
						"Year 13",
					];
				}
				else if (educationType == "University") {
					yearOptions = [
						"1st Year",
						"2nd Year",
						"3rd Year",
						"4th Year",
						"5th Year",
						"6th Year"
					];
				}
				else {
					for (let age = 16; age <= 80; age = age + 1) {
						yearOptions.push("Age " + age);
					}
				}
				newCurrentYearOptions[educationType] = yearOptions;
			}
			setCurrentYearOptions(newCurrentYearOptions);
		}, []);

		//Set the default value of the education answers
		useEffect(() => {
			if (defaultValue == null) {
				setEducationAnswers([]);
				return;
			}

			const validationResult = validateField(defaultValue, questionValidations);
			const questionHasError = validationResult.hasError;
			if (questionHasError == true) {
				setEducationAnswers([]);
			}
			else {
				setEducationAnswers(defaultValue);
			}
		}, [defaultValue]);

		const [forceUpdate, setForceUpdate] = useState(0);

		//Validate the education answers
		useEffect(() => {
			const validationResult = validateField(educationAnswers, questionValidations);
			const questionHasError = validationResult.hasError;
			const errorMessage = validationResult.errorMessage;

			if (forceUpdate > 0) {
				setErrors((prev) => {
					return {
						...prev,
						questionHasError: questionHasError, 
						questionError: errorMessage
					};
				});
			}
			
			props.handleChange(questionID, educationAnswers, questionHasError);
		}, [educationAnswers, forceUpdate]);

		//Validate the current education answer
		useEffect(() => {
			const currentValidationResult = validateField(currentEducationAnswer, educationAnswerValidations);
			//console.log(currentValidationResult);
			const currentQuestionHasError = currentValidationResult.hasError;

			if (forceUpdate > 0) {
				setErrors((prev) => {
					return {
						...prev,
						currentEducationError: currentQuestionHasError
					};
				});
			}
		}, [currentEducationAnswer, forceUpdate]);


		let labelColour = labelTextProps.color || "#000000";
		if (props.validationError != null && props.validationError != null) {
			labelColour = props.validationErrorColour || "#ff0000";
		}
		//Get the text for the question label
		const labelText = <Text {...labelTextProps} color={labelColour}>
			{questionLabel}
		</Text>
		
		const descriptionText = <Text {...labelTextProps} fontSize={"14px"} fontStyle={"italic"}>
			{questionDescription}
		</Text>

		const errorText = <Text {...errorTextProps}>
			{errors.questionError}
		</Text>

		let renderedRequiredAsterisk = null;
		if (required == true) {
			renderedRequiredAsterisk = requiredAsterisk;
		}

		//Define the add new item button
		useEffect(() => {
			let newItemButtonText = "Add education";
			if (educationAnswers.length > 0) {
				newItemButtonText = "Add more education";
			}
			setAddNewItemButton(<Button
				//When clicked, all this does is change the mode to the adding new time range item mode
				disabled={answersSuccessfullySubmitted}
				onClick={() => {
					setButtonOrEducation("education");
				}}
			>
				{newItemButtonText}
			</Button>);
		}, [educationAnswers]);

		//Define the cancel item button
		useEffect(() => {
			setCancelButton(<Button
				isDisabled={answersSuccessfullySubmitted}
				onClick={() => {
					setButtonOrEducation("button");
					setCurrentEducationAnswer(blankEducation);
					
					const validationResult = validateField(educationAnswers, questionValidations);

					setErrors((prev) => {
						return {
							...prev, 
							currentEducationError: true,
							questionError: validationResult.errorMessage
						};
					});
				}}
			>
				Cancel
			</Button>);
		}, [answersSuccessfullySubmitted, educationAnswers]);

		//This function is called when the cross on an existing education answer is pressed
		const removeEducation = function (educationObject) {
			//Define a temperary new array for all the exising education answers
			const newEducations = [];
			//Add all the existing answers to the temperary array
			newEducations.push(...educationAnswers);

			//Find time range answer to remove and remove it
			for (let index = 0; index < newEducations.length; index = index + 1) {
				const existingQualification = newEducations[index];
				if (compareObjects(existingQualification, educationObject)) {
					newEducations.splice(index, 1);
					break
				}
			}
			//Update the main time range answers array with the updated array (with the item removed)
			setEducationAnswers(newEducations);

			const validationResult = validateField(newEducations, questionValidations);
			const questionHasError = validationResult.hasError;
			setErrors((prev) => {
				return {
					...prev,
					questionHasError: questionHasError,
					questionError: validationResult.errorMessage,
				};
			});
			props.handleChange(questionID, newEducations, questionHasError);
		} 

		//Now create the actual array of badges with the correct properties so that to appear in the correct location
		//The row and column they're placed in depends on their index in the array
		useEffect(() => {
			const badgeArray = [];
			let includesCurrentEducation = false;
			for (let badgeIndex = 0; badgeIndex < educationAnswers.length; badgeIndex = badgeIndex + 1) {
				const educationObject = educationAnswers[badgeIndex];
				if (educationObject.graduated == false) {
					includesCurrentEducation = true;
				}
				const answerString = educationObject.institution + " - " + (educationObject.graduationYear || educationObject.currentYear);

				//Create the badge object
				const answerBadge = <Badge
					style={{
						alignItems: "center",
						marginRight: 10,
						marginTop: 10,
						backgroundColor:"#0e418f",
						color:"#e6edfa"
					}}
					key={answerString}
					size="small"
					columnStart={(badgeIndex % 2) + 1}
					rowStart={Math.floor(badgeIndex / 2) + 1}
				>
					{answerString}
					<Icon
						//This is a cross icon that when clicked removes the time range answer
						style={{
							cursor: "pointer",
							paddingLeft: 3,
							width: 20,
							height: 20,
						}}
						viewBox={{ width: 20, height: 20 }}
						paths={[
							{
								d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
								stroke: "white",
							},
						]}
						ariaLabel="button"
						onClick={() => {
							//When clicked, call the function to remove the item
							if (answersSuccessfullySubmitted=== false) {
								removeEducation(educationObject);
							}
						}}
					/>
				</Badge>
				//Add the created badge to the badge array
				badgeArray.push(answerBadge)
			}
			setBadgeUIs(badgeArray);
			setContainsCurrentEducation(includesCurrentEducation);
		}, [educationAnswers]);
		
		const finaliseCurrentAnswer = function (currentAnswer) {
			if (currentAnswer.graduated == true) {
				delete currentAnswer.currentYear;
			}
			else {
				delete currentAnswer.graduationYear;
			}
			return currentAnswer;
		}

		//Define the add button that submits a new answer to the education answers
		useEffect(() => {
			let addButtonColour = "";
			let addButtonDisabled = true;
			if (errors.currentEducationError !== true && answersSuccessfullySubmitted === false) {
				addButtonColour = "#82f768";
				addButtonDisabled = false;
			}

			setAddButton(<Button
				backgroundColor={addButtonColour}
				key={"AddButton"}
				isDisabled={addButtonDisabled}
				onClick={() => {
					//When clicked, run the necessary validations first
					//Rerun the basic validation
					const finalisedCurrentAnswer = finaliseCurrentAnswer(currentEducationAnswer);
					const validationResultForAnswer = validateField(finalisedCurrentAnswer, educationAnswerValidations);
					if (validationResultForAnswer.hasError != true) {
						//Create a temperary array of answers that can be used by the validation function to ensure they are valid, even with the new item
						const newQualificationAnswers = [...educationAnswers, finalisedCurrentAnswer];
						//Validate that none of the education answers (including the new one) are for the same education
						const validationResultForAnswers = validateField(newQualificationAnswers, questionValidations);
						
						if (validationResultForAnswers.hasError != true) {
							const sortedEducationAnswers = newQualificationAnswers.sort((a, b) => {
								if (a.graduated == true && b.graduated == false) {
									return 1;
								}
								else if (a.graduated == false && b.graduated == true) {
									return -1;
								}
								else if (a.graduationYear > b.graduationYear) {
									return -1;
								}
								else if (a.graduationYear < b.graduationYear) {
									return 1;
								}
								return 0;
							});
							//If the validation was successful, update the actual education answers with the new answer added
							setEducationAnswers(sortedEducationAnswers);
							//Reset the state back to 'button' for a new time range to potentially be added
							setButtonOrEducation("button");
							//Reset the current education answer to be blank for inputting a new education
							setCurrentEducationAnswer(blankEducation);
							//Reset all errors
							setErrors((prev) => {
								return {
									...prev,
									currentEducationError: true,
								};
							});
							props.handleChange(questionID, sortedEducationAnswers, false);
						}
						else {
							//If the validation was unsuccessful, flag an error to tell the user
							setErrors((prev) => {
								return {
									...prev,
									currentEducationError: true,
									questionError: validationResultForAnswers.errorMessage,
								};
							});
						}
					}
					else {
						//If the validation was unsuccessful, flag an error to tell the user
						setErrors((prev) => {
							return {
								...prev,
								currentEducationError: true,
								questionError: validationResultForAnswer.errorMessage,
							};
						});
					}
				}}
			>
			Add
			</Button>);
		}, [currentEducationAnswer, educationAnswers, answersSuccessfullySubmitted, errors.currentEducationError]);

		//Eduction type UI
		useEffect(() => {
			setEducationTypeUI(<FieldTemplate
				id={"InstitutionType"}
				fieldType={"dropdown"}
				label={"Institution type:"}
				value={currentEducationAnswer.type}
				disabled={answersSuccessfullySubmitted}
				options={educationTypes}

				//Runs when it detects a change in the input field
				onChange={(newValue) => {
					setCurrentEducationAnswer((prev) => {
						const newEducationModel = {...prev, type: newValue};
						if (newValue == "Home Education") {
							newEducationModel.institution = "Home Education";
						}
						else if (prev.type == "Home Education") {
							newEducationModel.institution = null;
						}
						return newEducationModel;
					});
					setForceUpdate((prev) => {
						return prev + 1
					});
				}}
			/>);
		}, [currentEducationAnswer.type]);

		//Institution name UI
		useEffect(() => {
			let label = "Institution name";
			if (currentEducationAnswer.type == "Secondary School") {
				label = "School name";
			}
			else if (currentEducationAnswer.type == "Sixth Form/College") {
				label = "College name";
			}
			else if (currentEducationAnswer.type == "University") {
				label = "University name";
			}
			// if (currentEducationAnswer.type == "Home Education") {
			// 	setCurrentEducationAnswer((prev) => {
			// 		return {...prev, institution: "Home Education"};
			// 	});
			// 	setForceUpdate((prev) => {
			// 		return prev + 1
			// 	});
			// 	//setInstitutionNameUI(null);
			// 	//return;
			// }
			setInstitutionNameUI(<FieldTemplate
				id={"Institution"}
				fieldType={"text"}
				label={label + ":"}
				value={currentEducationAnswer.institution}
				// defaultValue={"charters"}
				placeholder={label}
				disabled={answersSuccessfullySubmitted}
				hidden={currentEducationAnswer.type == "Home Education"}

				//Runs when it detects a change in the input field
				onChange={(newValue) => {
					setCurrentEducationAnswer((prev) => {
						return {...prev, institution: newValue};
					});
					setForceUpdate((prev) => {
						return prev + 1
					});
				}}
			/> );
		} , [currentEducationAnswer.type, currentEducationAnswer.institution]);
		
		//console.log(currentEducationAnswer.graduated);

		//Already graduated UI
		useEffect(() => {
			setGraduatedUI(<FieldTemplate
				id={"Graduated"}
				fieldType={"switch"} 
				label={"Already graduated?"}
				isChecked={currentEducationAnswer.graduated || containsCurrentEducation}
				disabled={answersSuccessfullySubmitted || containsCurrentEducation}

				//Runs when it detects a change in the input field
				onChange={(newValue) => {
					setCurrentEducationAnswer((prev) => {
						const newEducationModel = {...prev, graduated: newValue};
						return newEducationModel;
					});
					setForceUpdate((prev) => {
						return prev + 1
					});
				}}
			/>);
		} , [currentEducationAnswer.graduated, containsCurrentEducation]);

		//Graduation year UI
		useEffect(() => {
			if (graduationYearOptions == null) {
				//setGraduationYearUI(null);
				return;
			}
			setGraduationYearUI(<FieldTemplate
				id={"GraduationYear"}
				fieldType={"dropdown"}
				label={"Graduation year:"}
				value={currentEducationAnswer.graduationYear}
				disabled={answersSuccessfullySubmitted}
				options={graduationYearOptions}
				hidden={currentEducationAnswer.graduated != true}

				//Runs when it detects a change in the input field
				onChange={(newValue) => {
					setCurrentEducationAnswer((prev) => {
						return {...prev, graduationYear: newValue};
					});
					setForceUpdate((prev) => {
						return prev + 1
					});
				}}
			/>);
		} , [currentEducationAnswer.graduated, currentEducationAnswer.graduationYear, graduationYearOptions]);

		//Current year UI
		useEffect(() => {
			if (currentEducationAnswer.type == null || currentYearOptions == null) {
				//setCurrentYearUI(null);
				return;
			}
			setCurrentYearUI(<FieldTemplate
				id={"CurrentYear"}
				fieldType={"dropdown"} 
				label={"Current year:"}
				value={currentEducationAnswer.currentYear}
				disabled={answersSuccessfullySubmitted}
				hidden={currentEducationAnswer.graduated == true}
				options={currentYearOptions[currentEducationAnswer.type]}

				//Runs when it detects a change in the input field
				onChange={(newValue) => {
					setCurrentEducationAnswer((prev) => {
						return {...prev, currentYear: newValue};
					});
					setForceUpdate((prev) => {
						return prev + 1
					});
				}}
			/>);
		} , [currentEducationAnswer.graduated, currentEducationAnswer.currentYear, currentYearOptions, currentEducationAnswer.type]);

			
		//Depending on the current state, return the correct components
		let formItem = null
		if (buttonOrEducation == "button") {
			//If the current state is 'button', return the add new item button
			formItem = addNewItemButton;
		}
		else if (buttonOrEducation == "education") {
			//If the current state is 'time' (meaning a new time range is being inputted), return the time fields as well as errors and relevent buttons
			formItem = <Flex direction={"column"} gap={"2vh"}>
				<Flex direction={"column"} gap={"2vh"}>
					<Flex direction={"column"}>
						{educationTypeUI}
						{institutionNameUI}
						{graduatedUI}
						{graduationYearUI}
						{currentYearUI}
					</Flex>
					<Flex justifyContent={"right"}>
						{cancelButton}
						{addButton}
					</Flex>
				</Flex>
			</Flex>
		}

		//Return text field component to calling function (Most likely a Form Template)
		return (
			<Flex direction="column" alignItems={"center"}>
				<Flex direction="column" marginBottom={"1vh"}>
					<Flex alignItems={"center"}>
						{renderedRequiredAsterisk}
						{labelText}
					</Flex>
					{descriptionText}
				</Flex>
				<Flex direction="column" alignItems={"center"}>
					{formItem}
					<Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
						{errorText}
					</Flex>
					<Flex direction={"column"} alignItems={"center"} gap={"0vh"}>
						{badgeUIs}
					</Flex>  
				</Flex>
			</Flex>
		);
	// }
	// catch (error) {
	// 	throw "TutorSubjectsTemplate Error: " + error;
	// }
}

export default TutorEducationTemplate;