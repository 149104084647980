import { Button, Flex, Icon, ScrollView, Text } from '@aws-amplify/ui-react';
import {Navigate} from "react-router-dom";
import {React, useState, useEffect } from 'react';
import { FormTemplate } from '../../custom-ui-components';
import { LoadingSpinner } from '../LoadingPage';
import {MdDelete, MdSend} from 'react-icons/md';
import PopUpTemplate from '../../custom-ui-components/form-components/PopUpTemplate';
import { ErrorLoadingPage } from '../ErrorLoadingPage';

const GiveAccessToTutor = function(props) {
    const [questionAnswers, setQuestionAnswers] = useState({});
    const [redirect, setRedirect] = useState(null);
    const [newCode, setNewCode] = useState(false);
    const [emailSize, setEmailSize] = useState("18px");
    const [forceUpdate, setForceUpdate] = useState(0);
    const [errorPopUp, setErrorPopUp] = useState(false);
    const [deletePopUp, setDeletePopUp] = useState(false);
    const [deletingEmail, setDeletingEmail] = useState(false);
    const [deletedEmail, setDeletedEmail] = useState(false);
    const [notifyPopUp, setNotifyPopUp] = useState(false);
    const [notifyingTutor, setNotifyingTutor] = useState(false);
    const [notifiedTutor, setNotifiedTutor] = useState(false);
    const [currentEmail, setCurrentEmail] = useState(false);
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    const APIFunctions = props.APIFunctions;
    //Get the current user to pass to subsequent functions
    const user = props.user;
    const width = props.width;
    

    const title = <Text {...titleTextProps} >
        Give a new tutor access to sign up
    </Text>

    const submitAnswers = async function(returnedAnswers) {
        try {
            setQuestionAnswers(returnedAnswers);
            const newTutorEmail = returnedAnswers.tutorEmail.toLowerCase();
            const validationCode = await APIFunctions.addNewValidTutorEmail(newTutorEmail);
            setNewCode(validationCode);
            setForceUpdate(forceUpdate + 1);
        }
        catch (error) {
            throw {
                message: "Error giving access to new tutor",
                error: error
            };
        }
    }

    const exitForm = function (resetForm, formSubmitted) {
        if (formSubmitted == true) {
            resetForm();
        }
    }

    const newTutorForm = function () {
        const questions = [];

        questions.push({
            id: "tutorEmail", 
            label: "Tutor's email:",
            type: "text",
            description: "This will allow any user with this email to sign up as a tutor. It will also generate a verification code that will need to be sent to them.",
            validations: [{"type":"Email"}],
            required: true,
            defaultValue: null
        });

        const submitText = "Successfully added tutor email. Verification code: " + newCode;
        const preSubmitPopUpText = "Submitting email..."
        const newTutorForm = <FormTemplate 
            {...props} 
            preSubmitPopUpText={preSubmitPopUpText} 
            submitPopUpText={submitText} 
            submitAnswers={submitAnswers} 
            existingAnswers={questionAnswers}
            questions={questions} 
            okButtonFunction={exitForm} 
        />

        return newTutorForm;
    }
    
    const title2 = <Text {...titleTextProps} >
        Existing verified tutors:
    </Text>

    const existingDescriptionText = <Text {...standardTextProps} fontSize={"16px"}>
        These are the existing verified tutors emails that have not signed up as a tutor yet 
    </Text>

    const existingVerifiedEmails = props.validTutorEmails;

    const askDelete = function () {
        setDeletePopUp(true);
    }

    const askNotify = function () {
        setNotifyPopUp(true);
    }

    const deleteEmail = async function (tutor) {
        try {
            setDeletingEmail(true);
            if (tutor == null) {
                tutor = currentEmail;
            }
            const email = tutor.email;
            const verification = tutor.verification;
            await APIFunctions.deleteValidTutorEmail(email, verification);
            setDeletedEmail(true);
            setDeletingEmail(false);
        }
        catch (error) {
            console.log(error);
            setDeletePopUp(false);
            setDeletingEmail(false);
            setDeletedEmail(false);
            if (typeof(error) == "string") {
                setErrorPopUp(error);
            }
            else {
                setErrorPopUp("Error removing valid email");
            }
        }
    }

    const notifyTutor = async function (tutor) {
        try {
            setNotifyingTutor(true);
            if (tutor == null) {
                tutor = currentEmail;
            }
            const email = tutor.email;
            const notificationSuccess = await APIFunctions.notifyTutorHasAccess(email);
            console.log(notificationSuccess);
            setNotifiedTutor(true);
            setNotifyingTutor(false);
        }
        catch (error) {
            setNotifyPopUp(false);
            setNotifyingTutor(false);
            setNotifiedTutor(false);
            if (typeof(error) == "string") {
                setErrorPopUp(error);
            }
            else {
                try {
                    if (error.response.data == "Error notifying tutor they've been verified. Notification already sent") {
                        setErrorPopUp("Can't send notification - Notification already sent");
                    }
                    else {
                        setErrorPopUp("Error notifying tutor with email: " + currentEmail.email);
                    }
                } 
                catch {
                    setErrorPopUp("Error notifying tutor with email: " + currentEmail.email);
                }
            }
        }
    }

    let existingEmailsUI = null;
    let existingVerifiedEmailsFlexArray = [];
    if (existingVerifiedEmails == null) {
        existingEmailsUI = <LoadingSpinner height={props.height} />
    }
    else if (existingVerifiedEmails == "loading") {
        existingEmailsUI = <LoadingSpinner height={props.height} />
    }
    else if (existingVerifiedEmails == "error") {
        return <ErrorLoadingPage {...props}/>
    }
    else {
        if (emailSize != "18px") {
            //setEmailSize("18px");
        }
        const verifiedTextProps = {...standardTextProps};
        delete verifiedTextProps.maxWidth;
        verifiedTextProps.marginLeft = "1vw";
        verifiedTextProps.marginRight = "1vw";


        for (const verifiedEmail of existingVerifiedEmails) {
            let emailLength = verifiedEmail.email.length;
            let newEmailSize = ("1vw");
            if (emailLength > 80) {
                newEmailSize = (120/emailLength);
            }
            if (width < 600) {
                newEmailSize = (120/emailLength);
            }
            
            let currentEmailSize = 4;
            if (emailSize != "18px") {
                currentEmailSize = emailSize.split("v")[0];
            }

            if (newEmailSize < currentEmailSize) {
                setEmailSize(newEmailSize + "vw");
            }

            const iconSize = "40";
            const deleteButton = <Flex 
                style={{cursor:"pointer"}} 
                onClick={async (clickEvent) => {
                    const controlClick = clickEvent.ctrlKey;
                    setCurrentEmail({email: verifiedEmail.email, verification: verifiedEmail.verification});
                    if (controlClick != true) {
                        askDelete();
                    }
                    else {
                        deleteEmail({email: verifiedEmail.email, verification: verifiedEmail.verification});
                    }
                }}
                backgroundColor={"#3085c7"} 
                borderRadius={iconSize + "px"}
                width={iconSize + "px"}
                height={iconSize + "px"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <Icon
                    key={"deleteButton"}
                    width={"30px"}
                    height={"30px"}
                    as={MdDelete}
                />
            </Flex>

            let notifyButton = <Flex 
                style={{cursor:"pointer"}} 
                onClick={async (clickEvent) => {
                    const controlClick = clickEvent.ctrlKey;
                    setCurrentEmail({email: verifiedEmail.email, verification: verifiedEmail.verification});
                    if (controlClick != true) {
                        askNotify();
                    }
                    else {
                        notifyTutor({email: verifiedEmail.email, verification: verifiedEmail.verification})
                    }
                    
                }}
                    backgroundColor={"#3085c7"} 
                    borderRadius={iconSize + "px"}
                    width={iconSize + "px"}
                    height={iconSize + "px"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                <Icon
                    key={"notifyButton"}
                    width={"30px"}
                    height={"30px"}
                    as={MdSend}
                />
            </Flex>

            if (verifiedEmail.notified == true) {
                notifyButton = null;
            }

            const verifiedEmailFlex = <Flex 
                key={verifiedEmail.email} 
                backgroundColor={"#00ff00"} 
                gap={"2px"} 
                borderRadius={"1vh"}
                direction={"column"}
                position={"relative"}
            >
                <Text {...verifiedTextProps} fontSize={emailSize} marginTop={"1vw"} textAlign={"left"} maxWidth={"90vw"}>
                    Email: {verifiedEmail.email}
                </Text>
                <Text {...verifiedTextProps} fontSize={emailSize} marginBottom={"1vw"} textAlign={"left"}>
                    Code: {verifiedEmail.verification}
                </Text>
                <Flex position={"absolute"} right="-20px" bottom="-20px">
                    {deleteButton}
                </Flex>
                <Flex position={"absolute"} right="30px" bottom="-20px">
                    {notifyButton}
                </Flex>
            </Flex>
            existingVerifiedEmailsFlexArray.push(verifiedEmailFlex)
        }
    }
    existingEmailsUI = <Flex
        style={{
            borderTopStyle: "dotted",
            borderBottomStyle: "dotted",
            borderColor: "#2e66ff",
        }}
    >
        <ScrollView 
            maxHeight={"40vh"} 
            width={"40vw"} 
            margin={"10px"}
            
        >
        <Flex direction={"column"} alignItems={"center"} gap={"4vh"} marginBottom={"2vh"}>
            {existingVerifiedEmailsFlexArray}
        </Flex>
    </ScrollView></Flex>

    const deleteText = "Are you sure you want to remove this verified email?";
    const deletePopUpComponent = <PopUpTemplate text={deleteText} setPopUpVisibility={setDeletePopUp} yesButtonFunction={deleteEmail} noButtonFunction={function(){}} showXButton={true} />
    const deletingText = "Removing verified email...";
    const deletingEmailPopUp = <PopUpTemplate text={deletingText} setPopUpVisibility={setDeletingEmail} showXButton={false} />
    const deletedEmailText = "Verified email removed";
    const deletedEmailPopUp = <PopUpTemplate text={deletedEmailText} okButtonFunction={function(){}} setPopUpVisibility={setDeletedEmail} showXButton={false} />

    const notifyText = "Are you sure you want to notify " + currentEmail.email + "?";
    const notifyPopUpComponent = <PopUpTemplate text={notifyText} setPopUpVisibility={setNotifyPopUp} yesButtonFunction={notifyTutor} noButtonFunction={function(){}} showXButton={true} />
    const notifyingText = "Sending notification email...";
    const notifyingEmailPopUp = <PopUpTemplate text={notifyingText} setPopUpVisibility={setNotifyingTutor} showXButton={false} />
    const notifiedEmailText = "Notification email sent to " + currentEmail.email;
    const notifiedEmailPopUp = <PopUpTemplate text={notifiedEmailText} okButtonFunction={function(){}} setPopUpVisibility={setNotifiedTutor} showXButton={false} />

    const errorPopUpComponent = <PopUpTemplate text={errorPopUp} okButtonFunction={function(){}} setPopUpVisibility={setErrorPopUp} showXButton={true} />

    return (
        <div className='GiveAccessToTutorPage'>
            <Flex direction={"column"} alignItems={"center"} gap={"4vh"}>
                {redirect}
                {title}
                {newTutorForm()}
                <Flex direction={"column"} alignItems={"center"} gap={"1vh"}>
                    {title2}
                    {existingDescriptionText}
                </Flex>
                {existingEmailsUI}
                {deletePopUp && deletePopUpComponent}
                {deletingEmail && deletingEmailPopUp}
                {deletedEmail && deletedEmailPopUp}
                {notifyPopUp && notifyPopUpComponent}
                {notifyingTutor && notifyingEmailPopUp}
                {notifiedTutor && notifiedEmailPopUp}
                {(errorPopUp != false) && errorPopUpComponent}
            </Flex>
        </div>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default GiveAccessToTutor;