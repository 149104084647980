import React from "react";
import { Link } from "react-router-dom";
import { Flex, Text } from "@aws-amplify/ui-react";
import { AppNavButton } from "../../custom-ui-components";
import BackButton from "../../custom-ui-components/BackButton";

const TeachingResourcesAdmin = function(props) {
  const height = props.height;
  const titleTextProps = props.titleTextProps;
  const standardTextProps = props.standardTextProps;

  const titleText = <Text {...titleTextProps}>Teaching Resources for Tutors</Text>

  const viewResourcesButton = <Link to={"/Admin/TeachingResourcesSubject"} style={{textDecoration: 'none'}} key={"Find Resources"}><AppNavButton text={"Find Resources"}/></Link>

  const createResourceButton = <Link to={"/Admin/CreateTeachingResource"} style={{textDecoration: 'none'}} key={"Create Resource"}><AppNavButton text={"Create New Resources"}/></Link>

  const backButton = <BackButton {...props} defaultBack={"/Admin"} />

  return (
      <Flex direction={"column"} gap={"3vh"} alignItems={"center"}>
        {titleText}
        {backButton}
        {viewResourcesButton}
        {createResourceButton}
      </Flex>
  );
}

export default TeachingResourcesAdmin;