import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Icon, SelectField, Text, TextField } from "@aws-amplify/ui-react";
import { useState, useEffect } from 'react';
import { validateField } from "./FormValidation";
import OptionsTemplate from "./OptionsTemplate";
import { examBoards, qualificationLevels, subjects } from "../../App";
import {MdHelp} from 'react-icons/md';

const StudentSubjectsTemplate = function(props) {
	//try {
		const labelTextProps = props.questionLabelProps || {};
		const errorTextProps = props.questionErrorProps || {};
		//Set question variables from passed properties
		const questionID = props.id;
		let defaultValue = props.defaultValue;
		if (defaultValue == null || typeof(defaultValue) != "object") {
		defaultValue = {
			subject: null,
			examBoard: null,
			level: null,
			extraInfo: ""
		};
		}
		let questionValidations = props.validations;
		if (questionValidations == null) {
			questionValidations = [];
		}
		let flexDirection = props.direction;
		if (flexDirection == null) {
			flexDirection = "column";
		}
		const required = props.required;
		const requiredAsterisk = props.requiredAsterisk || <Text fontSize={"20px"} color={"#ff0000"}>*</Text>;
		const answersSuccessfullySubmitted = props.answersSuccessfullySubmitted;

		//Set state variables used to store the current state and any errors
		const [subjectAnswer, setSubjectAnswer] = useState(defaultValue);
		const [errors, setErrors] = useState({questionError: ""});
		const [subjectUI, setSubjectUI] = useState(null);
		const [examBoardUI, setExamBoardUI] = useState(null);
		const [levelUI, setLevelUI] = useState(null);
		const [extraInfoUI, setExtraInfoUI] = useState(null);
		const [UI, setUI] = useState(null);
		const [showHelp, setShowHelp] = useState({
			show: false,
			pointerType: null,
			clicked: false,
			hovering: false,
			mouseOverWindow: false,
			crossClicked: false,
			crossStop: false,
			mouseOverHelp: false
		});
		const [helpButton, setHelpButton] = useState(null);
		const [instructions, setInstructions] = useState(null);

		//Help setting
		useEffect(() => {
			if (showHelp.crossClicked && !showHelp.mouseOverHelp) {
				const newShowHelp = {...showHelp};
				newShowHelp.crossClicked = false;
				setShowHelp(newShowHelp);
		}
		}, [showHelp]);

		//Initial validation
		useEffect(() => {
			const validationResult = validateField(subjectAnswer, questionValidations);
			const questionHasError = validationResult.hasError;
			props.handleChange(questionID, subjectAnswer, questionHasError);
		}, []);

		//Subject UI
		useEffect(() => {
			const subjectOptionComponents = OptionsTemplate(subjects, "dropdown");
			const subjectDropdown = <SelectField
				placeholder={"-Select subject-"}
				value={subjectAnswer.subject}
				size={props.size}
				disabled={answersSuccessfullySubmitted}
				marginTop={"-8px"}
				//Runs when it detects a change in the input field
				onChange={(e) => {
					const currentValue = e.target.value;
					const newSubjectAnswer = {...subjectAnswer, subject: currentValue};
					setSubjectAnswer(newSubjectAnswer);
					const validationResult = validateField(newSubjectAnswer, questionValidations);
					const questionHasError = validationResult.hasError;
					const questionErrorMessage = validationResult.errorMessage;
					props.handleChange(questionID, newSubjectAnswer, questionHasError);

					setErrors({
						questionHasError:questionHasError, 
						questionError:questionErrorMessage
					});
				}} 
			>
				{subjectOptionComponents}
			</SelectField>

			setSubjectUI(subjectDropdown);
		}, [subjectAnswer]);

		//Exam Board UI
		useEffect(() => {
			const examBoardOptionComponents = OptionsTemplate([...examBoards, "N/A"], "dropdown");
			const examBoardDropdown = <SelectField
				placeholder={"-Select exam board-"}
				value={subjectAnswer.examBoard}
				size={props.size}
				disabled={answersSuccessfullySubmitted}
				marginTop={"-8px"}
				//Runs when it detects a change in the input field
				onChange={(e) => {
					let currentValue = e.target.value;
					const newSubjectAnswer = {...subjectAnswer, examBoard: currentValue};
					setSubjectAnswer(newSubjectAnswer);
					const validationResult = validateField(newSubjectAnswer, questionValidations);
					const questionHasError = validationResult.hasError;
					const questionErrorMessage = validationResult.errorMessage;
					props.handleChange(questionID, newSubjectAnswer, questionHasError);

					setErrors({
						questionHasError:questionHasError, 
						questionError:questionErrorMessage
					});
				}} 
			>
				{examBoardOptionComponents}
			</SelectField>

			setExamBoardUI(examBoardDropdown);
		}, [subjectAnswer]);

		//Level UI
		useEffect(() => {
			const levelOptionComponents = OptionsTemplate(qualificationLevels, "dropdown");
			const levelDropdown = <SelectField
				placeholder={"-Select qualification level-"}
				value={subjectAnswer.level}
				size={props.size}
				disabled={answersSuccessfullySubmitted}
				marginTop={"-8px"}
				//Runs when it detects a change in the input field
				onChange={(e) => {
					const currentValue = e.target.value;
					const newSubjectAnswer = {...subjectAnswer, level: currentValue};
					setSubjectAnswer(newSubjectAnswer);
					const validationResult = validateField(newSubjectAnswer, questionValidations);
					const questionHasError = validationResult.hasError;
					const questionErrorMessage = validationResult.errorMessage;
					props.handleChange(questionID, newSubjectAnswer, questionHasError);

					setErrors({
						questionHasError:questionHasError, 
						questionError:questionErrorMessage
					});
				}} 
			>
				{levelOptionComponents}
			</SelectField>

			setLevelUI(levelDropdown);
		}, [subjectAnswer]);

		//Extra Info UI
		useEffect(() => {
			const extraInfo = <TextField
				value={subjectAnswer.extraInfo}
				size={props.size}
				disabled={answersSuccessfullySubmitted}
				marginTop={"-8px"}
				placeholder="Extra course info (optional)"
				//Runs when it detects a change in the input field
				onChange={(e) => {
					const currentValue = e.target.value;
					const newSubjectAnswer = {...subjectAnswer, extraInfo: currentValue};
					setSubjectAnswer(newSubjectAnswer);
					const validationResult = validateField(newSubjectAnswer, questionValidations);
					const questionHasError = validationResult.hasError;
					const questionErrorMessage = validationResult.errorMessage;
					props.handleChange(questionID, newSubjectAnswer, questionHasError);

					setErrors({
						questionHasError:questionHasError, 
						questionError:questionErrorMessage
					});
				}} 
			/>
			setExtraInfoUI(extraInfo);
		}, [subjectAnswer]);

		//All UI
		useEffect(() => {
			let labelColour = labelTextProps.color || "#000000";
			if (props.validationError != null && props.validationError != null) {
				labelColour = props.validationErrorColour || "#ff0000";
			}
			//Get the text for the question label
			const labelText = <Text {...labelTextProps} color={labelColour}>Subject:</Text>

			let errorText = <Text {...errorTextProps} position={"absolute"} top={"0px"}>{errors.questionError}</Text>
			if (errors.questionError === null || errors.questionError === "") {
				errorText = null;
			}
			if (errors.errorMessage === null) {
				setErrors({
					questionError:""
				});
			}

			let renderedRequiredAsterisk = null;
			if (required == true) {
				renderedRequiredAsterisk = requiredAsterisk;
			}

			let alignItems = null;
			if (flexDirection == "row") {
				alignItems="center"
			}

			if (errors.questionHasError == false && errors.questionError != props.warning) {
				const newErrors = {...errors}
				newErrors.questionError = props.warning
				setErrors(newErrors);
			}

			setUI(<Flex direction={flexDirection} alignItems={alignItems} width={"100%"}>
				{(showHelp.show) && instructions}
				<Flex alignItems={"center"} position={"relative"}>
					{renderedRequiredAsterisk}
					{labelText}
					{helpButton}
				</Flex>
				{subjectUI}
				{levelUI}
				{examBoardUI}
				{extraInfoUI}
				<Flex direction={"column"} alignItems={"center"} justifyContent={"center"} position={"relative"}>
					{errorText}
				</Flex>
			</Flex>);
		}, [subjectUI, examBoardUI, levelUI, extraInfoUI, errors, helpButton, showHelp, instructions]);

		//Help Button
		useEffect(() => {
			let helpButtonColour = "#ffd152";
			//let helpButtonColour = "#ffffff";
			if (showHelp.clicked && showHelp.mouseOverHelp && !showHelp.hovering) {
			  helpButtonColour = "#c42727";
			}
			else if (showHelp.clicked) {
			  helpButtonColour = "#eb6f42";
			}
			else if (showHelp.hovering || showHelp.mouseOverWindow) {
			  helpButtonColour = "#ebaa42 ";
			  //helpButtonColour = "#ff7f00";
			}

			const newHelpButton = <Icon
				key={"helpButton"}
				width={"30px"}
				height={"30px"}
				as={MdHelp}
				color={helpButtonColour}
				borderRadius={"7vh"}
				backgroundColor={"#4287f5"}
				style={{
				cursor: "pointer",
				zIndex:1
				}}
				position={"absolute"} 
				right={"-10px"}
				onClick={(event) => {
				const eventPointerType = event.nativeEvent.pointerType;
				const newShowHelp = {...showHelp};
				newShowHelp.clicked = true;
				if (showHelp.show == true) {
					if (showHelp.hovering == false) {
					newShowHelp.clicked = false;
					newShowHelp.show = false;
					newShowHelp.pointerType = null;
					}
					else {
					newShowHelp.hovering = false;
					newShowHelp.pointerType = eventPointerType;
					}
				}
				else {
					newShowHelp.pointerType = eventPointerType;
					newShowHelp.show = true;
				}
				setShowHelp(newShowHelp);
				}}
				onPointerEnter={(event) => {
				const eventPointerType = event.pointerType;
				const newShowHelp = {...showHelp};
				if (showHelp.crossClicked == true) {
					newShowHelp.crossStop = true;
				}
				newShowHelp.mouseOverHelp = true;
				if (eventPointerType == "mouse" && newShowHelp.crossStop == false) {
					if (showHelp.clicked == false) {
					newShowHelp.hovering = true;
					newShowHelp.show = true;
					newShowHelp.pointerType = eventPointerType;
					}
				}
				setShowHelp(newShowHelp);
				}}
				onPointerLeave={(event) => {
				const eventPointerType = event.pointerType;
				if (eventPointerType == "mouse") {
					const newShowHelp = {...showHelp};
					newShowHelp.mouseOverHelp = false;
					newShowHelp.hovering = false;
					newShowHelp.crossClicked = false;
					newShowHelp.crossStop = false
					newShowHelp.mouseOverWindow = false;
					if (showHelp.clicked == false) {
					newShowHelp.show = false;
					}
					setShowHelp(newShowHelp);
				}
				}}
			/>

			setHelpButton(newHelpButton);
		}, [showHelp]);

		//Instructions
		useEffect(() => {
			const xButton = <Flex
				key={"XButton"}
				direction={"row"}
				style={{
					cursor:"pointer",
					background:"#ff0202",
					zIndex:4
				}}
				width="30px"
				height="30px"
				textAlign="center"
				borderRadius="50%"
				fontSize="18px"
				justifyContent={"center"}
				alignSelf={"flex-end"}
				onClick={() => {
					const newShowHelp = {...showHelp};
					newShowHelp.show = false;
					newShowHelp.clicked = false;
					newShowHelp.pointerType = null;
					newShowHelp.crossClicked = true;
					newShowHelp.mouseOverWindow = false;
					setShowHelp(newShowHelp)
				}}
				position={"absolute"}
				top={"-10px"}
				right={"-10px"}
			>X</Flex>

			const instructionsFlex = <Flex
				backgroundColor={"#dddddd"}
				//direction={"column"}
				alignItems={"center"} 
				justifyContent={"center"} 
				position={"fixed"}
				top={"20vh"}
				alignSelf={"center"}
				width={"fit-content"} 
				style={{zIndex:3}}
				onPointerEnter={() => {
					const newShowHelp = {...showHelp};
					newShowHelp.mouseOverHelp = false;
					newShowHelp.mouseOverWindow = true;
					newShowHelp.hovering = false;
					setShowHelp(newShowHelp);
				}}
				onPointerLeave={() => {
					const newShowHelp = {...showHelp};
					newShowHelp.mouseOverWindow = false;
					if (showHelp.clicked == false && showHelp.hovering == false) {
					newShowHelp.show = false;
					}
					setShowHelp(newShowHelp);
				}}
			>
				{(showHelp.pointerType != "mouse" && !showHelp.hovering) && <Flex 
					backgroundColor={"#000000"}
					position={"fixed"}
					top={"0px"}
					left={"0px"}
					width={"100vw"}
					height={"100vh"}
					style={{
					opacity: 0.7
					}}
					onClick={() => {
					const newShowHelp = {...showHelp};
					newShowHelp.show = false;
					newShowHelp.clicked = false;
					newShowHelp.pointerType = null;
					setShowHelp(newShowHelp)
					}}
				/>}
				<Flex 
					maxWidth={"80vw"}
					backgroundColor={"#92e887"} 
					alignItems={"center"} 
					borderRadius={"10px"}
					position={"fixed"}
					top={"20vh"}
					alignSelf={"center"}
					border={"solid"}
					style={{
						zIndex:3,
					}}
					direction={"column"}
					maxHeight={"70vh"}
				>
					<Flex
						alignSelf={"center"}
						style={{
							overflowY: "auto",
							overflowX: "hidden"
						}}
						paddingLeft={"5px"}
						paddingRight={"10px"}
						direction={"column"}
					>
						<Text {...labelTextProps} fontSize={"18px"} textAlign={"center"} maxWidth={"350px"} marginTop={"10px"} marginBottom={"-20px"}>
							Subject Help
						</Text>
						<ul>
							<Text><li>Choose a subject from the list</li></Text>
							<li>
								<Text>Choose the level of study</Text>
								<ul><Text>
									<li>Level 3 Qualification = Alternative to A-Level (e.g. Level 3 BTEC)</li>
									<li>Level 2 Qualification = Alternative to GCSE (e.g. Level 2 BTEC)</li>
									<li>Key Stage 3 = Years 7, 8 and 9 (Age 11 - 14)</li>
									<li>Key Stage 2 = Years 3, 4, 5 and 6 (Age 7 - 11)</li>
									<li>Key Stage 1 = Years 1 and 2 (Age 5 - 7)</li>
								</Text></ul>
							</li>
							<li>
								<Text>There are 4 UK exam boards for A-Level and GCSE level study:</Text>
								<ul><Text>
									<li>AQA</li>
									<li>OCR</li>
									<li>Pearson</li>
									<li>WJEC (Eduqas)</li>
								</Text></ul>
							</li>
							<li>
								<Text>Feel free to provide us with any extra course info e.g.</Text>
								<ul><Text>
									<li>Specific course name</li>
									<li>Textbook name</li>
								</Text></ul>
							</li>
						</ul>
						<Text {...labelTextProps} fontSize={"16px"} textAlign={"center"} maxWidth={"350px"} marginTop={"-20px"} marginBottom={"10px"}>
							Don't worry if you're not sure about any of this information, you can always update it later
						</Text>
					</Flex>
					{xButton}
				</Flex>
			</Flex>
			
			setInstructions(instructionsFlex);
		}, [showHelp]);


		//flexDirection = "column";

		//Return text field component to calling function (Most likely a Form Template)
		return (
			<Flex>
				{UI}
			</Flex>
		);
  // }
  // catch (error) {
  //   	throw "DropDownQuestionTemplate Error: " + error;
  // }
}

export default StudentSubjectsTemplate;