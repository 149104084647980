import React, { useState } from 'react';
import { Flex, Text } from '@aws-amplify/ui-react';
import { AppNavButton, FormTemplate } from '../../custom-ui-components';
import { compareObjects, sleep } from '../../App';
import { Navigate } from 'react-router-dom';

const UserSettingsPage = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    //Get the current user to pass to subsequent functions
    const user = props.user;
    const userAttributes = user.attributes;
    const APIFunctions = props.APIFunctions;

    const [redirect, setRedirect] = useState(null);

    const userSettings = props.settings;

    const submitForm = async function(answers) {
        try {
            const newSettings = {...userSettings};
            const newAnswersArray = Object.entries(answers);
            for (const newAnswer of newAnswersArray) {
                const key = newAnswer[0];
                const value = newAnswer[1];
                newSettings[key] = value;
            }
            
            if (compareObjects(userSettings, newSettings)) {
                throw "No changes made";
            }
            
            const attributesToSubmit = {
                Name: "settings",
                Value: newSettings
            };
            const newAttributes = await APIFunctions.updateUserAttributes(attributesToSubmit);
        }
        catch (error) {
            if (error == "No changes made") {
                throw "No changes made to settings";
            }
            throw {
                message: "Error updating settings",
                error: error
            };
        }
    }

    const questions = [];

    questions.push({
        id: "distanceUnit",
        label: "Default Distance Unit:",
        type: "radio",
        options: ["imperial", "metric"],
        defaultValue: userSettings.distanceUnit
    });
    const settingsForm = <FormTemplate {...props} questions={questions} submitAnswers={submitForm} okButtonFunction={"reset"} submitPopUpText={"Successfully updated user settings"} />

    const backButtonFunction = function () {
        setRedirect(<Navigate to={"/User"} />);
    };
    const backButton = <AppNavButton text={"Back"} onClickFunction={backButtonFunction} />

    return (
        <div className='ProfilePage'>
            <Flex direction={"column"} alignItems={"center"}>
                {redirect}
                {backButton}
                {settingsForm}
            </Flex>
        </div>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default UserSettingsPage;