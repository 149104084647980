import { React, useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { LoadingSpinnerPage } from '../LoadingPage';
import { Button, Flex, Icon, Text } from '@aws-amplify/ui-react';
import CalendarTemplate from '../../custom-ui-components/CalendarViewTemplate';
import { ErrorLoadingPage } from '../ErrorLoadingPage';
import { AppNavButton } from '../../custom-ui-components';
import {MdOutlineMoreTime, MdSchool, MdMenuBook, MdHelp} from 'react-icons/md';
import {RiMoneyPoundCircleLine} from "react-icons/ri";

const TutorHome = function(props) {
    try {
        const titleTextProps = props.titleTextProps;
        const standardTextProps = props.standardTextProps;
        const width = props.width;
        //This is the main home page for tutors

        const lessons = props.tutorLessons;
        const tutorRequests = props.tutorTutorRequests;
        const tutorModel = props.tutorModel;

        const [redirect, setRedirect] = useState(null);
        const [hover, setHover] = useState(null);
        const [mousePosition, setMousePosition] = useState({ x: null, y: null });

        useEffect(() => {
            const updateMousePosition = function (event) {
                setMousePosition({ x: event.clientX, y: event.clientY });
            };
            window.addEventListener('mousemove', updateMousePosition);
        }, []);

        if (lessons == null || tutorRequests == null || tutorModel == null) {
            return <LoadingSpinnerPage {...props} height={props.height} />
        }
        else if (lessons == "loading" || tutorRequests == "loading" || tutorModel == "loading") {
            return <LoadingSpinnerPage {...props} height={props.height} />
        }
        else if (lessons == "error" || tutorRequests == "error" || tutorModel == "error") {
            return <ErrorLoadingPage {...props}/>
        }

        const welcomeText = <Text {...titleTextProps}>
            Tutor Dashboard
        </Text>

        let viewLessonsButton = <Link to={"/Tutor/MyLessons"} style={{textDecoration: 'none'}}><AppNavButton fontSize={"18px"} width={width} text="View all lessons"/></Link>
        
        let viewRequestsText = <Text {...standardTextProps}>
            View your lesson requests to accept/deny:
        </Text>
        const numLessonRequests = tutorRequests.length;
        let requestNotification = <Flex 
            borderRadius={"100px"} 
            backgroundColor={"#fc3903"} 
            width={"25px"} 
            height={"25px"}
            alignItems={"center"}
            justifyContent={"center"}
            fontSize={"15px"}
            fontWeight={"hairline"}
            color={"#ffffff"}
            position={"absolute"}
            right="-10px" 
            top="-10px"
        >
            {numLessonRequests}
        </Flex>
        let tutorRequestsButton = <Link to={"/Tutor/LessonRequests"} style={{textDecoration: 'none'}} key={"Tutor Requests"}><Flex position={"relative"}>
            {requestNotification}
            <AppNavButton text="Tutor Requests" fontSize={"18px"} width={width}/>
        </Flex></Link>

        let currentLessonsIntro = null;
        if (lessons.length == 0) {
            currentLessonsIntro = <Text {...standardTextProps}>
                You are not currently registered for any lessons
            </Text>
            viewLessonsButton = null;
        }
        else {
            currentLessonsIntro = <Text {...standardTextProps}>
                You are currently registered for {lessons.length} lessons
            </Text>
        }

        if (numLessonRequests == 0) {
            viewRequestsText = <Text {...standardTextProps}>
                You don't have any pending lesson requests
            </Text>
            tutorRequestsButton = null;
        }

        const calendar = {
            Monday: [{
                title: "Test Title",
                desciption: "Test description",
                startTime: "09:10",
                endTime:"17:45"
            }],
            Tuesday: [],
            Wednesday: [{
                title: "Test Title",
                desciption: "Test description",
                startTime: "10:00",
                endTime:"15:00"
            }],
            Thursday: [],
            Friday: [],
            Saturday: [],
            Sunday: []
        }

        const changeAvailability = function () {
            setRedirect(<Navigate to={"/Tutor/ChangeAvailability"} />);
        };
        const changeSubjects = function () {
            setRedirect(<Navigate to={"/Tutor/ChangeSubjects"} />);
        };
        const changeBankDetails = function () {
            setRedirect(<Navigate to={"/Tutor/ChangeBankDetails"} />);
        };

        const iconSize = "45";
        const changeAvailabilityButton = <Flex
            style={{cursor:"pointer"}} 
            onClick={changeAvailability}
            onMouseEnter={() => {
                setHover("Change Availability")
            }}
            onMouseLeave={() => {
                setHover(null);
            }}
            backgroundColor={"#d4db4d"} 
            borderRadius={iconSize/8 + "px"}
            width={iconSize + "px"}
            height={iconSize + "px"}
            justifyContent={"center"}
            alignItems={"center"}
        ><Icon
            key={"time"}
            width={"30px"}
            height={"30px"}
            as={MdOutlineMoreTime}
        /></Flex>

        const changeSubjectsButton = <Flex
            style={{cursor:"pointer"}} 
            onClick={changeSubjects}
            onMouseEnter={() => {
                setHover("Add/Remove Subjects")
            }}
            onMouseLeave={() => {
                setHover(null);
            }}
            backgroundColor={"#d4db4d"} 
            borderRadius={iconSize/8 + "px"}
            width={iconSize + "px"}
            height={iconSize + "px"}
            justifyContent={"center"}
            alignItems={"center"}
        ><Icon
            key={"book"}
            width={"30px"}
            height={"30px"}
            as={MdMenuBook}
        /></Flex>
        
        const existingBankingDetails = tutorModel.bankDetails || {};
        let missingBankDetails = false;
        if (existingBankingDetails.accountNumber == null) {
            missingBankDetails = true;
        }
        else if (existingBankingDetails.sortCode == null) {
            missingBankDetails = true;
        }
        else if (existingBankingDetails.bankName == null) {
            missingBankDetails = true;
        }
        else if (existingBankingDetails.name == null) {
            missingBankDetails = true;
        }
        else if (existingBankingDetails.address == null) {
            missingBankDetails = true;
        }

        let bankDetailsNotification = null;
        if (missingBankDetails) {
            bankDetailsNotification = <Flex 
                borderRadius={"100px"} 
                backgroundColor={"#fc3903"} 
                width={"25px"} 
                height={"25px"}
                alignItems={"center"}
                justifyContent={"center"}
                fontSize={"15px"}
                fontWeight={"hairline"}
                color={"#ffffff"}
                position={"absolute"}
                right="-10px" 
                top="-10px"
            >
                1
            </Flex>
        }

        const changeBankDetailsButton = <Flex
            style={{cursor:"pointer"}} 
            onClick={changeBankDetails}
            onMouseEnter={() => {
                if (missingBankDetails == false) {
                    setHover("Change Bank Details")
                }
                else {
                    setHover("Add Bank Details")
                }
            }}
            onMouseLeave={() => {
                setHover(null);
            }}
            backgroundColor={"#d4db4d"} 
            borderRadius={iconSize/8 + "px"}
            width={iconSize + "px"}
            height={iconSize + "px"}
            justifyContent={"center"}
            alignItems={"center"}
            position={"relative"}
        >
            <Icon
                key={"book"}
                width={"30px"}
                height={"30px"}
                as={RiMoneyPoundCircleLine}
            />
            {bankDetailsNotification}
        </Flex>
        
        
        let right = null;
        let left = mousePosition.x + 10;
        if (mousePosition.x > (2*width/3)) {
            left = null;
            right = width - mousePosition.x + 10;
        }
        let hoverMenuComponent = <Flex 
            backgroundColor={"#069dd4"} 
            borderRadius={"1vh"}
            width={"fit-content"}
            paddingLeft={"0.5vw"}
            paddingRight={"0.5vw"}
            paddingTop={"0.5vh"}
            paddingBottom={"0.5vh"}
            height={"fit-content"}
            position="fixed"
            top={mousePosition.y - 30}
            left={left}
            right={right}
            style={{
            zIndex:5
            }}
            direction={"column"}
        >
            {hover}
        </Flex>
        if (hover == null) {
            hoverMenuComponent = null;
        }
        
        const teachingResourcesButton = <Link to={"/Tutor/TeachingResourcesSubject"} style={{textDecoration: 'none'}} key={"teachingResourcesButton"}><Flex position={"relative"}>
            <AppNavButton text="Subject Teaching Resources" fontSize={"18px"} width={width}/>
        </Flex></Link>

        const tutorHelpButton = <Link to={"/Tutor/Help"} style={{textDecoration: 'none'}} key={"tutorHelpButton"}><Flex position={"relative"}>
            <AppNavButton text="Tutoring Help" fontSize={"18px"} width={width}/>
        </Flex></Link>

        let tutorReferalExplain = null;
        const questionMarkIcon = <Flex 
            backgroundColor={"#2c628f"}
            justifyContent={"center"}
            width={"30px"}
            height={"30px"}
            alignItems={"center"}
            borderRadius={"20px"}
            style={{cursor:"default"}}
            
            onMouseEnter={() => {
                setHover(<Flex direction={"column"} maxWidth={"250px"} gap={"0px"}>
                    <Text {...standardTextProps} fontSize={"14px"}>Give this code out to others looking to tutor, if they enter it when they register you will be entered into a £100 prize pool after they've taught their first session!</Text>
                </Flex>);
            }}
            onMouseLeave={() => {
                setHover(null);
            }}
        >
            <Text {...standardTextProps} color={"#ebab34"}>?</Text>
            
        </Flex>
        const referralCode = <Flex alignItems={"center"} position={"relative"}>
            <Text {...standardTextProps} fontSize={"17px"}>My tutor referral code: {tutorModel.referralCode}</Text>
            {questionMarkIcon}
            {tutorReferalExplain}
        </Flex>

        let numRequestedAmendments = 0;
        for (const lesson of lessons) {
            const lessonAmendments = lesson.amendments || [];
            for (const amendment of lessonAmendments) {
                if (amendment.confirmed != true && amendment.rejectedBy == null) {
                    const requestedBy = amendment.requestedBy;
                    if (requestedBy.userType != "tutor") {
                        numRequestedAmendments = numRequestedAmendments + 1;
                    }
                }
            }
        }

        let respondToAmendmentsButton = null;
        if (numRequestedAmendments > 0) {
            let buttonNotification = <Flex 
                borderRadius={"100px"} 
                backgroundColor={"#fc3903"} 
                width={"25px"} 
                height={"25px"}
                alignItems={"center"}
                justifyContent={"center"}
                fontSize={"15px"}
                color={"#ffffff"}
                position={"absolute"}
                right="-10px" 
                top="-10px"
            >
                {numRequestedAmendments}
            </Flex>

            let pluralText = "request";
            if (numRequestedAmendments > 1) {
                pluralText = "requests";
            }
            const buttonText = "Respond to lesson amendment " + pluralText;
            const buttonElement = <Flex position={"relative"}>
                {buttonNotification}
                <AppNavButton fontSize={"18px"} width={width} text={buttonText} />
            </Flex>
            respondToAmendmentsButton = <Link to={"/Tutor/RespondToAmendments"} style={{textDecoration: 'none'}}>{buttonElement}</Link>
        }
        
        let bankDetailsReminder = null;
        if (lessons.length > 0 && existingBankingDetails.accountNumber == null) {
            bankDetailsReminder = <Link to={"/Tutor/ChangeBankDetails"} style={{textDecoration: 'none'}}>
                <Flex 
                    backgroundColor={"#fad905"} 
                    paddingTop={"4px"} 
                    paddingBottom={"4px"} 
                    paddingLeft={"10px"} 
                    paddingRight={"10px"} 
                    borderRadius={"10px"}
                    position={"relative"}
                >
                    <Text {...standardTextProps}>Add bank details!</Text>
                    <Flex 
                        borderRadius={"100px"} 
                        backgroundColor={"#fc3903"} 
                        width={"25px"} 
                        height={"25px"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        fontSize={"15px"}
                        fontWeight={"hairline"}
                        color={"#ffffff"}
                        position={"absolute"}
                        right="-10px" 
                        top="-10px"
                    >1</Flex>
                </Flex>
            </Link>
        }

        let verifyIDButton = null;
        if (lessons.length > 0 || true) {
            verifyIDButton = <Link to={"/VerifyID"} style={{textDecoration: 'none'}}>
                <Flex 
                    backgroundColor={"#fad905"} 
                    paddingTop={"4px"} 
                    paddingBottom={"4px"} 
                    paddingLeft={"10px"} 
                    paddingRight={"10px"} 
                    borderRadius={"10px"}
                    position={"relative"}
                >
                    <Text {...standardTextProps}>Verify ID!</Text>
                    <Flex 
                        borderRadius={"100px"} 
                        backgroundColor={"#fc3903"} 
                        width={"25px"} 
                        height={"25px"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        fontSize={"15px"}
                        fontWeight={"hairline"}
                        color={"#ffffff"}
                        position={"absolute"}
                        right="-10px" 
                        top="-10px"
                    >1</Flex>
                </Flex>
            </Link>
        }

        return (
            <Flex direction={"column"} gap={"6vh"} alignItems={"center"}>
                {redirect}
                {hoverMenuComponent}
                <Flex direction={"column"} alignItems={"center"}>
                    {welcomeText}
                    <Flex>
                        {changeAvailabilityButton}
                        {changeSubjectsButton}
                        {changeBankDetailsButton}
                    </Flex>
                    {bankDetailsReminder}
                    {/* {verifyIDButton} */}
                </Flex>
                <Flex direction={"column"} alignItems={"center"}>
                    {currentLessonsIntro}
                    {viewLessonsButton}
                </Flex>
                <Flex direction={"column"} alignItems={"center"}>
                    {respondToAmendmentsButton}
                </Flex>
                <Flex direction={"column"} alignItems={"center"}>
                    {viewRequestsText}
                    {tutorRequestsButton}
                </Flex>
                <Flex direction={"column"} gap={"2vh"} alignItems={"center"}>
                    {tutorHelpButton}
                    {/* {teachingResourcesButton} */}
                </Flex>
                {referralCode}
                {/* <CalendarTemplate {...props} items={calendar}/> */}
            </Flex>
        );  
    }
    catch (error) {
      throw "TutorHome Error: " + error;
    }
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default TutorHome;