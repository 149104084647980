import React, { useEffect, useState } from "react";
import {NavigationBarTemplate, TextFieldTemplate} from '../../custom-ui-components';
import { Button, CheckboxField, Divider, Flex, Text } from "@aws-amplify/ui-react";
import PopUpTemplate from "../../custom-ui-components/form-components/PopUpTemplate";

const MassEmail = function(props) {
	const standardTextProps = props.standardTextProps;
	const titleTextProps = props.titleTextProps;
	const width = props.width;
	const title = <Text {...titleTextProps}>Mass Email</Text>;
	const APIFunctions = props.APIFunctions;

	const noUsersSelected = {
		parents: false,
		students: false,
		tutors: false,
		admins: false,
		noGroup: false,
		all: false
	};
	const [selectedUsers, setSelectedUsers] = useState(noUsersSelected);
	const [userButtons, setUserButtons] = useState(null);
	//User selection
	useEffect(() => {
		const updateSelectedUsers = function (userType) {
			if (userType == "all") {
				const newUsersSelected = {...noUsersSelected};
				newUsersSelected.all = !selectedUsers.all;
				setSelectedUsers(newUsersSelected);
			}
			else {
				const newUsersSelected = {...selectedUsers};
				newUsersSelected["all"] = false;
				newUsersSelected[userType] = !newUsersSelected[userType];
				setSelectedUsers(newUsersSelected);
			}
		}

		const userOptions = [
			{buttonText: "Parents", option: "parents"},
			{buttonText: "Students", option: "students"},
			{buttonText: "Tutors", option: "tutors"},
			{buttonText: "Admins", option: "admins"},
			{buttonText: "No Group", option: "noGroup"},
			{buttonText: "All Signed Up Users", option: "all"}
		];

		let flexDirection = "row";
		let buttonSize = "medium";
		let flexGap = "2vw";
		if (width < 850) {
			flexDirection = "column";
			buttonSize = "small";
			flexGap = "0.5vh";
		}

		const userButtonsArray = [];
		for (const userOption of userOptions) {
			const usersName = userOption.buttonText;
			let backgroundColour = null;
			if (selectedUsers[userOption.option]) {
				backgroundColour = "#a89e32";
			}
			const userButton = <Button
				key={userOption.option + "button"}
				backgroundColor={backgroundColour}
				size={buttonSize}
				onClick={() => {
					updateSelectedUsers(userOption.option);
				}}
			>
				{usersName}
			</Button>
			userButtonsArray.push(userButton);
		}

		const userButtonsFlex = <Flex direction={"column"} gap={"1vh"} alignItems={"center"}>
			<Text {...standardTextProps}>Select Users to Email:</Text>
			<Flex direction={flexDirection} gap={flexGap} width={"fit-content"}>
				{userButtonsArray}
			</Flex>
		</Flex>

		setUserButtons(userButtonsFlex);
	}, [selectedUsers, width]);

	const [lessonStatus, setLessonStatus] = useState(null);
	const [lessonStatusBoxes, setLessonStatusBoxes] = useState(null);
	//Lesson status selection
	useEffect(() => {
		let flexDirection = "row";
		let buttonSize = "large";
		let flexGap = "5vw";
		if (width < 700) {
			flexDirection = "column";
			buttonSize = "medium";
			flexGap = "0.5vh";
		}

		const updateActiveLessons = function (button, checked) {
			if (lessonStatus == button) {
				setLessonStatus(null);
			}
			else {
				setLessonStatus(button);
			}
		}

		const onlyActiveLessonsButton = <CheckboxField
			key={"activeLessonsButton"}
			size={buttonSize}
			checked={lessonStatus == true}
			onChange={() => {
				updateActiveLessons(true);
			}}
		/>

		const onlyInactiveLessonsButton = <CheckboxField
			key={"inactiveLessonsButton"}
			size={buttonSize}
			checked={lessonStatus == false}
			onChange={() => {
				updateActiveLessons(false);
			}}
		/>

		const lessonStatusFlex = <Flex direction={flexDirection} gap={flexGap} width={"fit-content"} alignSelf={"center"}>
			<Flex 
				gap={"1vw"} 
				style={{cursor:"pointer"}}
				onClick={() => {
					updateActiveLessons(true);
				}}
			>
				{onlyActiveLessonsButton}
				<Text {...standardTextProps} fontSize={"15px"}>Only users <u>with</u> an active lesson(s)</Text>
			</Flex>
			<Flex 
				gap={"1vw"} 
				style={{cursor:"pointer"}}
				onClick={() => {
					updateActiveLessons(false);
				}}
			>
				{onlyInactiveLessonsButton}
				<Text {...standardTextProps} fontSize={"15px"}>Only users <u>without</u> an active lesson</Text>
			</Flex>
		</Flex>

		setLessonStatusBoxes(lessonStatusFlex);
	}, [lessonStatus, width]);

	const [subject, setSubject] = useState("");
	const [subjectInput, setSubjectInput] = useState(null);
	//Subject input
	useEffect(() => {
		const subjectChange = function (id, answer, hasError) {
			setSubject(answer);
		}
		const newSubjectInput = <TextFieldTemplate 
			id={"subject"}
			handleChange={subjectChange} 
			width={"80vw"}
			maxWidth={"500px"}
		/>

		const subjectFlex = <Flex direction={"column"} alignItems={"center"} gap={"0.5vh"}>
			<Text {...standardTextProps}>Email Subject:</Text>
			<Text {...standardTextProps} fontSize={"15px"}>(Please include either "NDT" or "Next Door Tutor")</Text>
			{newSubjectInput}
		</Flex>
		setSubjectInput(subjectFlex);
	}, []);

	const [greeting, setGreeting] = useState("");
	const [greetingInput, setGreetingInput] = useState(null);
	const [personalise, setPersonalise] = useState("group");
	const [greetingPreview, setGreetingPreview] = useState("");
	//Greetings input
	useEffect(() => {
		const greetingChange = function (id, answer, hasError) {
			setGreeting(answer);
		}
		let newGreetingInput = <TextFieldTemplate 
			id={"greeting"}
			handleChange={greetingChange} 
			width={"80vw"}
			maxWidth={"300px"}
		/>

		const personaliseOptions = [
			{buttonText: "First Name", option: "firstName"},
			{buttonText: "Full Name", option: "fullName"},
			{buttonText: "Group", option: "group"}, 
			{buttonText: "None", option: null}
		];
		let personaliseTitle = <Text {...standardTextProps} fontSize={"15px"}>Personalise By:</Text>
		let personaliseButtonsArray = [];
		for (const personaliseOption of personaliseOptions) {
			let backgroundColour = null;
			if (personalise == personaliseOption.option) {
				backgroundColour = "#a89e32";
			}
			const personaliseButton = <Button
				key={personaliseOption.option + "button"}
				backgroundColor={backgroundColour}
				size={"small"}
				onClick={() => {
					setPersonalise(personaliseOption.option);
				}}
			>
				{personaliseOption.buttonText}
			</Button>
			personaliseButtonsArray.push(personaliseButton);
		}

		let includeGreetingBackground = "#a89e32";
		let removeGreetingBackground = null;
		let greetingExampleText = <Text {...standardTextProps} fontSize={"15px"}>Greeting start: (e.g. To, Dear)</Text>
		if (typeof(greeting) != "string") {
			includeGreetingBackground = null;
			removeGreetingBackground = "#a89e32";
			greetingExampleText = null;
			newGreetingInput = null;
			personaliseButtonsArray = null;
			personaliseTitle = null;
		}
		const includeGreetingButton = <Button
			key={"includeGreetingButton"}
			backgroundColor={includeGreetingBackground}
			//size={buttonSize}
			onClick={() => {
				if (greeting == false) {
					setGreeting("");
				}
			}}
		>
			{"Include Greeting"}
		</Button>
		const removeGreetingButton = <Button
			key={"removeGreetingButton"}
			backgroundColor={removeGreetingBackground}
			//size={buttonSize}
			onClick={() => {
				setGreeting(false);
			}}
		>
			{"Remove Greeting"}
		</Button>

		let greetingExample = null;
		if (typeof(greeting) == "string") {
			const userOptionsArray = Object.entries(selectedUsers);
			const selectedUsersArray = [];
			for (const userOption of userOptionsArray) {
				if (userOption[1] == true) {
					selectedUsersArray.push(userOption[0]);
				}
			}

			let personalisedName = "Next Door Tutor User";
			if (personalise == "firstName" || personalise == "fullName") {
				if (selectedUsersArray.length > 1 || (selectedUsersArray.length != 0 && selectedUsersArray[0] != "noGroup" && selectedUsersArray[0] != "admins")) {
					if (personalise == "firstName") {
						personalisedName = "John";
					}
					else {
						personalisedName = "John Smith";
					}
				}
			}
			else if (personalise == "group") {
				if (selectedUsersArray.length > 0) {
					if (selectedUsersArray[0] == "all") {
						personalisedName = "Tutor";
					}
					else if (selectedUsersArray[0] != "noGroup") {
						personalisedName = selectedUsersArray[0].charAt(0).toUpperCase() + selectedUsersArray[0].slice(1, selectedUsersArray[0].length - 1);
					}
				}
			}
			if (greeting != "") {
				setGreetingPreview(greeting + " " + personalisedName + ",\n\n");
				greetingExample = <Text {...standardTextProps} fontSize={"17px"} fontStyle={"italic"} marginTop={"-15px"}>{greeting} {personalisedName},</Text>
			}
			else {
				setGreetingPreview(personalisedName + ",\n\n");
				greetingExample = <Text {...standardTextProps} fontSize={"17px"} fontStyle={"italic"} marginTop={"-15px"}>{personalisedName},</Text>
			}
		}

		const greetingFlex = <Flex direction={"column"} alignItems={"center"} gap={"1.5vh"}>
			<Text {...standardTextProps}>Email Greeting:</Text>
			<Flex direction={"row"} gap={"1vw"}>
				{includeGreetingButton}
				{removeGreetingButton}
			</Flex>
			<Flex direction={"column"} alignItems={"center"} gap={"0.5vh"}>
				{personaliseTitle}
				<Flex direction={"row"} gap={"1vw"}>
					{personaliseButtonsArray}
				</Flex>
			</Flex>
			<Flex direction={"column"} alignItems={"center"} gap={"0.5vh"}>
				{greetingExampleText}
				{newGreetingInput}
				{greetingExample}
			</Flex>
			
		</Flex>
		setGreetingInput(greetingFlex);
	}, [greeting, personalise, selectedUsers]);

	const [emailBody, setEmailBody] = useState("");
	const [emailBodyInput, setEmailBodyInput] = useState(null);
	//Body input
	useEffect(() => {
		const bodyChange = function (id, answer, hasError) {
			setEmailBody(answer);
		}
		const newBodyInput = <TextFieldTemplate 
			id={"subject"}
			handleChange={bodyChange} 
			width={"80vw"}
			maxWidth={"800px"}
			rows={15}
			wrap={"wrap"}
			extraProps={{
				textType: "paragraph"
			}}
		/>

		const bodyFlex = <Flex direction={"column"} alignItems={"center"} gap={"0.5vh"}>
			<Text {...standardTextProps}>Email Body:</Text>
			<Text {...standardTextProps} fontSize={"15px"}>(Not including greeting)</Text>
			{newBodyInput}
		</Flex>
		setEmailBodyInput(bodyFlex);
	}, []);

	const [preview, setPreview] = useState(null);
	//Preview
	useEffect(() => {
		let sendToString = "BCC:";
		if (selectedUsers.all) {
			sendToString = sendToString + " All signed up users";
		}
		else {
			const userTypesArray = Object.keys(selectedUsers);
			const selectedUsersArray = [];
			for (const userType of userTypesArray) {
				if (selectedUsers[userType]) {
					selectedUsersArray.push(userType);
				}
			}
			if (selectedUsersArray.length > 0) {
				sendToString = sendToString + " All ";
			}
			for (let userTypeIndex = 0; userTypeIndex < selectedUsersArray.length; userTypeIndex = userTypeIndex + 1) {
				const userType = selectedUsersArray[userTypeIndex];
				let userTypeString = "";
				if (userType == "noGroup") {
					userTypeString = "unregistered users";
				}
				else {
					userTypeString = userType;
				}
				sendToString = sendToString + userTypeString;
				if (userTypeIndex == selectedUsersArray.length - 2) {
					sendToString = sendToString + " and ";
				}
				else if (userTypeIndex < selectedUsersArray.length - 2) {
					sendToString = sendToString + ", ";
				}
			}
		}

		const sendToText = <Text {...standardTextProps} fontSize={"18px"}>{sendToString}</Text>

		const subjectText = <Text {...standardTextProps} fontSize={"18px"}>Subject: {subject}</Text>

		let bodyString = emailBody;
		if (typeof(greeting) == "string") {
			bodyString = greetingPreview + " " + bodyString;
		}
		const bodyTextArray = [];
		const bodySplit1 = bodyString.split("\\n");
		for (let split1Index = 0; split1Index < bodySplit1.length; split1Index = split1Index + 1) {
			const bodySplit1Part = bodySplit1[split1Index];
			const bodySplit2 = bodySplit1Part.split("\n");
			if (bodyTextArray.length == 0) {
				bodyTextArray.push(...bodySplit2);
			}
			else {
				bodyTextArray[bodyTextArray.length - 1] = bodyTextArray[bodyTextArray.length - 1] + "\\n" + bodySplit2[0];
				if (bodySplit2.length > 1) {
					bodyTextArray.push(...bodySplit2.slice(1, bodySplit2.length));
				}
			}
		}
		const bodyFlexArray = [];
		for (let bodyTextIndex = 0; bodyTextIndex < bodyTextArray.length; bodyTextIndex = bodyTextIndex + 1) {
			const bodyText = bodyTextArray[bodyTextIndex];
			bodyFlexArray.push(
				<Text
					key={"bodyText:" + bodyTextIndex}
					{...standardTextProps} 
					fontSize={"18px"}
					marginTop={"0px"}
					marginBottom={"0px"}
					height={"25px"}
				>
					{bodyText}
				</Text>
			);
		}

		const previewTitle = <Text {...standardTextProps} alignSelf={"center"}>Preview:</Text>
		const previewFlex = <Flex direction={"column"} alignItems={"flex-start"} gap={"1vh"}>
			{previewTitle}
			{sendToText}
			<Divider orientation="horizontal" />
			{subjectText}
			<Divider orientation="horizontal" />
			<Flex direction={"column"} alignItems={"flex-start"} gap={"0vh"}>
				{bodyFlexArray}
			</Flex>
			<Divider orientation="horizontal" />
		</Flex>
		setPreview(previewFlex);
	}, [greeting, greetingPreview, personalise, selectedUsers, subject, emailBody]);

	const [previewEmailSent, setPreviewEmailSent] = useState(false);
	const [mainEmailSent, setMainEmailSent] = useState(false);
	const [emailSending, setEmailSending] = useState(false);
	const [sendButton, setSendButton] = useState(null);
	const [popUp, setPopUp] = useState({
		show: false,
		text: "",
		okButtonFunction: null,
	});
	//Send button
	useEffect(() => {
		const selectedUsersArray = [];
		const userTypesArray = Object.entries(selectedUsers);
		for (const userTypeObject of userTypesArray) {
			if (userTypeObject[1]) {
				selectedUsersArray.push(userTypeObject[0]);
			}
		}

		const sendPreviewButtonDisabled = emailBody == "" || subject == "" || selectedUsersArray.length == 0 || emailSending;
		const sendButtonDisabled = !previewEmailSent || mainEmailSent;
		
		const sendEmail = async function (sendPreivew) {
			try {
				setEmailSending(true);
				let popUpText = "Sending mass email...";
				if (sendPreivew) {
					popUpText = "Sending preview email...";
				}
				setPopUp({
					show: true,
					text: popUpText,
					okButtonFunction: null
				});
				const emailReturn = await APIFunctions.adminMassEmail(selectedUsersArray, lessonStatus, personalise, subject, emailBody, greeting, sendPreivew);
				const numUsersEmailed = emailReturn.sentToUsers;
				popUpText = "Mass email successfully sent to " + numUsersEmailed + " users";
				if (sendPreivew) {
					popUpText = "Preview email sent";
				}
				setPopUp({
					show: true,
					text: popUpText,
					okButtonFunction: hidePopUp
				});
				setEmailSending(false);
				return true;
			}
			catch (error) {
				setPopUp({
					show: true,
					text: error,
					okButtonFunction: hidePopUp
				});
				console.log("Error sending mass email: " + error);
				setEmailSending(false);
			}
		}

		const newSendButton = <Button
			disabled={sendButtonDisabled}
			onClick={async () => {
				const sendSuccess = await sendEmail(false);
				if (sendSuccess) {
					setMainEmailSent(true);
				}
			}}
			width={"fit-content"}
		>
			Send
		</Button>

		const sendPreivewButton = <Button
			disabled={sendPreviewButtonDisabled}
			onClick={async () => {
				const sendSuccess = await sendEmail(true);
				if (sendSuccess) {
					setPreviewEmailSent(true);
				}
			}}
			width={"fit-content"}
		>
			Send Preview
		</Button>

		const sendButtonFlex = <Flex alignSelf={"center"}>
			{sendPreivewButton}
			{newSendButton}
		</Flex>
		setSendButton(sendButtonFlex);
	}, [greeting, personalise, selectedUsers, lessonStatus, subject, emailBody, previewEmailSent, mainEmailSent, emailSending, APIFunctions]);

	const hidePopUp = function () {
		setPopUp({
			show: false,
			text: "",
			okButtonFunction: null
		});
	}

  	return (
		<Flex direction={"column"} gap={"2vh"}>
			{title}
			{userButtons}
			{lessonStatusBoxes}
			{subjectInput}
			{greetingInput}
			{emailBodyInput}
			{preview}
			{sendButton}
			<Flex direction={"column"} alignItems={"center"}>
				{popUp.show && <PopUpTemplate text={popUp.text} setPopUpVisibility={hidePopUp} okButtonFunction={popUp.okButtonFunction} showXButton={false} />}
			</Flex>
		</Flex>
  	);
}

export default MassEmail;