import React, { useState } from "react";
import { Button, Flex, Grid, Text } from "@aws-amplify/ui-react";
import { calculateLessonCost, calculateLessonWage } from "../../App";


const ViewPricing = function(props) {
  const titleTextProps = props.titleTextProps;
  const standardTextProps = props.standardTextProps;

  const titleText = <Text {...titleTextProps}>Lesson Pricing</Text>


  let tableFontProps = {...standardTextProps};
  if (props.width < 520) {
    tableFontProps.fontSize = "17px";
  } 
  tableFontProps.style = {cursor: "pointer"}

  const tableColour = "#000000";
  tableFontProps.margin = "1vh";
  const creditsTableProps = {...tableFontProps};
  creditsTableProps.fontSize = "1.5vh";
  creditsTableProps.fontStyle = "italic";
  creditsTableProps.alignItems = "center";
  creditsTableProps.color = "#3c00f2";
  creditsTableProps.style = null;
  const tableHeaderProps = {...tableFontProps}
  tableHeaderProps.fontSize = "1.6vh";
  tableHeaderProps.style = null;

  const lessonPricingTable = <Grid  border={"solid"} borderRadius={"10px"}>
    <Text {...tableHeaderProps} column={1} row={1} fontSize={"20px"} >Lesson Pricing</Text>

    <Text {...tableHeaderProps} column={2} row={1}>45 Minutes</Text>
    <Text {...tableHeaderProps} column={3} row={1}>1 Hour</Text>
    <Text {...tableHeaderProps} column={4} row={1}>1 Hour 30 Minutes</Text>
    <Text {...tableHeaderProps} column={5} row={1}>2 Hours</Text>

    <Text {...tableHeaderProps} column={1} row={2}>A Level Tutor (In-Person/Online)</Text>
    <Text {...tableHeaderProps} column={1} row={3}>University Tutor (In-Person)</Text>
    <Text {...tableHeaderProps} column={1} row={4}>University Tutor (Online)</Text>

    <Text {...creditsTableProps} column={2} row={2}>{calculateLessonCost("A-Level", null, null, "In-Person", true, 45)}</Text>
    <Text {...creditsTableProps} column={3} row={2}>{calculateLessonCost("A-Level", null, null, "In-Person", true, 60)}</Text>
    <Text {...creditsTableProps} column={4} row={2}>{calculateLessonCost("A-Level", null, null, "In-Person", true, 90)}</Text>
    <Text {...creditsTableProps} column={5} row={2}>{calculateLessonCost("A-Level", null, null, "In-Person", true, 120)}</Text>

    <Text {...creditsTableProps} column={2} row={3}>{calculateLessonCost("University", null, null, "In-Person", true, 45)}</Text>
    <Text {...creditsTableProps} column={3} row={3}>{calculateLessonCost("University", null, null, "In-Person", true, 60)}</Text>
    <Text {...creditsTableProps} column={4} row={3}>{calculateLessonCost("University", null, null, "In-Person", true, 90)}</Text>
    <Text {...creditsTableProps} column={5} row={3}>{calculateLessonCost("University", null, null, "In-Person", true, 120)}</Text>

    <Text {...creditsTableProps} column={2} row={4}>{calculateLessonCost("University", null, null, "Online", true, 45)}</Text>
    <Text {...creditsTableProps} column={3} row={4}>{calculateLessonCost("University", null, null, "Online", true, 60)}</Text>
    <Text {...creditsTableProps} column={4} row={4}>{calculateLessonCost("University", null, null, "Online", true, 90)}</Text>
    <Text {...creditsTableProps} column={5} row={4}>{calculateLessonCost("University", null, null, "Online", true, 120)}</Text>

    <Flex backgroundColor={tableColour} height={"0.2vh"} columnStart={1} columnEnd={6} row={2} />
    <Flex backgroundColor={tableColour} height={"0.2vh"} columnStart={1} columnEnd={6} row={3} />
    <Flex backgroundColor={tableColour} height={"0.2vh"} columnStart={1} columnEnd={6} row={4} />

    <Flex backgroundColor={tableColour} width={"0.2vh"} column={2} rowStart={1} rowEnd={5} />
    <Flex backgroundColor={tableColour} width={"0.2vh"} column={3} rowStart={1} rowEnd={5} />
    <Flex backgroundColor={tableColour} width={"0.2vh"} column={4} rowStart={1} rowEnd={5} />
    <Flex backgroundColor={tableColour} width={"0.2vh"} column={5} rowStart={1} rowEnd={5} />
  </Grid>

const lessonWagesTable = <Grid  border={"solid"} borderRadius={"10px"}>
<Text {...tableHeaderProps} column={1} row={1} fontSize={"20px"}>Lesson Wages</Text>

<Text {...tableHeaderProps} column={2} row={1}>45 Minutes</Text>
<Text {...tableHeaderProps} column={3} row={1}>1 Hour</Text>
<Text {...tableHeaderProps} column={4} row={1}>1 Hour 30 Minutes</Text>
<Text {...tableHeaderProps} column={5} row={1}>2 Hours</Text>

<Text {...tableHeaderProps} column={1} row={2}>A Level Tutor (In-Person/Online)</Text>
<Text {...tableHeaderProps} column={1} row={3}>University Tutor (In-Person)</Text>
<Text {...tableHeaderProps} column={1} row={4}>University Tutor (Online)</Text>

<Text {...creditsTableProps} column={2} row={2}>{calculateLessonWage("A-Level", null, null, "In-Person", true, 45)}</Text>
<Text {...creditsTableProps} column={3} row={2}>{calculateLessonWage("A-Level", null, null, "In-Person", true, 60)}</Text>
<Text {...creditsTableProps} column={4} row={2}>{calculateLessonWage("A-Level", null, null, "In-Person", true, 90)}</Text>
<Text {...creditsTableProps} column={5} row={2}>{calculateLessonWage("A-Level", null, null, "In-Person", true, 120)}</Text>

<Text {...creditsTableProps} column={2} row={3}>{calculateLessonWage("University", null, null, "In-Person", true, 45)}</Text>
<Text {...creditsTableProps} column={3} row={3}>{calculateLessonWage("University", null, null, "In-Person", true, 60)}</Text>
<Text {...creditsTableProps} column={4} row={3}>{calculateLessonWage("University", null, null, "In-Person", true, 90)}</Text>
<Text {...creditsTableProps} column={5} row={3}>{calculateLessonWage("University", null, null, "In-Person", true, 120)}</Text>

<Text {...creditsTableProps} column={2} row={4}>{calculateLessonWage("University", null, null, "Online", true, 45)}</Text>
<Text {...creditsTableProps} column={3} row={4}>{calculateLessonWage("University", null, null, "Online", true, 60)}</Text>
<Text {...creditsTableProps} column={4} row={4}>{calculateLessonWage("University", null, null, "Online", true, 90)}</Text>
<Text {...creditsTableProps} column={5} row={4}>{calculateLessonWage("University", null, null, "Online", true, 120)}</Text>

<Flex backgroundColor={tableColour} height={"0.2vh"} columnStart={1} columnEnd={6} row={2} />
<Flex backgroundColor={tableColour} height={"0.2vh"} columnStart={1} columnEnd={6} row={3} />
<Flex backgroundColor={tableColour} height={"0.2vh"} columnStart={1} columnEnd={6} row={4} />

<Flex backgroundColor={tableColour} width={"0.2vh"} column={2} rowStart={1} rowEnd={5} />
<Flex backgroundColor={tableColour} width={"0.2vh"} column={3} rowStart={1} rowEnd={5} />
<Flex backgroundColor={tableColour} width={"0.2vh"} column={4} rowStart={1} rowEnd={5} />
<Flex backgroundColor={tableColour} width={"0.2vh"} column={5} rowStart={1} rowEnd={5} />
</Grid>


  return (
      <Flex direction={"column"} gap={"10vh"} alignItems={"center"} >
        {titleText}
        {lessonPricingTable}
        {lessonWagesTable}
      </Flex>
  );
}

export default ViewPricing;