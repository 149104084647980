import { Button, CheckboxField, Divider, Flex, Text } from '@aws-amplify/ui-react';
import React, { useEffect } from 'react';
import {Link, Navigate, useLocation} from "react-router-dom";
import {AppNavButton, FormTemplate, GuideStepTemplate, FooterTemplate} from '../../custom-ui-components'
import { useState } from 'react';


const TutorStartGuide = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    const bulletFontSize = (standardTextProps.fontSize.split("px")[0] * 0.8) + "px";
    const bulletTextProps = {...standardTextProps, textAlign: "left", fontSize: bulletFontSize};
    //Get the current user to pass to subsequent functions
    const user = props.user;

    const tutorModel = props.tutorModel;
    const lessons = props.tutorLessons;
    const tutorRequests = props.tutorTutorRequests;
    const standardWidth = "90vw";
    const [stepsUI, setStepsUI] = useState(null);
    const [stepsComplete, setStepsComplete] = useState({});

    //Step 1: Sign up
    const [signUpUI, setSignUpUI] = useState(null);
    useEffect(() => {
        const signedUp = true;
        if (stepsComplete.signUp != signedUp) {
            const newStepsComplete = {...stepsComplete};
            newStepsComplete.signUp = signedUp;
            setStepsComplete(newStepsComplete);
        }

        setSignUpUI(<GuideStepTemplate
            {...props}
            stepComplete={signedUp} 
            stepNumber={1} 
            link={"/"} 
            stepText={"Sign up to Next Door Tutor"} 
            moreDetailsFlex={null} 
            detailsAfterComplete={false}
        />);
    }, [standardTextProps, stepsComplete]);

    //Step 2: Register as a tutor
    const [registerUI, setRegisterUI] = useState(null);
    useEffect(() => {
        const registered = tutorModel != null && tutorModel.id == user.username;
        if (stepsComplete.registered != registered) {
            const newStepsComplete = {...stepsComplete};
            newStepsComplete.registered = registered;
            setStepsComplete(newStepsComplete);
        }

        const moreDetailsFlex = <Flex direction={"column"} alignItems="flex-start" gap="1vh" maxWidth={standardWidth}>
            <Text {...standardTextProps} alignSelf={"flex-start"} textAlign={"left"}>In order to register as a tutor, all you need to do is provide us with your:</Text>
            <Text {...bulletTextProps}>
                <li>First and last name</li>
                <li>UK mobile contact number (this is only used for emergency short notice notifications)</li>
                <li>Your gender</li>
                <li>Current level of education</li>
                <li>The subjects you wish to teach and the grades you got in them</li>
                <li>Your postcode (so we can work out how close you are to potential lessons)</li>
                <li>An indicator of how many sessions you'd like to teach per week</li>
                <li>Your weekly availability</li>
                <li>Whether you have any previous experience or other jobs</li>
                <li>How you found out about Next Door Tutor</li>
                <li>Consent to our terms of use</li>
            </Text>
            <Flex alignSelf={"center"}>
                <Link to={"/TutorRegister"}><Button >Tutor Registration Page</Button></Link>
            </Flex>
        </Flex>

        setRegisterUI(<GuideStepTemplate
            {...props}
            stepComplete={registered} 
            stepNumber={2} 
            link={"/TutorRegister"} 
            stepText={"Register as a tutor"} 
            moreDetailsFlex={moreDetailsFlex} 
            detailsAfterComplete={false}
        />);

    }, [tutorModel, standardTextProps, stepsComplete]);

    //Step 3: Wait for a lesson request
    const [waitForRequestUI, setWaitForRequestUI] = useState(null);
    useEffect(() => {
        const requestReceived = (tutorRequests != null && tutorRequests.length > 0) || (lessons != null && lessons.length > 0);
        if (stepsComplete.requestReceived != requestReceived) {
            const newStepsComplete = {...stepsComplete};
            newStepsComplete.requestReceived = requestReceived;
            setStepsComplete(newStepsComplete);
        }

        const registered = stepsComplete.registered;
        let button = null;
        if (registered) {
            button = <Flex alignSelf={"center"}>
                <Link to={"/Tutor/LessonRequests"} state={{back: "/Tutor/StartGuide"}}><Button >View current lesson requests</Button></Link>
            </Flex>
        }

        let moreDetailsFlex = <Flex direction={"column"} alignItems="flex-start" gap="1vh" maxWidth={standardWidth}>
            <Text {...bulletTextProps}>Hold tight for an email from us with your first tutoring opportunity</Text>
            <Text {...bulletTextProps}>You'll be given the following details for the potential lesson:</Text>
            <Text {...bulletTextProps}>
                <li>Lesson subject</li>
                <li>How much you'll be paid</li>
                <li>Whether it's an online or in-person lesson (or whether you can choose)</li>
                <li>The location of the lesson (if it's in-person)</li>
                <li>The length of the lesson</li>
                <li>The number of lessons per week</li>
                <li>The student's year group and set</li>
                <li>The student's weekly availability</li>
                <li>When the student would like the first lesson to be</li>
            </Text>
            <Text {...bulletTextProps}>You'll only be sent a request if the student's availability matches yours and you've said you'd like to teach the subject</Text>
            <Text {...bulletTextProps}>You will also be able to choose the assigned weekly day and time of the lesson (within the student's given availability)</Text>
            <Text {...bulletTextProps}>You are able to accept or decline lesson requests, however if you decline too many without a good reason, you may be less likely to receive other lesson requests in the future</Text>
            {button}
        </Flex>;

        setWaitForRequestUI(<GuideStepTemplate
            {...props}
            stepComplete={requestReceived} 
            stepNumber={3} 
            link={null} 
            stepText={"Wait for a lesson request"} 
            moreDetailsFlex={moreDetailsFlex} 
            detailsAfterComplete={true}
        />);
    }, [tutorRequests, standardTextProps, stepsComplete]);

    //Step 4: Add bank details
    const [addBankDetailsUI, setAddBankDetailsUI] = useState(null);
    useEffect(() => {
        let bankDetailsAdded = tutorModel != null && tutorModel.bankDetails != null && tutorModel.bankDetails.beneficiaryID != null;
        if (stepsComplete.bankDetailsAdded != bankDetailsAdded) {
            const newStepsComplete = {...stepsComplete};
            newStepsComplete.bankDetailsAdded = bankDetailsAdded;
            setStepsComplete(newStepsComplete);
        }

        let buttonText = "Add your bank details";
        if (bankDetailsAdded) {
            buttonText = "Update your bank details";
        }

        const registered = stepsComplete.registered;
        let button = null;
        if (registered) {
            button = <Flex alignSelf={"center"}>
                <Link to={"/Tutor/ChangeBankDetails"} state={{back: "/Tutor/StartGuide"}}><Button >{buttonText}</Button></Link>
            </Flex>
        }

        let moreDetailsFlex = <Flex direction={"column"} alignItems="flex-start" gap="1vh" maxWidth={standardWidth}>
            <Text {...bulletTextProps}>In order to get paid after lessons take place, we need the details of a UK bank account to pay into</Text>
            <Text {...bulletTextProps}>We require you to provide:</Text>
            <Text {...bulletTextProps}>
                <li>Account Number</li>
                <li>Sort Code</li>
                <li>Full Name</li>
                <li>Address</li>
                <li>Bank Name</li>
            </Text>
            <Text {...bulletTextProps}>We will add the credit to your Next Door Tutor account 3 days after you complete a lesson</Text>
            <Text {...bulletTextProps}>A payment will then be initiated to your bank account the next Sunday for all the credit currently in your account</Text>
            <Text {...bulletTextProps}>Please allow 24 hours for this payment to settle (but it usually happens much quicker!)</Text>
            {button}
        </Flex>;

        setAddBankDetailsUI(<GuideStepTemplate
            {...props}
            stepComplete={bankDetailsAdded} 
            stepNumber={4} 
            link={registered && "/Tutor/ChangeBankDetails" || null}
            backLink={"/Tutor/StartGuide"}
            newTab={false}
            stepText={"Add your bank details"} 
            moreDetailsFlex={moreDetailsFlex} 
            detailsAfterComplete={true}
        />);
    }, [tutorModel, standardTextProps, stepsComplete]);

    //Step 5: Manage reoccurring lesson
    const [manageLessonUI, setManageLessonUI] = useState(null);
    useEffect(() => {
        let managedLesson = false;
        if (lessons != null && lessons.length > 0) {
            for (const lesson of lessons) {
                if (lesson.amendments != null && lesson.amendments.length > 0) {
                    for (const amendment of lesson.amendments) {
                        const requestedBy = amendment.requestedBy;
                        if (requestedBy != null && requestedBy.userID == user.username) {
                            managedLesson = true;
                        }
                    }
                }
            }
        }

        if (stepsComplete.managedLesson != managedLesson) {
            const newStepsComplete = {...stepsComplete};
            newStepsComplete.managedLesson = managedLesson;
            setStepsComplete(newStepsComplete);
        }

        const registered = stepsComplete.registered;
        let button = null;
        if (registered) {
            button = <Flex alignSelf={"center"}>
                <Link to={"/Tutor/MyLessons"} state={{back: "/Tutor/StartGuide"}}><Button >Make an amendment to a lesson</Button></Link>
            </Flex>
        }

        let moreDetailsFlex = <Flex direction={"column"} alignItems="flex-start" gap="1vh" maxWidth={standardWidth}>
            <Text {...bulletTextProps}>Once you have been assigned a lesson, make sure to keep on top of when the next lesson will be</Text>
            <Text {...bulletTextProps}>Without any changes, the lesson will be set to keep happening every week</Text>
            <Text {...bulletTextProps}>You can make the following amendments to a lesson (some need to be approved by the tutor):</Text>
            <Text {...bulletTextProps}>
                <li>Cancel a single lesson</li>
                <li>Request to change the weekly day/time of the lesson</li>
                <li>Change the lesson to online</li>
                {/* <li>Set the final date for the last lesson</li> */}
                <li>Cancel all future lessons</li>
            </Text>
            {button}
        </Flex>;

        setManageLessonUI(<GuideStepTemplate
            {...props}
            stepComplete={managedLesson} 
            stepNumber={5} 
            link={registered && "/Tutor/MyLessons" || null}
            backLink={"/Tutor/StartGuide"}
            stepText={"Manage your reoccurring lesson(s)"} 
            moreDetailsFlex={moreDetailsFlex} 
            detailsAfterComplete={true}
        />);
    }, [lessons, standardTextProps, stepsComplete]);

    //Progress bar
    const [progressBarUI, setProgressBarUI] = useState(null);
    useEffect(() => {
        const stepsCompleteArray = Object.values(stepsComplete);
        const totalSteps = stepsCompleteArray.length;
        let totalStepsComplete = 0;
        for (const step of stepsCompleteArray) {
            if (step == true) {
                totalStepsComplete = totalStepsComplete + 1;
            }
        }
        const competionPercent = Math.round((totalStepsComplete / totalSteps) * 100) + "%";

        setProgressBarUI(
            <Flex 
                marginTop={"5vh"}
                marginBottom={"5vh"}
                width={"80vw"} 
                height={"35px"} 
                backgroundColor={"#dddddd"}
                alignItems={"center"}
                borderRadius={"1vh"}
                position={"relative"}
                direction={"column"}
                justifyContent={"center"}
                alignSelf={"center"}
            >
                <Flex 
                    width={competionPercent} 
                    height={"100%"}
                    borderRadius={"1vh"}
                    position={"absolute"}
                    left={"0px"}
                    top={"0px"}
                    backgroundColor={"#00ff00"} 
                />
                <Flex 
                    width={"100%"} 
                    height={"100%"}
                    position={"absolute"}
                    left={"0px"}
                    top={"0px"}
                    style={{borderStyle: "solid", borderWidth: "2px", borderColor: "#000000"}}
                    borderRadius={"1vh"}
                />
                <Text {...standardTextProps} fontSize={"15px"} position={"absolute"} alignSelf={"center"}>{competionPercent} Complete</Text>
            </Flex>
        );

    }, [stepsComplete]);

    //Extra info
    const [extraInfoUI, setExtraInfoUI] = useState(null);
    useEffect(() => {
        const registered = stepsComplete.registered;
        let messageText = "in-app messaging service"
        let tutorDashboardText = "Tutor Dashboard";
        let updateAvailabilityText = "Update your weekly availability";
        let updateSubjectsText = "Update the subjects you want to teach";
        let tutorGuidanceText = "Check out our guidance and help for tutoring";
        if (registered) {
            messageText = <Link to={"/Messages"}>{messageText}</Link>
            tutorDashboardText = <Link to={"/Tutor"}>{tutorDashboardText}</Link>
            updateAvailabilityText = <Link to={"/Tutor/ChangeAvailability"} state={{back: "/Tutor/StartGuide"}}>{updateAvailabilityText}</Link>
            updateSubjectsText = <Link to={"/Tutor/ChangeSubjects"} state={{back: "/Tutor/StartGuide"}}>{updateSubjectsText}</Link>
            tutorGuidanceText = <Link to={"/Tutor/Help"} state={{back: "/Tutor/StartGuide"}}>{tutorGuidanceText}</Link>
        }
        setExtraInfoUI(
        <Flex direction={"column"} alignItems="flex-start" gap="1vh" maxWidth={standardWidth}>
            <Text {...standardTextProps}>Further steps:</Text>
            <ul>
                <li><Text {...bulletTextProps}>Try messaging your students using our {messageText}</Text></li>
                <li>
                    <Text {...bulletTextProps}>From the {tutorDashboardText}, you can:</Text>
                    <ul><Text {...bulletTextProps}>
                        <li>{updateAvailabilityText}</li>
                        <li>{updateSubjectsText}</li>
                        <li>{tutorGuidanceText}</li>
                    </Text></ul>
                </li>
            </ul>
        </Flex>);
    }, [standardTextProps, stepsComplete]);

    useEffect(() => {
        const stepsFlex = <Flex direction={"column"} alignItems="flex-start" gap="1vh">
            <Divider minWidth={standardWidth}/>
            {signUpUI}
            <Divider minWidth={standardWidth}/>
            {registerUI}
            <Divider minWidth={standardWidth}/>
            {waitForRequestUI}
            <Divider minWidth={standardWidth}/>
            {addBankDetailsUI}
            <Divider minWidth={standardWidth}/>
            {manageLessonUI}
            <Divider minWidth={standardWidth}/>
            {progressBarUI}
            <Divider minWidth={standardWidth}/>
            {extraInfoUI}
        </Flex>
        setStepsUI(stepsFlex);
    }, [signUpUI, registerUI, waitForRequestUI, manageLessonUI, progressBarUI]);

    const titleText = <Text {...titleTextProps}>Guide for Tutors</Text>;

    return (  
        <Flex direction={"column"} justifyContent="center" alignItems="center" gap="1vh">
            {titleText}
            {stepsUI}
        </Flex>
    );
}


//Return the correct page back up to the app and index files (with authenticator to force login)
export default TutorStartGuide;