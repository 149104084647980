import TextFieldTemplate from "./TextFieldTemplate";
import DropDownQuestionTemplate from "./DropDownQuestionTemplate";
import RadioButtonsTemplate from "./RadioButtonsTemplate";
import CheckBoxesTemplate from "./CheckBoxesTemplate";
import TimeFieldTemplate from "./TimeFieldTemplate";
import TimeRangeTemplate from "./TimeRangeTemplate";
import { WeekAvailabilityTemplate } from "./WeekAvailabilityTemplate";
import { Flex, Button, Divider, Text} from "@aws-amplify/ui-react";
import { useState, useRef, useEffect } from 'react';
import DayAvailabilityTemplate from "./DayAvailabilityTemplate";
import AutoCompleteTemplate from "./AutoCompleteTemplate";
import PopUpTemplate from "./PopUpTemplate";
import TutorSubjectsTemplate from "./TutorSubjectsTemplate";
import RadioThenDetailsTemplate from "./RadioThenDetailsTemplate";
import ExistingOrNewAddressTemplate from "./ExistingOrNewAddressTemplate";
import CreateLessonTimeTemplate from "./CreateLessonTimeTemplate";
import { compareObjects, sleep } from "../../App";
import SliderTemplate from "./SliderTemplate";
import StartDateTemplate from "./StartDateTemplate";
import StudentSubjectsTemplate from "./StudentSubjectsTemplate";
import DateTemplate from "./DateTemplate";
import TutorEducationTemplate from "./TutorEducationTemplate";
import QuestionTemplate from "../QuestionTemplate";


const FormTemplate = function(props) {
    try {
        const titleTextProps = props.titleTextProps;
        const standardTextProps = props.standardTextProps;

        const questionLabelProps = {...standardTextProps};
        questionLabelProps.fontSize = "20px";
        questionLabelProps.textAlign = "center";
        questionLabelProps.alignSelf = "center";
        questionLabelProps.maxWidth = "400px";
        const questionErrorProps = {...standardTextProps};
        questionErrorProps.fontSize = "14px";
        questionErrorProps.position = "absolute";
        questionErrorProps.color = "#610000";
        questionErrorProps.maxWidth = "400px";

        let okButtonFunction = props.okButtonFunction;
        if (okButtonFunction == "reset") {
            okButtonFunction = function () {
                resetForm();
            }
        }
        const subForm = props.subForm || false;
        let formID = null;
        if (subForm == true) {
            formID = props.formID;
        }
        const existingAnswers = props.existingAnswers || {};
        let showSubmitPopUp = props.submitPopPup || true;
        if (showSubmitPopUp != true && showSubmitPopUp != false) {
            showSubmitPopUp = true;
        }

        const softSubmitChecks = props.softSubmitChecks || [];

        const marginTop = props.marginTop || "5vh";

        //This function is used to create a form component quickly and easily

        //Get all the questions for this form instance from the questions property passed from the calling function
        const questions = props.questions;
        //Default the number of questions to 0
        let numQuestions = 0;
        if (questions != null) {
            //Set the number of questions
            numQuestions = questions.length;
        }
        
        //Set the state variable for all the question answers, this is returned to the callilng function on form submit
        const [questionAnswers] = useState(existingAnswers);
        const [questionErrors, setQuestionErrors] = useState({});
        const [validationErrors, setValidationErrors] = useState({});
        const [anyErrors, setAnyErrors] = useState(true);
        const [clearPopUp, setClearPopUp] = useState(false);
        const [submitPopUp, setSubmitPopUp] = useState(false);
        const [preSubmitPopUp, setPreSubmitPopUp] = useState({show:false, text:""});
        const [formSubmissionErrorPopUp, setFormSubmissionErrorPopUp] = useState({show:false, message:""});
        const [areYouSurePopUp, setAreYouSurePopUp] = useState(false);
        const [formSubmitted, setFormSubmitted] = useState(false);
        const [answersSubmitted, setAnswersSubmitted] = useState(0);
        const [forceUpdate, setForceUpdate] = useState(0);
        const [resetQuestionAnswers, setResetQuestionAnswers] = useState(0);
        const [currentAnswerIteration, setCurrentAnswerIteration] = useState(1);
        const [questionClearQuestion, setQuestionClearQuestion] = useState({});

        useEffect(() => {
            const clearQuestions = {};
            for (const question of questions) {
                clearQuestions[question.id] = [];
            }
            for (const question of questions) {
                if (Array.isArray(question.clearQuestions)) {
                    for (const clearQuestionID of question.clearQuestions) {
                        clearQuestions[clearQuestionID].push({
                            questionID: question.id,
                            iteration: 0
                        });
                    }
                }
            }
            setQuestionClearQuestion(clearQuestions);
        }, []);

        //Define the function to handle any input changes in the form
        const handleChange = function(questionID, input, questionHasError) {
            // console.log("Question ID: " + questionID);
            // console.log("Input: " + input);
            const newFieldAnswer = input;
            //Set the state of the question answers based on the new input
            questionAnswers[questionID] = newFieldAnswer;

            const newQuestionError = questionHasError;
            const newQuestionErrors = questionErrors;
            newQuestionErrors[questionID] = newQuestionError;
            setQuestionErrors(newQuestionErrors);
            let anyQuestionErrors = false;
            const allQuestionErrors = Object.values(questionErrors);
            for (const questionHasError of allQuestionErrors) {
                anyQuestionErrors = anyQuestionErrors || questionHasError;
            }
            setAnyErrors(anyQuestionErrors);
            setForceUpdate(forceUpdate + 1);

            if (subForm == true) {
                props.handleChange(formID, questionAnswers, anyQuestionErrors);
            }
            let questionObject = {};
            for (const question of questions) {
                if (question.id == questionID) {
                    questionObject = question;
                }
            }
            if (questionObject.onChange != null) {
                questionObject.onChange(questionID, input, questionHasError);
            }
            
            if (questionClearQuestion != null && questionObject.clearQuestions != null && Array.isArray(questionObject.clearQuestions)) {
                for (const ID of questionObject.clearQuestions) {
                    questionAnswers[ID] = null;
                    const newQuestionClearQuestion = {...questionClearQuestion};
                    let questionClearObjects = [];
                    if (Array.isArray(questionClearQuestion[ID])) {
                        questionClearObjects = [...questionClearQuestion[ID]];
                    }
                    for (let clearObjectIndex = 0; clearObjectIndex < questionClearObjects.length; clearObjectIndex = clearObjectIndex + 1) {
                        const clearObject = questionClearObjects[clearObjectIndex];
                        if (clearObject.questionID == questionID) {
                            const newIteration = clearObject.iteration + 1;
                            const newObject = {
                                questionID: questionID,
                                iteration: newIteration
                            };
                            questionClearObjects.splice(clearObjectIndex, 1, newObject);
                        }
                    }
                    newQuestionClearQuestion[ID] = questionClearObjects;
                    setQuestionClearQuestion(newQuestionClearQuestion);
                }
            }

            if (props.returnAnswersEarly != null) {
                const earlyReturnQuestionIDs = props.returnAnswersEarly.questions;
                for (const earlyReturnQuestionID of earlyReturnQuestionIDs) {
                    if (earlyReturnQuestionID == questionID) {
                        props.returnAnswersEarly.handleChange(questionID, input, questionHasError);
                    }
                }
            }
        }
        
        if (numQuestions === 0) {
            return "form with no questions";
        }

        const resetAllAnswers = function() {
            setResetQuestionAnswers(resetQuestionAnswers + 1);
        }

        const preSubmitPopUpStages = async function() {
            setPreSubmitPopUp({
                show: true,
                text: "Verifying answers..."
            });
            const randomWait1 = Math.floor(Math.random() * 200) + 200;
            await sleep(randomWait1);
            setPreSubmitPopUp({
                show: true,
                text: "Submitting answers..."
            });
            const randomWait2 = Math.floor(Math.random() * 400) + 300;
            await sleep(randomWait2);
            setPreSubmitPopUp({
                show: true,
                text: "Waiting for server..."
            });
        }
        
        const submit = async function(overrides = {}) {
            try {
                console.log(questionAnswers);
                for (const submitCheck of softSubmitChecks) {
                    if (questionAnswers[submitCheck.questionID] != null) {
                        if (submitCheck.possibleAnswers != null) {
                            const expectedQuestionAnswers = submitCheck.possibleAnswers;
                            const givenQuestionAnswer = questionAnswers[submitCheck.questionID];
                            let foundAnswer = false;
                            if (givenQuestionAnswer != undefined) {
                                for (const possibleAnswer of expectedQuestionAnswers) {
                                    if (typeof(possibleAnswer) != "object") {
                                        if (possibleAnswer == givenQuestionAnswer) {
                                            foundAnswer = true;
                                        }
                                    }
                                    else if (typeof(possibleAnswer) == "object") {
                                        if (compareObjects(possibleAnswer, givenQuestionAnswer)) {
                                            foundAnswer = true;
                                        }
                                    }
                                }
                            }
                            else {
                                foundAnswer = true;
                            }
                            if (foundAnswer == false && overrides[submitCheck.questionID] != true) {
                                const popUpObject = {
                                    text: submitCheck.message,
                                    yesButtonProps: {...overrides}
                                }
                                popUpObject.yesButtonProps[submitCheck.questionID] = true
                                setAreYouSurePopUp(popUpObject);
                                return;
                            }
                        }
                        else if (submitCheck.length != null) {
                            const expectedLength = submitCheck.length;
                            let givenLength = questionAnswers[submitCheck.questionID].length;
                            if (submitCheck.answerObjectChild != null) {
                                givenLength = questionAnswers[submitCheck.questionID][submitCheck.answerObjectChild].length;
                            }
                            if (expectedLength != givenLength && overrides[submitCheck.questionID + "length"] != true) {
                                const popUpObject = {
                                    text: submitCheck.message,
                                    yesButtonProps: {...overrides}
                                }
                                popUpObject.yesButtonProps[submitCheck.questionID + "length"] = true
                                setAreYouSurePopUp(popUpObject);
                                return;
                            }
                        }
                    }
                }
                if (showSubmitPopUp) {
                    setAnswersSubmitted(currentAnswerIteration);
                    preSubmitPopUpStages();
                    setFormSubmitted(true);
                }
                //This is called when the submit button is pressed
                const validationValues = [];
                for (const question of questionComponents) {
                    const questionProps = question.props;
                    const questionID = questionProps.id;
                    const currentAnswer = questionAnswers[questionID];
                    const currentValidations = questionProps.validations;

                    let answerToCheck = currentAnswer;
                    if (typeof(currentAnswer) == "object") {
                        answerToCheck = JSON.stringify(currentAnswer);
                    }
                    validationValues.push({
                        fieldValue: answerToCheck,
                        validationTypes: currentValidations,
                        questionID: questionID
                    });
                    //The backend validates all the answers before submitting
                    
                }
                // const fieldValidations = await backendFieldValidations(validationValues);
                // if (fieldValidations.length > 0) {
                //     console.log("Invalid form submission, please try again.");
                //     console.log("API return validation:");
                //     console.log(fieldValidations);
                //     const errors = {};
                //     console.log("Questions with error:");
                //     for (const error of fieldValidations) {
                //         console.log(error.questionID);
                //         errors[error.questionID] = error.errorMessage;
                //     }
                //     setValidationErrors(errors);
                //     throw "Invalid answer given";
                // }
                
                
                //if (fieldValidations.length == 0) {
                if (true) {
                    setValidationErrors({});
                    console.log("Valid Submission");
                    //Once it's validated the answers, it calls the parent submit function so that the main page with the form can handle the answers in any way
                    try {
                        await props.submitAnswers(questionAnswers);
                        setCurrentAnswerIteration(currentAnswerIteration + 1);
                        if (showSubmitPopUp) {
                            setSubmitPopUp(true);
                        }
                        setPreSubmitPopUp({show: false, text: ""});
                    }
                    catch (error) {
                        console.log(error);
                        props.APIFunctions.logFormError(error, questionAnswers);
                        let errorMessage = "Unknown error";
                        if (error.questionErrors != null && Array.isArray(error.questionErrors)) {
                            const questionErrors = error.questionErrors;
                            if (questionErrors.length == 1) {
                                errorMessage = "";
                            }
                            if (questionErrors.length > 1) {
                                errorMessage = "Question errors: ";
                            }
                            const errors = {};
                            for (let errorIndex = 0; errorIndex < questionErrors.length; errorIndex = errorIndex + 1) {
                                const questionError = questionErrors[errorIndex];
                                errorMessage = errorMessage + questionError.errorMessage;
                                if (errorIndex < questionErrors.length - 1) {
                                    errorMessage = errorMessage + ", ";
                                }
                                errors[questionError.questionID] = questionError.errorMessage;
                                if (questionError.useErrorMessage == true) {
                                    errorMessage = questionError.errorMessage;
                                }
                            }
                            setValidationErrors(errors);
                        }
                        else if (error.message != null) {
                            errorMessage = error.message;
                        }
                        else {
                            if (typeof(error) == "string") {
                                errorMessage = error;
                            }
                        }
                        setCurrentAnswerIteration(currentAnswerIteration + 1);
                        setFormSubmissionErrorPopUp({show:true, message: errorMessage});
                        setPreSubmitPopUp({show: false, text: ""});
                    }
                    
                }
            }
            catch (error) {
                console.log(error);
                setCurrentAnswerIteration(currentAnswerIteration + 1);
                setFormSubmissionErrorPopUp({show:true, message:error});
                setPreSubmitPopUp({show: false, text: ""});
            }
        }

        //Define the static question properties that are the same for all questions
        const staticProps = {};
        staticProps.handleChange = handleChange;
        staticProps.answersSuccessfullySubmitted = formSubmitted;
        staticProps.validationErrorColour = "#ff0000";

        staticProps.questionErrorProps = questionErrorProps;
        staticProps.questionLabelProps = questionLabelProps;

        //Initialise variables used for creating questions
        let questionComponents = [];
        let renderComponents = [];
        let questionIDs = [];
        //Iteratively create all question components
        for (let questionIndex = 0; questionIndex < numQuestions; questionIndex = questionIndex + 1) {
            const questionProps = {}
            //Get the corresponding question passed in the questions prop
            const question = questions[questionIndex];
            //Check what type of question it is
            const questionType = question.type;

            //Set all the question properties that are unique to this question
            let nameKey = question.key;
            if (nameKey == null) {
                nameKey = question.id
            }
            questionProps.key = nameKey + resetQuestionAnswers;
            questionProps.passedKey = nameKey + resetQuestionAnswers;
            questionProps.id = question.id;
            questionProps.label = question.label;
            questionProps.options = question.options;
            questionProps.required = question.required;
            questionProps.placeHolder = question.placeholder;
            questionProps.defaultValue = question.defaultValue;
            questionProps.required = question.required;
            questionProps.validations = question.validations;
            questionProps.validationError = validationErrors[question.id];
            questionProps.clearQuestions = question.clearQuestions;
            questionProps.clearParent = questionClearQuestion[question.id];
            questionProps.optionLinks = question.optionLinks;
            questionProps.warning = question.warning;
            questionProps.showErrorImmediately = question.showErrorImmediately;
            
            if (questionProps.validations == null) {
                questionProps.validations = [];
            }
            questionProps.description = question.description;
            questionProps.extraProps = {...question.extraProps};

            const onlyShow = question.onlyShow
            if (onlyShow != null) {
                const onlyShowDependentQuestionID = onlyShow.id;
                let onlyShowDependentAnswers = onlyShow.answers;
                try {
                    if (typeof(onlyShowDependentAnswers) == "string") {
                        throw "String"
                    }
                    const test = onlyShowDependentAnswers[0];
                }
                catch {
                    onlyShowDependentAnswers = [onlyShowDependentAnswers];
                }
                let shouldShow = false;
                for (const answerOption of onlyShowDependentAnswers) {
                    if (answerOption == questionAnswers[onlyShowDependentQuestionID]) {
                        shouldShow = true;
                        questionProps[onlyShow.id] = answerOption;
                    }
                }
                if (shouldShow == false) {
                    if ((questionAnswers[question.id] != null && questionAnswers[question.id] != false) || (questionErrors[question.id] != null && questionErrors[question.id] != false)) {
                        handleChange(question.id, false, false);
                    }
                    continue;
                }
            }
            
            if (question.disabled == true) {
                questionProps.answersSuccessfullySubmitted = true;
            }
            
            //Add a required validation if required is true
            if (question.required === true) {
                //First check the 'required' validation has not already been added
                let requiredValidationExists = false;
                for (let validation of questionProps.validations) {
                    if (validation.type === "Required") {
                        requiredValidationExists = true;
                    }
                }
                if (requiredValidationExists === false) {
                    //Use unshift to add required validation to start of array
                    //This means it will be checked for being blank first
                    questionProps.validations.unshift ({
                        "type":"Required",
                        "questionType": questionType
                    });
                }
            }
            if (question.gradeAt != null) {
                questionProps.gradeAt = question.gradeAt;
            }
            
            //Generate the question prop based on the type of question
            //Pass in both the static and dynamic question properties
            let questionComponent = null;
            if (questionType === "text") {
                questionComponent = <TextFieldTemplate {...staticProps} {...questionProps} key={questionProps.key}/>
                // <QuestionTemplate 
                //     fieldType={"text"}
                //     //alignItems={"center"}
                //     {...staticProps} 
                //     {...questionProps}
                //     key={questionProps.key}
                // />
            }
            else if (questionType === "dropdown") {
                questionComponent = <DropDownQuestionTemplate {...staticProps} {...questionProps} key={questionProps.key}/>
            }
            else if (questionType === "radio") {
                questionComponent = <RadioButtonsTemplate {...staticProps} {...questionProps} key={questionProps.key}/>
            }
            else if (questionType === "checkbox") {
                questionComponent = <CheckBoxesTemplate {...staticProps} {...questionProps} key={questionProps.key} />
            }
            else if (questionType === "slider") {
                questionProps.minValue = question.minValue;
                questionProps.maxValue = question.maxValue;
                questionProps.minValueText = question.minValueText;
                questionProps.maxValueText = question.maxValueText;
                questionProps.step = question.step;
                questionProps.textValues = question.textValues;
                questionComponent = <SliderTemplate {...staticProps} {...questionProps} key={questionProps.key}/>
            }
            else if (questionType === "autocomplete") {
                questionComponent = <AutoCompleteTemplate {...staticProps} {...questionProps} key={questionProps.key} />
            }
            else if (questionType === "time") {
                questionComponent = <TimeFieldTemplate {...staticProps} {...questionProps} key={questionProps.key}/>
            }
            else if (questionType === "timerange") {
                questionComponent = <TimeRangeTemplate {...staticProps} {...questionProps} key={questionProps.key}/>
            }
            else if (questionType === "dayavailability") {
                questionComponent = <DayAvailabilityTemplate {...staticProps} {...questionProps} key={questionProps.key}/>
            }
            else if (questionType === "weekavailability") {
                questionComponent = <WeekAvailabilityTemplate {...staticProps} {...questionProps} key={questionProps.key}/>
            }
            else if (questionType === "tutorsubjects") {
                questionComponent = <TutorSubjectsTemplate {...staticProps} {...questionProps} key={questionProps.key}/>
            }
            else if (questionType === "tutoreducation") {
                questionComponent = <TutorEducationTemplate {...staticProps} {...questionProps} key={questionProps.key}/>
            }
            else if (questionType === "studentsubject") {
                questionComponent = <StudentSubjectsTemplate {...staticProps} {...questionProps} key={questionProps.key}/>
            }
            else if (questionType === "radiothendetails") {
                questionComponent = <RadioThenDetailsTemplate {...staticProps} {...questionProps} key={questionProps.key}/>
            }
            else if (questionType === "existingornewaddress") {
                questionComponent = <ExistingOrNewAddressTemplate {...staticProps} {...questionProps} key={questionProps.key}/>
            }
            else if (questionType === "date") {
                questionComponent = <DateTemplate {...staticProps} {...questionProps} key={questionProps.key}/>
            }
            else if (questionType === "startdate") {
                questionComponent = <StartDateTemplate {...staticProps} {...questionProps} key={questionProps.key}/>
            }
            else if (questionType === "createlessontime") {
                questionComponent = <CreateLessonTimeTemplate {...staticProps} {...questionProps} key={questionProps.key}/>
            }
            else if (questionType === "subform") {
                questionComponent = <FormTemplate {...staticProps} {...questionProps} subForm={true} handleChange={handleChange} formID={question.id} questions={question.questions} key={questionProps.key}/>
            }

            if (questionComponent != null) {
                const dividerComponent = <Divider orientation="horizontal" key={"divider" + questionIndex} />
                //Add the generated question component to the array of question components to be returned and rendered
                questionComponents.push(questionComponent);
                renderComponents.push(questionComponent, dividerComponent)
                //Add the question ID to an array of all the question IDs (This is used for checking states of questions)
                questionIDs.push(question.id);
            }
        }
        for (const questionID of Object.keys(questionErrors)) {
            if (questionIDs.includes(questionID) == false) {
                setQuestionErrors((prevState) => {
                    delete prevState[questionID];
                    return prevState;
                });
            }
        }

        let submitButtonDisabled = false;
        if (anyErrors !== false || formSubmitted || props.submitDisabled == true) {
            submitButtonDisabled = true;
        }

        const buttonHeight = "5vh";

        let submitButton = <Button
            children="Submit"
            type="button"
            variation="primary"
            isDisabled={submitButtonDisabled}
            fontSize={"2.2vh"}
            maxWidth={"16vh"}
            onClick={() => {
                submit();
            }}
        />
        if (subForm == true) {
            submitButton = null;
        }

        let clearAnswersText = "Reset Answers";
        if (questions.length == 1) {
            clearAnswersText = "Reset Answer";
        }
        let clearButton = <Button
            children={clearAnswersText}
            fontSize={"2.2vh"}
            type="button"
            variation="secondary"
            disabled={formSubmitted}
            maxWidth={"24vh"}
            onClick={(clickEvent) => {
                const controlClick = clickEvent.ctrlKey
                if (controlClick != true) {
                    setClearPopUp(true);
                }
                else {
                    resetAllAnswers();
                }
                
            }}
        />
        if (subForm == true || props.noClearButton == true) {
            clearButton = null;
        }

        const clearPopUpText = "Are you sure you want to reset your form answers?"
        
        const preSubmitPopUpText = props.preSubmitPopUpText || preSubmitPopUp.text;
        const submitPopUpText = props.submitPopUpText || "Successfully submitted answers";

        const resetForm = function() {
            resetAllAnswers();
            setFormSubmitted(false);
            setPreSubmitPopUp({
                show: false,
                text: ""
            });
            setSubmitPopUp(false);
            setFormSubmissionErrorPopUp({
                show: false,
                message: ""
            });
        }
        
        const submitOK = function() {
            okButtonFunction(resetForm, formSubmitted);
        };

        const invalidOKFunction = function () {
            setFormSubmitted(false);
        }

        return (
            <Flex 
                direction={"column"} 
                alignItems={"center"} 
                width={"100%"} 
                maxWidth={props.maxWidth || "700px"} 
                alignSelf={props.alignSelf}
            >
                <Flex 
                    direction={"column"} 
                    gap={"2vh"} 
                    marginTop={marginTop}
                    width={"100%"} 
                >       
                    {renderComponents}
                    <Flex justifyContent={"center"} gap={"5vw"} marginBottom={"5vh"}>
                        {clearButton}
                        {submitButton}
                    </Flex>
                </Flex>
                {clearPopUp && <PopUpTemplate text={clearPopUpText} setPopUpVisibility={setClearPopUp} yesButtonFunction={resetAllAnswers} noButtonFunction={function(){}} showXButton={true} />}
                {(preSubmitPopUp.show && (answersSubmitted == currentAnswerIteration)) && <PopUpTemplate key={"preSubmit" + preSubmitPopUp.text} text={preSubmitPopUpText} setPopUpVisibility={setPreSubmitPopUp} showXButton={false} />}
                {submitPopUp && <PopUpTemplate text={submitPopUpText} setPopUpVisibility={setSubmitPopUp} okButtonFunction={submitOK} showXButton={false} />}
                {formSubmissionErrorPopUp.show && <PopUpTemplate text={formSubmissionErrorPopUp.message} setPopUpVisibility={setFormSubmissionErrorPopUp} okButtonFunction={invalidOKFunction} showXButton={false} reloadOnDismiss={true} />}
                {(areYouSurePopUp != false) && <PopUpTemplate text={areYouSurePopUp.text} yesButtonFunction={submit} yesButtonProps={areYouSurePopUp.yesButtonProps} noButtonFunction={function(){}} setPopUpVisibility={setAreYouSurePopUp} showXButton={true} />}
            </Flex>
        );
    }
    catch (error) {
      throw "FormTemplate Error: " + error;
    }
}

// async function backendFieldValidations(validations) {
//     for (const validation of validations) {
//         validation.end = "backend";
//     }
//     const getParameters = {
//         body: {
//             "validations": JSON.stringify(validations)
//         }
//     };

//     const formValidationAPI = "formValidation";
//     const fieldValidationAPIPath = "/validateFormField";

//     try {
//         const validationReturn = await API.put(formValidationAPI, fieldValidationAPIPath, getParameters);
//         return validationReturn.questionErrors;
//     }
//     catch (error) {
//         console.log(error);
//         try {
//             console.log(error.response.data);
//         } catch {}
//         throw "Error checking field validation, access to API denied: " + error;
//     }
// }

export default FormTemplate;