import { Button, Flex, Icon, Text } from '@aws-amplify/ui-react';
import {React, useState } from 'react';
import { DropDownQuestionTemplate } from '../../custom-ui-components';
import AutoCompleteTemplate from '../../custom-ui-components/form-components/AutoCompleteTemplate';
import CheckBoxesTemplate from '../../custom-ui-components/form-components/CheckBoxesTemplate';
import TimeRangeTemplate from '../../custom-ui-components/form-components/TimeRangeTemplate';
import LessonTemplate from '../../custom-ui-components/LessonTemplate';
import { LoadingSpinner, LoadingSpinnerPage } from '../LoadingPage';
import {MdCached} from 'react-icons/md';
import { ErrorLoadingPage } from '../ErrorLoadingPage';

const StudentLessons = function(props) {
    const width = props.width;
    const lessons = props.studentLessons;
    const titleTextProps = {...props.titleTextProps};
    const standardTextProps = {...props.standardTextProps};
    //Get the current user to pass to subsequent functions
    const user = props.user;
    const amendLessons = props.amendLessons || false;

    const noFilters = {Day:false, Time:false, Subject:false, Tutor:false, Student:false}
    const [lessonFilters, setLessonFilters] = useState(noFilters);
    const [showFilters, setShowFilters] = useState(false);
    const showNoDaysValue = {Monday: false, Tuesday: false, Wednesday: false, Thursday: false, Friday: false, Saturday: false, Sunday: false};
    const [shownDays, setShownDays] = useState(showNoDaysValue);
    const [shownSubject, setShownSubject] = useState("");
    const [shownTutor, setShownTutor] = useState(null);
    //const showNoTimesValue = {Monday: false, Tuesday: false, Wednesday: false, Thursday: false, Friday: false, Saturday: false, Sunday: false};
    const [shownTimeRange, setShownTimeRange] = useState({startTime:"00:00", endTime: "23:59"});
    const [numRefreshes, setNumRefreshes] = useState(0);

    if (lessons == null) {
        return <LoadingSpinnerPage {...props} />
    }
    else if (lessons == "loading") {
        return <LoadingSpinnerPage {...props} />
    }
    else if (lessons == "error") {
        return <ErrorLoadingPage {...props}/>
    }

    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const lessonUIs = [];
    for (let dayIndex = 0; dayIndex < days.length; dayIndex = dayIndex + 1) {
        const currentDay = days[dayIndex];
        let shownLessons = [];
        for (const lesson of lessons) {
            if (lesson.lessonDay == currentDay) {
                let showLesson = true;
                if (!(shownSubject == lesson.subject || shownSubject == "" || lessonFilters.Subject == false)) {
                    showLesson = showLesson && false;
                }
                else if (!(shownTutor == null || shownTutor.id == lesson.tutorID || lessonFilters.Tutor == false)) {
                    showLesson = showLesson && false;
                }
                else if (lessonFilters.Time == true) {
                    const startTime = lesson.startTime;
                    const startHour = parseInt(startTime.split(":")[0]);
                    const startMinute = parseInt(startTime.split(":")[1]);
                    let startInt = (startHour * 60) + startMinute;
                    const endTime = lesson.endTime;
                    const endHour = parseInt(endTime.split(":")[0]);
                    const endMinute = parseInt(endTime.split(":")[1]);
                    let endInt = (endHour * 60) + endMinute;
                    if (!(shownTimeRange == null || (shownTimeRange.startInt <= startInt && shownTimeRange.endInt >= startInt))) {
                        showLesson = showLesson && false
                    }
                }
                if (showLesson == true) {
                    shownLessons.push(lesson);
                }
            }
        }

        const dayLessonUIs = [];
        shownLessons = shownLessons.sort((a, b) => {
            const aStart = a.startTime;
            const aStartHour = parseInt(aStart.split(":")[0]);
            const aStartMinute = parseInt(aStart.split(":")[1]);
            const aStartInt = (aStartHour * 60) + aStartMinute;
            const bStart = b.startTime;
            const bStartHour = parseInt(bStart.split(":")[0]);
            const bStartMinute = parseInt(bStart.split(":")[1]);
            const bStartInt = (bStartHour * 60) + bStartMinute;
            return aStartInt - bStartInt;
        });
        
        for (const lesson of shownLessons) {
            const UILesson = <LessonTemplate {...props} key={lesson.id} lessonID={lesson.id}/>
            dayLessonUIs.push(UILesson);
        }
        
        if (shownDays[currentDay] == true || lessonFilters.Day == false || lessonFilters.Day == undefined) {
            lessonUIs.push(...dayLessonUIs);
        }
    }

    let titleText = <Text {...titleTextProps} >
        Your weekly lessons
    </Text>
    if (amendLessons == true) {
        titleText = <Text {...titleTextProps} >
            Choose lesson to amend/cancel
        </Text>
    }

    const lessonsFoundText = <Text {...standardTextProps} fontSize={"18px"}>
        Lessons found: {lessonUIs.length}
    </Text>

    

    let showFiltersText = "Show Filters";
        if (showFilters == true) {
            showFiltersText = "Hide Filters"
        }
    const showFiltersButton = <Button
        key={"ShowFiltersButton"}
        onClick={() => {
            const previousLessonFilters = {...lessonFilters};
            if (showFilters == true) {
                setShowFilters(false);
            }
            else {
                setShowFilters(true);
            }
        }}
    >
        {showFiltersText}
    </Button>

    let refreshButtonDisabled = false;
    if (numRefreshes >= 10) {
        refreshButtonDisabled = true
    }
    const refreshButton = <Button
        key={"RefreshButton"}
        onClick={() => {
            //Prevent API spam by limiting the user to 10 refreshes (Resets when the page is reloaded)
            if (!refreshButtonDisabled) {
                props.refreshLessons();
                setNumRefreshes(numRefreshes + 1);
            }
        }}
        isDisabled={refreshButtonDisabled}
    >
        <Icon
            key={"refreshIcon"}
            width={"25px"}
            height={"25px"}
            as={MdCached}
        />
    </Button>

    const getFilterFlex = function () {
        const mainFilterTextProps = {...standardTextProps}
        const filterTextProps = {...mainFilterTextProps}
        mainFilterTextProps.fontSize = "20px"
        filterTextProps.fontSize = "18px";
        
        const checkboxTextProps = {...standardTextProps}
        checkboxTextProps.fontSize = "16px"

        let filterDirection = "row";
        if (width < 900) {
            filterDirection = "column"
        }

        const filterMenu = <CheckBoxesTemplate
            questionLabelProps = {mainFilterTextProps}
            checkboxTextProps = {checkboxTextProps}
            id = "FilterMenu"
            label = "Filter by:"
            options = {["Day", "Time", "Subject", "Tutor"]}
            defaultValue = {lessonFilters}
            handleChange = {(questionID, selectedAnswers, errors) => {
                const newFilters = {...selectedAnswers}
                setLessonFilters(newFilters);
            }}
            direction = {filterDirection}
        />

        const dayFilters = <CheckBoxesTemplate
            questionLabelProps = {filterTextProps}
            checkboxTextProps = {checkboxTextProps}
            id = "DayFilter"
            label = "Show days:"
            options = {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]}
            defaultValue = {shownDays}
            handleChange = {(questionID, selectedAnswers, errors) => {
                const newDayFilters = {...selectedAnswers}
                setShownDays(newDayFilters);
            }}
            direction = {filterDirection}
        />
        
        const subjectOptions = []
        for (const lesson of lessons) {
            const lessonSubject = lesson.subject;
            let subjectAlreadyExists = false;
            for (const existingSubject of subjectOptions) {
                if (existingSubject == lessonSubject) {
                    subjectAlreadyExists = true;
                    break;
                }
            }
            if (subjectAlreadyExists == false) {
                subjectOptions.push(lessonSubject);
            }
        }
        const subjectFilter = <DropDownQuestionTemplate
            questionLabelProps = {filterTextProps}
            id = "SubjectFilter"
            label = "Subject:"
            options = {subjectOptions}
            defaultValue = {shownSubject}
            placeHolder = "Choose a subject"
            handleChange = {(questionID, selectedAnswer, errors) => {
                setShownSubject(selectedAnswer);
            }}
            direction = {filterDirection}
        />

        const tutorNames = [];
        for (const lesson of lessons) {
            const tutorID = lesson.tutorID;
            const tutorFirstNames = lesson.tutorFirstNames;
            const tutorLastName = lesson.tutorLastName;
            tutorNames.push({id:tutorID, label: tutorFirstNames + ", " + tutorLastName});
        }
        const tutorFilter = <AutoCompleteTemplate
            questionLabelProps = {filterTextProps}
            id = "TutorFilter"
            label = "Tutor:"
            options = {tutorNames}
            defaultValue = {shownTutor}
            questionErrorProps = {{position:"absolute", style:{display:"none"}}}
            handleChange = {(questionID, selectedAnswer, errors) => {
                if (selectedAnswer === undefined) {
                    selectedAnswer = false
                }
                setShownTutor(selectedAnswer);
            }}
            direction = {filterDirection}
        />

        const timeFilter = <TimeRangeTemplate
            questionLabelProps = {filterTextProps}
            id = "TimeFilter"
            label = "Start time range:"
            label1={""}
            label2={""}
            defaultValue = {{startTime:shownTimeRange.startTime, endTime:shownTimeRange.endTime}}
            questionErrorProps = {{position:"absolute", style:{display:"none"}}}
            handleChange = {(questionID, selectedAnswer, errors) => {
                const startTime = selectedAnswer.startTime;
                const startHour = parseInt(startTime.split(":")[0]);
                const startMinute = parseInt(startTime.split(":")[1]);
                let startInt = (startHour * 60) + startMinute;
                const endTime = selectedAnswer.endTime;
                const endHour = parseInt(endTime.split(":")[0]);
                const endMinute = parseInt(endTime.split(":")[1]);
                let endInt = (endHour * 60) + endMinute;
                
                if (!(startInt >= 0)) {
                    startInt = null;
                }
                if (!(endInt >= 0)) {
                    endInt = null;
                }
                setShownTimeRange({startTime:startTime, endTime:endTime, startInt:startInt, endInt:endInt});
            }}
            direction = {filterDirection}
        />

        const filterFlex = <Flex alignItems={"center"} gap={"1vh"} direction={"column"}>
            {filterMenu}
            {lessonFilters.Day && dayFilters}
            {lessonFilters.Subject && subjectFilter}
            {lessonFilters.Tutor && tutorFilter}
            {lessonFilters.Time && timeFilter}
        </Flex>

        return filterFlex;
    }

    const filterFlex = getFilterFlex();
    
    return (
        <div className='StudentLessonsPage'>
            <Flex alignItems={"center"} gap={"1vh"} direction={"column"} marginBottom={"4vh"}>
                {titleText}
                <Flex gap={"2vh"} alignItems={"center"} direction={"column"}>
                    {showFiltersButton}
                    {showFilters && filterFlex}
                    <Flex direction={"column"} alignItems={"center"}>
                        <Flex alignItems={"center"}>
                            {lessonsFoundText}
                            {refreshButton}
                        </Flex>
                        <Flex direction={"column"} alignItems={"center"} gap={"5vh"}>
                            {lessonUIs}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </div>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default StudentLessons;