import React, { useEffect, useState } from "react";
import {NavigationBarTemplate} from '../../custom-ui-components';
import { Text } from "@aws-amplify/ui-react";

const Navbar = function(props) {
  const [buttonList, setButtonList] = useState([]);

  useEffect(() => {
    const newButtonList = [];
    const homeButton = {text: "Home", link: "/"};
    if (props.pageType == "home") {
      homeButton.currentPage = true;
    }
    newButtonList.push(homeButton);

    let messagesButton = false;
    if (props.userIsStudent) {
      let totalStudentNotifications = 0;
      const studentLessons = props.studentLessons;
      let numRequestedAmendments = 0;
      if (studentLessons != null) {
        for (const lesson of studentLessons) {
          const lessonAmendments = lesson.amendments || [];
          for (const amendment of lessonAmendments) {
            if (amendment.confirmed != true && amendment.rejectedBy == null) {
                const requestedBy = amendment.requestedBy || {};
              if (requestedBy.userType != "student") {
                numRequestedAmendments = numRequestedAmendments + 1;
              }
            }
          }
        }
        if (studentLessons.length > 0) {
          messagesButton = true;
        }
      }
      totalStudentNotifications = totalStudentNotifications + numRequestedAmendments;
      const studentButton = {text: "Student Dashboard", link: "/Student", notifications: totalStudentNotifications};
      if (props.pageType == "student") {
        studentButton.currentPage = true;
      }
      newButtonList.push(studentButton);
    }
    if (props.userIsParent) {
      let totalParentNotifications = 0;
      const parentLessons = props.parentLessons;
      let numRequestedAmendments = 0;
      if (parentLessons != null) {
        for (const lesson of parentLessons) {
          const lessonAmendments = lesson.amendments || [];
          for (const amendment of lessonAmendments) {
            if (amendment.confirmed != true && amendment.rejectedBy == null) {
                const requestedBy = amendment.requestedBy || {};
              if (requestedBy.userType != "parent") {
                numRequestedAmendments = numRequestedAmendments + 1;
              }
            }
          }
        }
        if (parentLessons.length > 0) {
          messagesButton = true;
        }
      }
      totalParentNotifications = totalParentNotifications + numRequestedAmendments;
      const parentButton = {text: "Parent Dashboard", link: "/Parent", notifications: totalParentNotifications};
      if (props.pageType == "parent") {
        parentButton.currentPage = true;
      }
      newButtonList.push(parentButton);
    }
    if (props.userIsTutor) {
      let totalTutorNotifications = 0;
      const tutorRequests = props.tutorTutorRequests;
      let numTutorRequests = 0;
      if (tutorRequests != null) {
        numTutorRequests = tutorRequests.length;
      }
      totalTutorNotifications = totalTutorNotifications + numTutorRequests;

      const tutorLessons = props.tutorLessons;
      let numRequestedAmendments = 0;
      if (tutorLessons != null) {
        for (const lesson of tutorLessons) {
          const lessonAmendments = lesson.amendments || [];
          for (const amendment of lessonAmendments) {
            if (amendment.confirmed != true && amendment.rejectedBy == null) {
                const requestedBy = amendment.requestedBy || {};
              if (requestedBy.userType != "tutor") {
                numRequestedAmendments = numRequestedAmendments + 1;
              }
            }
          }
        }
        if (tutorLessons.length > 0) {
          messagesButton = true;
        }
      }
      totalTutorNotifications = totalTutorNotifications + numRequestedAmendments;

      if (props.tutorModel != null && tutorLessons != null) {
        const bankDetails = props.tutorModel.bankDetails || {};
        let missingBankDetails = false;
        if (bankDetails.accountNumber == null) {
          missingBankDetails = true;
        }
        else if (bankDetails.sortCode == null) {
          missingBankDetails = true;
        }
        else if (bankDetails.bankName == null) {
          missingBankDetails = true;
        }
        else if (bankDetails.name == null) {
          missingBankDetails = true;
        }
        else if (bankDetails.address == null) {
          missingBankDetails = true;
        }

        if (tutorLessons.length > 0 && missingBankDetails) {
          totalTutorNotifications = totalTutorNotifications + 1;
        }
      }

      const tutorButton = {text: "Tutor Dashboard", link: "/Tutor", notifications: totalTutorNotifications};
      if (props.pageType == "tutor") {
        tutorButton.currentPage = true;
      }
      newButtonList.push(tutorButton);
    }
    if (props.userIsAdmin) {
      messagesButton = true;
      let totalAdminNotifications = 0;
      const validTutorEmails = props.validTutorEmails;
      let numUnNotifiedEmails = 0;
      if (validTutorEmails != null) {
        for (const validEmail of validTutorEmails) {
          if (validEmail.notified == false) {
            numUnNotifiedEmails = numUnNotifiedEmails + 1;
          }
        }
      }
      totalAdminNotifications = totalAdminNotifications + numUnNotifiedEmails;
      const lessonRequests = props.adminLessonRequests;
      let numLessonRequest = 0;
      if (lessonRequests != null) {
        for (const lessonRequest of lessonRequests) {
          if (lessonRequest.status == 0 || lessonRequest.status == 2) {
            numLessonRequest = numLessonRequest + 1
          }
        }
      }
      totalAdminNotifications = totalAdminNotifications + numLessonRequest;

      const adminTransactions = props.adminTransactions;
      let numRefundRequests = 0;
      if (adminTransactions != null && adminTransactions != "loading" && adminTransactions != "error") {
          for (const transaction of adminTransactions) {
              if (transaction.refundRequested == true) {
                  numRefundRequests = numRefundRequests + 1;
              }
          }
      }
      totalAdminNotifications = totalAdminNotifications + numRefundRequests;
      
      const adminButton = {text: "Admin Dashboard", link: "/Admin", notifications: totalAdminNotifications};
      if (props.pageType == "admin") {
        adminButton.currentPage = true;
      }
      newButtonList.push(adminButton);
    }

    if (messagesButton == true) {
      newButtonList.push({text: "Messages", link: "/Messages"});
    }

    newButtonList.push({text: "Sign Out", link: "/SignOut"});

    setButtonList(newButtonList);
  }, [props]);

  const lessonCreditString = props.lessonCreditString;

  return (
    <nav>
      <div className="Navbar">
        <div className="TutorNavBar">
          <NavigationBarTemplate {...props} buttons={buttonList} lessonCreditString={lessonCreditString}/>
        </div>
      </div>
    </nav>
    
  );
}

export default Navbar;