import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, RadioGroupField, SelectField, Text, TextField } from "@aws-amplify/ui-react";
import { useState, useEffect } from 'react';
import { validateField } from "./FormValidation";
import { DayPicker } from "react-day-picker";

const DateTemplate = function(props) {
  try {
    const labelTextProps = props.questionLabelProps || {};
    const errorTextProps = props.questionErrorProps || {};

    //Set question variables from passed properties
    const questionID = props.id;
    const questionLabel = props.label;
    const questionDescription = props.description;
    const required = props.required;
    const requiredAsterisk = props.requiredAsterisk;

    const currentDate = new Date().toDateString();
    const defaultValue = props.defaultValue || currentDate;
    let defaultSelected = "As soon as possible";
    if (new Date(defaultValue).toDateString() != currentDate) {
      defaultSelected = "On/after a future date";
    }
    let defaultDate = new Date (defaultValue);
    if (defaultValue == "blank") {
      defaultDate = null;
    }

    const questionValidations = props.validations;
    let addressValidationExists = false;
    for (let validation of questionValidations) {
      if (validation.type === "ValidDate") {
        addressValidationExists = true;
      }
    }
    
    const answersSuccessfullySubmitted = props.answersSuccessfullySubmitted;

    const extraProps = props.extraProps;
    const onlyDay = props.day || extraProps.day;

    
    //Set state variables used to store the current state and any errors
    const [selectedAnswer, setSelectedAnswer] = useState(defaultSelected);
    const [questionAnswer, setQuestionAnswer] = useState(new Date(defaultDate));
    const [errors, setErrors] = useState({questionError:""});

    useEffect(() => {
      const validationResult = validateField(questionAnswer, questionValidations);
      const questionHasError = validationResult.hasError;
      setErrors({
        questionHasError: questionHasError,
        questionError: validationResult.errorMessage
      });
      props.handleChange(questionID, new Date(questionAnswer).toDateString(), questionHasError);
    }, []);

    let labelColour = labelTextProps.color || "#000000";
    if (props.validationError != null && props.validationError != null) {
      labelColour = props.validationErrorColour || "#ff0000";
    }
    //Get the text for the question label
    const labelText = <Text {...labelTextProps} color={labelColour}>
      {questionLabel}
    </Text>

    const descriptionText = <Text {...labelTextProps} fontSize={"14px"} fontStyle={"italic"}>
      {questionDescription}
    </Text>
    
    let errorText = <Text {...errorTextProps}>
      {errors.questionError}
    </Text>

    let renderedRequiredAsterisk = null;
    if (required == true) {
      renderedRequiredAsterisk = requiredAsterisk;
    }

    
    if (errors.errorMessage === null) {
      setErrors({
        questionError:""
      });
    }



    let datePicker = null;
    let disabledDates = [];
    let onlyDayNum = null;
    if (onlyDay != null) {
      if (onlyDay == "Monday") {
        onlyDayNum = 1;
      }
      else if (onlyDay == "Tuesday") {
        onlyDayNum = 2;
      }
      else if (onlyDay == "Wednesday") {
        onlyDayNum = 3;
      }
      else if (onlyDay == "Thursday") {
        onlyDayNum = 4;
      }
      else if (onlyDay == "Friday") {
        onlyDayNum = 5;
      }
      else if (onlyDay == "Saturday") {
        onlyDayNum = 6;
      }
      else if (onlyDay == "Sunday") {
        onlyDayNum = 0;
      }

      disabledDates.push(
        (date) => {
          const dateDay = date.getDay();
          if (dateDay != onlyDayNum) {
            return true;
          }
          else {
            return false;
          }
        }
      );

      if (questionAnswer != null && questionAnswer.getDay() != onlyDayNum) {
        setQuestionAnswer(null);
      }
    }

    let cellSize = window.innerWidth / 11;
    if (cellSize > 40) {
      cellSize = 40;
    }
    else if (cellSize < 23) {
      cellSize = 23;
    }

    datePicker = <Flex 
      backgroundColor={"#eeeeee50"} 
      borderRadius={"10px"}
    >
      <DayPicker
        key={"dayPicker" + onlyDay}
        disabled={answersSuccessfullySubmitted || disabledDates}
        disableNavigation={answersSuccessfullySubmitted}
        mode="single"
        selected={questionAnswer}
        
        onSelect={(newDate)=> {
          console.log(newDate);
          setQuestionAnswer(newDate);
          const validationResult = validateField(newDate, questionValidations);
          const questionHasError = validationResult.hasError;
          const questionErrorMessage = validationResult.errorMessage;
          setErrors({
            questionHasError:questionHasError, 
            questionError:questionErrorMessage,
          });
          props.handleChange(questionID, newDate, questionHasError);
        }}
        style={{
          "--rdp-cell-size": cellSize + "px",
        }}
        fromDate={new Date()}
        styles={{
            //caption: { color: 'blue' },
            //head: { color: 'red' },
            //button: { color: 'red' },
            //button: {color: 'black', backgroundColor: 'red' },  
        }}
      />
    </Flex>

    //Return text field component to calling function (Most likely a Form Template)
    return (
      <Flex direction={"column"} gap={"1vh"}>
        <Flex direction={"column"} gap={"0.5vh"}>
          <Flex alignItems={"center"}>
            {renderedRequiredAsterisk}
            {labelText}
          </Flex>
          {descriptionText}
          <Flex direction={"column"} alignItems={"center"} gap={"1vh"}> 
            {datePicker}
          </Flex>
        </Flex>
        <Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
          {errorText}
        </Flex>
      </Flex>
    );
  }
  catch (error) {
    throw "StartDateTemplate Error: " + error;
  }
}

export default DateTemplate;