import {React, useState, useEffect } from 'react';
import { Button, Flex, Text, Image, Icon } from '@aws-amplify/ui-react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import techSupportEmail from "../../Images/Emails/techsupport@nextdoortutor.co.uk.png";
import {ReactComponent as TutorSignUpSVG} from "../../Images/TutorSignUp.svg";
import {ReactComponent as StudentSignUpSVG} from "../../Images/StudentSignUp.svg";
import {ReactComponent as ParentSignUpSVG} from "../../Images/ParentSignUp.svg";

import EmailNotVerified from '../EmailNotVerified';
import { LoadingSpinner, LoadingSpinnerPage } from '../LoadingPage';
import { ErrorLoadingPage } from '../ErrorLoadingPage';
import {MdHelp} from 'react-icons/md';
import PopUpTemplate from '../../custom-ui-components/form-components/PopUpTemplate';

import { AppNavButton } from '../../custom-ui-components';

const Home = function(props) {
    const width = props.width;
    const widthThreshold = 900;
    let smallerThanWidthThreshold = false;
    let flexMaxWidth = widthThreshold + "px";
    if (width < widthThreshold) {
        smallerThanWidthThreshold = true;
        flexMaxWidth = "90vw";
    }

    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    const userIsStudent = props.userIsStudent;
    const userIsParent = props.userIsParent
    const userIsTutor = props.userIsTutor;
    const userIsAdmin = props.userIsAdmin;
    const studentLessons = props.studentLessons;
    const studentLessonRequests = props.studentLessonRequests;
    const tutorLessons = props.tutorLessons;
    const tutorTutorRequests = props.tutorTutorRequests;
    const parentLessons = props.parentLessons;
    const parentLessonRequests = props.parentLessonRequests;
    const StyledLink = props.styledLink;
    const [helpPopUp, setHelpPopUp] = useState(false);
    const [redirect, setRedirect] = useState(null);


    //If the user is an admin, redirect to the admin dashboard the first time the page loads
    useEffect(() => {
        if (userIsAdmin != null && userIsAdmin && !props.initialAdminRedirect) {
            props.setInitialAdminRedirect(true);
            setRedirect(<Navigate to={"/Admin"} />);
        }
    }, []);

    if (userIsStudent == null || userIsParent == null || userIsTutor == null || userIsAdmin == null) {
        return <LoadingSpinnerPage {...props} />
    }

    //console.log(props.user.attributes.email_verified);
    if (props.user.attributes.email_verified !== true) {
        return (
          <EmailNotVerified {...props} />
        );
    }

    const lastMouseClick = props.lastMouseClick;

    let imageSize = "20vw"
    let flexDirection = "row";
    let flexGap = "10vw";
    if (width < 500) {
        flexDirection = "column";
        flexGap = "2vh"
        imageSize = "60vw"
    }
    const registerAsStudentButton = <Link to="/StudentGuide" style={{textDecoration: 'none'}} key={"registerAsStudentButton"}>
        <StudentSignUpSVG 
            width={imageSize} 
            height={imageSize}
        />
    </Link>
    const registerAsParentButton = <Link to="/ParentGuide" style={{textDecoration: 'none'}} key={"registerAsParentButton"}>
        <ParentSignUpSVG 
            width={imageSize} 
            height={imageSize}
        />
    </Link>
    const registerAsTutorButton = <Link to="/TutorGuide" style={{textDecoration: 'none'}} key={"registerAsTutorButton"}>
        <TutorSignUpSVG 
            width={imageSize} 
            height={imageSize}
        />
    </Link>

    let signUpFlex = <Flex direction={flexDirection} gap={flexGap}>
        {registerAsTutorButton}
        {registerAsParentButton}
        {registerAsStudentButton}
    </Flex>

    if (width < 500) {
        signUpFlex = <Flex direction={flexDirection} gap={flexGap}>
            {registerAsParentButton}
            {registerAsTutorButton}
            {registerAsStudentButton}
        </Flex>
    }
    
    // const studentRegisterText = <Text {...standardTextProps}>
    //     Register as a student if you are a student looking for tutoring and would like to manage your own lessons
    // </Text>
    // const parentRegisterText = <Text {...standardTextProps}>
    //     Register as a parent if you are a parent looking for tutoring for your child (You can manage multiple children from one parent account)
    // </Text>
    // const tutorRegisterText = <Text {...standardTextProps}>
    //     Register as a tutor if you're looking to tutor students
    // </Text>

    if (!userIsStudent && !userIsTutor && !userIsParent) {
        const welcomeText1 = <Text {...titleTextProps}  >
            Welcome to the Next Door Tutor App!
        </Text>

        const welcomeText2 = <Text {...standardTextProps} >
            From here you will be able manage all your lessons and account details.
        </Text>

        const welcomeText3 = <Text {...standardTextProps} fontSize={"32px"}>
            Sign up as one of the following:
        </Text>

        const helpText = <Text {...standardTextProps} fontSize="20px">
            If you are having any issues with registering (or with the app in general), please contact:
        </Text>

        let techSupportEmailImage = <Image src={techSupportEmail} width={"25vw"}/>
        if (width < 900) {
            techSupportEmailImage = <Image src={techSupportEmail} width={"80vw"}/>
        }

        return (
            <div className='HomePage'>
                <Flex 
                    direction={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    gap={"6vh"}
                >
                    {welcomeText1}
                    {/* {welcomeText2} */}
                    {welcomeText3}
                    {signUpFlex}
                    
                    {/* <Flex direction={"column"} alignItems={"center"} backgroundColor={"#edc328"} borderRadius={"2vh"} padding={"2vh"} maxWidth={flexMaxWidth}>
                        {parentRegisterText}
                        {registerAsParentButton}
                    </Flex>
                    <Flex direction={"column"} alignItems={"center"} backgroundColor={"#94ed28"} borderRadius={"2vh"} padding={"2vh"} maxWidth={flexMaxWidth}>
                        {studentRegisterText}
                        {registerAsStudentButton}
                    </Flex>
                    <Flex direction={"column"} alignItems={"center"} backgroundColor={"#28edb5"} borderRadius={"2vh"} padding={"2vh"} maxWidth={flexMaxWidth}>
                        {tutorRegisterText}
                        {registerAsTutorButton}
                    </Flex> */}
                    <Flex direction={"column"} gap={"10px"} alignItems={"center"}>
                        {helpText}
                        {techSupportEmailImage}
                    </Flex>
                    <br />
                </Flex>
            </div>    
        );
    }

    const welcomeText = <Text {...titleTextProps} fontSize={"40px"}>
        Home
    </Text>

    const studentHome = [];
    if (!userIsStudent && !userIsParent) {
        studentHome.push(registerAsStudentButton);
    }
    const tutorHome = [];
    if (!userIsTutor) {
        tutorHome.push(registerAsTutorButton);
    }
    const parentHome = [];
    if (!userIsStudent && !userIsParent) {
        parentHome.push(registerAsParentButton);
    }

    if (userIsStudent) {
        studentHome.push(<Text {...titleTextProps} key={"StudentHomeTitleText"}>
            You are registered as a student:
        </Text>);
        if (studentLessons == null || studentLessonRequests == null) {
            studentHome.push(<LoadingSpinner key={"studentLoadingSpinner"} />);
        }
        else if (studentLessons == "loading" || studentLessonRequests == "loading") {
            studentHome.push(<LoadingSpinner key={"studentLoadingSpinner"} />);
        }
        else if (studentLessons == "error" || studentLessonRequests == "error") {
            return <ErrorLoadingPage {...props}/>
        }
        else {
            const assignedLessons = studentLessons.length;

            let numRequests = 0;
            for (const request of studentLessonRequests) {
                if (request.status != 3 && request.status != 10) {
                    numRequests = numRequests + 1;
                }
            }
            let lessonsPlural = "";
            if (assignedLessons > 1 || assignedLessons == 0) {
                lessonsPlural = "s";
            }
            let requestsPlural = "";
            if (numRequests > 1 || numRequests == 0) {
                requestsPlural = "s";
            }
            
            studentHome.push(<Text {...standardTextProps} key={"StudentHomeNumLessonsText"}>
                You currently have {<StyledLink to={"/Student/MyLessons"} >{assignedLessons} lesson{lessonsPlural}</StyledLink>} per week
            </Text>);

            studentHome.push(<Text {...standardTextProps} key={"StudentHomeNumLessonRequestsText"}>
                You have made {<StyledLink to={"/Student/LessonRequests"} >{numRequests} lesson request{requestsPlural}</StyledLink>}
            </Text>);
        }
        studentHome.push(<Text {...standardTextProps} key={"StudentHomeDashboardText"}> 
            Access the 'Student Dashboard' from the top menu
        </Text>);
    }

    if (userIsParent) {
        parentHome.push(<Text {...titleTextProps} key={"ParentHomeTitleText" + parentHome.length}>
            You are registered as a parent:
        </Text>);

        if (parentLessons == null || parentLessonRequests == null) {
            parentHome.push(<LoadingSpinner key={"parentLoadingSpinner"} />);
        }
        else {
            const assignedLessons = parentLessons.length;
            let numRequests = 0;
            for (const request of parentLessonRequests) {
                if (request.status != 3 && request.status != 10) {
                    numRequests = numRequests + 1;
                }
            }

            let lessonsPlural = "";
            if (assignedLessons > 1 || assignedLessons == 0) {
                lessonsPlural = "s";
            }
            let requestsPlural = "";
            if (numRequests > 1 || numRequests == 0) {
                requestsPlural = "s";
            }
            parentHome.push(<Text {...standardTextProps} key={"ParentHomeNumLessonText"}>
                Your children currently have {<StyledLink to={"/Parent/MyLessons"} >{assignedLessons} lesson{lessonsPlural}</StyledLink>} per week
            </Text>);

            parentHome.push(<Text {...standardTextProps} key={"ParentHomeNumLessonRequestsText"}>
                You have made {<StyledLink to={"/Parent/LessonRequests"} >{numRequests} lesson request{requestsPlural}</StyledLink>}
            </Text>);
        }
        parentHome.push(<Text {...standardTextProps} key={"ParentHomeDashboardText"}> 
            Access the 'Parent Dashboard' from the top menu
        </Text>);   
    }

    if (userIsTutor) {
        tutorHome.push(<Text {...titleTextProps} key={"TutorHomeTitleText"}>
            You are registered as a tutor:
        </Text>);

        if (tutorLessons == null || tutorTutorRequests == null) {
            tutorHome.push(<LoadingSpinner key={"tutorLoadingSpinner"} />);
        }
        else if (tutorLessons == "loading" || tutorTutorRequests == "loading") {
            tutorHome.push(<LoadingSpinner key={"tutorLoadingSpinner"} />);
        }
        else if (tutorLessons == "error" || tutorTutorRequests == "error") {
            return <ErrorLoadingPage {...props}/>
        }
        else {
            const assignedLessons = tutorLessons.length;
            const numTutorRequests = tutorTutorRequests.length;
            let lessonsPlural = "";
            if (assignedLessons > 1 || assignedLessons == 0) {
                lessonsPlural = "s";
            }
            let requestsPlural = "";
            if (numTutorRequests > 1 || numTutorRequests == 0) {
                requestsPlural = "s";
            }
            let currentLessonsText = <Text {...standardTextProps} key={"TutorHomeNumLessonsText"}>
                You are currently teaching {<StyledLink to={"/Tutor/MyLessons"}>{assignedLessons} lesson{lessonsPlural}</StyledLink>} per week
            </Text>
            if (assignedLessons == 0) {
                currentLessonsText = <Text {...standardTextProps} key={"TutorHomeNumLessonsText"}>
                You are currently teaching 0 lessons per week
            </Text>
            }
            tutorHome.push(currentLessonsText);

            let lessonRequestsText = <Text {...standardTextProps} key={"TutorHomeNumPotentialLessons"}>
                You have {<StyledLink to={"/Tutor/LessonRequests"}>{numTutorRequests} potential lesson request{requestsPlural}</StyledLink>} to accept
            </Text>
            if (numTutorRequests == 0) {
                lessonRequestsText = <Text {...standardTextProps} key={"TutorHomeNumPotentialLessons"}>
                    You have no new potential lesson requests to accept
                </Text>
            }
            tutorHome.push(lessonRequestsText);
        }

        tutorHome.push(<Text {...standardTextProps} key={"TutorHomeDashboardText" + tutorHome.length}>
            Access the 'Tutor Dashboard' from the top menu
        </Text>);
    }

    let studentFlex = <Button 
        backgroundColor={"#94ed28"} 
        borderRadius={"2vh"} 
        style={{cursor: "pointer"}}
        onClick={() => {
            setRedirect(<Navigate to={"/Student"} />)
        }}
    >
        <Flex 
            direction={"column"} 
            alignItems={"center"} 
            padding={"2vh"} 
            maxWidth={flexMaxWidth}
        >
            {studentHome}
        </Flex>
    </Button>

    let parentFlex = <Button 
        backgroundColor={"#94ed28"} 
        borderRadius={"2vh"} 
        style={{cursor: "pointer"}}
        onClick={() => {
            setRedirect(<Navigate to={"/Parent"} />)
        }}
    >
        <Flex 
            direction={"column"} 
            alignItems={"center"} 
            padding={"2vh"} 
            maxWidth={flexMaxWidth}
        >
            {parentHome}
        </Flex>
    </Button>

    let tutorFlex = <Button 
        backgroundColor={"#28edb5"} 
        borderRadius={"2vh"} 
        style={{cursor: "pointer"}}
        onClick={() => {
            setRedirect(<Navigate to={"/Tutor"} />)
        }}
    >
        <Flex 
            direction={"column"} 
            alignItems={"center"} 
            padding={"2vh"} 
            maxWidth={flexMaxWidth}
        >
            {tutorHome}
        </Flex>
    </Button>

    //Remove flexes if user is not a part of that group and is part of another group
    //Show only button (no flex) if user is not part of any group
    if (!userIsStudent) {
        if (userIsParent || userIsTutor) {
            studentFlex = null;
        }
        else {
            studentFlex = studentHome;
        }
    }
    if (!userIsParent) {
        if (userIsStudent || userIsTutor) {
            parentFlex = null;
        }
        else {
            parentFlex = parentHome;
        }
    }
    if (!userIsTutor) {
        if (userIsParent || userIsStudent) {
            tutorFlex = null;
        }
        else {
            tutorFlex = tutorHome;
        }
    }


    // const helpButton = <Flex 
    //     position={"fixed"} 
    //     width={"70px"}
    //     height={"70px"}
    //     borderRadius={"50px"}
    //     alignItems={"center"}
    //     justifyContent={"center"}
    //     right={"40px"}
    //     bottom={"40px"}
    //     style={{
    //         cursor: "pointer"
    //     }}
    // >
        
    // </Flex>

    const helpButton = <Icon
        key={"helpButton"}
        width={"7vh"}
        height={"7vh"}
        as={MdHelp}
        color={"#ffd152"}
        borderRadius={"7vh"}
        backgroundColor={"#4287f5"}
        style={{
            cursor: "pointer"
        }}
        position={"fixed"} 
        right={"3vw"}
        bottom={"3vh"}
        onClick={() => {
            setHelpPopUp(true);
        }}
    />

    const helpPopUpText = "This is the home page of the app. From here you can register as a student, parent or tutor. It will show what you're already registered as, along with some basic info.";

    

    const lessonCreditsFunction = function () {
        setRedirect(<Navigate to={"/LessonCredits"} />)
    };

    let lessonCreditsButton = null;
    if (userIsParent || userIsStudent) {
        lessonCreditsButton = <AppNavButton width={props.width} text={"Manage lesson credit"} onClickFunction={lessonCreditsFunction} />
    }

    return (
        <div className='HomePage'>
            {redirect}
            <Flex 
                direction={"column"}
                alignItems={"center"}
                gap={"5vh"}
            >
                {helpButton}
                {welcomeText}
                {lessonCreditsButton}
                {tutorFlex}
                {parentFlex}
                {studentFlex}
                {helpPopUp && <PopUpTemplate text={helpPopUpText} setPopUpVisibility={setHelpPopUp} okButtonFunction={function(){}} showXButton={true} />}
                
            </Flex>
        </div>    
    );

    
}

export default Home;
