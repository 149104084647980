import {React, useState } from 'react';
import { LoadingSpinner, LoadingSpinnerPage } from '../LoadingPage';
import { Button, Flex, Text } from '@aws-amplify/ui-react';
import LessonRequestTemplate from '../../custom-ui-components/LessonRequestTemplate';
import PopUpTemplate from '../../custom-ui-components/form-components/PopUpTemplate';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { ErrorLoadingPage } from '../ErrorLoadingPage';
import { AppNavButton } from '../../custom-ui-components';

const TutorLessonRequests = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    //Get the current user to pass to subsequent functions
    const user = props.user;
    const tutorRequests = props.tutorTutorRequests;    

    const location = useLocation();
    const state = location.state;

    const [redirect, setRedirect] = useState(null);

    if (tutorRequests == "error") {
        return <ErrorLoadingPage {...props}/>
    }

    let lessonRequestsUI = null;
    if (tutorRequests != null && tutorRequests != "loading") {
        lessonRequestsUI = [];
        for (const request of tutorRequests) {
            const requestUI = <LessonRequestTemplate key={request.id} userType={"tutor"} requestID={request.id} {...props} />
            lessonRequestsUI.push(requestUI);
        }
    }
    else {
        lessonRequestsUI = <LoadingSpinner />
    }

    const titleText = <Text {...titleTextProps}>All tutor lesson requests</Text>

    const backButtonFunction = function () {
        let redirectLink = "/Tutor";
        if (state != null && state.back != null) {
            redirectLink = state.back;
        }
        setRedirect(<Navigate to={redirectLink} />);
    };
    const backButton = <AppNavButton text={"Back"} onClickFunction={backButtonFunction} />

    let requestGap = "5vh";
    if (props.width < 830) {
        requestGap = "7vh";
    }

    
    if (tutorRequests != null) {
        if (tutorRequests.length == 0) {
            lessonRequestsUI = [];
            lessonRequestsUI.push(<Text {...standardTextProps} key={"NoRequestsText"}>You don't have any outstanding lesson requests</Text>);
        }
    }

    return (
        <div className='TutorLessonRequestsPage'>
            <Flex direction={"column"} alignItems={"center"} gap={"4vh"}>
                {titleText}
                {backButton}
                {redirect}
                <Flex direction={"column"} alignItems={"center"} gap={requestGap}>
                    {lessonRequestsUI}
                </Flex>
            </Flex>
        </div>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default TutorLessonRequests;