import { Flex, Text } from '@aws-amplify/ui-react';
import React from 'react';
import {Link, Navigate, useLocation} from "react-router-dom";
import {AppNavButton, FormTemplate} from '../../custom-ui-components'
import { useState} from 'react';
import * as Auth from 'aws-amplify/auth';

const TutorNeedsAttributes = function(props) {
    const [questionAnswers, setQuestionAnswers] = useState({});
    const [redirect, setRedirect] = useState(null);
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    const APIFunctions = props.APIFunctions

    const width = props.width;

    //Get the state props passed from the tutor home function
    const location = useLocation();
    const extraProps = location.state;
    if (extraProps == null) {
        return <Navigate to={"/Tutor"} />
    }
    const neededAttributes = extraProps.tutorNeededAttributes;
    if (neededAttributes == null || neededAttributes.length == 0) {
        return <Navigate to={"/Tutor"} />
    }

    //Get the current user to pass to subsequent functions
    const user = props.user;
    const userAttributes = user.attributes || {};

    let displayUsername = userAttributes.email;
    if (userAttributes.name != null) {
        displayUsername = userAttributes.name;
    }

    //Check which attributes are needed
    let needsFirstNames = false;
    let needsLastName = false;
    let needsPhoneNumber = false;
    // let needsSubjects = false;
    let needsAvailability = false;
    let needsNumSessionsWanted = false;
    let needsOtherJobs = false;
    let needsPreviousExperience = false;
    for (let neededAttributeIndex = 0; neededAttributeIndex < neededAttributes.length; neededAttributeIndex = neededAttributeIndex + 1) {
        const neededAttribute = neededAttributes[neededAttributeIndex];
        if (neededAttribute === "firstNames") {
            needsFirstNames = true;
        }
        if (neededAttribute === "lastName") {
            needsLastName = true;
        }
        else if (neededAttribute === "phoneNumber") {
            needsPhoneNumber = true;
        }
        // else if (neededAttribute === "subjects") {
        //     needsSubjects = true;
        // }
        else if (neededAttribute === "availability") {
            needsAvailability = true;
        }
        else if (neededAttribute === "numSessionsWanted") {
            needsNumSessionsWanted = true;
        }
        else if (neededAttribute === "otherJobs") {
            needsOtherJobs = true;
        }
        else if (neededAttribute === "previousExperience") {
            needsPreviousExperience = true;
        }
    }

    //Create an object for each needed question that is passed to the form template
    let questions = [];

    if (needsFirstNames) {
        questions.push({
            id: "firstNames", 
            label: "First Name(s):", 
            type: "text", 
            required: true, 
            validations: [{type: "Name"}], 
            defaultValue: null, 
            placeholder: "Enter name"
        });
    }
    if (needsLastName) {
        questions.push({
            id: "lastName", 
            label: "Last Name:", 
            type: "text", 
            required: true, 
            validations: [{type: "Name"}], 
            defaultValue: null, 
            placeholder: "Enter name"
        });
    }

    if (needsPhoneNumber) {
        questions.push({
            id: "phoneNumber", 
            label: "Contact Number:", 
            type: "text", 
            required: true, 
            validations: [{type: "UKMobPhone"}], 
            defaultValue: null, 
            placeholder: "Enter phone number"
        });
    }

    // if (needsSubjects) {
    //     questions.push({
    //         id: "subjects", 
    //         label: "Subject(s) you wish to teach:",
    //         description: "(You can add or remove any in the future)",
    //         type: "tutorsubjects", 
    //         required: false, 
    //         validations: [{type: "ValidTutorSubjects"}], 
    //         defaultValue: null, 
    //     });
    // }

    if (needsNumSessionsWanted) {
        questions.push({
            id: "numSessionsWanted", 
            label: "How many sessions would you ideally like to be tutoring per week?",
            description: "(You can change this later)",
            type: "dropdown", 
            options: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10+"],
            required: true, 
            validations: [{type: "ValidNumSessions"}], 
            defaultValue: null, 
        });
    }

    if (needsAvailability) {
        questions.push({
            id: "availability", 
            label: "Weekly availability:",
            description: "(You will be able to update this at any point)",
            type: "weekavailability", 
            required: false, 
            validations: [{type: "ValidWeekAvailability"}], 
            defaultValue: null, 
        });
    }

    if (needsPreviousExperience) {
        questions.push({
            id: "previousExperience", 
            label: "Do you have any previous tutoring experience?", 
            type: "radiothendetails", 
            options: ["Yes", "No"],
            required: true, 
            validations: [], 
            defaultValue: null, 
            placeholder: "Tell us about your experience"
        });
    }

    if (needsOtherJobs) {
        questions.push({
            id: "otherJobs", 
            label: "Do you have any other part time job(s)?",
            type: "radiothendetails", 
            required: true, 
            validations: [], 
            defaultValue: null, 
        });
    }
    
    const submitAnswers = async function(returnedAnswers) {
        const user = await Auth.getCurrentUser();

        setQuestionAnswers(returnedAnswers);
        console.log("Returned Answers");
        console.log(returnedAnswers);
        const newTutorModel = {...returnedAnswers};
        console.log(user);
        newTutorModel.email = userAttributes.email;
        const APIReturn = await APIFunctions.updateTutorModel(newTutorModel);
        console.log(APIReturn);
    }
    
    const exitForm = function () {
        console.log("Redirecting to /Tutor");
        setRedirect(<Navigate to={"/Tutor"} />)
    }

    const dashboardText1 = <Text
        color="#08007d"
        fontWeight={"bold"}
        fontSize="30px"
        textAlign={"center"}
        marginBottom={"2vh"}
    >
      Welcome to the Tutor Dashboard
    </Text>

    const dashboardText2 = <Text
        color="#08007d"
        fontWeight={"normal"}
        fontSize="20px"
        textAlign={"center"}
        marginLeft="20vw"
        marginRight="20vw"
    >
        Before you can access the full dashboard, you'll need to fill in some extra details
    </Text>

    const HomeButton = <Link to="/" style={{textDecoration: 'none'}}> 
        <AppNavButton text={"Home"} width={width} />
    </Link>

    const signOutButton = <Link to="/SignOut" style={{textDecoration: 'none'}}> 
        <AppNavButton text={"Sign Out"} width={width} />
    </Link>

    return (  
        <Flex direction={"column"} justifyContent="center" alignItems="center" gap="1vh" wrap={"wrap"}>
            {dashboardText1}
            <Flex justifyContent={"center"} gap={"5vw"} marginBottom={"2vh"}>
                {HomeButton}
                {signOutButton}
            </Flex>
            {dashboardText2}
            <FormTemplate {...props} submitAnswers={submitAnswers} existingAnswers={questionAnswers} questions={questions} okButtonFunction={exitForm} reloadAfter={false} />
            {redirect}
        </Flex>
    );
}


//Return the correct page back up to the app and index files (with authenticator to force login)
export default TutorNeedsAttributes;