import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, CheckboxField, Text } from "@aws-amplify/ui-react";
import { useState, useEffect } from 'react';
import { validateField } from "./FormValidation";
import { Link } from "react-router-dom";

const CheckBoxesTemplate = function(props) {
  try {
    const labelTextProps = {...props.questionLabelProps};
    const checkboxTextProps = {...props.checkboxTextProps};
    const errorTextProps = {...props.questionErrorProps};

    //Set question variables from passed properties
    const questionID = props.id;
    const questionLabel = props.label;
    const questionDescription = props.description;
    const required = props.required;
    const optionsLinks = props.optionLinks || {};
    let requiredAsterisk = props.requiredAsterisk;
    let options = props.options
    let defaultValue = props.defaultValue;
    let questionValidations = props.validations;
    if (questionValidations == null) {
      questionValidations = [];
    }
    let flexDirection = props.direction;
    if (flexDirection == null) {
      flexDirection = "column";
    }
    const answersSuccessfullySubmitted = props.answersSuccessfullySubmitted
    
    if (options == null) {
      options = [];
    }

    if (defaultValue == null) {
      defaultValue = {};
      for (const option of options) {
        defaultValue[option] = false;
      }
    }
    else {
      for (const option of options) {
        if (defaultValue[option] == null) {
          defaultValue[option] = false;
        }
      }
    }


    //Set state variables used to store the current state and any errors
    const [selectedAnswers, setSelectedAnswers] = useState(defaultValue);
    const [errors, setErrors] = useState({questionError:""});

    const [forceUpdate, setForceUpdate] = useState(0);

    useEffect(() => {
      const validationResult = validateField(selectedAnswers, questionValidations);
      const questionHasError = validationResult.hasError;
      props.handleChange(questionID, selectedAnswers, questionHasError);
      if (props.showErrorImmediately == true) {
        setErrors({
          questionHasError: questionHasError,
          questionError: validationResult.errorMessage
        });
      }
    }, []);

    let labelColour = labelTextProps.color || "#000000";
    if (props.validationError != null && props.validationError != null) {
      labelColour = props.validationErrorColour || "#ff0000";
    }
    //Get the text for the question label
    let labelText = null;
    if (questionLabel != null) {
      labelText = <Text {...labelTextProps} color={labelColour}>
        {questionLabel}
      </Text>
    }

    let descriptionText = null;
    if (questionDescription != null) {
      descriptionText = <Text {...labelTextProps} fontSize={"14px"} fontStyle={"italic"}>
        {questionDescription}
      </Text>
    }
    
    let errorText = null;
    if (errors.questionError != null && errors.questionError != "") {
      errorText = <Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
        <Text {...errorTextProps}>
          {errors.questionError}
        </Text>
      </Flex>
    }

    if (errors.questionError == "" && flexDirection == "row") {
      errorText = null;
    }

    if (requiredAsterisk == null) {
      requiredAsterisk = <Text {...labelTextProps} color={"#ff0000"}>*</Text>;
    }
    let renderedRequiredAsterisk = null;
    if (required == true) {
      renderedRequiredAsterisk = requiredAsterisk;
    }
    
    if (errors.errorMessage === null) {
      setErrors({
        questionError:""
      });
    }

    //Generate the checkbox field components
    const checkBoxComponents = []
    for (let optionIndex = 0; optionIndex < options.length; optionIndex = optionIndex + 1) {
      const option = options[optionIndex]
      let firstCheckbox = false;
      let lastCheckbox = false;
      // if (optionIndex == 0) {
      //   firstCheckbox = true;
      // }
      // else if (optionIndex == (options.length - 1)) {
      //   lastCheckbox = true;
      // }
      
      let optionText = null;
      if (optionsLinks[optionIndex] == null) {
        optionText = <Text {...checkboxTextProps} maxWidth={"400px"}>
          {option}
        </Text>
      }
      else {
        const optionLinks = optionsLinks[optionIndex];
        
        let optionStrings = [];
        let optionTotalString = "";
        for (const optionLink of optionLinks) {
          const link = optionLink.link;
          const startIndex = optionLink.startCharIndex;
          const endIndex = optionLink.endCharIndex;

          if (startIndex == null || endIndex == null || endIndex < startIndex || endIndex >= option.length || startIndex <= optionTotalString.length) {
            optionText = <Text {...checkboxTextProps} maxWidth={"400px"}>
              {option}
            </Text>
            break;
          }

          optionStrings.push(option.substring(optionTotalString.length, startIndex));
          optionStrings.push(<Link to={link} target="_blank" key={"link:" + link}>{option.substring(startIndex, endIndex + 1)}</Link>);
          optionTotalString = optionTotalString + option.substring(optionTotalString.length, startIndex) + option.substring(startIndex, endIndex + 1);
        }
        optionStrings.push(option.substring(optionTotalString.length, option.length));
        optionText = <Text {...checkboxTextProps} maxWidth={"400px"}>
          {optionStrings}
        </Text>
      }

      const checkBoxField = <CheckboxField
        label={optionText}
        key={option + "Checkbox"}
        checked={selectedAnswers[option]}
        value={option}
        size={"medium"}
        hasError={lastCheckbox}
        errorMessage={errors.questionError}
        disabled={answersSuccessfullySubmitted}
        inputStyles={{
          //color:"#ff0000",
          //backgroundColor:"#ff0000"
          
        }}
        fontStyle={{
          textColor:"#ff0000"
        }}

        //Runs when it detects a change in the input field
        onChange={(e) => {
          const currentValue = e.target.checked;
          const newAnswers = selectedAnswers;
          newAnswers[option] = currentValue;
          setSelectedAnswers(newAnswers);
          setForceUpdate(forceUpdate + 1);

          const validationResult = validateField(newAnswers, questionValidations);
          const questionHasError = validationResult.hasError;
          const questionErrorMessage = validationResult.errorMessage;
          props.handleChange(questionID, selectedAnswers, questionHasError);

          setErrors({
            questionHasError:questionHasError, 
            questionError:questionErrorMessage
          });
        }} 
      >
      </CheckboxField>
      checkBoxComponents.push(checkBoxField);
    }

    let alignItems = null;
    let alignCheckBoxes = "start";
    if (flexDirection == "row") {
      alignItems="center";
      alignCheckBoxes = "center";
    }

    //Return text field component to calling function (Most likely a Form Template)
    return (
      <Flex direction={flexDirection} gap={"1vh"} alignItems={alignItems}>
        <Flex alignItems={"center"} marginRight={"0vw"}>
          {renderedRequiredAsterisk}
          {labelText}
        </Flex>
        {descriptionText}
        <Flex direction={"column"} alignItems={"center"} gap={"3vh"}>
          <Flex direction={flexDirection} alignItems={alignCheckBoxes}>
            {checkBoxComponents}
          </Flex>
          {errorText}
        </Flex>
      </Flex>
    );
  }
  catch (error) {
    throw "CheckBoxesTemplate Error: " + error;
  }
}

export default CheckBoxesTemplate;