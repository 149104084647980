import { Flex, Text } from '@aws-amplify/ui-react';
import React, { useState } from 'react';
import { AppNavButton, FormTemplate } from '../../custom-ui-components';
import { Navigate, useLocation } from 'react-router-dom';
import { compareObjects } from '../../App';
import { LoadingSpinnerPage } from '../LoadingPage';
import { ErrorLoadingPage } from '../ErrorLoadingPage';

const TutorChangeAvailability = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    //Get the current user to pass to subsequent functions
    const user = props.user;
    const tutorModel = props.tutorModel;
    const APIFunctions = props.APIFunctions;

    const location = useLocation();
    const state = location.state;

    const [redirect, setRedirect] = useState(null);

    if (tutorModel == null) {
        return <LoadingSpinnerPage />
    }
    if (tutorModel == "error") {
        return <ErrorLoadingPage />
    }

    const titleText = <Text {...titleTextProps}>Change Availability</Text> 
    const descriptionText = <Text {...standardTextProps}>Use this page to add/remove your availability for teaching</Text> 

    const questions = [];
    questions.push({
        id: "availability", 
        label: "Weekly availability:",
        type: "weekavailability", 
        required: true, 
        validations: [{type: "ValidWeekAvailability"}], 
        defaultValue: tutorModel.availability, 
    });
    
    const exitForm = function () {
        let redirectLink = "/Tutor";
        if (state != null && state.back != null) {
            redirectLink = state.back;
        }
        setRedirect(<Navigate to={redirectLink} />);
    };

    const submitAnswers = async function(returnedAnswers) {
        try {
            console.log(returnedAnswers);
            const newAvailability = returnedAnswers.availability;
            const anyChange = !compareObjects(newAvailability, tutorModel.availability);
            if (anyChange == false) {
                throw "No changes made";
            }
            const newTutorModel = await APIFunctions.updateTutorModel({availability: newAvailability});
            console.log(newTutorModel);
        }
        catch (error) {
            if (error == "No changes made") {
                throw "No change to availability";
            }
        }
    };

    const availabilityForm = <FormTemplate {...props} submitAnswers={submitAnswers} questions={questions} okButtonFunction={exitForm} submitPopUpText={"Successfully updated availability"} />

    const backButton = <AppNavButton text={"Back"} onClickFunction={exitForm} width={props.width} />

    return (
        <div className='TutorChangeAvailabilityPage'>
            <Flex direction={"column"} alignItems={"center"}>
                {redirect}
                {titleText}
                {descriptionText}
                <Flex>
                    {backButton}
                </Flex>
                {availabilityForm}
            </Flex>
        </div>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default TutorChangeAvailability;